import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import { Authenticator } from '../../../common/components/Auth';
import { config } from '../../../../config';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(2)
    },
    iframe: {
        border: 0
    },
    loadingProgress: {
        marginLeft: '46%'
    }
});

class MetabaseFrameComponent extends Component {

    constructor(props) {
        super(props);
        this.classes = this.props.classes;

        this.state = {
            url: null,
            isLoading: true
        };

        this.onLoad = this.onLoad.bind(this);
    }

    componentDidMount() {
        this.getMetabaseUrl();
    }

    getMetabaseUrl() {
        return Authenticator
            .secureFetch(config.api + '/dashboard/metabase/' + this.props.id, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(data => {
                this.setState({ url: data.url });
            });
    }

    render() {
        return (
            <div className={this.classes.root}>
                {this.state.isLoading && <CircularProgress size={24} className={this.classes.loadingProgress} />}
                {this.state.url !== null && (
                    <iframe
                        className={classNames(this.classes.iframe, this.props.className)}
                        title={this.props.title}
                        src={this.state.url + '#bordered=true&titled=true'}
                        onLoad={this.onLoad}
                    />
                )}
            </div>
        );
    }

    onLoad() {
        this.setState({
            isLoading: false
        });
    }
}

MetabaseFrameComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export const MetabaseFrame = withStyles(styles)(MetabaseFrameComponent);
