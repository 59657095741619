import { FilterQuery, FilterKey, FilterValue } from '../../../common/helpers/Query';

export class StreamFilterQuery extends FilterQuery {

    constructor() {
        super([
            new FilterKey('times', 'Zeit', [
                new FilterValue('Heute'),
                new FilterValue('Gestern'),
                new FilterValue('Vorgestern')
            ]),
            new FilterKey('departments', 'Ressort', [
                new FilterValue('News'),
                new FilterValue('Politik'),
                new FilterValue('Geld'),
                new FilterValue('Unterhaltung'),
                new FilterValue('Sport'),
                new FilterValue('1. Bundesliga', 'Bundesliga', 'channels'),
                new FilterValue('Lifestyle', 'Lifestyle', 'channels'),
                new FilterValue('Ratgeber', 'Ratgeber', 'channels'),
                new FilterValue('Reisen', 'Reise', 'channels'),
                new FilterValue('Auto'),
                new FilterValue('Digital', 'Digital', 'channels'),
                new FilterValue('Spiele-News', 'Spiele', 'channels'),
                new FilterValue('Video unsortiert', 'Video'),
                // crawler saves region_details with special characters replaced
                // ö=oe, ü=ue, ä=ae
                // https://github.com/spring-media/CI-hud-backend/blob/66ae4129e7d4da8e7abf1c8f9f6b7568d4e50162/src/importer/article/ArticleImporter.js#L140
                new FilterValue('Hamburg', 'Hamburg', 'channels'),
                new FilterValue('Berlin', 'Berlin', 'channels'),
                new FilterValue('München', 'München', 'channels'),
                new FilterValue('Sachsen-Anhalt', 'Sachsen-Anhalt', 'channels'),
                new FilterValue('Saarland', 'Saarland', 'channels'),
                new FilterValue('Ruhrgebiet', 'Ruhrgebiet', 'channels'),
                new FilterValue('Nürnberg', 'Nürnberg', 'channels'),
                new FilterValue('Leipzig', 'Leipzig', 'channels'),
                new FilterValue('Köln', 'Köln', 'channels'),
                new FilterValue('Hannover', 'Hannover', 'channels'),
                new FilterValue('Frankfurt', 'Frankfurt', 'channels'),
                new FilterValue('Düsseldorf', 'Düsseldorf', 'channels'),
                new FilterValue('Dresden', 'Dresden', 'channels'),
                new FilterValue('Chemnitz', 'Chemnitz', 'channels'),
                new FilterValue('Bremen', 'Bremen', 'channels'),
                new FilterValue('Stuttgart', 'Stuttgart', 'channels'),
                new FilterValue('Thüringen', 'Thüringen', 'channels')
            ]),
            new FilterKey('types', 'Typ', [
                new FilterValue('bildPlus', 'BILD Plus'),
                new FilterValue('deskDelta', 'Desk-Delta')
            ]),
            new FilterKey('home', 'Home', [
                new FilterValue('desktop', 'Desktop', 'platforms.home'),
                new FilterValue('mobile', 'Mobile', 'platforms.home')
            ]),
            new FilterKey('facebook', 'Facebook', [
                new FilterValue('planned', 'geplant', 'platforms.facebook'),
                new FilterValue('live', 'gepostet', 'platforms.facebook'),
                new FilterValue('bildPage', 'Hauptaccount', 'platforms.facebook'),
                new FilterValue('sportPage', 'Sport', 'platforms.facebook')
            ])
        ]);
    }
}
