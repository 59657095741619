import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { ColorMapping } from '../../configs';
import { ColumnType } from '../JetTable';
import { observer } from 'mobx-react';
import { FilterStoreContext, Filter } from '../../helpers/filter';
import { action } from 'mobx';
import { Article, fixUrl } from '../../../common/helpers/Utils';
import { BODY_2, CAPTION } from '../../../common/configs/scaleCategory';
import { ThemedEcharts } from '../../../../echarts';

const BAR_CHART_LABEL_MIN_DEFAULT = 8000;
const MAX_TITLE_LENGTH = 36;
const PREMIUM_ICON = ' ✚'; // utf-8 symbol &#10010; HEAVY GREEK CROSS
const VIDEO_ICON = ' ▶'; // utf-8 symbol &#9654; BLACK RIGHT-POINTING TRIANGLE

const styles = () => createStyles({
    wrapper: {
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        textAlign: 'left'
    },
    chart: {
        minWidth: '800px',
        width: '100%',
        flex: '0 0 auto'
    }
});

const postProcessLabel = (title, relatedData) => {
    const article = new Article(relatedData);
    if (!title) {
        return '';
    }
    let result = `${title.slice(0, MAX_TITLE_LENGTH)}${title.length > MAX_TITLE_LENGTH ? '...' : ''}`;
    if (article.isPremium()) {
        result += PREMIUM_ICON;
    }
    if (article.isVideo()) {
        result += VIDEO_ICON;
    }
    // multiple line breaks would break view (every second row would have a missing value)
    // => replace line breaks with whitespaces
    return result.split('\n').join(' ');
};

export const JetBarChart = withStyles(styles)(observer(({ classes, columns, rowsData, excludes = [] }) => {

    const filterStore = useContext(FilterStoreContext);

    const numericColumns = columns
        .map((column, index) => ({ index, column }))
        .filter(({ column: { type, label } }) => type === ColumnType.NUMERIC && !excludes.includes(label));

    useEffect(action(() => {
        const columnLabels = numericColumns.map(({ column: { label = '' } }) => label);
        filterStore.useFilter(Filter.CHART_REFERRER, columnLabels);
        return action(() => {
            filterStore.unuseFilter(Filter.CHART_REFERRER, columnLabels);
        });
    }), [numericColumns]);

    const labels = rowsData.map(([{ data: title, relatedData }]) => postProcessLabel(title, relatedData));

    const barChartLabelMin = Object.values(filterStore.chartReferrer).filter(Boolean).length <= 2 ? 0 : BAR_CHART_LABEL_MIN_DEFAULT;
    const formatLabels = (params) => {
        if (params.value < barChartLabelMin) {
            return '';
        }
        if (params.value > 1000) {
            return `${Math.round(params.value / 1000)}k`;
        }
        return params.value;
    };

    const barChartOptions = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            textStyle: { ...CAPTION }
        },
        grid: {
            top: '1%',
            left: '1%',
            right: '1%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            axisLabel: { ...CAPTION, color: 'rgba(0, 0, 0, 0.8)' }
        },
        yAxis: {
            type: 'category',
            data: labels,
            axisLabel: { ...BODY_2, lineHeight: 12 },
            inverse: true,
            triggerEvent: true
        },
        series: numericColumns.map(({ index, column: { label = '' } }) => {
            if (!filterStore.isVisible(Filter.CHART_REFERRER, label)) {
                return { type: 'bar', data: [], stack: '0' };
            }
            return {
                name: label,
                type: 'bar',
                stack: '0',
                label: {
                    ...CAPTION,
                    color: '#FFFFFF',
                    show: true,
                    formatter: formatLabels
                },
                itemStyle: {
                    color: ColorMapping[label.toUpperCase()]
                },
                data: rowsData.map(values => values[index].data)
            };
        })
    };

    const onEvents = useMemo(() => {
        return {
            click: ({ dataIndex } = {}) => {
                const url = rowsData[dataIndex]?.[0]?.relatedData?.url;
                if (url) {
                    window.open(fixUrl(url), '_blank');
                }
            }
        };
    }, rowsData);

    return (
        <div className={classes.wrapper}>
            <ThemedEcharts
                option={barChartOptions}
                notMerge={true}
                onEvents={onEvents}
                className={classes.chart}
                // this has to be here as it's overwritten by eChart with a default of 300px
                // https://github.com/hustcc/echarts-for-react#3-component-props
                style={{
                    height: rowsData.length * 50
                }}
            />
        </div>
    );
}));

JetBarChart.propTypes = {
    columns: PropTypes.array.isRequired,
    rowsData: PropTypes.array.isRequired,
    excludes: PropTypes.array
};

