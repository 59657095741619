import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Divider } from '@material-ui/core';
import DevicesIcon from '@material-ui/icons/Devices';
import { CheckIcon } from '../Icon';
import { StreamItemData } from './StreamItemData';
import bildPlusImage from '../../../common/images/bild-plus.png';
import sportbildImage from '../../../common/images/sportbild.png';
import computerbildImage from '../../../common/images/computerbild.png';
import autobildImage from '../../../common/images/autobild.png';
import bzImage from '../../../common/images/bz.png';
import weltImage from '../../../common/images/welt.png';
import weltPlusImage from '../../../common/images/welt-plus.png';
import { BODY_2, SUBTITLE_1 } from '../../../common/configs/scaleCategory';
import { trackLink } from '../../../../trackingScript';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 8,
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            padding: 1
        }
    },
    mainArea: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    teaser: {
        position: 'relative',
        flexShrink: 0,
        display: 'flex',
        width: '50%',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            margin: '6px 2% 2% 2%',
            width: '96%',
            maxWidth: '100%'
        }
    },
    teaserImageQuad: {
        display: 'block',
        width: 'auto',
        height: 'auto',
        maxHeight: '220px',
        margin: 'auto'
    },
    teaserImageLandscape: {
        display: 'block',
        width: '100%',
        height: 'auto',
        maxWidth: '100%',
        maxHeight: '100%',
        margin: 'auto'
    },
    logo: {
        position: 'relative',
        left: 1,
        top: 5,
        zIndex: 1
    },
    plusLogo: {
        position: 'relative',
        zIndex: 1,
        display: 'block',
        width: 'auto',
        float: 'left'
    },
    bildPlusLogo: {
        height: 30,
        marginLeft: -4
    },
    weltPlusLogo: {
        height: 26
    },
    teaserLogo: {
        position: 'absolute',
        top: 2,
        left: 0
    },
    square: {
        height: 'auto',
        width: 30
    },
    rectangle: {
        height: 20,
        width: 'auto'
    },
    conversions: {
        display: 'block',
        float: 'left',
        marginTop: 3,
        marginLeft: -9,
        paddingLeft: 11,
        paddingRight: 4,
        height: 21,
        minWidth: 17,
        fontSize: 12,
        lineHeight: '21px',
        color: 'black',
        background: 'white',
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.5)'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        paddingRight: 0,
        margin: '0 16px 0 16px',
        flexGrow: 1,
        '& h1': {
            marginTop: 0
        },
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            margin: '0 8px 0 8px'
        }
    },
    hint: BODY_2,
    department: {
        textTransform: 'capitalize'
    },
    title: {
        ...SUBTITLE_1,
        textTransform: 'none',
        color: 'rgba(0, 0, 0, 0.8)',
        textDecoration: 'none'
    },
    childContainer: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column'
    },
    bottom: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end'
    },
    bottomRow: {
        display: 'flex',
        width: '100%',
        minHeight: 40,
        justifyContent: 'space-between',
        alignItems: 'center',
        '& p': {
            marginLeft: 8,
            marginRight: 2
        }
    },
    infoIcons: {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: 1,
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
            paddingLeft: 40
        }
    },
    bottomLeft: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            justifyContent: 'center'
        }
    },
    bottomRight: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            justifyContent: 'center'
        }
    },
    materialIcon: {
        fontSize: '1em',
        marginRight: 5,
        width: 'auto'
    }
});

const trackArticleStreamLink = (action, dataUrl, eventName = 'open-articlestream-link') => {
    trackLink({
        'app_name': 'articlestream',
        'event_name': eventName,
        'event_action': action,
        'event_label': dataUrl
    });
};

class StreamItemComponent extends Component {

    constructor(props) {
        super(props);
        this.classes = this.props.classes;
    }

    trackClick(e) {
        const { url } = this.props.data;
        // ctrlKey for window, metaKey for mac
        if (e.ctrlKey || e.metaKey) {
            trackArticleStreamLink('click', url, 'open-articlestream-link-in-a-new-tab');
        } else if (e.shiftKey) {
            trackArticleStreamLink('click', url, 'open-articlestream-link-in-a-new-window');
        } else {
            trackArticleStreamLink('click', url);
        }
    }

    trackContextMenu() {
        const { url } = this.props.data;
        trackArticleStreamLink('contextmenu', url);
    }

    render() {
        const data = this.props.data;
        let homeIcons;
        if (data.site === 'www.welt.de') {
            homeIcons = (<CheckIcon
                title="Artikel war nicht auf der Home"
                checkTitle="Artikel ist oder war auf der Home"
                icon={<DevicesIcon className={this.classes.materialIcon} />}
                isChecked={true}
            />);
        } else {
            homeIcons = [(
                <CheckIcon
                    key='desktop'
                    title="Artikel war nicht auf der stationären Home"
                    checkTitle="Artikel ist oder war auf der stationären Home"
                    iconClassName='fas fa-laptop'
                    isChecked={data.isOnDesktop}
                />
            ), (
                <CheckIcon
                    key='mobile'
                    title="Artikel war nicht auf der MDOT Home"
                    checkTitle="Artikel ist oder war auf der MDOT Home"
                    iconClassName='fas fa-mobile-alt'
                    isChecked={data.isOnMobile}
                />
            )];
        }

        const teaserImageClass = data.teaserImageFormat === 'quad' ? this.classes.teaserImageQuad : this.classes.teaserImageLandscape;

        return (
            <Paper className={this.classes.root}>
                <div className={this.classes.mainArea}>
                    <a
                        onClick={(e) => { this.trackClick(e); }}
                        onContextMenu={(e) => { this.trackContextMenu(e); }}
                        className={this.classes.teaser} href={data.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img className={teaserImageClass} src={data.teaserUrl} alt="Teaser"></img>
                        {this.getLogo(data)}
                    </a>
                    <div className={this.classes.content}>
                        <p className={this.classes.hint}>
                            <span>{data.time}</span>
                            {(data.department !== null || data.regionDetail !== null) &&
                                <span> - </span>
                            }
                            <span className={this.classes.department}>{data.department} {data.regionDetail}</span>
                        </p>
                        <a
                            onClick={(e) => { this.trackClick(e); }}
                            onContextMenu={(e) => { this.trackContextMenu(e); }}
                            className={this.classes.title}
                            href={data.url}
                            target="_blank"
                            rel="noopener noreferrer">
                            {data.title}
                        </a>
                        <div className={this.classes.childContainer}>
                            {this.props.children}
                        </div>
                        <div className={this.classes.bottom}>
                            <Divider />
                            <div className={this.classes.bottomRow}>
                                <div className={this.classes.infoIcons}>
                                    <div className={this.classes.bottomLeft}>
                                        <CheckIcon
                                            title="Artikel ist nicht auf Facebook"
                                            checkTitle="Artikel ist auf Facebook"
                                            iconClassName='fab fa-facebook'
                                            isChecked={data.isOnFacebook}
                                        />
                                        {homeIcons}
                                    </div>
                                    {this.props.iconBar}
                                </div>
                                {this.props.bottomRight}
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.bottom}
            </Paper>
        );
    }

    getLogo(data) {
        if (data.isPlusContent) {
            return this.getPlusLogoAndConversions(data);
        } else {
            return this.getSiteLogo(data);
        }
    }

    getPlusLogoAndConversions(data) {
        const conversions = data.conversions === undefined ? 0 : _.defaultTo(data.conversions.total, 0);
        // const conversions = _.defaultTo(data.conversions.total, 0);
        let logo;
        if (data.site === 'www.bild.de') {
            logo = (
                <React.Fragment>
                    <img src={bildPlusImage} className={classNames(this.classes.plusLogo, this.classes.bildPlusLogo)} alt="Bild Plus Logo"></img>
                    <p className={this.classes.conversions}>{conversions}</p>
                </React.Fragment>
            );
        } else {
            logo = (
                <React.Fragment>
                    <img src={weltPlusImage} className={classNames(this.classes.plusLogo, this.classes.weltPlusLogo)} alt="Welt Plus Logo"></img>
                </React.Fragment>
            );
        }
        return (
            <div className={this.classes.teaserLogo}>
                {logo}
            </div>
        );
    }

    getSiteLogo(data) {
        let image,
            text,
            shape = this.classes.square;

        switch (data.site) {
            case 'sportbild.bild.de':
                image = sportbildImage;
                text = 'SportBILD';
                break;
            case 'www.autobild.de':
                image = autobildImage;
                text = 'AutoBILD';
                break;
            case 'www.bz-berlin.de':
                image = bzImage;
                text = 'BZ-Berlin';
                break;
            case 'www.computerbild.de':
                image = computerbildImage;
                text = 'ComputerBILD';
                break;
            case 'www.welt.de':
                image = weltImage;
                text = 'Welt';
                shape = this.classes.rectangle;
                break;
            default:
                // skip
                return;
        }
        return (
            <div className={this.classes.teaserLogo}>
                <img src={image} className={classNames(this.classes.logo, shape)} alt={text}></img>
            </div>
        );
    }
}

StreamItemComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ]).isRequired,
    iconBar: PropTypes.element,
    bottomRight: PropTypes.element,
    bottom: PropTypes.element,
    data: PropTypes.instanceOf(StreamItemData).isRequired
};

export const StreamItem = withStyles(styles)(StreamItemComponent);
