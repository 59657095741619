export type HashRecord = {
    hash: string,
    highFrequencyQuery: boolean
}

type WatchlistHashRecord = {
    pageId: string,
    resultHash: HashRecord[]
}

type Logger = (...data: any[]) => void;

/**
 * The WatchlistStore is a dictionary for watchlist items and their firebase results.
 * Each watchlist item is represented by its pageId and has a set of hashes which are references
 * to documents in firestore {stage}/queryResult/{hash}
 */
export class WatchlistStore {

    private hashSet: WatchlistHashRecord[];
    private updated: boolean;
    private readonly log: Logger;
    private updateEventListener: (() => void)[];

    /**
     * Create a new WatchlistStore
     * @param logger optional logging function for example console.log
     */
    constructor(logger?: Logger) {
        this.hashSet = [];
        this.updated = false;
        this.updateEventListener = [];
        this.log = logger ? logger : () => undefined;
    }

    /**
     * Adds a callback which will be triggered if the WatchlistStore has changed and sync() has been called.
     * There will be only one invocation of onUpdate to prevent multiple events on multiple changes.
     * @param callback
     */
    addUpdateEventListener(callback: () => void) {
        if (!this.updateEventListener.includes(callback)) {
            this.updateEventListener.push(callback);
        }
    }

    /**
     * Remove onUpdate callback if it was added before
     * @param callback
     */
    removeUpdateEventListener(callback: () => void) {
        if (this.updateEventListener.includes(callback)) {
            this.updateEventListener = this.updateEventListener.filter(e => e !== callback);
        }
    }

    /**
     * Triggers the onUpdate callbacks if the WatchlistStore has been changed.
     */
    sync() {
        if (this.updated) {
            for (const callback of this.updateEventListener) {
                callback();
            }
            this.updated = false;
        }
    }

    /**
     * Will add a new WatchlistItem or resultHash to existing WatchlistItem records if not included.
     * If something was added, onUpdate will be triggered on sync() invocation.
     * @param pageId The pageId of the WatchlistItem.
     * @param resultHash The hash for the firebase document which belongs tho this WatchlistItem.
     */
    addWatchlistItemReference(pageId: string, resultHash: HashRecord) {
        const record = this.pageIdExists(pageId);
        if (record) {
            if (!record.resultHash.find(e => e.hash === resultHash.hash)) {
                this.log('# WatchlistStore.addItemReference pageId added>>>', pageId, resultHash);
                record.resultHash.push(resultHash);
                this.updated = true;
            }
        } else {
            this.log('# WatchlistStore.addItemReference pageId added>>>', pageId, resultHash);
            this.hashSet.push({ pageId, resultHash: [resultHash] });
            this.updated = true;
        }
    }

    /**
     * Will remove a WatchlistItem if included.
     * If something was removed, onUpdate will be triggered on sync() invocation.
     * @param pageId The pageId of the WatchlistItem.
     */
    removeWatchlistItemReference(pageId: string) {
        if (this.pageIdExists(pageId)) {
            this.log('# WatchlistStore.addItemReference pageId removed>>>', pageId);
            this.hashSet = this.hashSet.filter(e => e.pageId !== pageId);
            this.updated = true;
        }
    }

    /**
     * Returns an array of result hashes for the WatchlistItem if found
     * @param pageId The pageId of the WatchlistItem.
     * @returns Array of resultHashes to firestore.
     */
    getFirebaseHashes(pageId: string): HashRecord[] | undefined {
        return this.hashSet.find((e) => e.pageId === pageId)?.resultHash;
    }

    /**
     * Returns an array of pageIds of WatchlistItems stored
     * @returns Array of pageIds
     */
    getWatchlistItemsPageIds(): string[] {
        return this.hashSet.map(e => e.pageId);
    }

    private pageIdExists(pageId: string): WatchlistHashRecord | undefined {
        return this.hashSet.find(e => e.pageId === pageId);
    }
}
