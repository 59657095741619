import { AuthRole, Authenticator } from '../../components/Auth';
import { Tenant, tenants } from './tenants';

export class TenantManager {

    static getTenants(): Tenant[] {
        const userRoles = this.getUserRoles();
        if (userRoles === undefined) {
            return [];
        }
        return userRoles.map(userRole => {
            const currentTenant = tenants.find(tenant => tenant.role === userRole);
            return currentTenant;
        }).filter((tenant) => tenant !== undefined) as Tenant[];
    }

    static getTenantByPath(path: string) {
        const allowedTenants = this.getTenants();
        return allowedTenants.find((tenant) => {
            return tenant.path === path;
        });
    }

    static getTenantByRole(userRole: AuthRole) {
        const allowedTenants = this.getTenants();
        return allowedTenants.find(tenant => tenant.role === userRole);
    }

    private static getUserRoles() {
        return Authenticator.getRoles() as AuthRole[] | undefined;
    }
}

