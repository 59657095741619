import React, { useState } from 'react';
import { Popup } from './Popup';
import { Card, CardContent, CardActions, Button, makeStyles } from '@material-ui/core';
import SurveyIcon from './survey.svg';

export type SurveyPopupPops = {
    onSurveyStart: () => void
}

const useStyles = makeStyles(() => ({
    card: {
        minWidth: 500,
        padding: 5
    },
    cardContent: {
        display: 'flex',
        alignItems: 'center'
    },
    cardActions: {
        justifyContent: 'end'
    },
    surveyMessage: {
        fontWeight: 700,
        fontSize: 20,
        width: 200,
        lineHeight: '24px'
    },
    btn: {
        fontWeight: 400,
        fontSize: '14px',
        textTransform: 'none'
    }
}));

export const SurveyPopup = ({ onSurveyStart }: SurveyPopupPops) => {
    const [showSurvey, setShowSurvey] = useState(true);
    const classes = useStyles();

    return (
        <Popup isOpen={showSurvey} showCloseIcon={false} onClose={() => { setShowSurvey(false); }}>
            <Card className={classes.card}>
                <CardContent>
                    <div className={classes.cardContent}>
                        <div>
                            <img src={SurveyIcon}/>
                        </div>
                        <div>
                            <p className={classes.surveyMessage}>Bereit unser Produkt zu verbessern?</p>
                            <p>Sag uns deine Meinung in nur zwei Minuten.</p>
                        </div>
                    </div>
                </CardContent>
                <CardActions className={classes.cardActions}>
                    <Button variant="outlined" className={classes.btn} color="secondary" onClick={() => {
                        setShowSurvey(false);
                    }}>
                        Später erinnern
                    </Button>
                    <Button variant="contained" className={classes.btn} color="secondary" onClick={() => {
                        setShowSurvey(false);
                        onSurveyStart();
                    }}>
                        Jetzt starten
                    </Button>
                </CardActions>
            </Card>
        </Popup>
    );
};
