import React from 'react';
import ReactEcharts, { EChartsReactProps } from 'echarts-for-react';
import { echartsTheme } from './echartsTheme';

type ThemedEchartsProps = Omit<EChartsReactProps, 'theme'>

export const ThemedEcharts = (props: ThemedEchartsProps) => {
    return (
        <ReactEcharts { ...props } theme={echartsTheme} />
    );
};
