import React, { FunctionComponent, useState, useEffect } from 'react';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { Timestamp } from 'firebase/firestore';
import { firestoreStores, watchlistStore } from '../../../common/helpers/Firestore';
import { WatchlistItem } from './WatchlistItem';
import { ColumnData } from '../JetTable/ColumnData';
import { WatchlistResultBuilder } from './WatchlistResultBuilder';
import { WatchlistItemLoadingCard } from './WatchlistItemLoadingCard';
import { trackLink } from '../../../../trackingScript';
import type { HashRecord, WatchlistItemData } from '../../../common/helpers/Firestore';

const styles = (theme: Theme) => createStyles({
    card: {
        marginBottom: theme.spacing(2),
        width: '100%'
    }
});

export const hashesChanged = (hashRecordsA: HashRecord[] | undefined, hashRecordsB: HashRecord[] | undefined): boolean => {
    if (hashRecordsA?.length === hashRecordsB?.length) {
        if (hashRecordsA && hashRecordsB) {
            for (let i = 0; i < hashRecordsA?.length; i++) {
                if (hashRecordsA[i].hash !== hashRecordsB[i].hash) {
                    return true;
                }
            }
        }
        return false;
    }
    return true;
};

type Props = WithStyles<typeof styles> & {
    watchlistItem: WatchlistItemData,
    watchlistKey: string,
    settings: ColumnData[],
    log?: (message?: any, ...optionalParams: any[]) => void,
    onItemClose: (pageId: string) => void
}

type FirestoreDocument = {
    hash: string,
    document: any
}

const SelfloadingWatchlistItemComponent: FunctionComponent<Props> = (props) => {
    const { classes, settings, watchlistItem, watchlistKey } = props;
    const log = props.log ? props.log : () => undefined;
    const { userStore, firestore, queryStore } = firestoreStores;

    const [resultHashRecords, setResultHash] = useState<HashRecord[] | undefined>();
    const [subscriptions, setSubscriptions] = useState<any[] | undefined>();

    const firestoreDocuments: FirestoreDocument[] = [];
    const [rowData, setRowData] = useState<any | undefined>();
    const [outdated, setOutdated] = useState<boolean>(false);
    const [item, setItem] = useState<WatchlistItemData>(watchlistItem);

    const ITEM_OUTDATED_THRESHOLD_IN_SECONDS = 330;

    const isWatchlistItemOutdated = (hashRecords: HashRecord[]) => {
        let itemIsOutdated = false;
        const now = new Date().getTime();
        for (const document of firestoreDocuments) {
            const highFrequencyQuery = hashRecords.find(e => e.hash === document.hash)?.highFrequencyQuery;
            if (highFrequencyQuery) {
                const ageOfDataInSeconds = (now - (document.document.lastSync.seconds * 1000)) / 1000;
                if (ageOfDataInSeconds > ITEM_OUTDATED_THRESHOLD_IN_SECONDS) {
                    itemIsOutdated = true;
                    break;
                }
            }
        }
        return itemIsOutdated;
    };

    const update = (id: string, document: any) => {
        const existingEntry = firestoreDocuments.find((e: FirestoreDocument) => e.hash === id);
        if (!existingEntry) {
            firestoreDocuments.push({ hash: id, document: document });
        } else {
            existingEntry.document = document;
        }

        if (firestoreDocuments.length === resultHashRecords?.length) {
            const isItemOutdated = isWatchlistItemOutdated(resultHashRecords);
            setOutdated(isItemOutdated);

            const resultBuilder = new WatchlistResultBuilder(queryStore, watchlistItem.id);
            for (const doc of firestoreDocuments) {
                resultBuilder.addFirestoreResult(doc.hash, doc.document);
            }
            const pageConfig = queryStore.getPageConfig();

            if (pageConfig) {
                const watchlistData = resultBuilder.build();
                if (watchlistData) {
                    setRowData(watchlistData.find((row) => row[0]?.relatedData.id === watchlistItem.id));
                }
            }
        }
    };

    useEffect(() => {
        log('# WatchlistItem.useEffect [resultHash] has changed >>>', resultHashRecords?.length);
        if (resultHashRecords) {
            // subscribe to firestore
            const newSubscriptions = [];
            for (const hashRecord of resultHashRecords) {
                newSubscriptions.push(firestore.subscribeToQueryResult(hashRecord.hash, update));
            }
            // unsubscribe if there are any subscriptions
            if (subscriptions) {
                for (const unsubscribe of subscriptions) {
                    unsubscribe();
                }
            }
            setSubscriptions(newSubscriptions);
        }
    }, [resultHashRecords]);


    function fetchWatchListItem() {
        const hashes = watchlistStore.getFirebaseHashes(watchlistItem.id);
        if (hashesChanged(hashes, resultHashRecords)) {
            setResultHash(hashes ? [...hashes] : undefined);
            setRowData(undefined);
        }
    }

    const watchlistUpdateCallback = () => {
        fetchWatchListItem();
    };

    useEffect(() => {
        watchlistStore.addUpdateEventListener(watchlistUpdateCallback);
        fetchWatchListItem();
        return () => {
            watchlistStore.removeUpdateEventListener(watchlistUpdateCallback);
        };
    }, []);

    const onListItemRefresh = async (pageId: string) => {
        await userStore.refreshPageIdInWatchlist(watchlistKey, pageId);
        item.timestamp = Timestamp.now();
        setItem({ ...item });
        trackLink({
            'app_name': 'jetstream',
            'event_name': 'refresh-watchlist-item',
            'event_action': 'click',
            'event_label': null
        });
    };

    const onListItemLock = async (watchlistItemId: string) => {
        await userStore.lockPageIdInWatchlist(watchlistKey, watchlistItemId);
        item.locked = true;
        setItem({ ...item });
        trackLink({
            'app_name': 'jetstream',
            'event_name': 'lock-watchlist-item',
            'event_action': 'click',
            'event_label': null
        });
    };

    const onListItemUnlock = async (watchlistItemId: string) => {
        await userStore.unlockPageIdInWatchlist(watchlistKey, watchlistItemId);
        item.locked = false;
        setItem({ ...item });
        trackLink({
            'app_name': 'jetstream',
            'event_name': 'unlock-watchlist-item',
            'event_action': 'click',
            'event_label': null
        });
    };

    const makeLoadingContent = () => {
        return <WatchlistItemLoadingCard
            watchlistItem={watchlistItem}
            onItemClose={props.onItemClose}
        />;
    };

    const makeLoadedContent = () => {
        return <WatchlistItem
            watchlistItem={item}
            brand={queryStore.queryOptions.brand}
            rowData={rowData}
            className={classes.card}
            settings={settings}
            onClose={props.onItemClose}
            onRefresh={onListItemRefresh}
            onLock={onListItemLock}
            onUnlock={onListItemUnlock}
            outdated={outdated}
            time={queryStore.queryOptions.time}
        />;
    };

    return rowData ? makeLoadedContent() : makeLoadingContent();
};

export const SelfloadingWatchlistItem = withStyles(styles)(SelfloadingWatchlistItemComponent);
