import _ from 'lodash';
import React, { CSSProperties } from 'react';
import { JetOrderOption } from '../../helpers/Query';
import { ColumnType } from './ColumnType';
import { SumDataSparkline } from '../JetSum';
import { QueryProducer, BuildQueriesFunction, BuildQueryResultFunction, DataResult, DataResults, RequestType, RequestOptions } from '../../helpers/Query/QueryTypes';
import { JetColumnId } from '../JetPage/JetColumnId';
import { BarOption } from '../JetChart';

export type PostProcessor = (content: string, relatedData: {[id in JetColumnId]: string}) => React.ReactNode
export type ColumnResult = {
    data?: DataResult
    sparkline: number[]
    sparklineTimes: string[]
    relatedData: DataResults
    sumSparklines: ({
        sparkline: number[]
        combined: { [id in JetColumnId]?: number[] }
        sparklineTimes: string[]
    } | undefined)[]
    sharedMaxValues: {
        sparkline?: number
        barChart?: number
    }
}

export class ColumnData implements QueryProducer<ColumnResult[] | undefined> {
    constructor(
        public id: JetColumnId,
        public label: string,
        public type = ColumnType.NUMERIC,
        public isSortable = true,
        public orderBy = JetOrderOption.DESC
    ) {}

    style: CSSProperties = {}
    isHighlighted = false
    hasSparkline = false
    sparklineSharedMax = false
    sparklineStyle: CSSProperties = {}
    hasPercentChart = false
    percentChartStyle: CSSProperties = {}
    percentChartPostProcessor?: PostProcessor
    percentChartHideIfNotPremium = false
    hasBarChart = false
    hasTimelineBarChart = false
    barChartStyle: CSSProperties = {}
    barOptions: BarOption[] = []
    postProcessor: PostProcessor = e => e
    relatedColumns: JetColumnId[] = []
    requestOptions: RequestOptions = { type: RequestType.COLUMN }
    sumSparklines: SumDataSparkline[] = []
    hasRTR = false

    setHighlight({ isHighlighted = true } = {}) {
        this.isHighlighted = isHighlighted;
        return this;
    }

    setRTR({ hasRTR = true } = {}) {
        this.hasRTR = hasRTR;
        return this;
    }

    setSparkline({ hasSparkline = true, style = this.sparklineStyle, sparklineSharedMax = this.sparklineSharedMax, sumSparklines = this.sumSparklines } = {}) {
        this.hasSparkline = hasSparkline;
        this.sparklineStyle = style;
        this.sparklineSharedMax = sparklineSharedMax;
        this.sumSparklines = sumSparklines;
        return this;
    }

    setPercentChart({ hasPercentChart = true, style = this.percentChartStyle, postProcessor = this.percentChartPostProcessor, hideIfNotPremium = false } = {}) {
        this.hasPercentChart = hasPercentChart;
        this.percentChartStyle = style;
        this.percentChartPostProcessor = postProcessor;
        this.percentChartHideIfNotPremium = hideIfNotPremium;
        return this;
    }

    setPostProcessor(processor: PostProcessor) {
        this.postProcessor = processor;
        return this;
    }

    setRelatedColumns(ids: JetColumnId[]) {
        this.relatedColumns = ids;
        return this;
    }

    setBarChart({ hasBarChart = true, barOptions = this.barOptions, style = this.barChartStyle } = {}) {
        this.hasBarChart = hasBarChart;
        this.barOptions = barOptions;
        this.barChartStyle = style;
        this.relatedColumns = this.relatedColumns.concat(barOptions.map(({ id }) => id));
        return this;
    }

    setTimelineBarChart({ hasTimelineBarChart = true, style = this.style, sumSparklines = this.sumSparklines } = {}) {
        this.hasTimelineBarChart = hasTimelineBarChart;
        this.style = style;
        this.sumSparklines = sumSparklines;
        return this;
    }

    buildQueries: BuildQueriesFunction = (queryBuilder, { time }, overwrites) => {
        queryBuilder.setRequestOptionDefaults({ ...overwrites, ...this.requestOptions });

        if (this.id) {
            queryBuilder.addRequest(this.id, { sparkline: this.hasSparkline });
        }

        this.relatedColumns.forEach(relatedColumnId => {
            queryBuilder.addRequest(relatedColumnId);
        });

        this.sumSparklines
            .filter(sparkline => sparkline.isSparklineVisible(time))
            .forEach(sumSparkline => sumSparkline.buildQueries(queryBuilder, this.sumSparklines));
    }

    buildQueryResult: BuildQueryResultFunction<ColumnResult[] | undefined> = (getData) => {
        const sumSparklineGetData: typeof getData = (requestOptions) => getData({ ...this.requestOptions, ...requestOptions });
        const data = getData(this.requestOptions);
        const columnResults = data?.columnsData?.map((columnsData): ColumnResult => ({
            data: columnsData[this.id] as DataResult,
            sparkline: (this.hasSparkline && columnsData[`${this.id}Sparkline`] as number[]) || [],
            sparklineTimes: (this.hasSparkline && data.sparklineTimes) || [],
            relatedData: _.fromPairs(this.relatedColumns.map(id => [id, columnsData[id] as DataResult])),
            sumSparklines: this.sumSparklines.map(sumSparkline => {
                return sumSparkline.buildQueryResult(sumSparklineGetData, this.sumSparklines, 'columnsData', columnsData.id) as any;
            }),
            sharedMaxValues: {}
        })) ?? undefined;

        if (this.sparklineSharedMax) {
            const sparklineMax = Math.max(0, ...columnResults?.flatMap(({ sparkline }) => sparkline) ?? []);
            columnResults?.forEach((result) => {
                result.sharedMaxValues.sparkline = sparklineMax;
            });
        }
        if (this.hasBarChart) {
            const barChartMax = Math.max(0, ...columnResults?.map(({ relatedData }) =>
                this.barOptions.map(({ id }: { id: JetColumnId }) => relatedData[id] as number).reduce((sum, x) => sum + x, 0)
            ) ?? []);
            columnResults?.forEach((result) => {
                result.sharedMaxValues.barChart = barChartMax;
            });
        }

        return columnResults;
    }
}
