import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { PurestComponent } from '../../../../PurestComponent';
import { config } from '../../../../config';
import { InfoBox } from '../InfoBox';
import { StreamDivider, StreamSortSelect, StreamPage, StreamFilter } from '../Stream';
import { SortQuery, SortOption } from '../../../common/helpers/Query';
import { StreamFilterQuery } from '../../helpers/Query';
import { Network, NetworkQueue } from '../../../common/helpers/Network';
import { ProgressStatus } from '../ProgressView';
import { ArticleItem } from './ArticleItem';
import { withAuth } from '../../../common/components/Auth';

class ArticlePageComponent extends PurestComponent {
    constructor(props) {
        super(props);
        this.classes = props.classes;
        this.fbDailyClicksUrl = config.api + '/facebook/clicks/daily';
        this.networkQueue = new NetworkQueue();
        this.sortHelper = new SortQuery();
        this.filterHelper = new StreamFilterQuery();
        this.selectOptions = [
            { label: 'Datum absteigend', value: SortOption.dateDesc },
            { label: 'Datum aufsteigend', value: SortOption.date },
            { label: 'Klicks absteigend', value: SortOption.clicksDesc },
            { label: 'Klicks aufsteigend', value: SortOption.clicks },
            { label: 'Shares absteigend', value: SortOption.sharesDesc },
            { label: 'Shares aufsteigend', value: SortOption.shares }
        ];
        this.state = {
            data: [],
            date: new Date(),
            progressStatus: ProgressStatus.LOAD
        };
        this.handleFilterUpdate = this.handleFilterUpdate.bind(this);
        this.handleSortUpdate = this.handleSortUpdate.bind(this);
        this.handleLoadNextArticles = this.handleLoadNextArticles.bind(this);
        this.refreshArticleData = this.refreshArticleData.bind(this);
    }

    componentDidMount() {
        this.loadArticleData();
    }

    componentDidUpdate(prevProps) {
        switch (true) {
            case prevProps.location.pathname !== this.props.location.pathname:
                this.filterHelper.setQuerySettings(this.props.location, this.props.history, {});
                // fallthrough
            case JSON.stringify(prevProps.search) !== JSON.stringify(this.props.search):
                this.setState({
                    data: [],
                    progressStatus: ProgressStatus.LOAD
                }, () => {
                    // load new data
                    this.handleLoadNextArticles();
                });
                break;
            default:
                // ignore
        }
    }

    render() {
        const rawSettings = this.filterHelper.getQuerySettings(this.props.location);
        const sort = this.sortHelper.getAllSort(rawSettings).sortBy;
        const site = this.getSite();
        return (
            <StreamPage
                showLoadMoreButton={this.props.search.idCount === 0}
                progressStatus={this.state.progressStatus}
                onPaginationRequest={this.handleLoadNextArticles}
                onFilterUpdate={this.handleFilterUpdate}
                onAutoRefresh={this.refreshArticleData}
                topRight={this.getInfoBox(site)}
                filter={this.getFilter(site, rawSettings)}
            >
                {this.getArticleItems(sort)}
            </StreamPage>
        );
    }

    getSite() {
        return this.props.rest || this.props.tenant;
    }

    getInfoBox(site) {
        let brand;
        switch (site) {
            case 'bild':
            case 'sportbild':
                brand = 'BILD';
                break;
            case 'autobild':
                brand = 'AUTO BILD';
                break;
            case 'computerbild':
                brand = 'COMPUTER BILD';
                break;
            case 'bzberlin':
                brand = 'B.Z.';
                break;
            case 'welt':
                brand = 'WELT';
                break;
            default: brand = null;
        }
        if (brand) {
            return (<InfoBox
                url={this.fbDailyClicksUrl + '?brand=' + brand}
                title={'Heutige Facebook-Klicks'}
            />);
        }
        return null;
    }

    getFilter(site, rawFilter) {
        if (site !== 'bild') {
            return;
        }
        return (
            <StreamFilter
                onFilterChange={this.handleFilterUpdate}
                rawFilter={rawFilter}
                filterHelper={this.filterHelper}
            />
        );
    }

    getArticleItems(sort) {
        const items = [];
        let uniqueKey = 0;
        let lastItemData = null;
        const isDateSort = sort === SortOption.date || sort === SortOption.dateDesc;

        for (let i = 0; i < this.state.data.length; i++) {
            const itemData = this.state.data[i];
            // check if divider is needed for a new day, show dropdown only in first divider element
            if (i === 0) {
                items.push((
                    <StreamSortSelect
                        key={uniqueKey++}
                        time={isDateSort ? itemData.pubDate : this.state.date}
                        options={this.selectOptions}
                        selectedValue={sort}
                        onSortUpdate={this.handleSortUpdate}
                    />
                ));
            } else if (isDateSort && !itemData.isSamePublishDay(lastItemData)) {
                items.push((
                    <StreamDivider
                        key={uniqueKey++}
                        time={itemData.pubDate}
                    />
                ));
            }
            lastItemData = itemData;
            // add article item
            items.push((
                <ArticleItem key={uniqueKey++} data={{ ...itemData, teaserUrl: this.mapTeaserUrl(itemData.teaserUrl) }} />
            ));
        }
        return items;
    }

    mapTeaserUrl(teaserUrl) {
        const baseSIP2UrL = 'https://sip2.prd.bild.leancms.de/sip2/v2/i/bild';
        const baseBildUrl = 'https://images.bild.de';
        const { tenant } = this.props;

        if (teaserUrl && tenant === 'bild' && config.USE_BILD_IMAGE_SERVER) {
            const modifiedUrl = teaserUrl.replace(baseSIP2UrL, baseBildUrl);
            return modifiedUrl;
        }

        return teaserUrl;
    }

    loadArticleData(base, offset) {
        this.setState({
            progressStatus: ProgressStatus.LOAD
        });
        const request = this.createNetworkRequest(base, offset, null);
        // if completed successful and was newest request on queue
        const onSuccess = data => {
            this.setState(newState => {
                const newData = newState.data.concat(data);
                return {
                    data: newData,
                    progressStatus: newData.length > 0 ? ProgressStatus.SUCCESS : ProgressStatus.EMPTY
                };
            });
        };
        const onError = error => {
            this.setState({
                progressStatus: ProgressStatus.ERROR
            });
            console.error('request error', error);
        };
        this.networkQueue.runTask(request, onSuccess, onError);
    }

    refreshArticleData() {
        let base;
        const offset = 0;
        let limit;
        if (!_.isEmpty(this.state.data)) {
            base = this.state.data[0].pubDate;
            limit = this.state.data.length;
        }
        const request = this.createNetworkRequest(base, offset, limit);
        const onSuccess = newPageData => {
            this.setState(state => {
                const pageData = state.data.map(itemData => {
                    const newItemData = _.find(newPageData, { url: itemData.url });
                    return newItemData ? newItemData : itemData;
                });
                return {
                    data: pageData
                };
            });
        };
        const onError = error => {
            console.error('refresh error', error);
        };
        this.networkQueue.runBackgroundTask(request, onSuccess, onError);
    }

    createNetworkRequest(base, offset, limit) {
        const network = new Network(this.props.location, this.props.history);
        return network.loadArticleData(
            base,
            offset,
            this.props.search,
            this.filterHelper.getUrlFilter(this.props.location),
            this.getSite(),
            limit,
            this.sortHelper.getUrlSort(this.props.location).sortBy
        );
    }

    handleLoadNextArticles() {
        let base;
        let offset;
        if (this.state.data.length > 0) {
            base = this.state.data[0].pubDate;
            offset = this.state.data.length;
        }
        this.loadArticleData(base, offset);
    }

    handleFilterUpdate(filter) {
        this.filterHelper.setQuerySettings(this.props.location, this.props.history, filter);
        this.setState({
            data: [],
            progressStatus: ProgressStatus.LOAD
        }, () => {
            this.props.onFilterUpdate();
            this.handleLoadNextArticles();
        });
    }

    handleSortUpdate(event) {
        // overwrite filter with sort settings
        const sortBy = event.target.value;
        let sortSettings;
        if (this.selectOptions[0].value === sortBy) {
            // ignore default value
            sortSettings = {};
        } else {
            sortSettings = { sortBy };
        }
        this.sortHelper.setQuerySettings(this.props.location, this.props.history, sortSettings);
        this.setState({
            data: [],
            progressStatus: ProgressStatus.LOAD,
            date: new Date()
        }, () => {
            this.handleLoadNextArticles();
        });
    }
}

ArticlePageComponent.propTypes = {
    search: PropTypes.object.isRequired,
    onFilterUpdate: PropTypes.func.isRequired,
    tenant: PropTypes.string.isRequired,
    rest: PropTypes.string
};

export const ArticlePage = withAuth(ArticlePageComponent);
