export class TabData {

    label: string;
    path: string;

    constructor(label: string, path: string) {
        this.label = label;
        this.path = path;
    }
}
