import React, { FC } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { withAuth } from '../Auth';
import { AppPath } from '../../helpers/Route';

const useStyles = makeStyles((theme) => ({
    toolbarSpacer: theme.mixins.toolbar,
    tabBarSpacer: theme.mixins.tabBar
}));

type Props = {
    app: string,
    className?: string
}

const SpacerComponent: FC<Props> = ({ app, className }) => {
    const classes = useStyles();
    const isTabBarActive = [AppPath.ARTICLESTREAM, AppPath.JETSTREAM].includes(app as AppPath);
    return (
        <div className={classNames(
            classes.toolbarSpacer,
            { [classes.tabBarSpacer]: isTabBarActive },
            className
        )}/>
    );
};

SpacerComponent.propTypes = {
    app: PropTypes.string.isRequired,
    className: PropTypes.string
};

export const Spacer = withAuth(SpacerComponent);
