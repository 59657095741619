import React, { Card, createStyles, IconButton, Theme, withStyles, WithStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import {
    Close as CloseIcon
} from '@material-ui/icons';
import { ProgressStatus, ProgressView } from '../../../stream/components/ProgressView';
import type { WatchlistItemData } from '../../../common/helpers/Firestore';

const styles = (theme: Theme) => createStyles({
    card: {
        marginBottom: theme.spacing(2),
        width: '100%'
    },
    header: {
        overflow: 'auto',
        margin: '0px 0px 24px 0px'
    },
    textLine: {
        fontFamily: 'Lato, sans-serif',
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '19.1px',
        margin: '20px 0px 42px 0px',
        color: 'rgba(0,0,0,0.6)'
    },
    padding: {
        padding: '0px 0px 20px 0px'
    },
    headerRight: {
        float: 'right',
        whiteSpace: 'nowrap',
        paddingLeft: 12
    }
});


type Props = WithStyles<typeof styles> & {
    className?: string,
    watchlistItem: WatchlistItemData,
    onItemClose: (pageId: string) => void
}


const WatchlistItemLoadingCardComponent: FunctionComponent<Props> = (props) => {
    const { classes, watchlistItem, onItemClose } = props;

    return <Card variant="outlined" className={classes.card}>
        <div className={classes.header}>
            <div className={classes.headerRight}>
                <IconButton onClick={() => onItemClose(watchlistItem.id)}>
                    <CloseIcon />
                </IconButton>
            </div>
        </div>
        <ProgressView status={ProgressStatus.LOAD} className={classes.padding}/>
        <p className={classes.textLine} >Der Artikel wird geladen mit der ID: <b>{watchlistItem.id}</b></p>
    </Card>;
};
export const WatchlistItemLoadingCard = withStyles(styles)(WatchlistItemLoadingCardComponent);
