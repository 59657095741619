import React, { useEffect, useState } from 'react';
import { Accordion } from '../../../common/components/Accordion';
import { FilterMode } from '../../../common/helpers/Query/FilterQuery';
import { StreamFilterButton } from '../../../stream/components/Button/StreamFilterButton';

type Props = {
    filterTeasers: (filterMode: number) => void
};

export const TypeFilter = ({ filterTeasers }: Props) => {
    const [filterMode, setFilterMode] = useState(FilterMode.disabled);
    const toggelMode = () => {
        switch (filterMode) {
            case FilterMode.disabled:
                setFilterMode(FilterMode.enabled);
                break;
            case FilterMode.enabled:
                setFilterMode(FilterMode.inverted);
                break;
            case FilterMode.inverted:
                setFilterMode(FilterMode.disabled);
                break;
            default:
        }
    };

    useEffect(() => {
        filterTeasers(filterMode);
    }, [
        filterMode
    ]);

    return (
        <Accordion label={'Typ'}>
            <StreamFilterButton
                name={'Plus'}
                mode={filterMode}
                onClick={toggelMode}>
            </StreamFilterButton>
        </Accordion>
    );
};
