import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, TextField, MenuItem, Button } from '@material-ui/core';
import { PurestComponent } from '../../../../PurestComponent';
import { config } from '../../../../config';
import { DataValidator } from '../../../common/helpers/Validator';
import { Authenticator } from '../../../common/components/Auth';
import { Network } from '../../../common/helpers/Network';
import { ProgressView, ProgressStatus } from '../../../stream/components/ProgressView';

const styles = (theme) => ({
    root: {
        margin: 'auto',
        justifyContent: 'center',
        maxWidth: 768,
        padding: theme.spacing(2),
        '@media (max-width: 553px)': {
            flexDirection: 'column-reverse',
            alignItems: 'center',
            maxWidth: 350
        }
    },
    formControl: {
        width: '100%'
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: 200
    },
    menu: {
        width: 200
    },
    menuItem: {
        paddingTop: 0,
        paddingBottom: 0
    },
    button: {
        width: '50%',
        marginTop: 20,
        backgroundColor: '#fff',
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff'
        }
    },
    urlOutput: {
        width: '100%',
        marginTop: 20
    }
});

class ExactagConverterPageComponent extends PurestComponent {

    constructor(props) {
        super(props);
        this.classes = this.props.classes;
        this.state = {
            pages: [],
            shortenDomains: ['nicht kürzen', 'bit.ly', 'on.bild.de'],
            index: -1,
            shortenIndex: 0,
            urlInput: '',
            urlOutput: '',
            errorText: '',
            progressStatus: ProgressStatus.LOAD
        };
        this.url = config.api + '/exactag/channels';
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleDomainSelectChange = this.handleDomainSelectChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.network = new Network();
    }

    render() {
        return (
            <div className={this.classes.root}>
                <h2 className={this.classes.headline}>BILDplus-URL-Exactag-Converter</h2>
                <ProgressView
                    status={this.state.progressStatus}
                    className={this.classes.progressView}
                    classes={{
                        message: this.classes.progressMessage
                    }}
                >
                    <FormControl className={this.classes.formControl}>
                        <TextField
                            id="select-fbPage"
                            select
                            value={this.state.index}
                            onChange={this.handleSelectChange}
                            SelectProps={{
                                MenuProps: {
                                    className: this.classes.menu
                                }
                            }}
                            helperText="Bitte Channel auswählen"
                            margin="normal"
                        >
                            {this.state.pages.map((option, index) => (
                                <MenuItem className={this.classes.menuItem} key={option.id} value={index} data-value-channel-id={option.channelId}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            id="select-shortening"
                            select
                            value={this.state.shortenIndex}
                            onChange={this.handleDomainSelectChange}
                            SelectProps={{
                                MenuProps: {
                                    className: this.classes.menu
                                }
                            }}
                            helperText="Soll der Link mit Bitly gekürzt werden?"
                            margin="normal"
                        >
                            {this.state.shortenDomains.map((option, index) => (
                                <MenuItem className={this.classes.menuItem} key={index} value={index}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            id='input-url'
                            placeholder='Artikel-URL'
                            onChange={this.handleInputChange}
                            helperText={this.state.errorText}
                            error={this.state.errorText !== ''}
                        />
                        <Button
                            variant="contained"
                            disabled={(this.state.selectedPageId === -1 || this.state.urlInput === '')}
                            className={this.classes.button}
                            onClick={this.onButtonClick}>
                            Exactag-Link generieren
                        </Button>
                    </FormControl>
                    <p className={this.classes.urlOutput}>{this.state.urlOutput}</p>
                </ProgressView>
            </div>
        );
    }

    componentDidMount() {
        this.getPages()
            .then(res => {
                this.setState(() => {
                    const channelList = res;
                    channelList.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    });
                    return {
                        pages: channelList,
                        progressStatus: ProgressStatus.SUCCESS
                    };
                });
            })
            .catch(() => {
                this.setState(() => {
                    return {
                        progressStatus: ProgressStatus.ERROR
                    };
                });
            });
    }

    getPages() {
        return Authenticator
            .secureFetch(this.url, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
    }

    handleSelectChange(event) {
        const index = event.target.value;
        this.setState({
            index: index
        });
    }

    handleDomainSelectChange(event) {
        const index = event.target.value;
        this.setState({
            shortenIndex: index
        });
    }

    handleInputChange(event) {
        this.setState({
            urlInput: event.target.value
        });
    }

    async onButtonClick() {
        const url = this.state.urlInput;
        if (DataValidator.isValidUrl(url)) {
            const index = this.state.index;
            const pageId = this.state.pages[index].id;
            const channelId = this.state.pages[index].channelId;

            let exactagUrl = await this.network.createExactagUrl(url, pageId, channelId);
            if (this.state.shortenIndex > 0) {
                const domain = this.state.shortenDomains[this.state.shortenIndex];
                exactagUrl = await this.network.shortenUrl(exactagUrl, domain);
            }

            this.setState({
                errorText: '',
                urlOutput: exactagUrl
            });
        } else {
            this.setState({
                errorText: 'Bitte gültige Artikel-URL eingeben'
            });
        }
    }
}

ExactagConverterPageComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export const ExactagConverterPage = withStyles(styles)(ExactagConverterPageComponent);
