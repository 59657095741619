import React from 'react';
import { matchPath, withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withTheme } from '@material-ui/core/styles';
import { PurestComponent } from '../../../../PurestComponent';
import { TabData } from './TabData';

class TabBarComponent extends PurestComponent {

    render() {
        const tabs = this.props.tabs.map(tabData => {
            const path = `${this.props.targetPath}/${tabData.path}`;
            return (
                <Tab
                    key={tabData.label}
                    label={tabData.label}
                    component={Link}
                    to={this.props.withQueryParams ? {
                        pathname: path,
                        search: this.props.location.search
                    } : path}
                />
            );
        });
        return (
            <Tabs
                value={this.getSelectedTabIndex()}
                variant="scrollable"
                scrollButtons="off"
                TabIndicatorProps={{
                    style: {
                        backgroundColor: this.props.theme.palette.primary.main
                    }
                }}
            >
                {tabs}
            </Tabs>
        );
    }

    getSelectedTabIndex() {
        const currentPath = this.props.location.pathname;
        if (matchPath(currentPath, { path: this.props.targetPath, exact: true })) {
            // defaults to first possible state
            return 0;
        }
        for (let i = 0; i < this.props.tabs.length; i++) {
            const tab = this.props.tabs[i];
            const checkPath = `${this.props.targetPath}/${tab.path}`;
            if (matchPath(currentPath, { path: checkPath, exact: true })) {
                return i;
            }
        }
        // not valid url
        return false;
    }
}

TabBarComponent.defaultProps = {
    withQueryParams: false
};

TabBarComponent.propTypes = {
    theme: PropTypes.object.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.instanceOf(TabData)).isRequired,
    targetPath: PropTypes.string.isRequired,
    withQueryParams: PropTypes.bool
};

export const TabBar = withTheme(withRouter(TabBarComponent));
