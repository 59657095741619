
import React, { CSSProperties, FunctionComponent, useEffect, useRef, useState, useCallback } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { SumDataSparkline } from '../JetSum';
import type { ColumnResult } from '../JetTable';
import { TimelineBarChart } from './TimelineBarChart';

const styles = () => createStyles({
    root: {
        position: 'relative',
        width: '100%',
        textAlign: 'center'
    }
});

type Props = WithStyles<typeof styles> & {
    settings: SumDataSparkline[],
    data: ColumnResult['sumSparklines'],
    style?: CSSProperties
}

const SmallTimelineBarChartComponent: FunctionComponent<Props> = ({ classes, settings, data, style }: Props) => {

    const ref = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState<number>(0);

    // after first render
    useEffect(() => {
        const handleResize = () => {
            const element = ref && ref.current;
            if (element) {
                setWidth(element.offsetWidth);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            return window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getChartWidth = useCallback((barCount: number) => {
        const horizontalBarSpace = 60;
        return Math.min(horizontalBarSpace * barCount, width);
    }, [width]);

    return (
        <div ref={ref} className={classes.root}>
            {width !== 0 && <TimelineBarChart
                settings={settings}
                data={data}
                style={{
                    display: 'inline-block',
                    marginTop: 20,
                    ...style
                }}
                getChartWidth={getChartWidth}
            />}
        </div>
    );
};

export const SmallTimelineBarChart = withStyles(styles)(observer(SmallTimelineBarChartComponent));
