import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { action, makeObservable } from 'mobx';
import { withStyles } from '@material-ui/core/styles';
import { Switch, FormControlLabel, Grid } from '@material-ui/core';
import { JetTable } from '../JetTable';
import { JetSortQuery, JetFilterQuery } from '../../helpers/Query';
import { StreamFilter } from '../../../stream/components/Stream';
import { Page } from '../../../common/components/Page';
import { ProgressView, ProgressStatus } from '../../../stream/components/ProgressView';
import { SumSection } from '../JetSum';
import { JetBarChart } from '../JetChart';
import { Watchlist } from '../Watchlist';
import { firestoreContext } from '../../../common/helpers/Firestore';
import { ReferrerFilter } from '../../helpers/filter/ReferrerFilter';
import { ViewMode } from './ViewMode';
import { PAGE_CONFIGS } from './_pageConfigs';
import { BANNER_DESKTOP_HEIGHT } from '../../../common/components/Banner/BannerBar';
import { PositionHistory } from './PositionHistory';
// avoid circular dependency by using direct import
import { withAuth } from '../../../common/components/Auth/withAuth';
import { config } from '../../../../config';
import { Authenticator } from '../../../common/components/Auth';

const styles = () => ({
    progressView: {
        marginTop: 50
    },
    progressMessage: {
        marginTop: -42
    },
    pageOptionsContainer: {
        height: 48,
        marginTop: -18
    }
});

export class JetPageComponent extends Component {
    static contextType = firestoreContext;

    constructor(props) {
        super(props);
        makeObservable(this, { updateQueries: action });
        this.classes = this.props.classes;
        const config = this.getConfig();
        this.state = {
            config,
            regioChannels: [],
            viewMode: config?.pageConfig.viewModes[0] ?? null
        };
        this.handleFilterUpdate = this.handleFilterUpdate.bind(this);
        this.handleSortUpdate = this.handleSortUpdate.bind(this);
    }

    componentDidMount() {
        const { brand } = this.state.config;
        this.updateQueries();
        /* since News & Regios filter is only available for bild
           we only fetch the regios if the tenant is bild
        */
        if (brand === 'bild') {
            this.fetchRegioChannels();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname ||
            prevProps.location.search !== this.props.location.search) {
            const config = this.getConfig();
            this.setState({ config }, () => this.updateQueries());
            if (config.brand === 'bild' && this.state.regioChannels.length === 0) {
                this.fetchRegioChannels();
            }
        }
    }

    render() {
        const { viewMode, config } = this.state;
        if (config.view === 'positionAnalysis') {
            return <PositionHistory />;
        }
        const { queryStore = {} } = this.context;

        if (!queryStore.pageConfig) {
            return null;
        }

        const { queryOptions: { brand }, pageConfig: { sums: sumsSettings, columns, viewModes, label: pageLabel, watchlistKey } = {} } = queryStore;
        const { view } = config;

        const filterHelper = this.getFilterHelper(brand, view);
        const filters = filterHelper.getUrlFilter(this.props.location);
        const rawSettings = filterHelper.getQuerySettings(this.props.location);

        const sortHelper = this.getSortHelper(columns);
        const sortSettings = sortHelper.getAllSort(rawSettings);

        const progressStatus = this.getProgressStatus(queryStore);
        const isLoadingOrSuccess = [ProgressStatus.LOAD, ProgressStatus.SUCCESS].includes(progressStatus);
        const actualViewMode = this.getActualViewMode(viewModes, viewMode);
        const isTableVisible = isLoadingOrSuccess && actualViewMode === ViewMode.TABLE;
        const isBarChartVisible = progressStatus === ProgressStatus.SUCCESS && actualViewMode === ViewMode.BAR_CHART;
        const isWatchlistVisible = actualViewMode === ViewMode.WATCHLIST;

        return (
            <Page
                useBannerOffset={this.props.useBannerOffset}
                sidebarTitle="Filter nach:"
                sidebarContent={this.getSidebarContent(rawSettings, filterHelper)}
            >
                <Grid container justify="center" alignItems="center">
                    {sumsSettings.map((settings, i) => (
                        <SumSection
                            key={`${pageLabel}${settings.label}${i}`}
                            sumData={queryStore.queryResult?.sums[i]}
                            settings={settings}
                            brand={brand}
                            filters={filters}
                        />
                    ))}
                    {!isWatchlistVisible && <ReferrerFilter />}
                </Grid>
                <Grid container justify="flex-end" className={this.classes.pageOptionsContainer}>
                    {this.getTableOptions(viewModes, isBarChartVisible)}
                </Grid>
                {isTableVisible && <JetTable
                    columns={columns}
                    rowsData={queryStore.rowsResult || []}
                    sortBy={sortSettings.sortBy}
                    orderBy={sortSettings.orderBy}
                    onSortUpdate={this.handleSortUpdate}
                    stickyThreshold={this.props.useBannerOffset ? 112 + BANNER_DESKTOP_HEIGHT : 112 }
                />}
                {isBarChartVisible && <JetBarChart
                    columns={columns}
                    rowsData={queryStore.rowsResult}
                    excludes={['PIs', 'Read-Through']}
                />}
                {isWatchlistVisible && <Watchlist
                    watchlistKey={watchlistKey}
                    settings={columns}
                />}
                {!isWatchlistVisible && <ProgressView
                    status={progressStatus}
                    className={this.classes.progressView}
                    classes={{
                        message: this.classes.progressMessage
                    }}
                />}
            </Page>
        );
    }

    getConfig() {
        const brand = this.props.tenant;
        const view = this.props.rest;
        const views = PAGE_CONFIGS[brand];
        const pageConfig = views && views[view];
        if (!pageConfig) {
            return null;
        }
        return { brand, view, pageConfig };
    }

    getActualViewMode(viewModes, viewMode) {
        if (!viewModes.includes(viewMode)) {
            return viewModes[0];
        }
        return viewMode;
    }

    handleViewModeChange = (_, isBarChart) => {
        this.setState({
            viewMode: isBarChart ? ViewMode.BAR_CHART : ViewMode.TABLE
        });
    }

    getTableOptions(viewModes, isBarChartVisible) {
        if (viewModes.length === 1) {
            return null;
        }
        return <FormControlLabel
            control={
                <Switch
                    checked={isBarChartVisible}
                    onChange={this.handleViewModeChange}
                />
            }
            label="Balkenansicht"
        />;
    }

    getSidebarContent(rawFilter, filterHelper) {
        const filters = filterHelper.getUrlFilter(this.props.location);
        const showRegiosFilter = filters.departments && filters.departments.news === 1;

        return (
            <StreamFilter
                onFilterChange={this.handleFilterUpdate}
                rawFilter={rawFilter}
                filterHelper={filterHelper}
                showRegiosFilter={showRegiosFilter}
            />
        );
    }

    handleFilterUpdate(filter, key) {
        const { config: { brand, view } } = this.state;
        const filterHelper = this.getFilterHelper(brand, view);
        filterHelper.setQuerySettings(this.props.location, this.props.history, filter);
        this.context.queryStore.wait = true;
        if (key === 'time') {
            this.updateQueriesDebouncedFast();
        } else {
            this.updateQueriesDebouncedSlow();
        }
    }

    handleSortUpdate(sortBy, orderBy) {
        const { config: { pageConfig: { columns } } } = this.state;
        const sortHelper = this.getSortHelper(columns);
        const rawSettings = sortHelper.getQuerySettings(this.props.location);
        if (sortBy === sortHelper.sortKeys[0]) {
            // remove default value
            delete rawSettings.sortBy;
        } else {
            rawSettings.sortBy = sortBy;
        }
        const sortKeyIndex = sortHelper.sortKeys.findIndex(sortKey => sortKey === sortBy);
        const defaultOrder = sortHelper.defaultSortValues[sortKeyIndex];
        if (orderBy === defaultOrder) {
            // remove default value
            delete rawSettings.orderBy;
        } else {
            rawSettings.orderBy = orderBy;
        }
        sortHelper.setQuerySettings(this.props.location, this.props.history, rawSettings);
    }

    updateQueriesDebouncedFast = _.debounce(this.updateQueries.bind(this), 100);
    updateQueriesDebouncedSlow = _.debounce(this.updateQueries.bind(this), 1200);

    async updateQueries() {
        if (!this.state.config) {
            // wait for redirect to brand path
            return;
        }

        const { brand, view, pageConfig: { columns, filter: pageFilter } } = this.state.config;
        const filterHelper = this.getFilterHelper(brand, view);
        const { conversion, topTeaserPositionFilter } = pageFilter;
        const urlFilter = filterHelper.getUrlFilter(this.props.location);

        const filters = JetPageComponent.mergeDepartmentAndDivisionFilters(pageFilter, urlFilter);

        const customFilter = Object.assign({},
            conversion ? { conversion } : {},
            topTeaserPositionFilter ? { topTeaserPositionFilter } : {},
            filters.filter);
        if (!_.isEmpty(customFilter)) {
            filters.filter = customFilter;
        }

        const sortHelper = this.getSortHelper(columns);
        // default is set at the backend, but can be overridden by passing a value for `sparklineRate`
        // not in use at the moment
        // const sparklineRate = JetConfigQuery.getSparklineRate(filters.time);

        // use getAllSort instead of getUrlSort to always send sort options
        const rawSettings = sortHelper.getQuerySettings(this.props.location);
        const sort = sortHelper.getAllSort(rawSettings);

        // workaround to apply regio filter on reload, due to regio data not yet being loaded
        if (rawSettings.regios && !filters.regios) {
            filters['regios'] = Object.keys(rawSettings.regios).reduce((regioFilter, filterKey) => {
                regioFilter[filterKey] = parseInt(rawSettings.regios[filterKey], 10);
                return regioFilter;
            }, {});
        }

        const timeFilter = rawSettings.time || 'today';
        const requestedBy = {
            app: 'jetstream',
            view: view
        };

        Object.assign(this.context.queryStore, {
            wait: false,
            queryOptions: { brand, ...filters, ...sort, time: timeFilter, requestedBy },
            pageConfig: this.state.config.pageConfig
        });
    }

    getFilterWithOverwrites(filter, { sparklineTime }) {
        return {
            ...filter,
            ...(sparklineTime && { sparklineTime })
        };
    }

    getFilterHelper(brand, view) {
        const filterHelper = new JetFilterQuery();
        const { regioChannels } = this.state;
        filterHelper.addTypes(brand, view);
        filterHelper.addDepartments(brand, view);
        filterHelper.addRegios(brand, regioChannels);
        filterHelper.addDivisions(brand, view);
        filterHelper.addUserFilter(brand, view);
        filterHelper.addTaxonomies(brand, view);
        return filterHelper;
    }

    getSortHelper(columns) {
        const sortOptions = [];
        const orderOptions = [];
        for (const column of columns) {
            if (column.isSortable) {
                sortOptions.push(column.id);
                orderOptions.push(column.orderBy);
            }
        }
        return new JetSortQuery(sortOptions, orderOptions);
    }

    getProgressStatus(queryStore) {
        if (!queryStore.rowsResult) {
            return ProgressStatus.LOAD;
        }
        if (queryStore.rowsResult.length === 0) {
            return ProgressStatus.EMPTY;
        }
        return ProgressStatus.SUCCESS;
    }

    static mergeDepartmentAndDivisionFilters(pageFilter, urlFilter) {
        const { departments, divisions } = pageFilter;
        let mergedDepartments;
        let mergedDivisions;
        if (departments || urlFilter.departments) {
            mergedDepartments = { departments: { ...departments, ...urlFilter.departments } };
        }
        if (divisions || urlFilter.divisions) {
            mergedDivisions = { divisions: { ...divisions, ...urlFilter.divisions } };
        }
        return { ...urlFilter, ...mergedDivisions, ...mergedDepartments };
    }

    async fetchRegioChannels() {
        try {
            const regioChannelId = '5a17f72a3745843fb762a503';
            const api = `${config.api}/channels/bild/${regioChannelId}`;
            const response = await Authenticator.secureFetch(api);
            const regioChannels = response?.subCategories.map((category) => category.name);
            this.setState({ regioChannels });
        } catch (error) {
            console.error('error fetching regio channel', error);
        }
    }
}

JetPageComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    useBannerOffset: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    tenant: PropTypes.string.isRequired,
    rest: PropTypes.string.isRequired
};

export const JetPage = withStyles(styles)(
    withAuth(observer(JetPageComponent))
);
