import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withAuth } from '../../../common/components/Auth';
import { RouteManager } from '../../../common/helpers/Route';
import { ProgressStatus } from './ProgressStatus';
import { BODY_2 } from '../../../common/configs/scaleCategory';

const styles = () => ({
    message: BODY_2
});

class ProgressViewComponent extends Component {

    constructor(props) {
        super(props);
        this.classes = props.classes;
    }

    render() {
        let content;
        switch (this.props.status) {
            case ProgressStatus.LOAD:
                content = this.getProgressIndicator();
                break;
            case ProgressStatus.EMPTY:
                content = this.getEmptyContent();
                break;
            case ProgressStatus.ERROR:
                content = this.getErrorMessage();
                break;
            default:
                // success
                content = this.props.children;
                break;
        }
        return (
            <div className={this.props.className}>
                {content}
            </div>
        );
    }

    getProgressIndicator() {
        return (
            <div>
                <CircularProgress />
            </div>
        );
    }

    getEmptyContent() {
        return (
            <p className={this.classes.message}>
                Es wurden keine Artikel gefunden.
            </p>
        );
    }

    getErrorMessage() {
        return (
            <p className={this.classes.message}>
                Es gab ein Problem beim Laden der Daten -
                aktuell ist der {RouteManager.getAppTitle(this.props.app)} nur aus dem
                Axel-Springer-Netz verfügbar.
                Bei Fragen stehen wir gerne unter <a
                    href="https://bild.slack.com/messages/CAX8P3V4Y/"
                    target="_blank"
                    rel="noreferrer noopener"
                >#artikelstream</a> zur Verfügung.
            </p>
        );
    }
}

ProgressViewComponent.defaultProps = {
    children: null
};

ProgressViewComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ]),
    status: PropTypes.oneOf(Object.values(ProgressStatus)).isRequired,
    app: PropTypes.string.isRequired
};

export const ProgressView = withStyles(styles)(withAuth(ProgressViewComponent));
