import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getDisplayName } from '../../helpers/Component/getDisplayName';
import { RouteManager } from '../../helpers/Route';
import { Authenticator } from './Authenticator';

export const withAuth = (WrappedComponent) => {
    class WithAuth extends Component {
        render() {
            const { location } = this.props;
            const userRoles = Authenticator.getRoles();
            const routeData = RouteManager.getRouteData(location);
            const props = { userRoles, ...routeData, ...this.props };
            return <WrappedComponent {...props}/>;
        }
    }

    WithAuth.propTypes = {
        location: PropTypes.object.isRequired
    };

    WithAuth.displayName = `WithAuth(${getDisplayName(WrappedComponent)})`;

    return withRouter(WithAuth);
};
