import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { ErrorPage } from './ErrorPage';

const styles = () => ({
    hint: {
        fontSize: '15px'
    }
});

class UnauthorizedErrorPageComponent extends Component {
    render() {
        const { classes } = this.props;
        return (
            <ErrorPage
                subject=""
                icon={<LockIcon/>}
                description={(<>
                    Authentifizierung fehlgeschlagen.
                    <br/>
                    <span className={classes.hint}>Überprüfe bitte deine Zugangsdaten.</span>
                </>)}
                action="Erneut anmelden"
                actionLink="/"
            />
        );
    }
}

UnauthorizedErrorPageComponent.propTypes = {
    classes: PropTypes.object.isRequired
};

export const UnauthorizedErrorPage = withStyles(styles)(UnauthorizedErrorPageComponent);
