import React from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '../../../common/components/Auth';
import { TabBar, TabData } from '../../../common/components/NavigationBar';
import { PAGE_CONFIGS } from '../JetPage';

class JetTabBarComponent extends React.Component {
    render() {
        const tenant = this.props.tenant;
        const settings = Object.entries(PAGE_CONFIGS[tenant] || {});
        const tabs = settings.map(([site, { label }]) => new TabData(label, site));
        return (
            <TabBar
                tabs={tabs}
                targetPath={'/jetstream/' + tenant}
                withQueryParams={false}
            />
        );
    }
}

JetTabBarComponent.propTypes = {
    app: PropTypes.string.isRequired,
    tenant: PropTypes.string.isRequired
};

export const JetTabBar = withAuth(JetTabBarComponent);
