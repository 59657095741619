import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { firestoreContext } from '../../../common/helpers/Firestore';
import { StarButton } from './StarButton';
import { trackLink } from '../../../../trackingScript';

export const WatchlistToggle = observer(({ watchlistKey, id }: { watchlistKey: string, id: string | number }) => {
    const { userStore } = useContext(firestoreContext);
    const idString = String(id);
    const isActive = userStore.getWatchlistIds(watchlistKey)?.some(id2 => idString === id2) ?? false;
    const toggle = () => {
        if (isActive) {
            userStore.removePageIdFromWatchlist(watchlistKey, idString);
            trackLink({
                'app_name': 'jetstream',
                'event_name': 'unstar-watchlist-item',
                'event_action': 'click',
                'event_label': null
            });
        } else {
            userStore.addPageIdToWatchlist(watchlistKey, idString);
            trackLink({
                'app_name': 'jetstream',
                'event_name': 'star-watchlist-item',
                'event_action': 'click',
                'event_label': null
            });
        }
    };
    return (
        <StarButton
            isActive={isActive}
            onButtonClick={toggle}
        />
    );
});
