import React from 'react';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';


const useStyles = makeStyles(() => ({
    container: {
        width: '40px',
        textAlign: 'center',
        padding: '6px 8px 5px 8px',
        borderRadius: '8px'
    },
    danger: {
        background: '#F9EAE7',
        cursor: 'pointer',
        '&:hover': {
            background: '#F5CCC3'
        }
    },
    tooltipContainer: {
        position: 'relative',
        display: 'inline-block',
        '&:hover $tooltip': {
            visibility: 'visible'
        }
    },
    tooltip: {
        visibility: 'hidden',
        width: 248,
        height: 28,
        lineHeight: '28px',
        backgroundColor: '#A6A6A6',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '2px',
        padding: '5px 8px 5px 8px',
        position: 'absolute',
        zIndex: 1,
        top: -42,
        left: '50%',
        transform: 'translateX(-50%)',
        transition: 'opacity 0.3s, visibility 0.3s'
    }
}));

export const RTRDisplay = ({ rtr, showRTR = true }: { rtr: number, showRTR?: boolean }) => {
    const roundedRTR = Math.round(rtr);
    const threshold = 35;
    const classes = useStyles();

    const className = classNames({ [classes.danger]: roundedRTR < threshold && showRTR }, classes.container, classes.tooltipContainer);
    const tooltip = <div className={`${classes.tooltip}`}>Der Wert ist kleiner als der Durchschnitt</div>;
    const content = showRTR ?
        <>
            <span>{roundedRTR}</span>
            <span> %</span>
            {(roundedRTR < threshold ? tooltip : null)}
        </> : <span>N/A</span>;

    return (
        <div className={className}>
            {content}
        </div>
    );
};
