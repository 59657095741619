export const getIframeURLFromPathName = (pathName: string) => {
    if (/jetstream/.test(pathName)) {
        return 'https://forms.office.com/Pages/ResponsePage.aspx?id=bKPnoUhqaEedZT9nnA87Eg3sSBp-k3FEmyzcaXXOPeRUNloyQzNEQUpFSFUwSVVZR01YWEowSEEyRi4u';
    } else if (/heatstream/.test(pathName)) {
        return 'https://forms.office.com/Pages/ResponsePage.aspx?id=bKPnoUhqaEedZT9nnA87Eg3sSBp-k3FEmyzcaXXOPeRURFk1UklYNVY0RTNFRlZaNU40UTFER1hJUi4u';
    } else if (/articlestream/.test(pathName)) {
        return 'https://forms.office.com/Pages/ResponsePage.aspx?id=bKPnoUhqaEedZT9nnA87Eg3sSBp-k3FEmyzcaXXOPeRUOEdCNjFaR1FaQU5XS01ESllLUDRENzNDTS4u';
    }
    return '';
};

export const getViewNameFromPathName = (pathName: string) => {
    if (/jetstream/.test(pathName)) {
        return 'jetstream';
    } else if (/heatstream/.test(pathName)) {
        return 'heatstream';
    } else if (/articlestream/.test(pathName)) {
        return 'articlestream';
    }
    return '';
};

export const getDaysDiffFromNow = (date: string | Date) => {
    const differentInMS = new Date().getTime() - new Date(date).getTime();
    return Math.floor(differentInMS / (1000 * 3600 * 24));
};
