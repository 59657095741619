import React, { CSSProperties, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import { ColorMapping } from '../../configs';
import { BarOption } from './BarOption';
import { FilterStoreContext, Filter } from '../../helpers/filter';
import { CAPTION } from '../../../common/configs/scaleCategory';
import { ThemedEcharts } from '../../../../echarts/ThemedEcharts';

type Props = {
    label: string,
    barOptions: BarOption[],
    data: { [key: string]: any },
    max?: number,
    style?: CSSProperties,
    grid?: { left: string, right: string, top: string, bottom: string }
}

export const BarChart = observer(({ label, data: { relatedData = {}, sharedMaxValues = {} }, barOptions, style, grid }: Props) => {

    const filterStore = useContext(FilterStoreContext);

    const max = sharedMaxValues.barChart || barOptions
        .filter(({ label }) => filterStore.isVisible(Filter.CHART_REFERRER, label))
        .reduce((sum, { id }) => sum + (relatedData[id] || 0), 0);

    useEffect(action(() => {
        const labels = barOptions.map(({ label = '' }) => label);
        filterStore.useFilter(Filter.CHART_REFERRER, labels);
        return action(() => {
            filterStore.unuseFilter(Filter.CHART_REFERRER, labels);
        });
    }), [barOptions]);

    const chartOption = {
        tooltip: {
            // trigger: 'item'
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            textStyle: { ...CAPTION }
        },
        xAxis: {
            show: false,
            max
        },
        yAxis: {
            show: false,
            type: 'category',
            data: [label]
        },
        series: barOptions.map(({ id, label = '', color = ColorMapping[label.toUpperCase() as BarOption['label']] }) => {
            if (!filterStore.isVisible(Filter.CHART_REFERRER, label)) {
                return { type: 'bar', data: [], stack: '0' };
            }
            return {
                name: label,
                data: [relatedData[id]],
                type: 'bar',
                stack: '0',
                itemStyle: {
                    color
                }
            };
        }),
        grid: grid ?? {
            left: '1%',
            right: '1%',
            top: '1%',
            bottom: '1%'
        }
    };

    return (
        <ThemedEcharts
            option={chartOption}
            notMerge={true}
            style={{
                height: 40,
                width: 150,
                ...style
            }}
        />
    );
});
