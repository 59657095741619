import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';
import { Card, IconButton } from '@material-ui/core';
import {
    Close as CloseIcon,
    Refresh as RefreshIcon,
    Lock as LockClosedIcon,
    LockOpen as LockOpenIcon
} from '@material-ui/icons';
import { ColumnData, ColumnResult } from '../JetTable';
import { SumSparklineChart, BarChart, TimelineBarChart } from '../JetChart';
import { WatchlistTable, WatchlistTableSettingType } from './WatchlistTable';
import { WatchlistMetaData } from './WatchlistMetaData';
import { TimeLeft } from './TimeLeft';
import { TIME_LABEL } from '../../helpers/Query/JetFilterQuery';
import type { WatchlistItemData } from '../../../common/helpers/Firestore';

const styles = (theme: Theme) => createStyles({
    root: {
        textAlign: 'left'
    },
    header: {
        backgroundColor: '#F8F9FA'
    },
    headerLeft: {
        padding: theme.spacing(2)
    },
    headerRight: {
        float: 'right',
        whiteSpace: 'nowrap',
        paddingLeft: 12
    },
    title: {
        fontWeight: 'bold',
        margin: 0
    },
    department: {
        margin: 0,
        paddingTop: theme.spacing(),
        fontSize: '0.75em'
    },
    departmentLabel: {
        display: 'inline',
        fontWeight: 'bold',
        marginRight: theme.spacing(0.5)
    },
    departmentValue: {
        textTransform: 'capitalize'
    },
    scrollableBox: {
        overflowX: 'scroll'
    },
    content: {
        padding: theme.spacing(2),
        minWidth: 780
    },
    outdated: {
        opacity: 0.4
    }
});

type Props = WithStyles<typeof styles> & {
    className?: string,
    watchlistItem: WatchlistItemData,
    rowData?: (ColumnResult | undefined)[],
    settings: ColumnData[],
    brand?: string
    onClose: (watchlistItemId: string) => void
    onRefresh: (watchlistItemId: string) => void
    onLock: (watchlistItemId: string) => void
    onUnlock: (watchlistItemId: string) => void
    time?: keyof typeof TIME_LABEL
    outdated?: boolean
}

class WatchlistItemComponent extends PureComponent<Props> {

    render() {
        const { classes, className, watchlistItem, settings, onClose, rowData = [], time, brand } = this.props;
        const pisSparklineData = rowData[3];
        const pisSparklineSettings = settings[3];
        const [{ relatedData: metaData = {} } = {}] = rowData;
        return (
            <Card variant="outlined" className={classNames(classes.root, this.props.outdated ? classes.outdated : undefined, className)}>
                <div className={classes.header}>
                    <div className={classes.headerRight}>
                        {this.getIcons(watchlistItem)}
                        <IconButton onClick={() => onClose(watchlistItem.id)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className={classes.headerLeft}>
                        <WatchlistMetaData metaData={metaData} brand={brand}/>
                    </div>
                </div>
                {rowData.length > 0 && (
                    <div className={classes.scrollableBox}>
                        <div className={classes.content}>
                            <SumSparklineChart
                                sparklines={pisSparklineSettings.sumSparklines}
                                sparklinesData={pisSparklineData?.sumSparklines}
                                style={pisSparklineSettings.sparklineStyle}
                                grid={{
                                    left: 30,
                                    right: 30,
                                    top: 30,
                                    bottom: 30
                                }}
                                position={this.handleOverlayPosition}
                                time={time}
                            />
                            <WatchlistTable
                                maxColumns={4}
                                settings={this.getTableSettings(rowData, settings)}
                            />
                        </div>
                    </div>
                )}
            </Card>
        );
    }

    private getTableSettings(rowData: (ColumnResult | undefined)[], settings: ColumnData[]) {
        const relatedData = rowData[0]!.relatedData;
        const wasPremium = !!relatedData.wasPremium;
        const hasConversions = relatedData.conversion && relatedData.conversion > 0;
        const pisBarData = rowData[1]!;
        const pisBarSettings = settings[1];
        const conversionBarData = rowData[2]!;
        const conversionBarSettings = settings[2];
        const conversionSparklineData = rowData[4]!;
        const conversionSparklineSettings = settings[4];

        let tableSettings: WatchlistTableSettingType[] = [{
            label: 'PIs',
            width: '15%',
            content: relatedData.pisTotal,
            isNumber: true
        }, {
            label: 'PIs nach Referrer',
            width: wasPremium ? '35%' : '85%',
            colSpan: (!wasPremium && hasConversions) ? 3 : 1,
            content: (<BarChart
                label={pisBarSettings.label}
                barOptions={pisBarSettings.barOptions}
                data={pisBarData}
                style={pisBarSettings.barChartStyle}
                grid={{
                    left: '0',
                    right: '0',
                    top: '1%',
                    bottom: '1%'
                }}
            />)
        }];
        if (wasPremium) {
            tableSettings = tableSettings.concat([{
                label: 'PIs Premium Page',
                width: '25%',
                content: relatedData.pisPremium,
                isNumber: true
            }, {
                label: 'PIs Conversion Page',
                width: '25%',
                content: relatedData.pisConversionPage,
                isNumber: true
            }]);
        }
        if (hasConversions) {
            tableSettings = tableSettings.concat([{
                label: 'Conversions',
                width: '15%',
                content: relatedData.conversion,
                isNumber: true
            }, {
                label: 'Conversions nach Referrer',
                width: '35%',
                content: (<BarChart
                    label={conversionBarSettings.label}
                    barOptions={conversionBarSettings.barOptions}
                    data={conversionBarData}
                    style={conversionBarSettings.barChartStyle}
                    grid={{
                        left: '0',
                        right: '0',
                        top: '1%',
                        bottom: '1%'
                    }}
                />)
            }, {
                label: 'Conversions im Zeitverlauf',
                width: '50%',
                content: (<TimelineBarChart
                    settings={conversionSparklineSettings.sumSparklines}
                    data={conversionSparklineData.sumSparklines}
                    style={conversionSparklineSettings.style}
                />),
                colSpan: 2
            }]);
        }
        return tableSettings;
    }

    private handleOverlayPosition(pos: number[], params: any, dom: any, rect: any, size: any) {
        const spacing = 15;
        const viewSize = size.viewSize;
        const contentSize = size.contentSize;

        let x;
        // position content horizontally right (if possible, otherwise left)
        if (pos[0] + spacing + contentSize[0] <= viewSize[0]) {
            x = pos[0] + spacing;
        } else {
            x = pos[0] - spacing - contentSize[0];
        }
        // center content vertically (if possible, otherwise top)
        const y = Math.max(viewSize[1] * 0.5 - contentSize[1] * 0.5, 0);

        return [x, y];
    }

    private getIcons(watchlistItem: any) {
        if (watchlistItem.locked) {
            return (
                <IconButton onClick={() => this.props.onUnlock(watchlistItem.id)}>
                    <LockClosedIcon />
                </IconButton>
            );
        } else {
            return (
                <React.Fragment>
                    <TimeLeft timestamp={watchlistItem.timestamp} />
                    <IconButton onClick={() => this.props.onRefresh(watchlistItem.id)}>
                        <RefreshIcon />
                    </IconButton>
                    <IconButton onClick={() => this.props.onLock(watchlistItem.id)}>
                        <LockOpenIcon />
                    </IconButton>
                </React.Fragment>
            );
        }
    }
}

export const WatchlistItem = withStyles(styles)(observer(WatchlistItemComponent));
