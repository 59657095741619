import React, { useContext } from 'react';
import { matchPath } from 'react-router-dom';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { firestoreContext } from '../../../common/helpers/Firestore';
import { AppPath } from '../../../common/helpers/Route';
import { SumSection } from '../JetSum';
import { JetColumnId } from '../JetPage';
import type { QueryOptions } from '../../helpers/Query/QueryTypes';
import { DASHBOARD_CONFIGS } from './_dashboardConfigs';
import { DashboardTable } from './DashboardTable';

type Props = {
    match: {
        params: {
            brand: string,
            id: string
        }
    }
};

export const DASHBOARD_PATH = `/${AppPath.JETSTREAM}/:brand/dashboard/:id`;
export const isDashboardPath = (path = '') => !!matchPath(path, { path: DASHBOARD_PATH, exact: true, strict: true });

export const JetDashboard = observer(({ match: { params: { brand, id } } }: Props) => {
    const { config, style, filter: dashboardFilter } = DASHBOARD_CONFIGS[brand]?.[id] ?? {};

    document.title = config.label;
    const filter: QueryOptions = { brand, time: 'today', orderBy: 'desc', sortBy: JetColumnId.pisTotal, ...dashboardFilter };

    const { queryStore } = useContext(firestoreContext);
    runInAction(() => {
        queryStore.pageConfig = config;
        Object.assign(queryStore.queryOptions, filter);
    });

    const sums = config.sums.map((settings, i) => (
        <div key={`sum${i}`} style={{ gridArea: `sum${i}`, width: '100%', overflow: 'hidden', ...style[`sum${i}`] }}>
            <SumSection
                brand={brand}
                filters={filter}
                settings={settings}
                sumData={queryStore.queryResult?.sums[i] as React.ComponentProps<typeof SumSection>['sumData']}
            />
        </div>
    ));

    const tables = config.tables.map((table, i) => (
        <div key={`table${i}`} style={{ gridArea: `table${i}`, width: '100%', overflow: 'hidden', ...style[`table${i}`] }}>
            <DashboardTable
                table={table}
                data={queryStore.queryResult?.tables[i] ?? []}
            />
        </div>
    ));

    return (
        <div style={{ width: '100vw', height: '100vh', ...style['dashboard'] }}>
            {sums}
            {tables}
        </div>
    );
});
