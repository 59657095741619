import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Authenticator } from './Authenticator';
import { RouteManager } from '../../../common/helpers/Route';

export const AuthRoute = ({ component: Component, path, ...rest }) => {
    return (
        <Route
            path={path}
            {...rest}
            render={routeProps => {
                if (!Authenticator.isLoggedIn()) {
                    // redirect unauthenticated user to login
                    Authenticator.redirectToLogin();
                    return null;
                }

                // validate url
                const { location } = routeProps;
                const userRoles = Authenticator.getRoles();
                const redirect = RouteManager.getRedirect(location, userRoles);
                if (redirect) {
                    return redirect;
                }

                const routeData = RouteManager.getRouteData(location);

                // allow access to content by authorized component
                if (_.isUndefined(Component)) {
                    return rest.render({ userRoles, ...routeData, ...routeProps });
                } else {
                    return <Component userRoles={userRoles} {...routeData} {...routeProps}/>;
                }
            }}
        />
    );
};

AuthRoute.propTypes = {
    component: PropTypes.any,
    path: PropTypes.string
};
