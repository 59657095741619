import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { config } from '../../../../config';
import type { CSSProperties } from 'react';

const styles: Record<string, CSSProperties> = {
    loading: {
        display: 'block',
        marginTop: 16,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    text: {
        padding: 16,
        textAlign: 'center'
    },
    iframe: {
        position: 'fixed',
        border: 'none'
    }
};

export const PositionHistory = () => {
    const [isServiceAvailble, setIsServiceAvailable] = useState<boolean | null>(null);

    useEffect(() => {
        if (config.positionHistorySource === undefined) {
            throw new Error('Config value positionHistorySource is undefined.');
        }
        // check if outside office network / VPN
        fetch(config.positionHistorySource).then((response) => {
            setIsServiceAvailable(response.status !== 403);
        }).catch((error) => {
            // something strange happened, check failed, but service is maybe still available => play safe and show service
            console.error(error);
            setIsServiceAvailable(true);
        });
    }, []);

    if (isServiceAvailble === null) {
        // loading
        return <CircularProgress style={styles.loading} />;
    }

    if (isServiceAvailble === false) {
        return <p style={styles.text}>Dieser Service ist nur im Firmennetz verfügbar. Wenn Du nicht im Büro bist, verbinde Dich bitte mit dem VPN.</p>;
    }

    return (
        <iframe src={config.positionHistorySource} width='100%' height='100%' style={styles.iframe}></iframe>
    );
};
