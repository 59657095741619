import { Environments } from '@spring-media/ep-ui-components';
import { AuthRole } from '../../components/Auth';
import { AppPath } from './AppPath';
import ArticleStreamFavIcon from '../../images/artikelstream.svg';
import ExactagConverterFavIcon from '../../images/exactagconverter.svg';
import HeatstreamFavIcon from '../../images/heatstream.svg';
import JetstreamFavIcon from '../../images/jetstream-boring.svg';

export type AppElement = {
    title: string,
    path: AppPath,
    favIcon: string,
    environment?: typeof Environments[keyof typeof Environments]
    subElements: TenantElement[]
};

export type TenantElement = {
    role: AuthRole,
    label?: string,
    subElements?: Element[],
    shouldRedirect?: boolean
}

export type Element = {
    path: string,
    label?: string,
    subElements?: Element[],
    shouldRedirect?: boolean
}

export const routes: AppElement[] = [{
    title: 'Artikelstream',
    favIcon: ArticleStreamFavIcon,
    path: AppPath.ARTICLESTREAM,
    environment: Environments.ArticleStream,
    subElements: [{
        role: AuthRole.BILD,
        label: 'Bild',
        subElements: [
            { path: 'bzberlin', label: 'BZ' },
            { path: 'autobild', label: 'AutoBild' },
            { path: 'computerbild', label: 'ComputerBild' },
            { path: 'sportbild', label: 'SportBild' },
            { path: 'all', label: 'Alle' }
        ]
    }, {
        role: AuthRole.WELT,
        label: 'Welt'
    }]
},
{
    title: 'Exactag Converter',
    favIcon: ExactagConverterFavIcon,
    path: AppPath.EXACTAG_CONVERTER,
    environment: Environments.ExactagConverter,
    subElements: [
        { role: AuthRole.BILD }
    ]
}, {
    title: 'Jetstream',
    favIcon: JetstreamFavIcon,
    path: AppPath.JETSTREAM,
    environment: Environments.JetStream,
    subElements: [{
        role: AuthRole.BILD,
        subElements: [
            { path: 'topArticle', label: 'Top Artikel' },
            { path: 'homeWeb', label: 'Home Web' },
            { path: 'premium' },
            { path: 'premiumSportBild' },
            { path: 'video' },
            { path: 'seo' },
            { path: 'watchlist' },
            { path: 'positionAnalysis' },
            {
                path: 'admin',
                subElements: [
                    { path: 'stats' }
                ],
                shouldRedirect: true
            }
        ],
        shouldRedirect: true
    }, {
        role: AuthRole.WELT,
        subElements: [
            { path: 'topArticle' },
            { path: 'home' },
            { path: 'premium' },
            { path: 'video' },
            { path: 'push' },
            { path: 'seo' },
            { path: 'watchlist' },
            {
                path: 'dashboard',
                subElements: [
                    { path: 'newsroom_kpis' },
                    { path: 'conversion_goals' },
                    { path: 'top_articles' }
                ],
                shouldRedirect: true
            }
        ],
        shouldRedirect: true
    }, {
        role: AuthRole.TRAVELBOOK,
        subElements: [
            { path: 'topArticle' },
            { path: 'seo' },
            { path: 'watchlist' }
        ],
        shouldRedirect: true
    }, {
        role: AuthRole.TECHBOOK,
        subElements: [
            { path: 'topArticle' },
            { path: 'seo' },
            { path: 'watchlist' }
        ],
        shouldRedirect: true
    }, {
        role: AuthRole.FITBOOK,
        subElements: [
            { path: 'topArticle' },
            { path: 'seo' },
            { path: 'watchlist' }
        ],
        shouldRedirect: true
    }, {
        role: AuthRole.STYLEBOOK,
        subElements: [
            { path: 'topArticle' },
            { path: 'seo' },
            { path: 'watchlist' }
        ],
        shouldRedirect: true
    }, {
        role: AuthRole.HOMEBOOK,
        subElements: [
            { path: 'topArticle' },
            { path: 'seo' },
            { path: 'watchlist' }
        ],
        shouldRedirect: true
    }]
}, {
    title: 'Heatstream',
    favIcon: HeatstreamFavIcon,
    path: AppPath.HEATSTREAM,
    environment: Environments.HeatStream,
    subElements: [
        AuthRole.BILD,
        AuthRole.TRAVELBOOK,
        AuthRole.TECHBOOK,
        AuthRole.FITBOOK,
        AuthRole.STYLEBOOK,
        AuthRole.HOMEBOOK
    ].map(role => ({
        role,
        subElements: [{ path: 'web' }, { path: 'app' }],
        shouldRedirect: true
    }))
}];
