import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { Accordion } from '../../../common/components/Accordion';
import { withAuth } from '../../../common/components/Auth';
import type { RouteData } from '../../../common/helpers/Route';

type TypeFilterProps = {
    onTimeFilterChange: (positionTimeFilter: { showPositionTime: boolean, showAufmacherTime: boolean }) => void
} & RouteData

const useStyles = makeStyles(() => ({
    timeFilter: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

export const TimeFilter = withAuth(({ onTimeFilterChange, rest }: TypeFilterProps) => {
    const styles = useStyles();
    const [showPositionTime, setShowPositionTime] = useState(true);
    const [showAufmacherTime, setShowAufmacherTime] = useState(true);
    // disable lead time for app view
    const isAppView = rest === 'app';

    useEffect(() => {
        onTimeFilterChange({
            showPositionTime,
            showAufmacherTime: isAppView ? false : showAufmacherTime
        });
    }, [showPositionTime, showAufmacherTime, isAppView]);

    return (
        <Accordion label='Standzeit'>
            <div className={styles.timeFilter}>
                <FormControlLabel
                    control={
                        <Checkbox
                            data-testid='position-checkbox'
                            checked={showPositionTime}
                            onChange={(e) => { setShowPositionTime(e.target.checked); }}
                        />}
                    label="Position"
                />
                {!isAppView && <FormControlLabel
                    control={
                        <Checkbox
                            data-testid='aufmacher-checkbox'
                            checked={showAufmacherTime}
                            onChange={(e) => { setShowAufmacherTime(e.target.checked); }}
                        />}
                    label="Aufmacher"
                />}
            </div>
        </Accordion>
    );
});
