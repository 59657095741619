import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Icon, Tooltip } from '@material-ui/core';

const styles = () => ({
    container: {
        display: 'flex',
        padding: '5px 5px 5px 0'
    },
    icon: {
        fontSize: '1em',
        marginRight: 5,
        width: 'auto'
    },
    checkIcon: {
        visibility: 'visible',
        fontSize: '1em',
        color: '#1fe022',
        paddingLeft: 2
    },
    checkIconHidden: {
        visibility: 'hidden'
    }
});

class CheckIconComponent extends Component {

    constructor(props) {
        super(props);
        this.classes = this.props.classes;
    }

    render() {
        const title = this.props.isChecked ? this.props.checkTitle : this.props.title;
        let icon;
        if (this.props.icon) {
            icon = this.props.icon;
        } else {
            const iconClass = classNames(this.props.iconClassName, this.classes.icon);
            icon = <Icon className={iconClass}/>;
        }
        const checkClass = this.getCheckIconClass(this.props.isChecked);
        return (
            <Tooltip title={title} placement="bottom">
                <div className={this.classes.container}>
                    {icon}
                    <Icon className={classNames('fas fa-check', checkClass)}/>
                </div>
            </Tooltip>
        );
    }

    getCheckIconClass(shouldCheck) {
        if (shouldCheck) {
            return this.classes.checkIcon;
        } else {
            return classNames(this.classes.checkIcon, this.classes.checkIconHidden);
        }
    }
}

CheckIconComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    checkTitle: PropTypes.string.isRequired,
    icon: PropTypes.element,
    iconClassName: PropTypes.string,
    isChecked: PropTypes.bool.isRequired
};

export const CheckIcon = withStyles(styles)(CheckIconComponent);
