import React, { useState, ReactElement } from 'react';
import { makeStyles, IconButton, useMediaQuery } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    leftArrow: {
        position: 'absolute',
        top: '40%',
        left: '-50px'
    },
    rightArrow: {
        position: 'absolute',
        top: '40%',
        right: '-50px'
    },
    groupedChart: {
        position: 'relative'
    },
    ellipses: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        gap: '4px',
        marginTop: '8px'
    },
    ellipse: {
        width: '12px',
        height: '12px',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        transition: 'background-color 0.5s ease',
        borderRadius: '10px'
    },
    selectedEllipse: {
        backgroundColor: theme.palette.primary.main
    }
}));

export const PieChartGroup = ({ children }: { children: ReactElement[] }) => {
    const styles = useStyles();
    const isMobile = useMediaQuery('(max-width:700px) or ((min-width:900px) and (max-width:960px))');

    const [touchPosition, setTouchPosition] = useState(0);
    const [currentChildIndex, setCurrentChildIndex] = useState(0);

    const moveForward = () => {
        if (currentChildIndex < children.length - 1) {
            setCurrentChildIndex(currentChildIndex + 1);
        }
    };
    const moveBackward = () => {
        if (currentChildIndex > 0) {
            setCurrentChildIndex(currentChildIndex - 1);
        }
    };
    const onTouchMove = (e: any) => {
        const currentTouch = e.touches[0].clientX;
        const diff = touchPosition - currentTouch;

        if (diff > 5) {
            moveForward();
        }

        if (diff < -5) {
            moveBackward();
        }

        setTouchPosition(0);
    };

    const ellipses = children.map((_, i) => (<div
        key={i}
        onClick={() => { setCurrentChildIndex(i); }}
        className={classNames([styles.ellipse], { [styles.selectedEllipse]: i === currentChildIndex })}
    >
    </div>
    ));

    if (children.length === 0) {
        return null;
    }

    if (!isMobile) {
        return <>{children}</>;
    }

    return (
        <div>
            <div
                className={styles.groupedChart}
                onTouchStart={(e) => { setTouchPosition(e.touches[0].clientX); }}
                onTouchMove={onTouchMove}
            >
                <IconButton className={styles.leftArrow} onClick={moveBackward} disabled={currentChildIndex === 0}>
                    <KeyboardArrowLeftIcon />
                </IconButton>
                {children[currentChildIndex]}
                <IconButton className={styles.rightArrow} onClick={moveForward} disabled={currentChildIndex === children.length - 1} >
                    <KeyboardArrowRightIcon />
                </IconButton>
            </div>
            <div className={styles.ellipses}>{ellipses}</div>
        </div>
    );
};
