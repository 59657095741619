import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { getDisplayName } from './getDisplayName';
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

export type WithBreakpoint = {
    matchesBreakpoint: boolean
}

export const withBreakpoint = <T extends WithBreakpoint>(breakpoint: Breakpoint) =>
    (WrappedComponent: React.ComponentType<T>) => {
        function WithBreakpoint(props: Omit<T, keyof WithBreakpoint>) {
            const theme = useTheme();
            const matchesBreakpoint = useMediaQuery(theme.breakpoints.up(breakpoint));
            return <WrappedComponent {...props as T} matchesBreakpoint={matchesBreakpoint} />;
        }

        WithBreakpoint.displayName = `WithBreakpoint(${getDisplayName(WrappedComponent)})`;

        return WithBreakpoint;
    };
