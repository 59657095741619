import React, { Component } from 'react';
import LockIcon from '@material-ui/icons/Lock';
import { ErrorPage } from './ErrorPage';

export class ForbiddenErrorPage extends Component {
    render() {
        return (
            <ErrorPage
                icon={<LockIcon />}
                description={<>
                    Kein Zugriff?
                    <br/>
                    Beantrage jetzt deine Berechtigung über das IT-Portal.
                </>}
                action="Zum IT-Portal"
            />
        );
    }
}
