import React, { FunctionComponent, ReactElement } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { observer } from 'mobx-react';

const styles = () => createStyles({
    row: {
        display: 'flex',
        alignItems: 'center'
    },
    headerCell: {
        boxSizing: 'border-box'
    },
    contentCell: {
        borderBottom: 0,
        boxSizing: 'border-box',
        fontWeight: 'bold'
    }
});

export type WatchlistTableSettingType = {
    label: string,
    width: string,
    content: any,
    colSpan?: number,
    isNumber?: boolean
};

type Props = WithStyles<typeof styles> & {
    maxColumns: number,
    settings: WatchlistTableSettingType[]
};

const WatchlistTableComponent: FunctionComponent<Props> = observer(({ classes, maxColumns, settings }) => {

    const rows: ReactElement[] = [];
    const headerCells: ReactElement[] = [];
    const contentCells: ReactElement[] = [];

    for (const setting of settings) {
        const colSpan = setting.colSpan ?? 1;
        headerCells.push((
            <TableCell
                key={'head:' + setting.label}
                colSpan={colSpan}
                variant="head"
                className={classes.headerCell}
                style={{
                    width: setting.width
                }}
            >
                {setting.label}
            </TableCell>
        ));
        contentCells.push((
            <TableCell
                key={'content:' + setting.label}
                colSpan={colSpan}
                className={classes.contentCell}
                style={{
                    width: setting.width
                }}
            >
                {setting.isNumber ? parseInt(setting.content, 10).toLocaleString() : setting.content}
            </TableCell>
        ));
    }

    const pushCellsToRow = (startIndex: number, endIndex: number) => {
        rows.push((
            <TableRow key={rows.length} className={classes.row}>
                {headerCells.slice(startIndex, endIndex)}
            </TableRow>
        ));
        rows.push((
            <TableRow key={rows.length} className={classes.row}>
                {contentCells.slice(startIndex, endIndex)}
            </TableRow>
        ));
    };


    let startIndex = 0;
    let endIndex = 0;
    let usedColumns = 0;
    while (endIndex < headerCells.length) {
        const colSpan = headerCells[endIndex].props.colSpan;
        const isLastCell = endIndex == headerCells.length - 1;
        const colSpanExceedsMaxColumns = usedColumns + colSpan > maxColumns;
        if (colSpanExceedsMaxColumns) {
            pushCellsToRow(startIndex, endIndex);
            if (isLastCell) {
                pushCellsToRow(endIndex, endIndex + 1);
            }
            usedColumns = 0;
            startIndex = endIndex;
        }
        if (!colSpanExceedsMaxColumns && isLastCell) {
            pushCellsToRow(startIndex, endIndex + 1);
        }
        usedColumns += colSpan;
        endIndex++;
    }
    return (
        <Table>
            <TableBody>
                {rows}
            </TableBody>
        </Table>
    );
});

WatchlistTableComponent.displayName = 'WatchlistTable';

export const WatchlistTable = withStyles(styles)(WatchlistTableComponent);
