import React, { useEffect, useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const formatAge = (age: number) => {
    const hours = Math.floor(age / 3600);
    const minutes = Math.floor((age - (hours * 3600)) / 60);
    if (hours >= 24) {
        return '> 24h';
    } else if (hours === 0) {
        return minutes < 15 ? '< 15m' : `${minutes}m`;
    }
    return minutes > 0 ? `${hours}h ${minutes}m` : `${hours}h`;
};

type FormattedAgeProps = {
     age?: number,
     teaserAgeClass: string,
     abbreviation: string,
     title: string
}
type MobileFormattedAgeProps = {
    leadTime?: number,
    positionTime?: number,
    teaserAgeClass: string
}

export const FormattedAge = ({ age, teaserAgeClass, abbreviation, title }: FormattedAgeProps) => {
    const formattedAge = age ? formatAge(age) : '?';
    const tooltipTitle = formattedAge !== '?' ? title : title + ' nicht verfügbar';

    return (
        <Tooltip data-testid='tooltip' title={tooltipTitle} arrow>
            <b className={teaserAgeClass} style={{ backgroundColor: formattedAge === '< 15m' ? 'red' : 'black' }}>
                {abbreviation} {formattedAge}
            </b>
        </Tooltip>
    );
};

export const MobileFormattedAge = ({ leadTime, positionTime, teaserAgeClass }: MobileFormattedAgeProps) => {
    let formattedLeadTime = leadTime ? formatAge(leadTime) : '?';
    let formattedPositionTime = positionTime ? formatAge(positionTime) : '?';
    const timeRef = useRef<HTMLParagraphElement>(document.createElement('p'));

    formattedLeadTime = `AZ: ${formattedLeadTime}`;
    formattedPositionTime = `SZ: ${formattedPositionTime}`;

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (timeRef.current.innerHTML.startsWith('AZ')) {
                timeRef.current.innerHTML = formattedPositionTime;
                timeRef.current.style.backgroundColor = '#666';
            } else {
                timeRef.current.innerHTML = formattedLeadTime;
                timeRef.current.style.backgroundColor = '#000';
            }
        }, 3000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <b className={teaserAgeClass} ref={timeRef} />
    );
};

