import React, { FunctionComponent, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CircularProgress, Container, Divider, Grid, Tooltip, Typography } from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import { Authenticator } from '../../../common/components/Auth';
import { firestoreStores } from '../../../common/helpers/Firestore';
import { config } from '../../../../config';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    root: {
        borderRadius: '8px',
        color: 'rgba(0,0,0,0.8)',
        fontSize: '14px',
        height: '164px',
        marginBottom: '8px',
        textAlign: 'left',
        width: '260px',
        '@media (max-width: 600px)': {
            width: '100%'
        }
    },
    content: {
        padding: '8px 16px'
    },
    itemNow: {
        maxWidth: '135px'
    },
    itemForecast: {
        maxWidth: '107px'
    },
    item: {
        flexGrow: 2,
        '@media (max-width: 600px)': {
            maxWidth: '100%'
        }
    },
    title: {
        color: 'rgba(0,0,0,0.6)',
        fontFamily: 'Lato, sans-serif',
        fontSize: '20px',
        fontWeight: 700,
        letterSpacing: '0.4px',
        paddingBottom: '4px'
    },
    subtitle: {},
    number: {
        fontSize: '20px',
        fontWeight: 700,
        marginRight: '8px'
    },
    trendNeutral: {
        color: '#1260D6'
    },
    trendUp: {
        color: '#09976C'
    },
    trendDown: {
        color: '#C83110'
    },
    trendIcon: {
        display: 'block',
        height: '16px',
        width: '16px'
    },
    valueLine: {
        fontSize: '12px',
        lineHeight: '24px',
        padding: 0,
        paddingTop: '6px',
        paddingBottom: '6px'
    },
    trendLine: {
        padding: 0,
        margin: 0,
        maxWidth: '135px'
    },
    tooltip: {
        backgroundColor: '#ADADAD',
        borderRadius: '2px',
        color: '#ffffff',
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '17px',
        letterSpacing: '0.4px',
        padding: '5px,8px',
        textTransform: 'none',
        width: '164px'
    },
    loading: {
        display: 'block',
        marginTop: 16,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    divider: {
        margin: '12px 0'
    }
}));

type VisitPredictionData = {
    visitsTillNow: number;
    predictedVisitsTillEOD: number;
    averageVisitsTillNow: number;
    averageVisitsTillEOD: number;
    trend: number;
    currentHour: number;
    previousVisitPrediction?: Omit<VisitPredictionData, 'previousVisitPrediction'>
};

const VisitPredictionComponent: FunctionComponent = () => {
    const classes = useStyles();

    const isBetaUser = firestoreStores.userStore.getBetaStatus();

    const [visitPredictionData, setVisitPredictionData] = useState<VisitPredictionData>();

    let visitPredictionTimer: number;
    const fetchVisitPredictionData = async () => {
        try {
            const response = await Authenticator.secureFetch(`${config.api}/visit-prediction`);
            setVisitPredictionData(response);

            // set new timer to fetch new data at the next full hour + 1 minute, just in case the local clock is not synced perfectly
            visitPredictionTimer = window.setTimeout(fetchVisitPredictionData, (3_600_000 - Date.now() % 3_600_000) + 60_000);
        } catch (err) {
            visitPredictionTimer = window.setTimeout(fetchVisitPredictionData, 60_000);
        }
    };

    useEffect(() => {
        if (isBetaUser) {
            fetchVisitPredictionData();
        }

        return () => {
            if (visitPredictionTimer) {
                clearTimeout(visitPredictionTimer);
            }
        };
    }, [isBetaUser]);

    if (!isBetaUser) {
        return <></>;
    }

    const trendThreshold = 200_000;

    const numberFormat = Intl.NumberFormat(undefined, { maximumFractionDigits: 2 });
    const percentageFormat = Intl.NumberFormat(undefined, { style: 'percent', maximumFractionDigits: 2 });

    if (!visitPredictionData) {
        return <CircularProgress className={classes.loading}/>;
    }

    let trendClass = classes.trendNeutral;
    let trendIcon = null;
    const trendPercentage = visitPredictionData?.trend - 1;
    const deltaPredictionPreviousHour = visitPredictionData.previousVisitPrediction ? visitPredictionData.predictedVisitsTillEOD - visitPredictionData.previousVisitPrediction.predictedVisitsTillEOD : 0;
    if (visitPredictionData && deltaPredictionPreviousHour < (-1 * trendThreshold)) {
        trendClass = classes.trendDown;
        trendIcon = <><TrendingDownIcon className={classes.trendIcon}/> Trend</>;
    } else if (visitPredictionData && deltaPredictionPreviousHour > trendThreshold) {
        trendClass = classes.trendUp;
        trendIcon = <><TrendingUpIcon className={classes.trendIcon}/> Trend</>;
    }

    return <Card className={classes.root}>
        <CardContent className={classes.content}>
            <Typography variant='subtitle1' className={classes.title} align='left'>Visits Heute</Typography>
            <Grid container spacing={3} justify='space-between'>
                <Grid item className={classNames(classes.item, classes.itemNow)}>
                    <Typography variant='subtitle2' className={classes.subtitle}>Aktuell</Typography>
                    <Container className={classes.valueLine}><span className={classes.number}>{numberFormat.format(visitPredictionData.averageVisitsTillNow / 1_000_000)}</span> Mio</Container>
                    <Container className={classes.trendLine}>
                        <Tooltip classes={{ tooltip: classes.tooltip }} placement='bottom' title='Durchschnitt im Vergleich zur gleichen Stunde am gleichen Wochentag'>
                            <div className={trendClass}>{percentageFormat.format(trendPercentage)} {trendPercentage > 0 ? 'über dem Durchschnitt' : 'unter dem Durchschnitt'}</div>
                        </Tooltip>
                    </Container>
                </Grid>
                <Divider orientation="vertical" flexItem className={classes.divider}/>
                <Grid item className={classNames(classes.item, classes.itemForecast)}>
                    <Typography variant='subtitle2' className={classes.subtitle}>Prognose</Typography>
                    <Container className={classes.valueLine}><span className={classes.number}>{numberFormat.format(visitPredictionData.predictedVisitsTillEOD / 1_000_000)}</span> Mio</Container>
                    <Container className={classes.trendLine}>
                        <Tooltip classes={{ tooltip: classes.tooltip }} placement='bottom' title={'Trend im Vergleich zur letzten Stunde der heutigen Vorhersage. Letztes Update vor ' + (new Date()).getMinutes() + ' Minuten. Nächstes: ' + (visitPredictionData.currentHour % 23 + 1) + ' Uhr'}>
                            <div className={trendClass}>{trendIcon ?? 'stabiler Trend'}</div>
                        </Tooltip>
                    </Container>
                </Grid>
            </Grid>
        </CardContent>
    </Card>;
};

export const VisitPrediction = VisitPredictionComponent;
