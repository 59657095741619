import { JetColumnId } from '../../jetstream/components/JetPage/JetColumnId';

export class SyncedConfig {

    constructor({ tachoGoals = {},
        tachoGoalsPageViewsFromSearchByDepartment = {},
        tachoGoalsPageViewsTotalByDepartment = {} } = {}
    ) {
        this.tachoGoals = tachoGoals;
        this.goals = tachoGoalsPageViewsTotalByDepartment;
        this.searchGoals = tachoGoalsPageViewsFromSearchByDepartment;
    }

    getTachoGoal(timeFilter, department, column, subTenant) {
        return this.tachoGoals[`${this.getColumnPrefix(column)}${timeFilter}_${this.getDepartmentString(department)}${this.getSubTenantSuffix(subTenant)}`];
    }

    getColumnPrefix(column = JetColumnId.conversion) {
        if (column === JetColumnId.conversion) {
            return '';
        }
        return `${column}_`;
    }

    getSubTenantSuffix(subTenant) {
        if (subTenant) {
            return `_${subTenant}`;
        }
        return '';
    }

    getDepartmentString = (department = {}) => Object.entries(department)
        .sort(([d1], [d2]) => d1.localeCompare(d2))
        .map(([department, val]) => department.toLowerCase() + (val === 1 ? '' : val))
        .join('_');
}
