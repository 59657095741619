import React from 'react';
import { FirestoreStore } from './FirestoreStore';
import { UserStore } from './UserStore';
import { QueryStore } from './QueryStore';
import { WatchlistStore } from './WatchlistStore';

const firestore = new FirestoreStore();
const userStore = new UserStore(firestore);
export const watchlistStore = new WatchlistStore();
const queryStore = new QueryStore(firestore, userStore, watchlistStore);

export const firestoreStores = {
    firestore,
    userStore,
    queryStore
};

export const firestoreContext = React.createContext(firestoreStores);
export const FirestoreProvider = firestoreContext.Provider;
