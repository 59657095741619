export class DataValidator {
    static isValidUrl(input) {
        // cms-id (8 digits) or url with cms-id
        if (input.length === 8 && !isNaN(parseInt(input, 10))) {
            return true;
        } else if (this.getCmsIdFromUrl(input) !== -1) {
            return true;
        } else if (this.getLeanIdFromUrl(input)) {
            return true;
        } else {
            return false;
        }
    }

    static getLeanIdFromUrl(url) {
        const parts = url.split('-');
        if (parts.length > 0 && parts[parts.length - 1].length === 24) {
            const leanIdMatch = /([a-f\d]{24})/.exec(parts[parts.length - 1]);
            if (leanIdMatch && leanIdMatch.length > 1) {
                return leanIdMatch[1];
            }
        }

        return undefined;
    }

    static getCmsIdFromUrl(url) {
        if (typeof url !== 'undefined') {
            let matches = url.match(/-[0-9]{2,15}(\.|,)/);
            if (matches) {
                matches = matches[0].match(/[0-9]{8}/);
                if (matches) {
                    return matches[0];
                }
            }
        }
        return -1;
    }
}
