export enum ColorMapping {
    MOBIL = '#fb7293',
    DESKTOP = '#37A2DA',
    WEB = '#37A2DA',
    APP = '#9FE6B8',
    SEARCH = '#D86FCD',
    DISCOVER = '#50A053',
    SOCIAL = '#FFDB5C',
    MISC = '#ff9f7f',
    GESTERN = '#aaaaaa',
    NEWS = '#EFB9CB',
    'KEIN REFERRER' = '#9BA3F2',
    'MEDIA STARTS' = '#37A2DA'
}
