import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { Table, TableBody, TableRow, TableCell, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { PageResult, PageData } from '../JetPage/PageData';
import { ColumnType } from '../JetTable';
import { ArrayElement } from '../../../common/helpers/Utils/types';

const useStyles = makeStyles(() => ({
    row: {
        height: 93,
        '&:last-child td': {
            borderBottom: 0
        }
    },
    tableCell: {
        fontSize: 21
    },
    headerCell: {
        boxSizing: 'border-box'
    },
    number: {
        fontWeight: 'bold'
    }
}));

type Props = {
    data: PageResult['columns'],
    table: ArrayElement<PageData['tables']>
};

export const DashboardTable: FunctionComponent<Props> = observer(({ table: { columns, numberOfRows = 5 }, data }) => {
    const classes = useStyles();
    const headerCells = columns.map(({ type, id, label }) => {
        const isNumber = type === ColumnType.NUMERIC;
        return (
            <TableCell
                key={id}
                className={classes.tableCell}
                align={isNumber ? 'right' : 'left'}
                variant="head"
            >
                {label}
            </TableCell>
        );
    });

    const rows = new Array(numberOfRows).fill(0).map((_, rowIndex) => {
        const cells = columns.map(({ type, id }, columnIndex) => {
            const content = data[columnIndex]?.[rowIndex]?.data;
            const isNumber = type === ColumnType.NUMERIC;
            return (
                <TableCell
                    key={id}
                    className={classNames(
                        classes.tableCell, {
                            [classes.number]: isNumber
                        }
                    )}
                    align={isNumber ? 'right' : 'left'}
                >
                    {isNumber && content ? content.toLocaleString() : content}
                </TableCell>
            );
        });

        return (
            <TableRow key={rowIndex} className={classes.row}>
                {cells}
            </TableRow>
        );
    });

    return (
        <Table>
            <TableBody>
                <TableRow key='header'>
                    {headerCells}
                </TableRow>
                {rows}
            </TableBody>
        </Table>
    );
});
