import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { PurestComponent } from '../../../../PurestComponent';
import { FilterMode } from '../../../common/helpers/Query';

const styles = (theme) => createStyles({
    root: {
        margin: theme.spacing(0.5),
        transition: 'width 0.25s',
        lineHeight: '19px'
    }
});

class StreamFilterButtonComponent extends PurestComponent {

    constructor(props) {
        super(props);
        this.classes = this.props.classes;
    }

    render() {
        let icon = null;
        if (this.props.mode === FilterMode.enabled) {
            icon = <DoneIcon/>;
        } else if (this.props.mode === FilterMode.inverted) {
            icon = <CloseIcon/>;
        }
        const style = icon ? { background: 'rgba(0, 0, 0, 0.12)' } : {};
        return (
            <Chip
                icon={icon}
                style = {style}
                label={this.props.name}
                onClick={this.props.onClick}
                className={this.classes.root}
            />
        );
    }
}

StreamFilterButtonComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    mode: PropTypes.number.isRequired
};

export const StreamFilterButton = withStyles(styles)(StreamFilterButtonComponent);
