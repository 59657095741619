import { Query } from '../../../common/helpers/Query';
import { JetOrderOption } from './JetOrderOption';

export class JetSortQuery extends Query {
    constructor(sortKeys, defaultSortValues) {
        super();
        this.sortKeys = sortKeys;
        this.defaultSortValues = defaultSortValues;
    }

    getAllSort(querySettings) {
        const validSettings = {};
        // sort
        let index = this.sortKeys.indexOf(querySettings.sortBy);
        index = index === -1 ? 0 : index;
        validSettings.sortBy = this.sortKeys[index];
        // order
        const rawOrderBy = querySettings.orderBy;
        if (Object.values(JetOrderOption).includes(rawOrderBy)) {
            validSettings.orderBy = rawOrderBy;
        } else {
            validSettings.orderBy = this.defaultSortValues[index];
        }
        return validSettings;
    }

    // get only sort settings if selected by user
    getUsedSort(querySettings) {
        const validSettings = {};
        // sort
        const rawSortBy = querySettings.sortBy;
        const index = this.sortKeys.indexOf(rawSortBy);
        if (index !== -1) {
            validSettings.sortBy = this.sortKeys[index];
        }
        // order
        const rawOrderBy = querySettings.orderBy;
        if (Object.values(JetOrderOption).includes(rawOrderBy)) {
            validSettings.orderBy = rawOrderBy;
        }
        return validSettings;
    }

    getUrlSort(location) {
        const querySettings = this.getQuerySettings(location);
        return this.getUsedSort(querySettings);
    }
}
