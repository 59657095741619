import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { firestoreStores, watchlistStore } from '../../../common/helpers/Firestore';
import { ColumnData } from '../JetTable/ColumnData';
import { WatchlistSearchBar } from './WatchlistSearchBar';
import { WatchlistReferrerFilter } from './WatchlistReferrerFilter';
import { SelfloadingWatchlistItem } from './SelfloadingWatchlistItem';
import type { WatchlistItemData } from '../../../common/helpers/Firestore';

const styles = (theme: Theme) => createStyles({
    root: {
        position: 'relative'
    },
    card: {
        marginBottom: theme.spacing(2),
        width: '100%'
    }
});

type Props = WithStyles<typeof styles> & {
    watchlistKey: string,
    settings: ColumnData[]
}

const WatchlistComponent: FunctionComponent<Props> = (props) => {
    const { classes, watchlistKey, settings } = props;
    const { userStore } = firestoreStores;
    const [listItems, setListItems] = useState<WatchlistItemData[]>([]);
    const watchlistStoreCallback = useRef<(() => void) | null>(null);

    const itemInserted = () => {
        const watchlistItemsFromDb = userStore.getWatchlist(props.watchlistKey) || [];
        const watchlistItems = watchlistItemsFromDb.map((item: WatchlistItemData) => ({
            id: item.id,
            timestamp: item.timestamp,
            locked: item.locked
        }));
        setListItems([...watchlistItems]);
    };

    const onItemClose = (pageId: string) => {
        userStore.removePageIdFromWatchlist(watchlistKey, pageId);
    };

    useEffect(() => {
        if (watchlistStoreCallback.current) {
            watchlistStore.removeUpdateEventListener(watchlistStoreCallback.current);
        }

        watchlistStoreCallback.current = () => {
            itemInserted();
        };

        watchlistStore.addUpdateEventListener(watchlistStoreCallback.current);
        itemInserted();

        return () => {
            if (watchlistStoreCallback.current) {
                watchlistStore.removeUpdateEventListener(watchlistStoreCallback.current);
            }
        };
    }, [props.watchlistKey]);

    // if item exists it will be put to the top of the list
    const onItemInserted = (ids: string[]) => {
        const newItems = [];
        for (const id of ids) {
            const item = listItems.find((listItem: WatchlistItemData) => listItem.id === id);
            if (item) {
                newItems.push(item);
            }
        }
        const filteredItems = listItems.filter((item: WatchlistItemData) => !ids.includes(item.id));
        if (newItems.length > 0) {
            setListItems([...newItems, ...filteredItems]);
        }
    };

    const watchlistItemElements = listItems.map((item: WatchlistItemData) => {
        return (<
            SelfloadingWatchlistItem
            watchlistItem={item}
            watchlistKey={watchlistKey}
            settings={settings}
            key={item.id}
            log={console.log}
            onItemClose={onItemClose}
        />);
    });

    return <div className={classes.root}>
        <WatchlistSearchBar
            watchlistKey={watchlistKey}
            onItemInserted={onItemInserted}
        />
        {listItems.length > 0 && <WatchlistReferrerFilter watchlistKey={watchlistKey}/>}
        {watchlistItemElements}
    </div>;
};

export const Watchlist = withStyles(styles)(WatchlistComponent);
