
import React, { CSSProperties, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { ThemedEcharts } from '../../../../echarts';
import { ColorMapping } from '../../configs';
import { SumDataSparkline } from '../JetSum';
import { FilterStoreContext, Filter } from '../../helpers/filter';
import type { ColumnResult } from '../JetTable';
import { CAPTION } from '../../../common/configs/scaleCategory';

type Props = WithStyles<typeof styles> & {
    settings: SumDataSparkline[],
    data: ColumnResult['sumSparklines'],
    style: CSSProperties,
    getChartWidth?: (barCount: number) => number
}

const styles = () => createStyles({
    root: {
        textAlign: 'left' // fix tooltip overlay to always be left aligned (even when parent has textAlign center)
    }
});

const getReducedData = (data: ColumnResult['sumSparklines']) => {
    const times = data[0]?.sparklineTimes ?? [];
    let lastHour = -1;
    const reducedSparklineTimes: string[] = [];
    const reducedSparklines: (number)[][] = data.map(() => []);
    for (let i = 0; i < times.length; i++) {
        const time = new Date(times[i]);
        const hour = time.getHours();
        if (lastHour !== hour) {
            const timeFrameEnd = new Date(time);
            timeFrameEnd.setHours(timeFrameEnd.getHours() + 1);
            const formattedTime = `${time.getHours()} - ${timeFrameEnd.getHours()} Uhr`;
            reducedSparklineTimes.push(formattedTime);
            reducedSparklines.forEach((sparkline, sparklineIndex) => {
                const sparklineUnit = (data[sparklineIndex]?.sparkline || [])[i];
                sparkline.push(sparklineUnit || 0);
            });
            lastHour = hour;
        } else {
            reducedSparklines.forEach((sparkline, sparklineIndex) => {
                const sparklineUnit = (data[sparklineIndex]?.sparkline || [])[i];
                sparkline[sparkline.length - 1] += sparklineUnit || 0;
            });
        }
    }

    return {
        sparklineTimes: reducedSparklineTimes,
        sparklines: reducedSparklines
    };
};

export const TimelineBarChart = withStyles(styles)(observer(({
    settings, data, style, getChartWidth, classes
}: Props) => {

    const filterStore = useContext(FilterStoreContext);

    useEffect(action(() => {
        const labels = settings.map(({ label = '' }) => label);
        filterStore.useFilter(Filter.CHART_REFERRER, labels);
        return action(() => {
            filterStore.unuseFilter(Filter.CHART_REFERRER, labels);
        });
    }), [settings]);

    const reducedData = getReducedData(data);

    const chartOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            textStyle: { ...CAPTION }
        },
        xAxis: {
            show: true,
            data: reducedData.sparklineTimes,
            axisTick: {
                alignWithLabel: true
            },
            axisLabel: { ...CAPTION, color: 'rgba(0, 0, 0, 0.8)' }
        },
        yAxis: {
            show: false
        },
        grid: {
            left: 20,
            right: 22,
            top: '1%',
            bottom: 20
        },
        series: settings.map(({ label = '' }, i) => {
            if (!filterStore.isVisible(Filter.CHART_REFERRER, label)) {
                return { type: 'bar', stack: '0', data: [] };
            }
            return {
                name: label,
                data: reducedData.sparklines[i],
                type: 'bar',
                stack: '0',
                barWidth: 10,
                itemStyle: {
                    color: ColorMapping[label.toUpperCase() as keyof typeof ColorMapping]
                }
            };
        })
    };
    const barCount = reducedData.sparklineTimes.length;
    return (
        <ThemedEcharts
            option={chartOption}
            notMerge={true}
            className={classes.root}
            style={{
                height: 60,
                width: getChartWidth?.(barCount) || '100%',
                ...style
            }}
        />
    );
}));
