import React, { useEffect } from 'react';
import { colorToStyle } from './ColorScale';
import { TeaserWithData } from './HeatmapSync';
import { FormattedAge, MobileFormattedAge } from './FormattedAge';
import { Conversion } from './Conversion';
import { NotificationPrompt } from './NotificationPrompt';
import homebotNotificationImage from './images/homebotNotification.svg';
import { useMediaQuery } from '@material-ui/core';

export enum DialogState {
    OpenDialog = 'openDialog',
    CloseDialog = 'closeDialog'
}

type AnchorOverlayComponentProps = {
    teaser: TeaserWithData,
    isAufmacher: boolean,
    showTeaserPosition: boolean,
    showPositionTime: boolean,
    showAufmacherTime: boolean,
    showConversion: boolean,
    classes: Record<'teaser' | 'rankNumber' | 'teaserPosition' | 'teaserAge' | 'teaserInfo' | 'teaserNotification' | 'notificationPromptOverlay', string>
    rect: { top?: number, left?: number, width: number, height: number }
}

const renderBackground = (teaser: TeaserWithData) => {
    return (
        <div>
            <img src={teaser.screenshot} alt='Teaser Bild' style={{
                width: '100%',
                height: '100%'
            }} />
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    left: 0,
                    right: 0,
                    backgroundColor: colorToStyle(teaser.color),
                    position: 'absolute',
                    display: 'inline'
                }}
            />
        </div>
    );
};

const AnchorOverlayComponent = ({
    teaser,
    classes,
    rect,
    showTeaserPosition,
    showPositionTime,
    showAufmacherTime,
    showConversion,
    isAufmacher }: AnchorOverlayComponentProps) => {
    let content = null;
    if (teaser.screenshot) {
        content = renderBackground(teaser);
    }

    const backgroundStyle = teaser.screenshot ? {} : {
        backgroundColor: colorToStyle(teaser.color)
    };
    const isMobile = useMediaQuery('(max-width:430px)');
    const [showNotificationPrompt, setShowNotificationPrompt] = React.useState(false);
    const [dialogOpened, setDialogOpened] = React.useState(false);

    const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        window.dispatchEvent(new CustomEvent(DialogState.OpenDialog));
        setShowNotificationPrompt(true);
    };
    const handleClose = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setShowNotificationPrompt(false);
        setDialogOpened(false);
    };

    const hidePrompt = () => {
        setShowNotificationPrompt(false);
        setDialogOpened(false);
    };

    const handleDialogOpen = () => {
        setDialogOpened(true);
    };

    useEffect(() => {
        window.addEventListener(DialogState.OpenDialog, handleDialogOpen);
        window.addEventListener(DialogState.CloseDialog, hidePrompt);
        return () => {
            window.removeEventListener(DialogState.OpenDialog, handleDialogOpen);
            window.removeEventListener(DialogState.CloseDialog, hidePrompt);
        };
    });

    return (
        <a
            hidden={false}
            href={teaser.url}
            className={classes.teaser}
            target="_blank"
            rel="noopener noreferrer"
            style={{
                ...rect,
                ...backgroundStyle
            }}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
                if (showNotificationPrompt || dialogOpened) {
                    e.preventDefault();
                    setShowNotificationPrompt(false);
                    setDialogOpened(false);
                }
            }}
        >
            {content}
            <div className={classes.teaserInfo}>
                <b className={classes.rankNumber}>{`${teaser.rank}`}</b>
                {isAufmacher && showAufmacherTime && !isMobile &&
                    <FormattedAge
                        age={teaser.leadTime}
                        teaserAgeClass={classes.teaserAge}
                        abbreviation="AZ: "
                        title="Aufmacherzeit (letzte 48h)"
                    />
                }
                {isAufmacher && showPositionTime && !isMobile &&
                    <FormattedAge
                        age={teaser.positionTime}
                        teaserAgeClass={classes.teaserAge}
                        abbreviation='SZ: '
                        title='Standzeit (letzte 48h)'
                    />
                }
                {isAufmacher && isMobile &&
                    <MobileFormattedAge
                        leadTime={teaser.leadTime}
                        positionTime={teaser.positionTime}
                        teaserAgeClass={classes.teaserAge}
                    />
                }
                {showTeaserPosition && <b className={classes.teaserPosition}>{teaser.teaserPosition || '-'}</b>}
                {showConversion && <Conversion conversion={teaser.conversion} isPremium={teaser.isPremium} />}
                {teaser.homebotNotification && <img className={classes.teaserNotification} onClick={handleOpen} src={homebotNotificationImage} />}
                {showNotificationPrompt && <div className={classes.notificationPromptOverlay}>
                    <NotificationPrompt leanId={teaser.homebotNotification?.leanId} hidePrompt={hidePrompt} handleClose={handleClose} />
                </div>}
            </div>
        </a>
    );
};

export const AnchorOverlay = AnchorOverlayComponent;
