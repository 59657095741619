import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import { ThemedEcharts } from '../../../../echarts';
import { ColorMapping } from '../../configs';
import { useSize } from '../../../common/helpers/Utils';
import { FilterStoreContext, Filter } from '../../helpers/filter';
// use direct import to avoid circular dependency
import { SumDataSparkline } from '../JetSum/SumDataSparkline';
import { CAPTION } from '../../../common/configs/scaleCategory';
import { TimeHelper } from '../../../common/helpers/Time';

const sumSparklines = (combined, sparkline) => sparkline.map((value, i) => (combined[i] || 0) + (parseInt(value, 10) || 0));

export const SumSparklineChart = observer(({ sparklines = [], sparklinesData = [], grid, position, time, style, showLegend = false }) => {
    const filterStore = useContext(FilterStoreContext);

    const usedSparklines = sparklines.filter(sparkline => sparkline.isSparklineVisible(time));
    const displayedSparklines = usedSparklines.filter(({ label }) => filterStore.isVisible(Filter.CHART_REFERRER, label));

    // use wrapper to calculate echarts dimensions
    const rootRef = useRef(null);
    const [width, height] = useSize(rootRef);

    useEffect(action(() => {
        const labels = usedSparklines.map(({ label = '' }) => label);
        filterStore.useFilter(Filter.CHART_REFERRER, labels);
        return action(() => {
            filterStore.unuseFilter(Filter.CHART_REFERRER, labels);
        });
    }), [sparklines]);

    const calcSelectedCombinedSparkline = (combineIndex) => {
        const combinedData = sparklinesData[combineIndex];
        if (!combinedData) {
            return undefined;
        }
        const selectedSparklines = displayedSparklines
            .filter(({ id }) => id !== SumDataSparkline.SELECTED_COMBINED)
            .map(({ id }) => combinedData?.combined[id])
            .filter(Array.isArray);

        return selectedSparklines.reduce(sumSparklines, []);
    };

    const sparklineTimes = sparklinesData.find(data => {
        return !!data && data.sparklineTimes.length > 0;
    })?.sparklineTimes || [];

    const sparklineOption = {
        ...showLegend && {
            legend: {
                data: sparklines.map(({ label }) => label),
                bottom: 0
            }
        },
        tooltip: {
            trigger: 'axis',
            textStyle: { ...CAPTION },
            position
        },
        xAxis: {
            boundaryGap: false,
            data: sparklineTimes.map(sparklineTime => `${TimeHelper.getHourAndMinute(new Date(sparklineTime))} Uhr`),
            axisLabel: { ...CAPTION, color: 'rgba(0, 0, 0, 0.8)' }
        },
        yAxis: {
            type: 'value',
            show: false
        },
        series: sparklines.map((sparkline, i) => {
            if (!displayedSparklines.includes(sparkline)) {
                return { data: [], type: 'line' };
            }
            const { id, label = '', chartOptions = {} } = sparkline;
            return {
                data: id === SumDataSparkline.SELECTED_COMBINED ? calcSelectedCombinedSparkline(i) : sparklinesData[i]?.sparkline || [],
                name: label,
                type: 'line',
                areaStyle: {},
                symbol: 'none',
                ...chartOptions,
                itemStyle: {
                    color: ColorMapping[label.toUpperCase()],
                    ...chartOptions.itemStyle
                }
            };
        }),
        grid: grid || {
            left: 30,
            right: 30,
            top: '1%',
            bottom: showLegend ? '30%' : '10%'
        }
    };
    return (
        <div
            ref={rootRef}
            style={{
                height: 240,
                width: '100%',
                ...style
            }}>
            <ThemedEcharts
                option={sparklineOption}
                notMerge={true}
                style={{
                    // needs pixel units because of echarts limitation
                    width: width,
                    height: height
                }}
            />
        </div>
    );
});

SumSparklineChart.propTypes = {
    sparklines: PropTypes.array.isRequired,
    sparklinesData: PropTypes.array,
    style: PropTypes.object,
    grid: PropTypes.object,
    position: PropTypes.func,
    time: PropTypes.string,
    showLegend: PropTypes.bool
};
