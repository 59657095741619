import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { ThemedEcharts } from '../../../../echarts';
import { ColorMapping } from '../../configs';
import FlagIcon from './flag.svg';
import SuccessIcon from './success.svg';
import GoalIcon from './goalIcon.svg';

const useStyles = makeStyles((theme) => ({
    container: {
        fontFamily: 'Lato',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        backgroundColor: '#fff',
        boxShadow: `0px 1px 3px 0px rgba(0, 0, 0, 0.2),
                    0px 2px 2px 0px rgba(0, 0, 0, 0.12),
                    0px 0px 2px 0px rgba(0, 0, 0, 0.14)
                    `,
        borderRadius: '4px',
        width: '280px',
        height: '280px'
    },
    sumText: {
        fontSize: 32,
        lineHeight: '60px',
        background: `linear-gradient(94.45deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent'
    },
    sumPercentage: {
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.6)'
    },
    sum: {
        display: 'flex',
        cursor: 'pointer',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '23%',
        left: '100px'
    },
    title: {
        fontSize: 20,
        color: 'rgba(0, 0, 0, 0.6)',
        position: 'absolute',
        bottom: '40px'
    },
    flag: {
        position: 'absolute',
        cusror: 'pointer',
        bottom: '80px',
        right: '40px'
    },
    success: {
        color: '#09976C'
    },
    notification: {
        display: 'flex',
        position: 'absolute',
        width: '200px',
        alignItems: 'center',
        gap: '10px',
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: '12px',
        paddingLeft: '12px',
        borderRadius: '4px',
        boxShadow: `0px 1px 3px 0px rgba(0, 0, 0, 0.2),
                    0px 2px 2px 0px rgba(0, 0, 0, 0.12),
                    0px 0px 2px 0px rgba(0, 0, 0, 0.14)
                    `,
        height: '40px',
        zIndex: 10000
    },
    legend: {
        position: 'absolute',
        boxSizing: 'border-box',
        top: '60px',
        right: '0px',
        fontSize: 12,
        backgroundColor: '#fff',
        boxShadow: `0px 1px 3px 0px rgba(0, 0, 0, 0.2),
                    0px 2px 2px 0px rgba(0, 0, 0, 0.12),
                    0px 0px 2px 0px rgba(0, 0, 0, 0.14)
                    `,
        zIndex: 1000,
        width: '240px',
        padding: '16px',
        borderRadius: '8px',
        color: 'rgba(0, 0, 0, 0.6)'
    },
    legendArea: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '8px'
    },
    legendColor: {
        borderRadius: '50%',
        width: '10px',
        height: '10px'
    },
    colorContainer: {
        display: 'flex',
        gap: '12px'
    },
    leftMargin: {
        marginLeft: '-2px'
    }
}));

export type TotalPieChartType = {
    title: string,
    goal?: number,
    data?: { [key: string]: number },
    chartOptions: { id: string, label: string }[]
};

const getGoalToDisplay = (goal: number) => {
    if (goal < 1000) {
        return `${goal}`;
    } else if (goal < 1000000) {
        return `${Math.round(goal / 1000)} K`;
    }
    return `${Math.round(goal / 1000000)} M`;
};

const Legend = ({ title, goal, data, chartOptions }: TotalPieChartType) => {
    const styles = useStyles();
    if (!data) { return null; }
    const sum = Object.values(data).reduce((acc, value) => acc + value, 0);
    const legends = chartOptions
        .filter(({ id }) => data[id])
        .map(({ id, label }) => (
            <div key={id} className={styles.legendArea}>
                <div className={styles.colorContainer}>
                    <span className={styles.legendColor} style={{ backgroundColor: ColorMapping[label.toUpperCase() as keyof typeof ColorMapping] }} />
                    <span>{label}</span>
                </div>
                <span>{data[id].toLocaleString()}</span>
            </div>
        ));

    return (
        <div className={styles.legend}>
            <div className={styles.legendArea}>
                <span>{title}</span>
                <span>{sum.toLocaleString()}</span>
            </div>
            <div className={styles.legendArea}>
                <div className={styles.colorContainer}>
                    <img className={styles.leftMargin} src={GoalIcon} />
                    <span className={styles.leftMargin}>Ziel</span>
                </div>
                <span>{goal?.toLocaleString()}</span>
            </div>
            {legends}
        </div>
    );
};


export const PieChart = ({ title, goal, data, chartOptions }: TotalPieChartType) => {
    const [showGoalNotification, setShowGoalNotification] = useState(false);
    const [showLegend, setShowLegend] = useState(false);
    const styles = useStyles();

    if (!data || Object.keys(data).length === 0 || !goal) {
        return null;
    }

    const colors = chartOptions
        .filter(({ id }) => data[id])
        .map(({ label }) => ColorMapping[label.toUpperCase() as keyof typeof ColorMapping]);

    const values = chartOptions
        .filter(({ id }) => data[id])
        .map(({ id, label }) => ({ value: data[id], name: label }));

    const sum = Object.values(data).reduce((acc, value) => acc + value, 0);
    const totalToDisplay = getGoalToDisplay(sum);
    const goalToDisplay = getGoalToDisplay(goal);
    const getChartOptions = () => {
        const total = values.length == 1 ? goal : sum;
        return {
            tooltip: {
                trigger: 'item',
                valueFormatter: (value: any) => `${value.toLocaleString()} (${Math.round((value / total * 100))}%)`
            },
            series: [
                {
                    type: 'pie',
                    radius: ['47%', '60%'],
                    center: ['50%', '70%'],
                    startAngle: 90,
                    width: '280px',
                    height: '300px',
                    labelLine: {
                        show: false
                    },
                    label: {
                        show: false
                    },
                    top: '-100px',
                    color: colors,
                    data: [...values, {
                        // make a record to fill the remaning
                        value: goal - sum,
                        itemStyle: {
                            color: '#f0f0f0',
                            decal: {
                                symbol: 'none'
                            }
                        },
                        label: {
                            show: false
                        },
                        emphasis: {
                            disabled: true
                        },
                        tooltip: {
                            show: false
                        }

                    }]
                }
            ]
        };
    };

    return (
        <div className={styles.container}>
            <ThemedEcharts
                option={getChartOptions()}
                notMerge={true}
                style={{
                    height: 300,
                    width: 300,
                    marginLeft: '2px'
                }}
                opts={{ renderer: 'svg' }}
            />
            <div className={styles.sum}
                onMouseOver={() => { setShowLegend(true); }}
                onMouseLeave={() => { setShowLegend(false); }}>
                <div className={styles.sumText}>{totalToDisplay}</div>
                <div className={styles.sumPercentage}>
                    <span className={classNames({ [styles.success]: sum >= goal })}>{Math.round(((sum / goal) * 100))}</span> % von {goalToDisplay}
                </div>
                {showLegend && <Legend title={title} data={data} goal={goal} chartOptions={chartOptions} />}
            </div>
            <div className={styles.title}>
                {title}
            </div>
            {
                sum >= goal &&
                <div className={styles.flag}
                    onMouseOver={() => { setShowGoalNotification(true); }}
                    onMouseLeave={() => { setShowGoalNotification(false); }}>
                    <img src={FlagIcon} />
                    {showGoalNotification &&
                        <div className={styles.notification}>
                            <img src={SuccessIcon} />
                            <div>Das ziel ist erreicht!</div>
                        </div>
                    }
                </div>
            }
        </div >
    );
};
