import { TimeHelper } from '../../../common/helpers/Time';
import { ArticleHistory, ArticleItem, FacebookPost } from '../Article/ArticleStreamTypes';

type PageData = {
    name: string
    url: string
    time: string
}
export class StreamItemData {

    public readonly url: string;
    public readonly cmsId: number;
    public readonly site: string;
    public readonly department: string;
    public readonly regionDetail: string | null;
    public readonly teaserUrl: string;
    public readonly teaserImageFormat: 'quad' | 'landscape';
    public readonly title: string;
    public readonly isPlusContent: boolean;
    public readonly isOnMobile: boolean;
    public readonly isOnDesktop: boolean;
    public readonly isOnFacebook: boolean;
    public readonly livePages: PageData[];
    public readonly plannedPages: PageData[];
    public readonly pubDate: string;
    public readonly time: string;
    public readonly conversions: {total: number, youtube: number, instagram: number};

    constructor(rawData: ArticleItem, site: string) {
        const domain = this.getDomain(site, rawData);
        this.url = this.getUrl(rawData, domain);
        this.cmsId = rawData.article.cmsId;
        this.site = `${domain}`;
        this.conversions = rawData.article.conversions;
        this.department = rawData.article.department;
        this.regionDetail = rawData.article.regionDetail;
        this.teaserUrl = rawData.article.teaserImageUrl;
        this.teaserImageFormat = rawData.article.teaserImageFormat ? rawData.article.teaserImageFormat : 'quad';
        this.pubDate = rawData.article.publicationDate;
        this.time = TimeHelper.getHourAndMinute(rawData.article.publicationDate);
        this.title = rawData.article.title;
        this.isPlusContent = rawData.article.isPremium;
        const pages = this.checkForLiveAndPlannedPages(rawData.facebookPosts);
        this.livePages = pages.live;
        this.plannedPages = pages.planned;
        const isDesktopAndMobile = this.checkForIsDesktopAndMobile(rawData.articleHistory);
        this.isOnMobile = isDesktopAndMobile.isMobile;
        this.isOnDesktop = isDesktopAndMobile.isDesktop;
        this.isOnFacebook = rawData.facebookPosts.filter(e => {
            return new Date(e.publicationDate).getTime() < new Date().getTime();
        }).length > 0;
    }

    private getDomain(site: string, rawData: ArticleItem) {
        if (site === 'all') {
            return `${rawData.article?.domain}`;
        }
        if (rawData.article.department?.toLowerCase() === 'sportbild' ||
            rawData.article.canonicalUrl?.endsWith('sport.html')) {
            return 'sportbild.bild.de';
        }
        return site === 'bzberlin' ? 'www.bz-berlin.de' : `www.${site}.de`;
    }

    private getUrl(rawData: ArticleItem, domain: string) {
        if (rawData.article.canonicalUrl === null || rawData.article.canonicalUrl.length === 0) {
            return `https://${domain}/-${rawData.article.cmsId}`;
        }
        return `https://${domain}${rawData.article.canonicalUrl}`;
    }

    isSamePublishDay(articleData: any) {
        return TimeHelper.isSameDay(this.pubDate, articleData.pubDate);
    }


    private checkForLiveAndPlannedPages(facebookPosts: FacebookPost[]) {
        const livePages: PageData[] = [];
        const plannedPages: PageData[] = [];
        facebookPosts.forEach((post) => {
            const pub = new Date(post.publicationDate);
            const timeText = TimeHelper.getHourAndMinute(post.publicationDate);
            const pageData = {
                name: post.page.name,
                url: 'https://business.facebook.com/' + post.page.id + '/posts/' + post.id,
                time: timeText
            };
            if (pub.getTime() <= Date.now()) {
                livePages.push(pageData);
            } else {
                plannedPages.push(pageData);
            }
        });
        return {
            live: livePages,
            planned: plannedPages
        };
    }

    private checkForIsDesktopAndMobile(articleHistory: ArticleHistory) {
        const isDesktop = articleHistory && articleHistory.homesPlacedOn.includes('desktop');
        const isMobile = articleHistory && articleHistory.homesPlacedOn.includes('mobile');
        return { isDesktop: isDesktop, isMobile: isMobile };
    }
}

