import { Query } from './Query';

export const SortOption = {
    date: 'pubDate',
    dateDesc: 'pubDate-desc',
    mobile: 'mobile',
    desktop: 'desktop',
    clicks: 'clicksTotal',
    clicksDesc: 'clicksTotal-desc',
    shares: 'sharesTotal',
    sharesDesc: 'sharesTotal-desc'
};

export class SortQuery extends Query {

    constructor() {
        super();
        this.sortTypes = [
            SortOption.dateDesc,
            SortOption.date,
            SortOption.mobile,
            SortOption.desktop,
            SortOption.clicks,
            SortOption.clicksDesc,
            SortOption.shares,
            SortOption.sharesDesc
        ];
    }

    getUrlSort(location) {
        const querySettings = this.getQuerySettings(location);
        return this.getUsedSort(querySettings);
    }

    // get only sort settings if selected by user
    getUsedSort(querySettings) {
        const validSettings = {};
        const index = this.sortTypes.indexOf(querySettings.sortBy);
        if (index !== -1) {
            validSettings.sortBy = this.sortTypes[index];
        }
        return validSettings;
    }

    getAllSort(querySettings) {
        const validSettings = {};
        let index = this.sortTypes.indexOf(querySettings.sortBy);
        index = index === -1 ? 0 : index;
        validSettings.sortBy = this.sortTypes[index];
        return validSettings;
    }
}
