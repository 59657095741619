import { JetColumnId } from '../../components/JetPage/JetColumnId';
import { TIME_LABEL } from './JetFilterQuery';
import type { Timestamp } from 'firebase/firestore';
import type { QueryBuilder } from './QueryBuilder';

export type DataResult = string | number
export type DataResults = {[id in JetColumnId]?: DataResult}

export type RawResult = {
    columnsData: {[id: string]: DataResult | number[]}[]
    sumsDataV2: {[id: string]: DataResult | number[]}
    sparklineTimes: string[]
    executionTime: number
    lastSync: Timestamp
}

export enum RequestType {
    SUM = 'sum',
    COLUMN = 'column'
}

export type Filter = {
    departments?: any
    filter?: any
}

export type Sort = {
    sortBy?: JetColumnId
    orderBy?: 'asc' | 'desc'
}

export type QueryOptions = {
    pageIds?: string[] | string,
    brand?: string
    groupBrands?: string[]
    time?: keyof typeof TIME_LABEL
    sparklineTime?: keyof typeof TIME_LABEL
} & Filter & Sort

export type RequestOptions = {
    id?: string
    type?: RequestType
} & QueryOptions

export type Query = QueryOptions & {
    columns: { id: string }[]
    sums: { id: string }[]
}

export type BuildQueriesFunction = (queryBuilder: QueryBuilder, queryOptions: QueryOptions, overwrites?: QueryOptions) => void
export type BuildQueryResultFunction<T> = (getData: (requestOptions: RequestOptions) => RawResult | undefined) => T

export interface QueryProducer<T> {
    buildQueries: BuildQueriesFunction
    buildQueryResult: BuildQueryResultFunction<T>
}
