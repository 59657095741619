// note: on build, this file is compiled into client code executed in a browser
// keep this in mind and don't leak secure information here!
/* eslint-disable no-process-env */
const prodHost = 'stream.springtools.de';
export const LEAN_ID_PLACEHOLDER = 'LEAN_ID_PLACEHOLDER';
export const config = {
    debug: localStorage?.debug === 'true',
    showTeaserLabels: localStorage?.showTeaserLabels === 'true',
    showConversion: localStorage?.showConversion === 'true',
    showCTR: localStorage?.showCTR === 'true',
    api: getApiEndpoint(),
    jwtTestToken: process.env.REACT_APP_JWT_TEST_TOKEN,
    prodHost,
    stage: process.env.REACT_APP_STAGE || getStage(),
    positionHistorySource: process.env.REACT_APP_API_POSITION_HISTORY_SOURCE,
    firebase: {
        apiKey: 'AIzaSyAnzqslAzd8z8yNWPk3xs5qKctPHcJC91g',
        authDomain: 'spring-content-intelligence.firebaseapp.com',
        databaseURL: 'https://spring-content-intelligence.firebaseio.com',
        projectId: 'spring-content-intelligence',
        storageBucket: 'spring-content-intelligence.appspot.com',
        messagingSenderId: '428479599576',
        appId: '1:428479599576:web:49758b211d9538339098ad'
    },
    watchlistExpireMs: 48 * 60 * 60 * 1000,
    USE_BILD_IMAGE_SERVER: true,
    isLocalHost: isLocalHost(),
    editorLink: (process.env.REACT_APP_STAGE || getStage()) === 'production' ? `https://editor.bild.leancms.de/article/${LEAN_ID_PLACEHOLDER}/meta` : `https://editor.stg.bild.leancms.de/article/${LEAN_ID_PLACEHOLDER}/meta`
};

function getStage() {
    const host = window?.location?.host;
    if (host === prodHost) {
        return 'production';
    } else {
        return 'staging';
    }
}

function isLocalHost() {
    return window && window.location && window.location.host === 'localhost:8080';
}

function getApiEndpoint() {
    if (process.env.REACT_APP_API) {
        return process.env.REACT_APP_API;
    }
    const protocol = window && window.location && window.location.protocol;
    const hostname = window && window.location && window.location.hostname;
    return `${protocol}//${hostname}:3000`;
}

export const debugLog = (...args) => {
    if (config.debug) {
        console.log(...args);
    }
};
