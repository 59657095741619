import objectHash from 'object-hash';
import { JetColumnId } from '../../components/JetPage/JetColumnId';
import { RequestOptions, RequestType, Query, QueryOptions, QueryProducer } from './QueryTypes';

export type QueryData = {
    hash: string,
    pageIds?: string[],
    requestOptions: RequestOptions,
    pageConfig: QueryProducer<any>,
    query: Query
}

export class QueryBuilder {
    requestOptionDefaults: RequestOptions = {}
    queries: {
        hash: string,
        requestOptions: RequestOptions,
        requests: {
            [id: string]: { [key: string]: boolean }
        }
    }[] = []

    setRequestOptionDefaults(requestOptions: RequestOptions) {
        this.requestOptionDefaults = requestOptions;
    }

    addRequest(id: JetColumnId | null, options: { [key: string]: boolean } = {}, requestOptions?: RequestOptions) {
        const parsedId = String(id);
        options.value = options.value ?? true;
        const { requests } = this.getQuery({ ...this.requestOptionDefaults, ...requestOptions });
        requests[parsedId] = requests[parsedId] || {};
        const request = requests[parsedId];
        Object.entries(options).forEach(([key, value]) => {
            request[key] = request[key] || value;
        });
    }

    private getQuery(requestOptions: RequestOptions) {
        const hash = objectHash(requestOptions);
        const existingQuery = this.queries.find(query => query.hash === hash);
        if (existingQuery) {
            return existingQuery;
        }
        const query = { hash, requestOptions, requests: {} };
        this.queries.push(query);
        return query;
    }

    getQueries(pageConfig: QueryProducer<any>, queryOptions: QueryOptions): QueryData[] {
        pageConfig.buildQueries(this, queryOptions);
        return this.queries
            .filter(({ requestOptions: { type } = {} }) => {
                if (!type) {
                    console.log('request without type should not happen');
                }
                return !!type;
            })
            .map(({ hash, requestOptions = {}, requests }) => {
                const { type, ...overwrites } = requestOptions;
                const requestObject = Object.entries(requests)
                    // remove id from request (always available in response by default)
                    .filter(([id]) => id !== JetColumnId.id)
                    .map(([id, options]) => ({ ...options, id }));
                return {
                    hash,
                    pageIds: queryOptions?.pageIds && Array.isArray(queryOptions?.pageIds) ? queryOptions?.pageIds : [],
                    requestOptions,
                    pageConfig,
                    query: {
                        ...queryOptions,
                        ...overwrites,
                        columns: type === RequestType.COLUMN ? requestObject : [],
                        sums: type === RequestType.SUM ? requestObject : []
                    }
                };
            });
    }
}
