import React, { FunctionComponent } from 'react';
import { createStyles, IconButton, WithStyles, withStyles } from '@material-ui/core';
import {
    Close as CloseIcon,
    Warning as WarningIcon,
    Error as ErrorIcon,
    CheckCircle as CheckCircleIcon,
    Info as InfoIcon
} from '@material-ui/icons';
import classNames from 'classnames';

const styles = () => createStyles({
    red: {
        backgroundColor: '#F9EAE7'
    },
    redIcon: {
        color: '#C83110'
    },
    yellow: {
        backgroundColor: '#FEF9DF'
    },
    yellowIcon: {
        color: '#F8D627'
    },
    green: {
        backgroundColor: '#E6F5F0'
    },
    greenIcon: {
        color: '#09976C'
    },
    blue: {
        backgroundColor: '#E7EFFB'
    },
    blueIcon: {
        color: '#1260D6'
    },
    banner: {
        top: 0,
        zIndex: 1001,
        width: '100%',
        position: 'fixed',
        padding: '8px 0px 6px 0px',
        textAlign: 'left',
        fontFamily: 'Lato, sans-serif',
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '19.1px',
        color: 'rgba(0,0,0,0.8)',
        display: 'flex',
        alignItems: 'flex-start'
    },
    title: {
        padding: '0px 48px 0px 0px',
        order: 2
    },
    text: {
        order: 3
    },
    closeButton: {
        padding: '0px 16px 0px 0px',
        marginLeft: 'auto',
        order: 4,
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0)'
        }
    },
    icon: {
        padding: '0px 48px 0px 24px',
        order: 1
    },
    '@media (max-width: 553px)': {
        banner: {
            minHeight: 74,
            padding: '16px 0px 22px 0px',
            flexWrap: 'wrap'
        },
        text: {
            width: '100%',
            padding: '8px 40px 0px 24px',
            order: 4
        },
        closeButton: {
            padding: '0px 29px 0px 0px',
            marginLeft: 'auto',
            order: 3
        }
    }
});


type Props = WithStyles<typeof styles> & {
    icon?: any
    title?: string
    message?: string
    closable?: boolean
    color: string
    closeCallback?: () => void
}

export type BannerProps = WithStyles<typeof styles> & {
    title: string,
    message: string,
    closable?: boolean
    closeCallback?: () => void
}

const cropString = (message: string, max: number) => {
    return message.slice(0, max) + (message.length > max ? ' ...' : '');
};

const BannerListComponent: FunctionComponent<Props> = (props: Props) => {

    return <div className={`${props.classes.banner} ${props.color}`}>
        {props.icon}
        {props.title || props.message ?
            <>
                <b className={props.classes.title}>{props.title}</b>
                <span className={props.classes.text}>{cropString(props.message || '', 117)}</span>
            </>
            : null}
        {props.closable ? <IconButton className={props.classes.closeButton} onClick={props.closeCallback}>
            <CloseIcon />
        </IconButton> : null}
    </div>;
};

const Banner = withStyles(styles)(BannerListComponent);

const WarningBannerComponent: FunctionComponent<BannerProps> = (props: BannerProps) => {
    return (<Banner
        color={props.classes.yellow}
        icon={<WarningIcon className={classNames(props.classes.icon, props.classes.yellowIcon)}/>}
        title={props.title}
        message={props.message}
        closable={props.closable}
        closeCallback={props.closeCallback}
    />);
};

const ErrorBannerComponent: FunctionComponent<BannerProps> = (props: BannerProps) => {
    return (<Banner
        color={props.classes.red}
        icon={<ErrorIcon className={classNames(props.classes.icon, props.classes.redIcon)}/>}
        title={props.title}
        message={props.message}
        closable={props.closable}
        closeCallback={props.closeCallback}
    />);
};

const SuccessBannerComponent: FunctionComponent<BannerProps> = (props: BannerProps) => {
    return (<Banner
        color={props.classes.green}
        icon={<CheckCircleIcon className={classNames(props.classes.icon, props.classes.greenIcon)}/>}
        title={props.title}
        message={props.message}
        closable={props.closable}
        closeCallback={props.closeCallback}
    />);
};

const InfoBannerComponent: FunctionComponent<BannerProps> = (props: BannerProps) => {
    return (<Banner
        color={props.classes.blue}
        icon={<InfoIcon className={classNames(props.classes.icon, props.classes.blueIcon)}/>}
        title={props.title}
        message={props.message}
        closable={props.closable}
        closeCallback={props.closeCallback}
    />);
};

export const WarningBanner = withStyles(styles)(WarningBannerComponent);
export const ErrorBanner = withStyles(styles)(ErrorBannerComponent);
export const SuccessBanner = withStyles(styles)(SuccessBannerComponent);
export const InfoBanner = withStyles(styles)(InfoBannerComponent);
