import _ from 'lodash';
import type { CSSProperties } from 'react';
import { TIME_LABEL } from '../../helpers/Query/JetFilterQuery';
import { RawResult, RequestType, RequestOptions, QueryOptions, DataResult } from '../../helpers/Query/QueryTypes';
import { SumDataSparkline } from './SumDataSparkline';
import type { BarOption } from '../JetChart';
import { QueryBuilder } from '../../helpers/Query/QueryBuilder';
import type { JetColumnId } from '../JetPage';
export class SumData {
    id: JetColumnId | null;
    tachoKey?: string;
    subTenant?: string;
    label?: string;
    style: Record<string, CSSProperties>;
    hasSparkline: boolean;
    hasTimeline: boolean;
    hasVisitPrediction: boolean;
    sparklinesPerRow: number;
    sparklines: SumDataSparkline[];
    sumInSparkline: boolean;
    requestOptions: RequestOptions;
    relatedColumns: JetColumnId[];
    subtractionColumns: JetColumnId[];

    sparklineStyle?: CSSProperties;
    timeFilter?: string;
    sparklineShowLegend?: boolean;
    sparklineTimeFilter?: string;
    hasBarChart?: boolean;
    barOptions!: BarOption[];
    barLabel!: string;
    barStyle?: CSSProperties;
    hasTacho?: boolean;
    hasPie?: boolean
    tachoStyle?: Record<string, unknown>;

    constructor(id: JetColumnId | null, label?: string, style = {}) {
        this.id = id;
        this.label = label;
        this.style = style;
        this.hasSparkline = false;
        this.hasTimeline = false;
        this.hasVisitPrediction = false;
        this.sparklinesPerRow = 1;
        this.sparklines = [];
        this.sumInSparkline = false;
        this.requestOptions = { type: RequestType.SUM };
        this.relatedColumns = [];
        this.subtractionColumns = [];
    }

    setSparkline({
        hasSparkline = true,
        sparklines,
        style = this.sparklineStyle,
        showLegend = false,
        timeFilter = this.timeFilter,
        sparklinesPerRow = this.sparklinesPerRow,
        sumInSparkline = this.sumInSparkline
    }: {
        hasSparkline?: boolean,
        sparklines?: SumDataSparkline[]
        style?: CSSProperties,
        showLegend?: boolean,
        timeFilter?: string,
        sparklinesPerRow?: number,
        sumInSparkline?: boolean,
    } = {}) {
        this.hasSparkline = hasSparkline;
        this.sparklines = sparklines || [new SumDataSparkline(this.id)];
        this.sparklineStyle = style;
        this.sparklineShowLegend = showLegend;
        this.sparklineTimeFilter = timeFilter;
        this.sparklinesPerRow = sparklinesPerRow;
        this.sumInSparkline = sumInSparkline;
        return this;
    }

    setBarChart({
        hasBarChart = true,
        barOptions,
        label,
        style
    }: {
        hasBarChart: boolean,
        barOptions: BarOption[],
        label: string,
        style: CSSProperties
    }) {
        this.hasBarChart = hasBarChart;
        this.barOptions = barOptions;
        this.relatedColumns = this.relatedColumns.concat(barOptions.map(({ id }) => id));
        this.barLabel = label;
        this.barStyle = style;
        return this;
    }

    setPieChart({
        hasPie = true,
        barOptions
    }: {
        hasPie: boolean,
        barOptions: BarOption[],
    }) {
        this.hasPie = hasPie;
        this.barOptions = barOptions;
        this.label = '';
        this.relatedColumns = this.relatedColumns.concat(barOptions.map(({ id }) => id));
        return this;
    }

    setTacho({
        hasTacho = true,
        style = {},
        tachoKey,
        subTenant
    }: {
        hasTacho?: boolean,
        style?: Record<string, unknown>,
        tachoKey?: string,
        subTenant?: string
    } = {}) {
        this.hasTacho = hasTacho;
        this.tachoStyle = style;
        this.tachoKey = tachoKey;
        this.subTenant = subTenant;
        return this;
    }

    setTimeline({
        style = this.sparklineStyle,
        timeFilter = this.timeFilter
    }) {
        this.hasTimeline = true;
        this.sparklineStyle = style;
        this.sparklineTimeFilter = timeFilter;
        return this;
    }

    setVisitPrediction({ hasVisitPrediction = true }: { hasVisitPrediction?: boolean }) {
        this.hasVisitPrediction = hasVisitPrediction;
        return this;
    }

    setSubtraction(subtractionColumns = this.subtractionColumns) {
        this.subtractionColumns = subtractionColumns;
        this.relatedColumns = this.relatedColumns.concat(subtractionColumns);
        return this;
    }

    setRequestOptions(requestOptions: RequestOptions) {
        this.requestOptions = { ...this.requestOptions, ...requestOptions };
        this.sparklines.forEach((sparkline) => {
            sparkline.requestOptions = sparkline.requestOptions || requestOptions;
        });
        return this;
    }

    isSparklineVisible(time: keyof typeof TIME_LABEL) {
        return !Array.isArray(this.sparklineTimeFilter) || this.sparklineTimeFilter.some((label) => label === TIME_LABEL[time]);
    }

    buildQueries(queryBuilder: QueryBuilder, { time = '' }: QueryOptions | { time: string }) {
        const { id, hasSparkline, sparklines, requestOptions, relatedColumns, hasTacho } = this;
        queryBuilder.setRequestOptionDefaults(requestOptions);

        if (id) {
            queryBuilder.addRequest(id, { sparkline: false });
        }

        relatedColumns.forEach((id) => {
            queryBuilder.addRequest(id, { sparkline: false });
        });

        // tacho goals only exists for today filter, so disable yesterday requests for other time filters
        if (hasTacho && time === 'today') {
            const tachoOptions = { sparkline: true, value: false };
            const tachoRequestOptions: RequestOptions = { sparklineTime: 'yesterday' };
            queryBuilder.addRequest(id, tachoOptions, tachoRequestOptions);
            this.subtractionColumns.forEach(subtractionColumn => {
                queryBuilder.addRequest(subtractionColumn, tachoOptions, tachoRequestOptions);
            });
        }

        if (hasSparkline && this.isSparklineVisible(time as keyof typeof TIME_LABEL)) {
            queryBuilder.setRequestOptionDefaults({ ...requestOptions, id: 'sparkline' });
            sparklines
                .filter(sparkline => sparkline.isSparklineVisible(time))
                .forEach(sparkline => sparkline.buildQueries(queryBuilder, sparklines));
        }
    }

    buildQueryResult = (getData: (requestOptions: RequestOptions) => RawResult | undefined) => {
        const data = getData(this.requestOptions);
        const sumsData = data?.sumsDataV2;
        const yesterday = getData({ ...this.requestOptions, sparklineTime: 'yesterday' });
        const sparklineGetData = (requestOptions: RequestOptions) => getData({ ...this.requestOptions, id: 'sparkline', ...requestOptions });
        const parsedInt = String(this.id);
        return sumsData && {
            data: sumsData[parsedInt] as number - this.subtractionColumns.reduce((sum, id) => sum + (sumsData[id] as number || 0), 0),
            relatedData: _.fromPairs(this.relatedColumns.map(id => [id, sumsData[id] && parseFloat(sumsData[id] as string)])),
            yesterdaySparkline: this.subtractSparkline(
                yesterday?.sumsDataV2?.[`${this.id}Sparkline`],
                this.subtractionColumns.map(id => yesterday?.sumsDataV2?.[`${id}Sparkline`])
            ),
            sparklines: this.sparklines.map(sumSparkline =>
                sumSparkline.buildQueryResult(sparklineGetData, this.sparklines)
            )
        };
    }

    subtractSparkline(sparkline?: DataResult | number[], subtractSparklines?: (DataResult | number[] | undefined)[]) {
        if (!Array.isArray(sparkline) || !Array.isArray(subtractSparklines) || subtractSparklines.length === 0) {
            return sparkline;
        }
        return sparkline.map((x, i) => {
            return x - (subtractSparklines as number[][]).reduce((sum, subtractSparkline) => {
                return sum + ((subtractSparkline)[i] || 0);
            }, 0);
        });
    }
}
