import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { TimeHelper } from '../../../common/helpers/Time';
import { SUBTITLE_1 } from '../../../common/configs/scaleCategory';

const styles = () => ({
    root: {
        marginTop: 32,
        marginBottom: 8,
        textAlign: 'left'
    },
    text: {
        ...SUBTITLE_1,
        color: 'rgba(0, 0, 0, 0.8)'
    }
});

class StreamDividerComponent extends Component {

    constructor(props) {
        super(props);
        this.classes = this.props.classes;
    }

    render() {
        return (
            <div className={this.classes.root}>
                <h3 className={this.classes.text}>
                    {TimeHelper.getDate(this.props.time)}
                </h3>
                {this.props.children}
            </div>
        );
    }
}

StreamDividerComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
    time: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]).isRequired
};

export const StreamDivider = withStyles(styles)(StreamDividerComponent);
