import _ from 'lodash';
import hash from 'object-hash';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { Timestamp, getFirestore, doc, setDoc, onSnapshot, serverTimestamp, increment } from 'firebase/firestore';
import { config } from '../../../../config';
import { Authenticator } from '../../components/Auth';
import { SyncedConfig } from '../../configs/SyncedConfig';
import { LocalStorage } from '../../components/Storage';
import { decompressQueryResultInplace } from './Decompression';
import type { FirebaseApp } from 'firebase/app';
import type { Firestore, DocumentData } from 'firebase/firestore';
import type { Auth, User } from 'firebase/auth';
import type { HeatmapResponse } from '../../../heatmap/components/Heatmap';
import type { Query } from '../../../jetstream/helpers/Query';

const REFRESH_VIEW_INTERVAL = 10 * 60 * 1000;
const EXPIRE_HEATMAP_MS = 60 * 60 * 1000;

let app: FirebaseApp;
let firestore: Firestore;
let auth: Auth;

export class FirestoreStore {

    static user: User | null;
    static loginSuccess: () => void
    static loginCompleted = new Promise<void>(loginSuccess => {
        FirestoreStore.loginSuccess = loginSuccess;
    })
    static _firestore = FirestoreStore.loginCompleted.then(() => firestore);
    static _syncedConfigs: Record<string, {
        config?: SyncedConfig, subscribers: ((syncedConfig: SyncedConfig) => void)[]
    }> = {}

    static async init() {
        if (app) {
            return;
        }
        app = initializeApp(config.firebase);
        firestore = getFirestore(app);
        auth = getAuth(app);
        auth.onAuthStateChanged(FirestoreStore.setUser);
    }

    static async setUser(user: User | null) {
        if (!user) {
            let token = LocalStorage.getFirestoreToken();
            if (!Authenticator.isTokenActive(token)) {
                const { firestoreToken } = await Authenticator.secureFetch(config.api + '/api/login/firestoreToken');
                token = firestoreToken;
            }
            signInWithCustomToken(auth, token!);
        }
        FirestoreStore.user = user;
        if (user) {
            FirestoreStore.loginSuccess();
            FirestoreStore.checkChangedRoles(user);
        }
    }

    static async checkChangedRoles(user: User) {
        const { claims: { roles } } = await user.getIdTokenResult();
        const authRoles = Authenticator.getRoles() as string[];
        const hasEqualRoles = _.isEqual(new Set(roles as string[]), new Set(authRoles));
        if (!hasEqualRoles && LocalStorage.shouldTryRelogin()) {
            console.log('Firestore login roles are different from Auth roles (forcing new login)', { firestoreRoles: roles, authRoles });
            await auth.signOut();
            window.location.reload();
        }
    }

    async waitForLogin() {
        await FirestoreStore.loginCompleted;
    }

    async getUserConfigRef() {
        await FirestoreStore.loginCompleted;
        return doc(firestore, `/stage/${config.stage}/user/${FirestoreStore.user!.uid}`);
    }

    /**
     * Creates a query document in stage/${config.stage}/query/${queryHash}
     * Creates a listener on stage/${config.stage}/queryResult/${queryHash}
     * @param query
     * @param updateHandler a callback which is invoked if the result document changes
     * @returns
     */
    subscribeQuery(query: Query, updateHandler: (data: DocumentData) => void) {
        const id = hash(query);
        console.log('subscribedQuery', { query, id });
        this._setQuery(id, query);
        const unsubscribe = onSnapshot(doc(firestore, `/stage/${config.stage}/queryResult/${id}`), (snapshot) => {
            const data = snapshot.data();
            if (!data) {
                return;
            }
            decompressQueryResultInplace(data);
            const expirationDate = this._getExpirationDate(query);
            if (this._isExpired(data.lastSync, expirationDate)) {
                return;
            }
            updateHandler(data);
        });
        const intervalId = setInterval(() => this._setQuery(id, query), REFRESH_VIEW_INTERVAL);
        return () => {
            unsubscribe();
            this._setQuery(id);
            clearInterval(intervalId);
        };
    }

    _getExpirationDate({ time, sparklineTime }: Query) {
        const expires = new Date();
        if (sparklineTime === 'yesterday') {
            expires.setHours(0, 0, 0, 0);
            return expires;
        }
        let expireMinutes;
        switch (sparklineTime || time) {
            case '2h':
                expireMinutes = 30;
                break;
            case '1h':
                expireMinutes = 20;
                break;
            case '30min':
                expireMinutes = 10;
                break;
            default:
                expireMinutes = 60;
        }
        expires.setMinutes(expires.getMinutes() - expireMinutes);
        return expires;
    }

    _isExpired(lastSync: Timestamp, expireDate: Date) {
        if (!(lastSync instanceof Timestamp)) {
            return true;
        }
        if (lastSync.toDate() < expireDate) {
            return true;
        }
        return false;
    }

    _setQuery = (id: string, query?: Record<string, unknown>) => {
        setDoc(doc(firestore, `/stage/${config.stage}/query/${id}`), {
            ...query,
            requests: increment(query ? 1 : 0),
            requestTimes: {
                [FirestoreStore.user!.uid]: query ? serverTimestamp() : null
            },
            updateTime: serverTimestamp()
        }, { merge: true });
    }

    subscribeConfig = (brand: string, updateHandler: (syncedConfig: SyncedConfig) => void) => {
        const _syncedConfigs = FirestoreStore._syncedConfigs;
        if (!_syncedConfigs[brand]) {
            _syncedConfigs[brand] = { subscribers: [updateHandler] };
            FirestoreStore.loginCompleted.then(() => {
                onSnapshot(doc(firestore, `/stage/${config.stage}/config/${brand}`), (snapshot) => {
                    const data = snapshot.data();
                    const syncedConfig = new SyncedConfig(data);
                    _syncedConfigs[brand].config = syncedConfig;
                    _syncedConfigs[brand].subscribers.forEach((handler) => handler(syncedConfig));
                });
            });
        } else {
            _syncedConfigs[brand].subscribers.push(updateHandler);
            if (_syncedConfigs[brand].config) {
                updateHandler(_syncedConfigs[brand].config as SyncedConfig);
            }
        }
        return () => {
            const index = _syncedConfigs[brand].subscribers.findIndex(sub => sub === updateHandler);
            _syncedConfigs[brand].subscribers.splice(index, 1);
        };
    }

    subscribeHeatmap(heatmap: { url: string }, updateHandler: (heatmap: HeatmapResponse) => void) {
        const queryId = hash(heatmap);

        this._setHeatmap(queryId, heatmap);
        const unsubscribe = onSnapshot(doc(firestore, `/stage/${config.stage}/heatmapResult/${queryId}`), (snapshot) => {
            const data = snapshot.data();
            if (!data) {
                return;
            }
            const expirationDate = new Date(new Date().getTime() - EXPIRE_HEATMAP_MS);
            if (this._isExpired(data.lastSync, expirationDate)) {
                return;
            }
            updateHandler(data as HeatmapResponse);
        });
        const intervalId = setInterval(() => this._setHeatmap(queryId, heatmap), REFRESH_VIEW_INTERVAL);
        return () => {
            unsubscribe();
            this._setHeatmap(queryId);
            clearInterval(intervalId);
        };
    }

    _setHeatmap = (id: string, heatmap?: Record<string, unknown>) => {
        setDoc(doc(firestore, `/stage/${config.stage}/heatmap/${id}`), {
            ...heatmap,
            requests: increment(heatmap ? 1 : 0),
            requestTimes: {
                [FirestoreStore.user!.uid]: heatmap ? serverTimestamp() : null
            },
            updateTime: serverTimestamp()
        }, { merge: true });
    }

    /**
     * Subscribes to a document change on a query result stored in Firestore.
     * Returns a subscription reference which can be used to unsubscribe from the document change.
     * On a document change, a callback will be invoked, receiving the Firestore hash and the latest state of the document.
     * @param id Firestore hash the document is stored at
     * @param callback Callback which will be invoked on document change
     * @returns subscription or undefined if not found
     */
    subscribeToQueryResult(firestoreHash: string, callback: (firestoreHash: string, document: any) => void) {
        return onSnapshot(doc(firestore, `/stage/${config.stage}/queryResult/${firestoreHash}`), (snapshot) => {
            const data = snapshot.data();
            if (!data) {
                return;
            }
            decompressQueryResultInplace(data);
            callback(firestoreHash, data);
        });
    }

    /**
     * Subscribes to a document change on a config document for the specified tenant in Firestore.
     * Returns a subscription reference which can be used to unsubscribe from the document change.
     * On a document change, a callback will be invoked, receiving the latest state of the document.
     * @param tenant The tenant of the config to subscribe
     * @param callback Callback which will be invoked on document change
     * @returns subscription or undefined if not found
     */
    async subscribeToConfig(tenant: string, callback: (document: any) => void) {
        return onSnapshot(doc(firestore, `/stage/${config.stage}/config/${tenant}`), (snapshot) => {
            const data = snapshot.data();
            if (!data) {
                return;
            }
            callback(data);
        });
    }
}
