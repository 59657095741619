import _ from 'lodash';
import { Query } from './Query';

/**
 * @enum {Number}
 */
export const FilterType = {
    chips: 0,
    select: 1
};

/**
 * @enum {Number}
 */
export const FilterMode = {
    inverted: -1,
    disabled: 0,
    enabled: 1
};

export class FilterKey {

    /**
     *
     * @param {String} id - Key used for API requests and query parameter.
     * @param {String} label - Value used as chip area title.
     * @param {[FilterValue]} content
     * @param {FilterType} type
     * @param {String} value
     */
    constructor(id, label, content = null, type = FilterType.chips, value = null) {
        this.id = id;
        this.label = label;
        this.content = content;
        this.type = type;
        this.value = value;
    }

    copy() {
        const id = this.id;
        const label = this.label;
        const filterValues = this.content.map(filterValue => filterValue.copy());
        const type = this.type;
        const value = this.value;
        return new FilterKey(id, label, filterValues, type, value);
    }
}

export class FilterValue {

    /**
     *
     * @param {String} id - Key used for API request.
     * @param {String} label - Value used in Query Parameter, API Request and Chip Label.
     * @param {String} apiParentPath - Dot separated request body path for the API request (note: not yet supported for FilterType.select)
     * @param {FilterMode} value
     */
    constructor(id, label = null, apiParentPath = null, value = FilterMode.disabled) {
        this.id = id;
        this.label = label || id;
        this.apiParentPath = apiParentPath;
        this.value = value;
    }

    copy() {
        const id = this.id;
        const label = this.label;
        const apiParentPath = this.apiParentPath;
        const value = this.value;
        return new FilterValue(id, label, apiParentPath, value);
    }
}

export class FilterQuery extends Query {

    constructor(configuration) {
        super();
        this.configuration = configuration;
    }

    // get settings for all filter
    getAllFilter(rawCategories) {
        // create deep copy of configuration (preventing reference issues)
        const result = this.configuration.map(category => category.copy());
        for (const category of result) {
            // check if category is existing in raw input
            if (Object.hasOwn(rawCategories, category.id)) {
                if (category.type === FilterType.select) {
                    // set select value
                    const radioButtons = category.content;
                    const rawValue = rawCategories[category.id];
                    category.value = this._getValidatedSelectValue(rawValue, radioButtons);
                } else {
                    // set chip value
                    const chips = category.content;
                    const rawChips = rawCategories[category.id];
                    for (const chip of chips) {
                        // check if chip is exsting in raw input
                        if (Object.hasOwn(rawChips, chip.label)) {
                            // update the state of the chip
                            const rawValue = rawChips[chip.label];
                            chip.value = this._getValidatedChipValue(rawValue);
                        }
                    }
                }
            }
        }
        return result;
    }

    // get only settings for filter used by user
    getUsedFilter(rawCategories) {
        const result = {};
        const categories = this.configuration;
        for (const category of categories) {
            // check if category is existing in raw input
            if (Object.hasOwn(rawCategories, category.id)) {
                if (category.type === FilterType.select) {
                    this._addSelectValue(result, category, rawCategories);
                } else {
                    this._addUsedChipValues(result, category, rawCategories);
                }
            }
        }
        return result;
    }

    getUrlFilter(location) {
        const rawCategories = this.getQuerySettings(location);
        const result = {};
        const categories = this.configuration;
        for (const category of categories) {
            // check if category is existing in raw input
            if (Object.hasOwn(rawCategories, category.id)) {
                if (category.type === FilterType.select) {
                    this._addSelectValue(result, category, rawCategories);
                } else {
                    this._addUrlChipValues(result, category, rawCategories);
                }
            }
        }
        return result;
    }

    _addUsedChipValues(result, category, rawCategories) {
        // add category with an empty object
        result[category.id] = {};
        const chips = category.content;
        const rawChips = rawCategories[category.id];
        for (const chip of chips) {
            // check if chip is existing in raw input
            if (Object.hasOwn(rawChips, chip.label)) {
                // set value
                const rawValue = rawChips[chip.label];
                const value = this._getValidatedChipValue(rawValue);
                if (value === FilterMode.disabled) {
                    // ignore default value
                    continue;
                }
                result[category.id][chip.label] = value;
            }
        }
    }

    _addUrlChipValues(result, category, rawCategories) {
        const chips = category.content;
        const rawChips = rawCategories[category.id];
        for (const chip of chips) {
            // check if chip is existing in raw input
            if (Object.hasOwn(rawChips, chip.label)) {
                const rawValue = rawChips[chip.label];
                const value = this._getValidatedChipValue(rawValue);
                if (value === FilterMode.disabled) {
                    // ignore default value
                    continue;
                }
                const apiParentPath = chip.apiParentPath;
                if (apiParentPath) {
                    // add value to custom path
                    _.set(result, `${apiParentPath}.${chip.id}`, value);
                } else {
                    // add value to category path
                    // use setWith to ensure that numerical keys are creating objects, not arrays
                    _.setWith(result, `${category.id}.${chip.id}`, value, Object);
                }
            }
        }
    }

    _addSelectValue(result, category, rawCategories) {
        const radioButtons = category.content;
        const rawValue = rawCategories[category.id];
        const value = this._getValidatedSelectValue(rawValue, radioButtons);
        if (value === radioButtons[0].id) {
            // ignore default value
            return;
        }
        result[category.id] = value;
    }

    _getValidatedChipValue(rawValue) {
        const validValues = [FilterMode.inverted, FilterMode.disabled, FilterMode.enabled];
        const parsedValue = parseInt(rawValue, 10);
        return validValues.indexOf(parsedValue) === -1 ? FilterMode.disabled : parsedValue;
    }

    _getValidatedSelectValue(rawValue, radioButtons) {
        const validValues = radioButtons.map(radioButton => radioButton.id);
        return validValues.indexOf(rawValue) === -1 ? validValues[0] : rawValue;
    }
}
