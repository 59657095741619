export enum AuthRole {
    BILD = 'BILD',
    WELT = 'WELT',
    TRAVELBOOK = 'TRAVELBOOK',
    TECHBOOK = 'TECHBOOK',
    FITBOOK = 'FITBOOK',
    STYLEBOOK = 'STYLEBOOK',
    HOMEBOOK = 'HOMEBOOK',
    CATEGORIZATION = 'CATEGORIZATION'
}
