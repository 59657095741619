import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Sidebar } from './Sidebar';
import classNames from 'classnames';
import { BANNER_DESKTOP_HEIGHT } from '../Banner/BannerBar';

const styles = (theme) => {
    const leftSidebarWidth = 320;
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('sidebarTransform')]: {
                flexDirection: 'row'
            }
        },
        bannerOffset: {
            top: [[BANNER_DESKTOP_HEIGHT], '!important']
        },
        leftSidebar: {
            position: 'static',
            width: leftSidebarWidth,
            flexShrink: 0,
            maxWidth: 320,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 16,
            [theme.breakpoints.up('sidebarTransform')]: {
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                marginTop: 0,
                marginLeft: 0,
                zIndex: 500,
                overflowY: 'scroll'
            }
        },
        content: (props) => {
            let marginLeft = 0;
            let marginRight = 0;
            if (props.contentMargin) {
                marginLeft = props.contentMargin.left || 0;
                marginRight = props.contentMargin.right || 0;
            }
            return {
                flexGrow: 1,
                flexShrink: 1,
                textAlign: 'center',
                marginLeft: marginLeft,
                marginRight: marginRight,
                [theme.breakpoints.up('sidebarTransform')]: {
                    width: 0,
                    marginLeft: leftSidebarWidth + marginLeft
                }
            };
        },
        centerContent: (props) => ({
            padding: theme.spacing(2),
            boxSizing: 'border-box',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: props.contentMaxWidth || 1500
        })
    };
};

class PageComponent extends Component {

    constructor(props) {
        super(props);
        this.interval = undefined;
    }

    componentDidMount() {
        this.registerAutoRefresh();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname ||
            prevProps.autoRefreshInterval !== this.props.autoRefreshInterval) {
            // reset auto refresh timer
            this.unregisterAutoRefresh();
            this.registerAutoRefresh();
        }
    }

    componentWillUnmount() {
        this.unregisterAutoRefresh();
    }

    render() {
        const { classes, sidebarTitle, sidebarContent, children, useBannerOffset } = this.props;
        return (
            <div className={classes.root}>
                <Sidebar
                    className={classNames(classes.leftSidebar, useBannerOffset ? classes.bannerOffset : undefined)}
                    title={sidebarTitle}
                >
                    {sidebarContent}
                </Sidebar>
                <div className={classes.content}>
                    <div className={classes.centerContent}>
                        {children}
                    </div>
                </div>
            </div>
        );
    }

    registerAutoRefresh() {
        if (_.isNil(this.interval) && this.props.autoRefreshInterval) {
            this.interval = setInterval(this.props.onAutoRefresh, this.props.autoRefreshInterval);
        }
    }

    unregisterAutoRefresh() {
        if (!_.isNil(this.interval)) {
            clearInterval(this.interval);
            this.interval = undefined;
        }
    }
}

PageComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    useBannerOffset: PropTypes.bool,
    sidebarTitle: PropTypes.string,
    sidebarContent: PropTypes.element,
    autoRefreshInterval: PropTypes.number,
    onAutoRefresh: PropTypes.func,
    contentMaxWidth: PropTypes.number,
    contentMargin: PropTypes.shape({
        left: PropTypes.number,
        right: PropTypes.number
    })
};

export const Page = withStyles(styles)(withRouter(PageComponent));
