import React, { Component } from 'react';
import { createStyles, Button, WithStyles, withStyles, Theme } from '@material-ui/core';
import { ConfirmDialog } from './ConfirmDialog';
import classNames from 'classnames';

const styles = (theme: Theme) => createStyles({
    button: {
        color: theme.palette.primary.main,
        backgroundColor: '#fff',
        border: '1px solid #BBB',
        height: 56,
        verticalAlign: 'middle',
        padding: '3px 12px 0px 12px',
        margin: '0px 24px',
        [theme.breakpoints.down('xs')]: {
            margin: '-26px 0px 20px 0px'
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff'
        }
    },
    disabled: {
        backgroundColor: '#ddd',
        border: '1px solid #ddd'
    }
});

type Props = WithStyles<typeof styles> & {
    label: string
    disabled?: boolean
    confirmDialogHead?: string
    confirmDialogText: string
    onConfirm: () => void
}

interface State {
    showDialog: boolean;
  }

export class ConfirmButtonComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showDialog: false
        };
    }

    private readonly onConfirm = () => {
        this.setState({ showDialog: false });
        this.props.onConfirm();
    }

    private readonly onCancel = () => {
        this.setState({ showDialog: false });
    }

    private readonly onClick = () => {
        this.setState({ showDialog: true });
    }

    render() {
        const { classes } = this.props;
        let banner = null;
        if (this.state.showDialog === true) {
            banner = <ConfirmDialog
                head={ this.props.confirmDialogHead }
                label={ this.props.confirmDialogText }
                onConfirm={ this.onConfirm }
                onCancel={ this.onCancel}
            />;
        }

        return (
            <>
                <Button size="small"
                    className={classNames(
                        classes.button,
                        { [classes.disabled]: this.props.disabled }
                    )}
                    disabled={this.props.disabled}
                    onClick={this.onClick}>
                    {this.props.label}
                </Button>
                {banner}
            </>
        );
    }
}

export const ConfirmButton = withStyles(styles)(ConfirmButtonComponent);
