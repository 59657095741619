import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ThemedEcharts } from '../../../../echarts';

const START_ANGLE = 90;
const END_ANGLE = -280;

const useStyles = makeStyles(() => ({
    container: {
        fontFamily: 'Lato, sans- serif',
        textAlign: 'left',
        width: '328px',
        height: '162px',
        backgroundColor: '#fff',
        boxShadow: `0px 0px 2px rgba(0, 0, 0, 0.14),
                    0px 2px 2px rgba(0, 0, 0, 0.12),
                    0px 1px 3px rgba(0, 0, 0, 0.2);`,
        borderRadius: '4px',
        padding: '16px',
        marginBottom: '20px',
        fontSize: '12px',
        color: 'rgba(0, 0, 0, 0.6)'
    },
    title: {
        fontWeight: 700,
        fontSize: 16,
        color: 'rgba(0, 0, 0, 0.8)'
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    score: {
        display: 'flex',
        alignItems: 'center',
        gap: 10
    },
    stats: {
        display: 'flex',
        flexBasis: 400,
        gap: 8,
        flexDirection: 'column'
    },
    chart: {
        display: 'flex',
        flexBasis: 300,
        flexDirection: 'column',
        height: '140px',
        left: 30
    },
    currentCTR: {
        width: 10,
        height: 10,
        backgroundColor: '#0F4A60'
    },
    averageCTR: {
        width: 10,
        height: 10,
        backgroundColor: 'rgba(0, 0, 0, 0.32)'
    },
    averageScore: {
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.8)',
        fontSize: 12,
        marginLeft: 20
    },
    currentScore: {
        fontWeight: 700,
        color: 'rgba(0, 0, 0, 0.8)',
        fontSize: 16,
        marginLeft: 35
    }
}));

const defaultChartOptions = {
    axisLabel: {
        show: false
    },
    axisTick: {
        show: false
    },
    splitLine: {
        show: false
    },
    pointer: {
        show: false
    },
    title: {
        show: true
    },
    detail: {
        show: false
    }
};

export const CTRChart = ({ currentValue, averageValue }: { currentValue: number, averageValue: number }) => {
    const classes = useStyles();
    const options = {
        graphic: {
            elements: [
                {
                    type: 'text',
                    position: [90, 320],
                    style: {
                        text: '0',
                        textAlign: 'center',
                        fill: 'rgba(0, 0, 0, 0.32)',
                        fontSize: 16,
                        fontWeight: 500,
                        lineWidth: 50
                    }
                },
                {
                    type: 'text',
                    position: [420, 320],
                    style: {
                        text: '100',
                        textAlign: 'center',
                        fill: 'rgba(0, 0, 0, 0.32)',
                        fontSize: 16,
                        fontWeight: 500,
                        lineWidth: 50
                    }
                }
            ]
        },
        series: [
            {
                name: '',
                type: 'gauge',
                radius: '70%',
                data: [],
                max: 100,
                splitNumber: 2,
                startAngle: START_ANGLE,
                endAngle: END_ANGLE,
                axisLine: {
                    lineStyle: {
                        color: [
                            [currentValue / 100, '#0F4A60'],
                            [1, '#f0f0f0']
                        ],
                        width: 15
                    }
                },
                ...defaultChartOptions
            },
            {
                name: '',
                type: 'gauge',
                radius: '40%',
                data: [],
                max: 100,
                splitNumber: 2,
                startAngle: START_ANGLE,
                endAngle: END_ANGLE,
                axisLine: {
                    lineStyle: {
                        color: [
                            [averageValue / 100, 'rgba(0, 0, 0, 0.32)'],
                            [1, '#f0f0f0']
                        ],
                        width: 7
                    }
                },
                ...defaultChartOptions
            }
        ]
    };

    return (
        <div className={classes.container}>
            <div className={classes.title}>CTR Performance</div>
            <div className={classes.content}>
                <div className={classes.stats}>
                    <div className={classes.score}>
                        <div className={classes.currentCTR}></div>
                        <div>Aktuelle CTR</div>
                        <div className={classes.currentScore}>{currentValue} %</div>
                    </div>
                    <div className={classes.score}>
                        <div className={classes.averageCTR}></div>
                        <div>Durchschn. CTR</div>
                        <div className={classes.averageScore}>{averageValue} %</div>
                    </div>
                </div>
                <div className={classes.chart}>
                    <ThemedEcharts
                        option={options}
                        notMerge={true}
                        opts={{ renderer: 'svg' }}
                    />
                </div>
            </div>
        </div>
    );
};
