import React from 'react';
import { firestoreContext } from '../../../common/helpers/Firestore';
import { autorun, computed, observable, reaction, runInAction, toJS, makeObservable } from 'mobx';
import { DataResults } from '../../../jetstream/helpers/Query';
import { PageData } from '../../../jetstream/components/JetPage/PageData';
import { ColumnResult } from '../../../jetstream/components/JetTable';
import { config, debugLog } from '../../../../config';
import { Color } from './ColorScale';
import type { HeatmapResponse } from './HeatmapResponse';
import type { Teaser } from './Teaser';
import { JetColumnId } from '../../../jetstream/components/JetPage';

export type TeaserWithData = Teaser & {
    rank: number
    relatedData?: DataResults
    leadTime?: number
    isVideo?: boolean
    positionTime: number
    value: number
    color: Color,
    homebotNotification?: HomebotNotification
}

type HomebotNotification = {
    id?: number,
    articleUid?: string,
    leanId: string,
    position?: string,
    platform?: string,
    pis?: number,
    premiumPis?: number,
    conversionPis?: number,
    searchReferral?: number,
    isPremium?: boolean,
    channelConfigName?: string,
    createdAt?: string,
    updatedAt?: string,
}

type RelatedDataMap = {
    [id: string]: DataResults
}

const hasRedirect = (teaser: Teaser) => teaser.forwardLeanId && teaser.forwardLeanId !== '-1';

class HeatmapData {

    screenshotImage?: HTMLImageElement;
    heatmap?: HeatmapResponse;
    queryResults?: ColumnResult[];
    pisHome: number;
    homeReferral: number;

    constructor(private readonly sync: HeatmapSync) {
        makeObservable(this, {
            screenshotImage: observable,
            heatmap: observable,
            queryResults: observable,
            CTR: computed,
            ready: computed,
            teaserMapByDocumentId: computed,
            teaserWithData: computed,
            groups: computed
        });

        reaction(() => this.heatmap?.screenshot, (screenshot) => {
            if (!screenshot) {
                return;
            }
            const image = new Image();
            image.addEventListener('load', () => {
                runInAction(() => {
                    this.screenshotImage = image;
                });
            });
            image.src = screenshot;
        });

        if (config.debug) {
            autorun(() => {
                console.log('invalid teasers', toJS(this.heatmap?.teasers?.filter((teaser) => !this.validTeaser(teaser))));
            });
        }

        this.pisHome = 0;
        this.homeReferral = 0;
    }

    get ready() {
        debugLog('heatmapdata ready', !!(this.screenshotImage && this.heatmap && this.teaserWithData), this.screenshotImage, this.heatmap, this.teaserWithData);
        return !!(this.screenshotImage && this.heatmap && this.teaserWithData);
    }

    get CTR() {
        return Math.round((this.homeReferral / Math.max(this.pisHome, 1)) * 100);
    }

    get teaserMapByDocumentId() {
        return this.queryResults?.reduce((byDocumentId, { data, relatedData }) => {
            if (data) {
                byDocumentId[data] = relatedData;
            }
            return byDocumentId;
        }, {} as RelatedDataMap);
    }

    get teaserWithData(): TeaserWithData[] | null {
        const getRankValue = this.sync.getRankValue;
        if (!this.teaserMapByDocumentId || !getRankValue) {
            return null;
        }

        return this.heatmap?.teasers?.filter(this.validTeaser)
            .map((teaser) => {
                const relatedData = hasRedirect(teaser) ? this.teaserMapByDocumentId?.[teaser.forwardLeanId] : this.teaserMapByDocumentId?.[teaser.documentId];
                return Object.assign({}, teaser, {
                    relatedData,
                    value: getRankValue?.(relatedData) ?? 0
                } as TeaserWithData);
            }) ?? null;
    }

    get groups() {
        const groups: { [key: string]: { type: string, teaser: TeaserWithData[] } } = {};
        this.teaserWithData?.forEach((teaser) => {
            const key = teaser.group;
            if (key) {
                if (!groups[key]) {
                    const type = key.replace(/"/g, '').split('_').pop();
                    if (type) {
                        groups[key] = { type, teaser: [] };
                    }
                }
                groups[key].teaser.push(teaser);
            }
        });
        return groups;
    }

    validTeaser = (teaser: Teaser): boolean => !!teaser.documentId && !(teaser.rect.x <= 0 && teaser.rect.y <= 0)
}

export class HeatmapSync {

    unsubscribe?: () => void;
    unsubscribeCTRMap: { [key: string]: () => void }
    getRankValue?: (relatedData?: DataResults) => number;
    heatmapData: HeatmapData;
    private nextHeatmapData: HeatmapData | null = null;

    constructor(private readonly context: React.ContextType<typeof firestoreContext>) {
        this.unsubscribeCTRMap = {};
        makeObservable<HeatmapSync, 'nextHeatmapData'>(this, {
            getRankValue: observable,
            heatmapData: observable,
            nextHeatmapData: observable
        });

        this.heatmapData = new HeatmapData(this);

        reaction(() => ({
            nextHeatmapData: this.nextHeatmapData,
            ready: this.nextHeatmapData?.ready
        }), ({ nextHeatmapData, ready }) => {
            if (nextHeatmapData && ready) {
                debugLog('new data ready', this.heatmapData, nextHeatmapData);
                this.heatmapData = nextHeatmapData;
                this.nextHeatmapData = null;
            }
        });

        reaction(() => context.queryStore.queryResult, (queryResult) => {
            const queryResults = queryResult?.columns?.[0];
            if (queryResults) {
                (this.nextHeatmapData || this.heatmapData).queryResults = queryResults;
            }
        });
    }

    async subscribeHeatmap(url: string, pageConfig: PageData) {
        this.unsubscribe?.();
        runInAction(() => {
            this.heatmapData = new HeatmapData(this);
            this.nextHeatmapData = new HeatmapData(this);
        });
        await this.context.firestore.waitForLogin();
        this.unsubscribe = this.context.firestore.subscribeHeatmap({
            url
        }, (heatmap: HeatmapResponse) => {
            this.nextHeatmapData = new HeatmapData(this);
            this.nextHeatmapData.heatmap = heatmap;
            this.nextHeatmapData.homeReferral = this.heatmapData.homeReferral;
            this.nextHeatmapData.pisHome = this.heatmapData.pisHome;

            const { teasers = [] } = heatmap || {};
            runInAction(() => {
                // fetch data from queryStore with info gotten from heatmap
                this.context.queryStore.pageConfig = pageConfig;
                this.context.queryStore.queryOptions = {
                    groupBrands: ['bild', 'books'],
                    time: '15min',
                    leanIds: [...teasers.filter((teaser) => !hasRedirect(teaser) && teaser.documentId).map(({ documentId }) => documentId),
                        ...teasers.filter(hasRedirect).map(({ forwardLeanId }) => forwardLeanId)]
                } as any;
                // add queryResults to new heatmapdata if query not changed => queryResult will still exist else null
                const queryResults = this.context.queryStore.queryResult?.columns?.[0];
                if (queryResults && this.nextHeatmapData) {
                    this.nextHeatmapData.queryResults = queryResults;
                }
            });
        });
    }

    async subscribeCTRWeb() {
        this.subscribeCTR([{ id: JetColumnId.pisHomeTotal }], ['wDmWJyqHFeqhJHmeuqfN'], [], 'pis-home-web');
        this.subscribeCTR([{ id: JetColumnId.ctrHomeReferralWeb }], [], [{ id: JetColumnId.ctrHomeReferralWeb }], 'home-referral-web');
    }

    async subscribeCTRApp() {
        this.subscribeCTR([{ id: JetColumnId.pisHomeTotal }], ['26324062', '20595788'], [], 'pis-home-app');
        this.subscribeCTR([{ id: JetColumnId.ctrHomeReferralApp }], [], [{ id: JetColumnId.ctrHomeReferralApp }], 'home-referral-app');
    }

    unsubscribeCTR() {
        Object.values(this.unsubscribeCTRMap).forEach((unsubscribeFunc) => unsubscribeFunc());
    }

    private async subscribeCTR(columns: { id: string }[], pageIds: string[], sums: { id: string }[], id: string) {
        this.unsubscribeCTRMap[id]?.();
        await this.context.firestore.waitForLogin();
        this.unsubscribeCTRMap[id] = this.context.firestore.subscribeQuery(
            {
                groupBrands: ['bild', 'books'],
                time: '15min',
                pageIds,
                columns,
                sums
            }, (data: any) => {
                runInAction(() => {
                    if (data && data.columnsData && data.columnsData.length > 0) {
                        const columnData = data.columnsData[0];
                        switch (id) {
                            case 'pis-home-web':
                                console.log('pis-home-web', columnData[JetColumnId.pisHomeTotal]);
                                this.heatmapData.pisHome = columnData[JetColumnId.pisHomeTotal];
                                if (this.nextHeatmapData) {
                                    this.nextHeatmapData.pisHome = columnData[JetColumnId.pisHomeTotal];
                                }
                                break;
                            case 'pis-home-app':
                                this.heatmapData.pisHome = data.columnsData.reduce(
                                    (accumulator: number, currentValue: Record<string, number>) =>
                                        accumulator + currentValue.pisHomeTotal,
                                    0
                                );
                                console.log('pis-home-app', this.heatmapData.pisHome);
                                if (this.nextHeatmapData) {
                                    this.nextHeatmapData.pisHome = columnData[JetColumnId.pisHomeTotal];
                                }
                                break;
                            case 'home-referral-web':
                                console.log('home-referral-web', data.sumsDataV2[JetColumnId.ctrHomeReferralWeb]);
                                this.heatmapData.homeReferral = data.sumsDataV2[JetColumnId.ctrHomeReferralWeb];
                                if (this.nextHeatmapData) {
                                    this.nextHeatmapData.homeReferral = data.sumsDataV2[JetColumnId.ctrHomeReferralWeb];
                                }
                                break;
                            case 'home-referral-app':
                                console.log('home-referral-app', data.sumsDataV2[JetColumnId.ctrHomeReferralApp]);
                                this.heatmapData.homeReferral = data.sumsDataV2[JetColumnId.ctrHomeReferralApp];
                                if (this.nextHeatmapData) {
                                    this.nextHeatmapData.homeReferral = data.sumsDataV2[JetColumnId.ctrHomeReferralApp];
                                }
                                break;
                            default:
                        }
                    }
                });
            });
    }
}
