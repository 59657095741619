import React from 'react';
import { makeStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Accordion } from '../../../common/components/Accordion';
import { withAuth } from '../../../common/components/Auth';
import type { RouteComponentProps } from 'react-router';
import type { RouteData } from '../../../common/helpers/Route';

const useStyles = makeStyles(() => ({
    select: {
        width: 285
    }
}));

type Props = RouteComponentProps & RouteData

export const Menu = withAuth((props: Props) => {
    const classes = useStyles();

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const { value } = event.target;
        props.history.push(`/heatstream/bild/${value}`);
    };

    const items = [
        <MenuItem key="1" value="web">Home Web</MenuItem>,
        <MenuItem key="2" value="app">Home App</MenuItem>
    ];

    return (
        <Accordion label="Bühnen">
            <Select
                className={classes.select}
                value={props.rest}
                onChange={handleChange}
            >
                {items}
            </Select>
        </Accordion>
    );
});
