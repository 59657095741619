import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { ButtonBase, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

/*
    source: https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/ExpansionPanelSummary/ExpansionPanelSummary.js
    => changed to display only the button
*/
const styles = theme => {
    const transition = {
        duration: theme.transitions.duration.shortest
    };
    return {
        root: {
            display: 'flex',
            minHeight: theme.spacing(6),
            transition: theme.transitions.create(['min-height', 'background-color'], transition),
            padding: `0 ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
            '&:hover:not($disabled)': {
                cursor: 'pointer'
            },
            '&$focused': {
                backgroundColor: theme.palette.grey[300]
            },
            '&$disabled': {
                opacity: 0.38
            }
        },
        expanded: {},
        focused: {},
        disabled: {},
        expandIcon: {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%) rotate(0deg)',
            transition: theme.transitions.create('transform', transition),
            '&:hover': {
                // disable the hover effect for the IconButton,
                // -> a hover effect should apply to the entire expand button and not only to IconButton
                backgroundColor: 'transparent'
            },
            '&$expanded': {
                transform: 'translateY(-50%) rotate(180deg)'
            }
        }
    };
};

class ExpandButtonComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focused: false
        };
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        const {
            classes,
            className,
            disabled,
            expanded
        } = this.props;

        const { focused } = this.state;

        return (
            <ButtonBase
                focusRipple={false}
                disableRipple
                disabled={disabled}
                component="div"
                className={classNames(
                    classes.root,
                    {
                        [classes.disabled]: disabled,
                        [classes.expanded]: expanded,
                        [classes.focused]: focused
                    },
                    className
                )}
                onFocusVisible={this.handleFocus}
                onBlur={this.handleBlur}
                onClick={this.handleChange}
            >
                <IconButton
                    disabled={disabled}
                    className={classNames(classes.expandIcon, {
                        [classes.expanded]: expanded
                    })}
                    component="div"
                    tabIndex={-1}
                    aria-hidden="true"
                >
                    <ExpandMoreIcon/>
                </IconButton>
            </ButtonBase>
        );
    }

    handleFocus() {
        this.setState({
            focused: true
        });
    }

    handleBlur() {
        this.setState({
            focused: false
        });
    }

    handleChange(event) {
        const { onClick } = this.props;
        if (onClick) {
            onClick(event);
        }
    }
}

ExpandButtonComponent.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * @ignore
     * If `true`, the summary will be displayed in a disabled state.
     */
    disabled: PropTypes.bool,
    /**
     * @ignore
     * If `true`, expands the summary, otherwise collapse it.
     */
    expanded: PropTypes.bool,
    /**
     * @ignore
     */
    onClick: PropTypes.func
};

ExpandButtonComponent.defaultProps = {
    disabled: false
};

export const ExpandButton = withStyles(styles)(ExpandButtonComponent);
