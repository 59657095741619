export interface ArticleExistsNetworkEndpoint {
    articlesExist(tenant: string, searchValues: string): Promise<any>;
}

export type ArticleSearchResult = {
    searchValue: string,
    leanId: string,
    legacyId: string,
    articlePageId: string
}

export type InsertResponse = {
    articlesFound: ArticleSearchResult [],
    articlesNotFound: string[]
}

/**
 * Performs an ArticleSearch against ArticleExistNetworkEndpoint
 * including check against empty string and response ordering by search order.
 */
export class ArticleSearch {

    private readonly articleExistsEndpoint: ArticleExistsNetworkEndpoint;

    constructor(articleExistEndpoint: ArticleExistsNetworkEndpoint) {
        this.articleExistsEndpoint = articleExistEndpoint;
    }

    /**
     * Searching article references by url, coremediaId or leanIds
     * @param {*} array of space separated string containg ids or urls to search for
     * @returns { articlesFound: [ArticleSearchResult], articlesNotFound: [string] }
     */
    async search(tenant: string, searchValues: string): Promise<InsertResponse> {
        const result: InsertResponse = { articlesFound: [], articlesNotFound: [] };
        try {
            if (searchValues.trim().length > 0) {
                const response = await this.articleExistsEndpoint.articlesExist(tenant, searchValues);
                result.articlesFound = this.sortArticlesFoundBySearchOrder(searchValues, response.articlesFound);
                result.articlesNotFound = response.articlesNotFound;
            }
        } catch (e) {
            console.error(e);
            result.articlesNotFound = searchValues.split(' ');
        }
        return Promise.resolve(result);
    }

    /**
     * Sorting articlesFound by searchValue order using bubble sort
     * @param searchValues
     * @param articlesFound
     * @returns sorted results
     */
    private sortArticlesFoundBySearchOrder(searchValues: string, articlesFound: ArticleSearchResult[]) {
        if (articlesFound.length < 2) {
            return articlesFound;
        }
        const result = articlesFound;
        let sorted = true;
        do {
            sorted = true;
            for (let i = 1; i < articlesFound.length; i++) {
                const firstIndex = searchValues.indexOf(result[i - 1].searchValue);
                const secondIndex = searchValues.indexOf(result[i].searchValue);
                if (secondIndex < firstIndex) {
                    const temp = result[i - 1];
                    result[i - 1] = result[i];
                    result[i] = temp;
                    sorted = false;
                }
            }
        } while (!sorted);
        return result;
    }
}
