import React, { FunctionComponent, useState, ChangeEvent, KeyboardEvent, useContext } from 'react';
import { makeStyles, TextField, Fab, Tooltip } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { firestoreContext } from '../../../common/helpers/Firestore';
import { Network } from '../../../common/helpers/Network';
import { ConfirmButton } from './ConfirmButton';
import { trackLink } from '../../../../trackingScript';
import { ArticleSearch } from './ArticleSearch';

const useStyles = makeStyles(() => ({
    search: {
        maxWidth: 600,
        width: '100%',
        marginBottom: 50,
        '&.Mui-focused fieldset': {
            borderColor: 'green'
        }
    },
    add: {
        position: 'absolute',
        marginTop: 40,
        marginLeft: -20
    }
}));

type Props = {
    watchlistKey: string,
    onItemInserted: (ids: string[]) => void,
}

type ArticleSearchResult = {
    searchValue: string,
    leanId: string,
    legacyId: string,
    articlePageId: string
}

type InsertResponse = {
    articlesFound: ArticleSearchResult [],
    articlesNotFound: string[]
}

export const WatchlistSearchBar: FunctionComponent<Props> = observer(({ watchlistKey, onItemInserted }) => {
    const classes = useStyles();
    const [searchValue, setSearchValue] = useState('');
    const [error, setError] = useState('');
    const [info, setInfo] = useState('');
    const [insertIsRunning, setInsertIsRunning] = useState(false);
    const [timeOutCallback, setTimeOutCallback] = useState<any | null>(null);
    const { userStore, queryStore } = useContext(firestoreContext);

    const checkIfArticleExists = async (searchValues: string) => {
        const { brand } = queryStore.queryOptions;
        const articleSearch = new ArticleSearch(new Network());
        return articleSearch.search(brand || '', searchValues);
    };

    const getArticleId = (article: Record<any, any>, tenant: string | undefined): string => {
        // always prioritize lean id for bild
        if (tenant === 'bild') {
            return article.leanId || article.legacyId || article.articlePageId;
        }
        return article.legacyId || article.leanId || article.articlePageId;
    };

    const deduplicate = (insertResponse: InsertResponse): InsertResponse => {
        const { brand } = queryStore.queryOptions;
        const result: InsertResponse = { articlesFound: [], articlesNotFound: insertResponse.articlesNotFound };
        const usedIds: string[] = [];
        insertResponse.articlesFound.forEach(article => {
            const id = getArticleId(article, brand);
            if (!usedIds.includes(id)) {
                usedIds.push(id);
                result.articlesFound.push(article);
            }
        });
        return result;
    };

    const addExistingIdsToWatchlist = async (searchValues: string): Promise<InsertResponse> => {
        const { brand } = queryStore.queryOptions;
        const result = await checkIfArticleExists(searchValues);
        const deduplicated = deduplicate(result);
        const idsForInsertion = deduplicated.articlesFound.map((e) => getArticleId(e, brand)).filter(e => e);
        onItemInserted(idsForInsertion);
        await userStore.addPageIdsToWatchlist(watchlistKey, idsForInsertion);
        return deduplicated;
    };

    const trackSearchBar = (searchVal: string) => {
        if (searchVal.includes(',')) {
            trackLink({
                'app_name': 'jetstream',
                'event_name': 'add-multiple-items-to-watchlist',
                'event_action': 'click',
                'event_label': searchVal
            });
        } else {
            trackLink({
                'app_name': 'jetstream',
                'event_name': 'add-item-to-watchlist',
                'event_action': 'click',
                'event_label': searchVal
            });
        }
    };

    const getNumberOfNewAddedPageIds = (articlesFound: ArticleSearchResult[], watchlistPageIds?: string[]) => {
        let insertedCount = 0;

        for (const article of articlesFound) {
            if (
                !watchlistPageIds?.includes(article.legacyId) &&
                !watchlistPageIds?.includes(article.leanId) &&
                !watchlistPageIds?.includes(article.articlePageId)
            ) {
                insertedCount++;
            }
        }
        return insertedCount;
    };

    const printInputResponse = (idsGiven: (string | null)[], idsInserted: number, idsNotFound: (string | null)[]) => {
        if (idsNotFound.length > 0) {
            setInfo('');
            setError(`${idsInserted} von ${idsGiven?.length} Artikel(n) hinzugefügt.
            ID's ${idsNotFound?.join(', ')} nicht gefunden.`);
            setSearchValue(idsNotFound.join(', '));
        } else {
            setError('');
            setInfo(`${idsInserted} von ${idsGiven?.length} Artikel(n) hinzugefügt`);
            setSearchValue('');
            if (timeOutCallback !== null) {
                clearTimeout(timeOutCallback);
            }
            const timeOut = setTimeout(() => {
                setInfo('');
            }, 8000);
            setTimeOutCallback(timeOut);
        }
    };

    const handleSubmit = async () => {
        if (insertIsRunning) {
            return;
        }
        setError('');
        const ids = searchValue.split(' ');
        if (ids.length > 40) {
            setError(`${ids.length} Einträge erkannt. Es können maximal 40 Artikel gleichzeitig hinzugefügt werden.`);
            return;
        }
        const existingWatchListPageIds = userStore.getWatchlistIds(watchlistKey);

        setInfo('Einen Moment...');
        setInsertIsRunning(true);
        const response: InsertResponse = await addExistingIdsToWatchlist(searchValue);
        const insertedCount = getNumberOfNewAddedPageIds(response.articlesFound, existingWatchListPageIds);
        printInputResponse(ids, insertedCount, response.articlesNotFound);
        setInsertIsRunning(false);
        if (response.articlesNotFound.length === 0) {
            trackSearchBar(searchValue);
        }
    };

    const handleKeyPress = ({ key, target }: KeyboardEvent<HTMLInputElement>) => {
        if (key === 'Enter') {
            if (target instanceof HTMLInputElement) {target.blur();}
            handleSubmit();
        }
    };
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setError('');
        setSearchValue(event.target.value);
    };

    const handleDelete = async () => {
        const existingWatchListPageIds = userStore.getWatchlistIds(watchlistKey);
        if (!existingWatchListPageIds) {
            return;
        }
        await userStore.clearWatchlist(watchlistKey);
        trackLink({
            'app_name': 'jetstream',
            'event_name': 'empty-watchlist',
            'event_action': 'click',
            'event_label': null
        });
    };

    const existingWatchListPageIds = userStore.getWatchlistIds(watchlistKey);
    return (
        <div
            onDragOver={(event) => event.preventDefault()}
        >
            <TextField
                className={classes.search}
                variant='outlined'
                type='text'
                color='primary'
                value={searchValue}
                onChange={handleChange}
                onSubmit={handleSubmit}
                onKeyPress={handleKeyPress}
                label='CMS IDs, Lean IDs oder URLs eingeben (max. 40)'
                error={!!error}
                helperText={info || error }
            />
            <Tooltip title="Artikel zur Watchlist hinzufügen" enterDelay={1000} leaveDelay={100}>
                <Fab className={classes.add} size="small" color="primary" onClick={handleSubmit}>
                    <AddIcon />
                </Fab>
            </Tooltip>
            <ConfirmButton
                label="Liste leeren"
                disabled={ insertIsRunning || !existingWatchListPageIds || existingWatchListPageIds.length === 0}
                onConfirm={handleDelete}
                confirmDialogHead="Liste leeren"
                confirmDialogText="Alle Einträge werden aus der WatchList entfernt."
            />
        </div>
    );
});
