import React, { useState, useEffect } from 'react';
import { config } from '../../../../config';
import type { Timestamp } from 'firebase/firestore';

export const TimeLeft = ({ timestamp }: { timestamp: Timestamp }) => {
    const [now, setNow] = useState(new Date());
    useEffect(() => {
        const interval = setInterval(() => setNow(new Date()), 5000);
        // cleanup
        return () => clearInterval(interval);
    }, [timestamp]);
    const timeLeftHours = Math.floor((config.watchlistExpireMs - now.getTime() + timestamp.toMillis()) / (60 * 60 * 1000));
    const timeLeftMinutes = Math.floor((config.watchlistExpireMs - now.getTime() + timestamp.toMillis()) / (60 * 1000)) % 60;
    if (timeLeftHours > 0) {
        return <>noch {timeLeftHours}h</>;
    }
    if (timeLeftMinutes > 0) {
        return <>noch {timeLeftMinutes}min</>;
    }
    return <>abgelaufen</>;
};

TimeLeft.displayName = 'TimeLeft';

