import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Popup } from './Popup';

const useStyles = makeStyles(() => ({
    survey: {
        border: 'none',
        maxWidth: '100%',
        maxHeight: '100vh'
    }
}));

export const Survey = ({ iframeUrl }: {iframeUrl: string}) => {
    const [showSurvey, setShowSurvey] = useState(true);
    const classes = useStyles();

    return (
        <Popup isOpen ={showSurvey} showCloseIcon={true} onClose={() => { setShowSurvey(false); }}>
            <iframe
                className={classes.survey}
                width='600px'
                height='750px'
                src={iframeUrl}
                frameBorder={0}
                marginWidth={0}
                marginHeight={0}
                allowFullScreen
            />
        </Popup>
    );
};
