import _ from 'lodash';
import React, { Component, CSSProperties } from 'react';
import { withStyles, createStyles, WithStyles, Paper, CircularProgress, Theme } from '@material-ui/core';
import { Page } from '../../../common/components/Page';
import { firestoreContext } from '../../../common/helpers/Firestore';
import { PageData } from '../../../jetstream/components/JetPage/PageData';
import { ColumnData } from '../../../jetstream/components/JetTable';
import { JetColumnId } from '../../../jetstream/components/JetPage';
import { observer } from 'mobx-react';
import { DataResults } from '../../../jetstream/helpers/Query';
import { Rect } from './Teaser';
import { AnchorOverlay, DialogState } from './AnchorOverlay';
import { HeatmapSync, TeaserWithData } from './HeatmapSync';
import { ColorScale } from './ColorScale';
import { Minimap } from './Minimap';
import { Sidebar } from '../sidebar';
import { config } from '../../../../config';
import { FilterUtils } from './filterUtils';
import { CTRChart } from './CTRChart';
import { AufmacherStats } from './AufmacherStats';
import { getCurrentCTRAverage } from './ctrUtils';

const styles = (theme: Theme) => createStyles({
    topShortcut: {
        position: 'fixed',
        left: '22px',
        fontSize: '14px',
        zIndex: 1000,
        bottom: '21px',
        padding: '5px',
        backgroundColor: '#fff',
        border: '1px solid #eee',
        borderRadius: '4px',
        textDecoration: 'none',
        color: theme.palette.primary.main,
        width: '250px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    shortCutAnchor: {
        textDecoration: 'none'
    },
    paper: {
        position: 'relative',
        width: '100%'
    },
    screenshot: {
        width: '100%'
    },
    teaser: {
        position: 'absolute'
    },
    teaserRelative: {
        position: 'relative'
    },
    rotationBox: {
        position: 'absolute',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column'
    },
    rankNumber: {
        color: 'white',
        marginRight: 10,
        background: 'black',
        borderRadius: '50%',
        width: 25,
        height: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    teaserInfo: {
        width: '100%',
        position: 'absolute',
        top: -5,
        display: 'flex',
        flexWrap: 'wrap'
    },
    teaserAge: {
        width: 'fit-content',
        height: 'fit-content',
        color: '#fff',
        marginRight: 10,
        padding: '2px 5px',
        borderRadius: '20px',
        background: '#000',
        transition: 'background-color 0.5s ease'
    },
    teaserPosition: {
        color: '#fff',
        marginRight: 10,
        padding: '2px 4px',
        borderRadius: '4px',
        background: '#000'
    },
    teaserNotification: {
        padding: 10,
        paddingBottom: 0,
        width: 40,
        height: 40,
        marginLeft: 'auto'
    },
    notificationPromptOverlay: {
        display: 'flex',
        width: '100%',
        padding: 10,
        paddingTop: 5,
        justifyContent: 'flex-end',
        flexDirection: 'row'
    },
    aufmacher: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center'
    },
    '@media (max-width: 1175px)': {
        aufmacher: {
            flexDirection: 'column'
        }
    }
});

type Props = WithStyles<typeof styles> & {
    useBannerOffset: boolean,
    location: {
        pathname: string
    }
}

type State = {
    width?: number,
    height?: number,
    showPremium: number,
    showTeaserPositon: boolean,
    showAufmacherTime: boolean,
    showPositionTime: boolean,
    averageCTR: number
}

enum TeaserType {
    Rotation = 'rotation',
    Slider = 'slider'
}

const PAGE_CONFIG = {
    'web': {
        url: 'https://m.bild.de/?_sp_version=999#cvd',
        config: new PageData({
            label: 'heatstream web',
            columns: [
                new ColumnData(JetColumnId.documentId, 'id')
                    .setRelatedColumns([
                        JetColumnId.homeReferralWeb,
                        JetColumnId.isPremium,
                        JetColumnId.createdAt
                    ])
            ]
        })
    },
    'app': {
        url: 'https://m.bild.de/buehne/jJt31TLVzDZzInKWHyk1?_sp_version=999#cvd',
        config: new PageData({
            label: 'heatstream app',
            columns: [
                new ColumnData(JetColumnId.documentId, 'id')
                    .setRelatedColumns([
                        JetColumnId.homeReferralApp,
                        JetColumnId.isPremium,
                        JetColumnId.createdAt
                    ])
            ]
        })
    }
};

// might be extended when new heatmap is added (eg. app home)
type PageRoutePath = 'web' | 'app';

const CTR_INTERVAL = 60000;

const HeatmapComponent = observer(class HeatmapComponent extends Component<Props, State> {
    context!: React.ContextType<typeof firestoreContext>
    private heatmapSync!: HeatmapSync;
    static contextType = firestoreContext;
    private averageCTRInterval: number;
    private ref = React.createRef<HTMLImageElement>();

    constructor(props: any) {
        super(props);
        const averageCTR = this.getAverageCtr();
        this.state = {
            showPremium: 0,
            showTeaserPositon: false,
            showAufmacherTime: true,
            showPositionTime: true,
            averageCTR
        };
        this.averageCTRInterval = window.setInterval(() => {
            const averageCTR = this.getAverageCtr();
            if (averageCTR !== this.state.averageCTR) {
                this.setState({ averageCTR });
            }
        }, CTR_INTERVAL);
    }

    componentDidMount() {
        const { url, config: pageConfig } = this.getConfig();
        this.initHeatmapSync();
        this.heatmapSync.subscribeHeatmap(url, pageConfig);
        this.subscribeCTR();
        this.heatmapSync.getRankValue = this.getRankValueFunction();
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('click', this.handleClick);
    }

    getConfig() {
        const page = this.getPage();
        return PAGE_CONFIG[page];
    }

    initHeatmapSync() {
        this.heatmapSync = this.heatmapSync || new HeatmapSync(this.context);
    }

    componentDidUpdate(prevProps: Props) {
        const { url, config: pageConfig } = this.getConfig();
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.heatmapSync.subscribeHeatmap(url, pageConfig);
            this.heatmapSync.unsubscribeCTR();
            this.subscribeCTR();
            this.heatmapSync.getRankValue = this.getRankValueFunction();
            const averageCTR = this.getAverageCtr();
            this.setState({ averageCTR });
        }
    }

    subscribeCTR() {
        const page = this.getPage();
        if (page === 'web') {
            this.heatmapSync.subscribeCTRWeb();
        } else if (page === 'app') {
            this.heatmapSync.subscribeCTRApp();
        }
    }

    getRankValueFunction = () => {
        const page = this.getPage();
        return (relatedData = {} as DataResults) => {
            let count = 0;
            if (page === 'web') {
                count = relatedData.homeReferralWeb as number;
            }
            if (page === 'app') {
                count = relatedData.homeReferralApp as number;
            }
            return Math.round(Math.max(count, 0) || 0);
        };
    }

    getPage() {
        const { location: { pathname } } = this.props;
        const [, , , page] = pathname.split('/');
        return page as PageRoutePath;
    }

    getAverageCtr() {
        const page = this.getPage();
        return getCurrentCTRAverage(page);
    }

    componentWillUnmount() {
        if (this.heatmapSync) {
            this.heatmapSync.unsubscribe?.();
            this.heatmapSync.unsubscribeCTR?.();
        }
        clearInterval(this.averageCTRInterval);
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('click', this.handleClick);

    }

    getTopArticleShortcut(page: string) {
        if (page === 'web') {
            const url = '/jetstream/bild/homeWeb?time=15min&sortBy=homeReferralWeb&orderBy=desc';
            const title = 'Jetstream - Top Home Artikel';

            return (
                <p className={this.props.classes.topShortcut}>
                    <a
                        className={this.props.classes.shortCutAnchor}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                        href={url}
                    >
                        {title}
                    </a>
                </p>
            );
        }
    }

    updateTimeFilter = (positionTimeFilter: { showPositionTime: boolean, showAufmacherTime: boolean }) => {
        this.setState({
            showPositionTime: positionTimeFilter.showPositionTime,
            showAufmacherTime: positionTimeFilter.showAufmacherTime
        });
    };

    render() {
        const { classes, useBannerOffset } = this.props;
        const page = this.getPage();
        this.initHeatmapSync();
        const showLoader = !this.heatmapSync.heatmapData.teaserWithData;
        const currentCTR = this.heatmapSync.heatmapData.CTR;
        const filterUtils = new FilterUtils(this.heatmapSync.heatmapData.teaserWithData || []);
        const { teasers, groups } = filterUtils.getFilteredTeaserAndGroups(this.state.showPremium);
        const topArticleShortcut = this.getTopArticleShortcut(page);
        const teasersWithoutGroups = teasers?.filter(({ group }) => !group) || [];
        const aufmacherStats = filterUtils.getAufmacherStats();
        const rotationTeasers: any = [];
        const sliderTeasers: any = [];
        Object.entries(groups).forEach(([, { type, teaser }]) => {
            if (type === TeaserType.Rotation) {
                rotationTeasers.push(teaser);
            } else if (type === TeaserType.Slider) {
                sliderTeasers.push(teaser);
            }
        });
        const allTeasers = _.flattenDepth([teasersWithoutGroups, rotationTeasers, sliderTeasers], 3);
        const filterTeasers = (showPremium: number) => {
            this.setState({ showPremium });
        };
        return (
            <Page
                useBannerOffset={useBannerOffset}
                sidebarContent={
                    <Sidebar
                        filterTeasers={filterTeasers}
                        onTimeFilterChange={this.updateTimeFilter.bind(this)}
                    />
                }
                contentMaxWidth={812}
                contentMargin={{ left: 40, right: 40 }}
            >
                {topArticleShortcut}
                {!showLoader && <div className={classes.aufmacher}>
                    <AufmacherStats {...aufmacherStats} />
                    <CTRChart currentValue={currentCTR} averageValue={this.state.averageCTR} />
                </div>
                }
                {showLoader ? <CircularProgress /> : <>
                    <ColorScale min={0} max={filterUtils.maxValue} />
                    <Paper className={classes.paper}>
                        <img
                            src={this.heatmapSync.heatmapData.heatmap?.screenshot}
                            className={classes.screenshot}
                            ref={this.ref}
                            onLoad={this.handleResize}
                            alt='screenshot'
                        />
                        {teasersWithoutGroups.map((teaser: TeaserWithData, i) => this.renderAnchor(teaser, page, `teaser_${i}`))}
                        {sliderTeasers.map((teasers: TeaserWithData[]) => teasers.map((teaser, i) => this.renderAnchor(teaser, page, `sliderTeaser_${i}`)))}
                        {rotationTeasers.map((teaser: TeaserWithData[], i: number) => (
                            <React.Fragment key={`rotationTeasers${i}`}>
                                {this.renderAnchor(teaser[0], page, `rotationTeasers${i}_0`)}
                                {this.renderRotationBox(`rotationTeasers${i}`, teaser.slice(1), page)}
                            </React.Fragment>
                        ))}
                    </Paper>
                    <Minimap showImage={true} targetRef={this.ref} targetSrc={this.heatmapSync.heatmapData.heatmap?.screenshot} teasers={allTeasers} />
                </>}
            </Page>
        );
    }

    renderRotationBox(keyPrefix: string, teasers: TeaserWithData[], page: string) {
        if (teasers && teasers.length > 0) {
            const rect = this.getScaledRectangle(teasers[0].rect);
            const scale = 0.3;
            const style: CSSProperties = {
                top: rect.top,
                left: this.state?.width || 0,
                height: rect.height,
                width: ((rect.width * scale) + 10) * Math.ceil(teasers.length / ~~(1 / scale))
            };
            return (
                <div key={keyPrefix} className={this.props.classes.rotationBox} style={style}>
                    {teasers.map((teaser, i) => this.renderAnchor(teaser, page, `${keyPrefix}_${i}`, 0.3, false))}
                </div>
            );
        }
        return null;
    }

    renderAnchor(teaser: TeaserWithData, page: string, key: string, scale?: number, absolute = true) {
        const { classes } = this.props;
        const { top, left, width, height } = this.getScaledRectangle(teaser.rect, scale);

        return (
            <AnchorOverlay
                key={key}
                isAufmacher={FilterUtils.isAufmacher(teaser)}
                showTeaserPosition={config.showTeaserLabels}
                showPositionTime={this.state.showPositionTime}
                showAufmacherTime={this.state.showAufmacherTime}
                showConversion={config.showConversion}
                classes={Object.assign({}, classes, !absolute && { teaser: classes.teaserRelative })}
                teaser={teaser}
                rect={Object.assign({ width, height }, absolute && { top, left })}
            />
        );
    }

    getPosition({ top, left }: { top: number, left: number }) {
        return { top, left };
    }

    getSize({ width, height }: { width: number, height: number }) {
        return { width, height };
    }

    getScaledRectangle(rect: Rect, scale = 1): { top: number, left: number, width: number, height: number } {
        const image = this.ref.current;
        if (!image || !this.state.width || !this.state.height) {
            return {
                top: 0,
                left: 0,
                width: 0,
                height: 0
            };
        }
        const widthRatio = this.state.width / image.naturalWidth;
        const heightRatio = this.state.height / image.naturalHeight;
        return {
            top: rect.y * heightRatio,
            left: rect.x * widthRatio,
            width: rect.width * scale * widthRatio,
            height: rect.height * scale * heightRatio
        };
    }

    handleResize = () => {
        const image = this.ref.current;
        if (!image) {
            return;
        }
        const rect = image.getBoundingClientRect();
        this.setState({
            width: rect.width,
            height: rect.height
        });
    }
    handleClick = () => {
        window.dispatchEvent(new CustomEvent(DialogState.CloseDialog));
    }
});


export const Heatmap = withStyles(styles)(HeatmapComponent);
