import { StreamItemData } from '../Stream';
import { ArticleItem, FacebookPost } from './ArticleStreamTypes';

export class ArticleItemData extends StreamItemData {

    public readonly fbShares: number;
    public readonly interactions: number;
    public readonly clicks: number;
    public readonly posts: any;
    public readonly pages: any;

    constructor(rawData: ArticleItem, site: string) {
        super(rawData, site);
        this.fbShares = this._countFbShares(rawData.facebookPosts);
        this.interactions = this._countInteractions(rawData.facebookPosts);
        this.clicks = this._countClicks(rawData.facebookPosts);
        this.posts = rawData.facebookPosts;
        this.pages = this._getPages(rawData.facebookPosts);
    }

    _getPages(posts: FacebookPost[]) {
        const pages: Record<string, any> = {};
        posts.forEach((post) => {
            const pageId = post.page.id;
            if (pages[pageId] === undefined) {
                pages[pageId] = [post];
            } else {
                pages[pageId].push(post);
            }
        });
        return pages;
    }

    _countFbShares(posts: FacebookPost[]) {
        let amount = 0;
        posts.forEach((post) => {
            amount += post.stats.sharesTotal;
        });
        return amount;
    }

    _countInteractions(posts: FacebookPost[]) {
        let amount = 0;
        posts.forEach((post) => {
            // count comments, reactions and shares
            amount += post.stats.commentsTotal
                + post.stats.reactionLike
                + post.stats.reactionLove
                + post.stats.reactionWow
                + post.stats.reactionHaha
                + post.stats.reactionAngry
                + post.stats.reactionSad
                + post.stats.reactionThankful;
        });
        return amount;
    }

    _countClicks(posts: FacebookPost[]) {
        let amount = 0;
        posts.forEach((post) => {
            amount += post.stats.clicksTotal;
        });
        return amount;
    }
}
