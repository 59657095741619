export class TimeHelper {
    /**
     * @param {string} rawDate
     * @return {string} hh:mm
     */
    static getHourAndMinute(rawDate: string) {
        const date = new Date(rawDate);
        const hour = TimeHelper._addZeroPrefix(date.getHours());
        const minute = TimeHelper._addZeroPrefix(date.getMinutes());
        return `${hour}:${minute}`;
    }

    /**
     * @param {string|Date} rawDate
     * @return {string} TT/MM/JJJJ
     */
    static getDate(rawDate: string | Date) {
        const date = new Date(rawDate);
        const day = TimeHelper._addZeroPrefix(date.getDate());
        const month = TimeHelper._addZeroPrefix(date.getMonth() + 1);
        return `${day}.${month}.${date.getFullYear()}`;
    }

    /**
     * @param {string} dateText1
     * @param {string} dateText2
     * @return {boolean}
     */
    static isSameDay(dateText1: string, dateText2: string) {
        const date1 = (new Date(dateText1)).toDateString();
        const date2 = (new Date(dateText2)).toDateString();
        return date1 === date2;
    }

    /**
     * @param {number} number
     * @return {string|number}
     */
    static _addZeroPrefix(number: number) {
        return number < 10 ? '0' + number : number;
    }
}
