import { CSSProperties } from 'react';
import { PageData } from '../JetPage/PageData';
import { SumData, SumDataSparkline } from '../JetSum';
import { JetColumnId } from '../JetPage/JetColumnId';
import { QueryOptions } from '../../helpers/Query/QueryTypes';
import { ColumnData } from '../JetTable/ColumnData';
import { ColumnType } from '../JetTable/ColumnType';

const DASHBOARD_SPARKLINE_SUM_STYLE = {
    label: {
        fontWeight: 'bold',
        fontSize: 22,
        marginTop: -235
    }
};
const DASHBOARD_TACHO_STYLE = {
    goalNotReachedColor: '#fd581d',
    goalReachedColor: '#68D673',
    label: {
        fontWeight: 'bold',
        fill: '#9D9D9D'
    }
};
const DASHBOARD_TACHOS_SUM_STYLE = {
    sum: {
        fontWeight: 'bold',
        fontSize: 40,
        lineHeight: 'normal',
        marginTop: -55
    },
    label: {
        fontWeight: 'bold',
        fontSize: 30,
        lineHeight: 'normal',
        marginBottom: 55
    }
};
const CONVERSION_TACHO_STYLE = {
    justifySelf: 'center',
    alignSelf: 'end',
    paddingTop: 70
};
const CONVERSION_TACHOS_SUM_STYLE = {
    sum: {
        ...DASHBOARD_TACHOS_SUM_STYLE.sum,
        fontSize: 60
    },
    label: {
        ...DASHBOARD_TACHOS_SUM_STYLE.label,
        marginBottom: 35
    }
};

export const DASHBOARD_CONFIGS: {
    [brand: string]: {
        [dashbord: string]: {
            config: PageData
            style: { [id: string]: CSSProperties } & { dashboard: CSSProperties },
            filter?: QueryOptions
        }
    }
} = {
    welt: {
        // ================================================ NEWSROOM KPIS ================================================
        'newsroom_kpis': {
            config: new PageData({
                label: 'Ziele & Tagesverlauf',
                sums: [
                    new SumData(JetColumnId.pisTotal, 'Page Views', DASHBOARD_TACHOS_SUM_STYLE)
                        .setTacho({ style: DASHBOARD_TACHO_STYLE }),
                    new SumData(JetColumnId.conversion, 'Conversions', DASHBOARD_TACHOS_SUM_STYLE)
                        .setTacho({ style: DASHBOARD_TACHO_STYLE }),
                    new SumData(JetColumnId.mediaStarted, 'Media Starts', DASHBOARD_TACHOS_SUM_STYLE)
                        .setTacho({ style: DASHBOARD_TACHO_STYLE })
                        .setRequestOptions({ id: 'media' }),
                    new SumData(null, 'Page Views im Tagesverlauf', DASHBOARD_SPARKLINE_SUM_STYLE)
                        .setSparkline({ style: { height: 200, maxWidth: '95%', width: '95%' }, showLegend: true, sparklines: [
                            new SumDataSparkline(SumDataSparkline.SELECTED_COMBINED, { label: 'Gestern', chartOptions: { stack: 'PIs Gestern', areaStyle: { opacity: 0.2 } } })
                                .setRequestOptions({ sparklineTime: 'yesterday' }),
                            new SumDataSparkline(JetColumnId.pisWeb, { label: 'Web', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.pisApps, { label: 'App', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.referrerSearch, { label: 'Search', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.referrerSocial, { label: 'Social', chartOptions: { stack: 'PIs' } }),
                            new SumDataSparkline(JetColumnId.referrerMisc, { label: 'Misc', chartOptions: { stack: 'PIs' } })
                        ] })
                ]
            }),
            style: {
                sum0: {
                    justifySelf: 'start',
                    alignSelf: 'end',
                    zoom: 1.3
                },
                sum1: {
                    justifySelf: 'center',
                    alignSelf: 'end',
                    zoom: 1.5
                },
                sum2: {
                    justifySelf: 'end',
                    alignSelf: 'end',
                    zoom: 1.3
                },
                sum3: {
                    paddingTop: 70,
                    height: '100%',
                    justifySelf: 'center',
                    alignSelf: 'center',
                    zoom: 2
                },
                dashboard: {
                    display: 'grid',
                    gridTemplateAreas: `
                        "sum0 sum1 sum2"
                        "sum3 sum3 sum3"
                    `,
                    gridTemplateRows: '51% 45%',
                    gridRowGap: '2%'
                }
            }
        },
        // ================================================ Conversion Goals ================================================
        'conversion_goals': {
            config: new PageData({
                label: 'Conversion-Tachos',
                sums: [
                    new SumData(JetColumnId.conversion, 'Conversions', CONVERSION_TACHOS_SUM_STYLE)
                        .setTacho({ style: DASHBOARD_TACHO_STYLE }),
                    new SumData(JetColumnId.conversion, 'Wirtschaft', CONVERSION_TACHOS_SUM_STYLE)
                        .setTacho({ style: DASHBOARD_TACHO_STYLE })
                        .setRequestOptions({ departments: { 'wirtschaft_finanzen': 1 } }),
                    new SumData(JetColumnId.conversion, 'N & G', CONVERSION_TACHOS_SUM_STYLE)
                        .setTacho({ style: DASHBOARD_TACHO_STYLE })
                        .setRequestOptions({ departments: { 'newsteam': 1 } }),
                    new SumData(JetColumnId.conversion, 'Politik DE', CONVERSION_TACHOS_SUM_STYLE)
                        .setTacho({ style: DASHBOARD_TACHO_STYLE })
                        .setRequestOptions({ departments: { 'politik_inland': 1 } }),
                    new SumData(JetColumnId.conversion, 'Ausland', CONVERSION_TACHOS_SUM_STYLE)
                        .setTacho({ style: DASHBOARD_TACHO_STYLE })
                        .setRequestOptions({ departments: { 'politik_ausland': 1 } }),
                    new SumData(JetColumnId.conversion, 'Wissen', CONVERSION_TACHOS_SUM_STYLE)
                        .setTacho({ style: DASHBOARD_TACHO_STYLE })
                        .setRequestOptions({ departments: { 'gesundheit_wissenschaft': 1 } }),
                    new SumData(JetColumnId.conversion, 'I & R', CONVERSION_TACHOS_SUM_STYLE)
                        .setTacho({ style: DASHBOARD_TACHO_STYLE })
                        .setRequestOptions({ departments: { 'investigativ': 1 } }),
                    new SumData(JetColumnId.conversion, 'Sport', CONVERSION_TACHOS_SUM_STYLE)
                        .setTacho({ style: DASHBOARD_TACHO_STYLE })
                        .setRequestOptions({ departments: { 'sport': 1 } }),
                    new SumData(JetColumnId.conversion, 'Lifestyle', CONVERSION_TACHOS_SUM_STYLE)
                        .setTacho({ style: DASHBOARD_TACHO_STYLE })
                        .setRequestOptions({ departments: { 'iconist': 1 } }),
                    new SumData(JetColumnId.conversion, 'Kultur', CONVERSION_TACHOS_SUM_STYLE)
                        .setTacho({ style: DASHBOARD_TACHO_STYLE })
                        .setRequestOptions({ departments: { 'kultur': 1 } }),
                    new SumData(JetColumnId.conversion, 'Debatte', CONVERSION_TACHOS_SUM_STYLE)
                        .setTacho({ style: DASHBOARD_TACHO_STYLE })
                        .setRequestOptions({ departments: { 'debatte': 1 } })
                ]
            }),
            style: {
                sum0: {
                    ...CONVERSION_TACHO_STYLE,
                    zoom: 1.2
                },
                sum1: CONVERSION_TACHO_STYLE,
                sum2: CONVERSION_TACHO_STYLE,
                sum3: CONVERSION_TACHO_STYLE,
                sum4: CONVERSION_TACHO_STYLE,
                sum5: CONVERSION_TACHO_STYLE,
                sum6: CONVERSION_TACHO_STYLE,
                sum7: CONVERSION_TACHO_STYLE,
                sum8: CONVERSION_TACHO_STYLE,
                sum9: CONVERSION_TACHO_STYLE,
                sum10: CONVERSION_TACHO_STYLE,
                dashboard: {
                    display: 'grid',
                    gridTemplateAreas: `
                        "sum0 sum0 sum1 sum2"
                        "sum3 sum4 sum5 sum6"
                        "sum7 sum8 sum9 sum10"
                    `,
                    height: '94vh',
                    width: '96vw',
                    margin: '4vh 2vw 2vh 2vw'
                }
            }
        },
        // ================================================ Top Articles ================================================
        'top_articles': {
            config: new PageData({
                label: 'Top Artikel',
                sums: [
                    new SumData(JetColumnId.pisTotal, 'Page Views', DASHBOARD_TACHOS_SUM_STYLE)
                        .setTacho({ style: DASHBOARD_TACHO_STYLE }),
                    new SumData(JetColumnId.conversion, 'Conversions', DASHBOARD_TACHOS_SUM_STYLE)
                        .setTacho({ style: DASHBOARD_TACHO_STYLE })
                ],
                tables: [{
                    columns: [
                        new ColumnData(JetColumnId.title, 'Titel', ColumnType.LINK, false),
                        new ColumnData(JetColumnId.pisTotal, 'PIs')
                    ],
                    filter: {
                        orderBy: 'desc',
                        sortBy: JetColumnId.pisTotal
                    },
                    numberOfRows: 5
                }, {
                    columns: [
                        new ColumnData(JetColumnId.title, 'Titel', ColumnType.LINK, false),
                        new ColumnData(JetColumnId.conversion, 'Conversions')
                    ],
                    filter: {
                        orderBy: 'desc',
                        sortBy: JetColumnId.conversion,
                        filter: {
                            plus: 1,
                            conversion: 1
                        }
                    },
                    numberOfRows: 5
                }]
            }),
            style: {
                sum0: {
                    justifySelf: 'center',
                    alignSelf: 'end',
                    zoom: 1.5
                },
                sum1: {
                    justifySelf: 'center',
                    alignSelf: 'end',
                    zoom: 1.5
                },
                dashboard: {
                    display: 'grid',
                    gridTemplateAreas: `
                        "sum0 sum1"
                        "table0 table1"
                    `,
                    gridColumnGap: 30,
                    height: '94vh',
                    width: '96vw',
                    margin: '4vh 2vw 2vh 2vw'
                }
            }
        }
    }
};
