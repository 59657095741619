import React, { useState, useRef, useEffect } from 'react';
import { withStyles, createStyles, WithStyles, Paper, Theme } from '@material-ui/core';
import useScrollPosition from '@react-hook/window-scroll';
import { MinimapOverlay } from './MinimapOverlay';

const styles = (theme: Theme) => createStyles({
    minimap: {
        position: 'fixed',
        right: 20,
        top: 70,
        maxHeight: '90vh',
        height: '90vh',
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    minimapRange: {
        appearance: 'none',
        zIndex: 10,
        position: 'absolute',
        left: 0,
        top: 0,
        margin: 0,
        transform: 'translateY(-100%) rotate(90deg)',
        transformOrigin: 'bottom left',
        background: 'transparent',
        backgroundColor: 'transparent',
        maxWidth: '90vh',
        width: '90vh',
        height: 'auto',
        '&:focus': {
            outline: 'none'
        },
        // firefox
        '&::-moz-range-thumb': {
            height: '7vh',
            width: 'var(--slider-thumb-width, 32px)',
            background: 'black',
            opacity: 0.5,
            cursor: 'pointer'
        },
        '&::-moz-range-track': {
            background: 'transparent'
        },
        // chrome
        '&::-webkit-slider-thumb': {
            appearance: 'none',
            height: '7vh',
            width: 'var(--slider-thumb-width, 32px)',
            background: 'black',
            opacity: 0.5,
            cursor: 'pointer'
        },
        '&::-webkit-slider-runnable-track': {
            background: 'transparent',
            padding: 0,
            margin: 0
        }
    }
});

function vh(v: number) {
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (v * h) / 100;
}

type Props = WithStyles<typeof styles> & {
    targetRef: React.RefObject<HTMLImageElement>,
    targetSrc: string | undefined,
    teasers: any,
    showImage: boolean
};

function usePrevious(value: string | number | undefined) {
    const ref = useRef<string | number | undefined>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function MinimapComponent(props: Props) {
    const [range, setRange] = useState<number>(0);
    const [svgWidth, setSvgWidth] = useState<number>(0);
    const [svgHeight, setSvgHeight] = useState<number>(0);
    const [top, setTop] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);
    const [background, setBackground] = useState<string>('transparent');

    const scrollY = useScrollPosition(0);
    const [prevScrollY] = [usePrevious(scrollY)];

    const minimapRef = useRef<HTMLDivElement>(null);
    const rangeRef = useRef<HTMLInputElement>(null);

    const rangeOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRange(+e.target.value);
        const pageHeight = document.documentElement.scrollHeight;
        // remove viewport height from pageHeight during scroll position calculation
        const pageScrollPosition = (+e.target.value / 100) * (pageHeight - vh(100));
        window.scrollTo(0, pageScrollPosition);
    };

    useEffect(() => {
        const minimapRange = rangeRef.current!;
        const minimap = minimapRef.current!;
        const target = props.targetRef.current!;

        const rect = target.getBoundingClientRect();
        const pageHeight = document.documentElement.scrollHeight;
        const imageClientHeight = rect.height;
        const heightAboveImage = rect.top + scrollY;

        const scale = vh(90) / pageHeight;

        // calculate displayed minimap and range width
        const calcWidth = (+target.naturalWidth / +target.naturalHeight) * vh(90);
        minimap.style.width = `${calcWidth}px`;
        minimapRange.style.height = `${calcWidth}px`;

        // calculate displayed slider height
        const calcRangeWidth = vh(100) * scale;
        minimapRange.style.setProperty('--slider-thumb-width', `${calcRangeWidth}px`);

        if (props.showImage) {
            const backgroundStyle = `rgba(0,0,0,.039) url('${props.targetSrc}') no-repeat center / contain`;
            setBackground(backgroundStyle);
        }
        if (prevScrollY !== scrollY) {
            // remove viewport height from pageHeight during range calculation
            const rangeValue = (scrollY / (pageHeight - vh(100))) * 100;
            setRange(rangeValue);
        }

        setTop(heightAboveImage * scale);
        setHeight(imageClientHeight * scale);
        setSvgWidth(target.naturalWidth);
        setSvgHeight(target.naturalHeight);
    }, [props.targetRef, props.teasers, scrollY]);

    return (
        <Paper className={props.classes.minimap} ref={minimapRef}>
            <input className={props.classes.minimapRange}
                type='range'
                min={0}
                max={100}
                ref={rangeRef}
                value={range}
                onChange={rangeOnChange}
                step={0.1}
            />
            <MinimapOverlay
                svgWidth={svgWidth}
                svgHeight={svgHeight}
                top={top}
                height={height}
                background={background}
                teasers={props.teasers}
            />
        </Paper>
    );
}

export const Minimap = withStyles(styles)(MinimapComponent);
