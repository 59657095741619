import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BugReportOutlinedIcon from '@material-ui/icons/BugReportOutlined';
import { ErrorPage } from './ErrorPage';

export class UnknownErrorPage extends Component {
    render() {
        const { match } = this.props;
        const statusCode = match?.params?.statusCode;
        const statusMessage = statusCode ? ` (${statusCode})` : '';
        return (
            <ErrorPage
                subject={`Fehlerbericht${statusMessage}`}
                icon={<BugReportOutlinedIcon/>}
                description={`Ein unbekannter Fehler ist aufgetreten.${statusMessage}`}
                action="Kontaktiere uns"
            />
        );
    }
}

UnknownErrorPage.propTypes = {
    match: PropTypes.object
};
