
import React, { FunctionComponent } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { IconButton, Theme, WithStyles } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import Tooltip from '@material-ui/core/Tooltip';

const styles = (theme: Theme) => createStyles({
    active: {
        color: theme.palette.secondary.main
    },
    inActive: {
        color: '#e0e0e0'
    }
});

type Props = WithStyles<typeof styles> & {
    isActive: boolean,
    onButtonClick: (isActive: boolean) => void
}

const StarButtonComponent: FunctionComponent<Props> = ({ classes, isActive, onButtonClick }) => {
    const tooltip = isActive ? 'Von Watchlist entfernen' : 'Zur Watchlist hinzufügen';
    const handleClick = () => onButtonClick(isActive);
    return (
        <Tooltip title={tooltip}>
            <IconButton onClick={handleClick}>
                <StarIcon className={isActive ? classes.active : classes.inActive} />
            </IconButton>
        </Tooltip>
    );
};

export const StarButton = withStyles(styles)(StarButtonComponent);
