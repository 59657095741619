import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemedEcharts } from '../../../../echarts';
import { CAPTION } from '../../../common/configs/scaleCategory';
import { TimeHelper } from '../../../common/helpers/Time';

export class SparklineChart extends Component {
    render() {
        return (
            <div>
                <ThemedEcharts
                    option={this.getSparklineOption(this.props.max, this.props.data, this.props.times)}
                    style={{
                        height: 50,
                        width: 300,
                        ...this.props.style
                    }}
                />
            </div>
        );
    }

    getSparklineOption(max, data = [], times = []) {
        return {
            tooltip: {
                trigger: 'axis',
                textStyle: { ...CAPTION }
            },
            xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
                data: times.map(time => `${TimeHelper.getHourAndMinute(new Date(time))} Uhr`)
            },
            yAxis: {
                type: 'value',
                max,
                show: false
            },
            series: [{
                data,
                type: 'line',
                areaStyle: {},
                symbol: 'none'
            }],
            grid: {
                left: '1%',
                right: '1%',
                top: '1%',
                bottom: '1%'
            }
        };
    }
}

SparklineChart.propTypes = {
    data: PropTypes.array.isRequired,
    times: PropTypes.array.isRequired,
    max: PropTypes.number,
    style: PropTypes.object
};
