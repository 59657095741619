import { hashesChanged } from '../../../jetstream/components/Watchlist/SelfloadingWatchlistItem';
import { WatchlistStore } from './WatchlistStore';

export type WatchlistQueryData = {
    pageId: string,
    hash: string,
    sparklineTime: string
}

/**
 * WatchlistStoreUpdater takes incomming query information and syncs them with the WatchlistStore
 */
export class WatchlistStoreUpdater {

    private readonly store: WatchlistStore;

    /**
     *
     * @param store instance of WatchlistStore used to update the queries
     */
    constructor(store: WatchlistStore) {
        this.store = store;
    }

    /**
     * Updates the WatchlistStore based on the given QuerryData.
     * New request will be added, missing queries will be removed.
     * Prevents WatchlistStore update if queries havent changed.
     * @param queryData
     */
    public update(queryData: WatchlistQueryData[]): void {
        const pageIds = queryData.map(e => e.pageId);
        const subscribedPageIds = this.store.getWatchlistItemsPageIds();
        const pageIdsRemove = subscribedPageIds.filter(e => !pageIds.includes(e));

        pageIds.forEach((pageId) => {
            this.removeReferencesIfHashesChanged(pageId, queryData);
        });

        queryData.forEach(entry => {
            this.store.addWatchlistItemReference(entry.pageId, {
                hash: entry.hash,
                highFrequencyQuery: entry.sparklineTime !== 'yesterday'
            });
        });

        // remove old Ids
        pageIdsRemove.forEach(pageId => {
            this.store.removeWatchlistItemReference(pageId);
        });
        this.store.sync();
    }

    /**
     * Removes references if new hashes and old hashes do not match
     * Prevents mixed hashes for different timefilters
     */
    private removeReferencesIfHashesChanged(pageId: string, queryData: WatchlistQueryData[]) {
        const newHashesForPageId = queryData.filter(e => e.pageId === pageId).map(e => {
            return { hash: e.hash, highFrequencyQuery: false };
        });
        const storedHashes = this.store.getFirebaseHashes(pageId);
        if (hashesChanged(newHashesForPageId, storedHashes)) {
            this.store.removeWatchlistItemReference(pageId);
        }
    }
}
