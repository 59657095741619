import { TeaserWithData } from './HeatmapSync';
import { getTeaserBackgroundColor } from './ColorScale';
import { Rect } from './Teaser';


export class FilterUtils {
    teaserWithData: TeaserWithData[];
    alteredTeasers: TeaserWithData[];
    constructor(teaserWithData: TeaserWithData[]) {
        this.teaserWithData = this.alteredTeasers = teaserWithData;
    }

    private calculateRank(teasers: TeaserWithData[]) {
        const sorted = [...teasers].sort((teaserA: TeaserWithData, teaserB: TeaserWithData) => teaserB.value - teaserA.value);
        const max = sorted?.[0]?.value || 0;
        let rank = 1;
        sorted.forEach((teaser, i, arr) => {
            const next = arr[i + 1];
            teaser.rank = rank;
            teaser.color = getTeaserBackgroundColor(teaser.value, max);
            if (next?.value !== teaser.value) {
                rank++;
            }
            if (teaser.mapcoords) {
                teaser.group = teaser.mapcoords && this.makeRectKey('svg', teaser.rect);
            }
        });
        return teasers;
    }

    private getGroups(teasers: TeaserWithData[]) {
        const groups: { [key: string]: { type: string, teaser: TeaserWithData[] } } = {};
        teasers?.forEach((teaser) => {
            const key = teaser.group;
            if (key) {
                if (!groups[key]) {
                    const type = key.replace(/"/g, '').split('_').pop();
                    if (type) {
                        groups[key] = { type, teaser: [] };
                    }
                }
                groups[key].teaser.push(teaser);
            }
        });
        return groups;
    }

    private makeRectKey(type: string, rect: Rect): string {
        return JSON.stringify(`${rect.x}:${rect.y}:${rect.width}:${rect.height}_${type}`);
    }

    get maxValue() {
        return this.alteredTeasers?.reduce((max, { value }) => Math.max(max, value), 0);
    }

    getFilteredTeaserAndGroups(showPremiumOnly: number) {
        const teasers = this.teaserWithData;
        let alteredTeasers = teasers;

        if (showPremiumOnly === 1) {
            alteredTeasers = teasers.map((teaser: TeaserWithData) => ({
                ...teaser,
                value: teaser.relatedData?.isPremium ? teaser.value : 0
            }));
        } else if (showPremiumOnly === -1) {
            alteredTeasers = teasers.map((teaser: TeaserWithData) => ({
                ...teaser,
                value: teaser.relatedData?.isPremium ? 0 : teaser.value
            }));
        }

        alteredTeasers = this.calculateRank(alteredTeasers);
        const groups = this.getGroups(alteredTeasers);

        this.alteredTeasers = alteredTeasers;

        return { teasers: alteredTeasers, groups };

    }

    public static isAufmacher(teaser: TeaserWithData) {
        const position = parseInt(teaser.teaserPosition || '', 10);
        return Number.isInteger(position) && position < 2;
    }

    getAufmacherStats() {
        const distinctTeasers: TeaserWithData[] = [];
        const teasersSet = new Set();
        const aufmacherTeasers = this.teaserWithData.filter((teaser) => {
            return FilterUtils.isAufmacher(teaser);
        });
        /*
           Since we can have more the one article/teaser in the same position (e.g. live ticker),
           we need to count only one article per teaser position.
        */
        aufmacherTeasers.forEach((teaser) => {
            if (!teasersSet.has(teaser.teaserPosition)) {
                teasersSet.add(teaser.teaserPosition);
                distinctTeasers.push(teaser);
            }
        });
        const premiumTeasers = distinctTeasers.filter((teaser) => teaser.isPremium);
        const videoTeasers = distinctTeasers.filter((teaser) => teaser.isVideo);

        return {
            aufmacherArticlesCount: distinctTeasers.length,
            premiumArticlesCount: premiumTeasers.length,
            videoArticlesCount: videoTeasers.length
        };
    }
}
