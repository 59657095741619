import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { UnauthorizedErrorPage } from './UnauthorizedErrorPage';
import { ForbiddenErrorPage } from './ForbiddenErrorPage';
import { UnknownErrorPage } from './UnknownErrorPage';

export class ErrorRouter extends Component {
    render() {
        return (
            <Switch>
                <Route path="/error/401" component={UnauthorizedErrorPage}/>
                <Route path="/error/403" component={ForbiddenErrorPage}/>
                <Route path="/error/:statusCode" component={UnknownErrorPage}/>
                <Route component={UnknownErrorPage}/>
            </Switch>
        );
    }
}
