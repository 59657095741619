/* eslint-disable camelcase */
import { config } from './config';

export function tealium(a, b, c, d) {
    if (!shouldTrack()) {
        return;
    }
    // set default layer
    window.utag_data = {
        page_name: null,
        app_name: null,
        env_tenant: null
    };
    // set debug mode
    document.cookie = `utagdb=${config.debug};path=/`;
    // disable automatic page tracking for SPA
    // https://docs.tealium.com/platforms/javascript/single-page-applications/
    window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
    window.utag_cfg_ovrd.noview = true;
    // ensure local testing will not send prod tracking data
    const environment = config.stage === 'production' ? 'prod' : 'dev';
    // load utag
    a = `//tags.tiqcdn.com/utag/axelspringer/tools/${environment}/utag.js`;
    b = document;
    c = 'script';
    d = b.createElement(c);
    d.src = a;
    d.type = 'text/java' + c;
    d.async = true;
    a = b.getElementsByTagName(c)[0];
    a.parentNode.insertBefore(d, a);
}

export async function trackLink(data) {
    if (!shouldTrack()) {
        return;
    }
    const utag = await getUtag();
    if (utag) {
        utag.ut.merge(utag.data, data, 1);
        utag.link({ ...utag.data });
    }
}

function shouldTrack() {
    return config.stage === 'production';
}

async function getUtag() {
    if (window.utag) {
        return window.utag;
    }
    const maxRetries = 10;
    // wait for utag to load
    for (let i = 0; i < maxRetries; i++) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        if (window.utag) {
            return window.utag;
        }
    }
    // return dummy to drop event
    return undefined;
}
