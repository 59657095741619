import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Tooltip } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '-5px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            justifyContent: 'center'
        }
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 5,
        '& p': {
            margin: 0
        }
    }
});

class IconBarComponent extends Component {

    constructor(props) {
        super(props);
        this.classes = this.props.classes;
    }

    render() {
        return (
            <div className={this.classes.root}>
                {this._getIcons()}
            </div>
        );
    }

    _getIcons() {
        const icons = [];
        for (let i = 0; i < this.props.data.length; i++) {
            const iconData = this.props.data[i];
            icons.push((
                <Tooltip title={iconData.hint} placement="bottom" key={i}>
                    <div className={this.classes.item}>
                        <p>{iconData.value}</p>
                        {iconData.icon}
                    </div>
                </Tooltip>
            ));
        }
        return icons;
    }
}

IconBarComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export const IconBar = withStyles(styles)(IconBarComponent);
