import _ from 'lodash';
import { PageData } from '../../../jetstream/components/JetPage/PageData';
import { observable, reaction, toJS, computed, autorun, makeObservable, runInAction } from 'mobx';
import { QueryBuilder, QueryData } from '../../../jetstream/helpers/Query/QueryBuilder';
import { RequestOptions, QueryOptions, RawResult } from '../../../jetstream/helpers/Query/QueryTypes';
import { UserStore } from './UserStore';
import objectHash from 'object-hash';
import { WatchlistStore } from './WatchlistStore';
import { FirestoreHash } from './FirestoreHash';
import { WatchlistQueryData, WatchlistStoreUpdater } from './WatchlistStoreUpdater';
import type { FirestoreStore } from './FirestoreStore';

export class QueryStore {
    unsubscribes: { [id: string]: () => void } = {}
    private readonly firestore: FirestoreStore;
    private readonly userStore: UserStore;
    private readonly watchlistStore: WatchlistStore
    private readonly watchlistStoreUpdater: WatchlistStoreUpdater

    constructor(firestore: FirestoreStore, userStore: UserStore, watchlistStore: WatchlistStore) {
        this.firestore = firestore;
        this.userStore = userStore;
        this.watchlistStore = watchlistStore;
        this.watchlistStoreUpdater = new WatchlistStoreUpdater(this.watchlistStore);
        makeObservable<QueryStore, 'queries' | 'rawQueryResults' | 'rawQueryResultsDebounced'>(this, {
            queryOptions: observable.struct,
            pageConfig: observable.ref,
            wait: observable,
            queries: computed,
            rawQueryResults: observable,
            rawQueryResultsDebounced: observable,
            queryResult: computed.struct,
            rowsResult: computed
        });

        this.init();
    }

    async init() {
        await this.firestore.waitForLogin();
        reaction(
            () => Object.keys(this.queries),
            (queryIds) => {
                const oldQueryIds = Object.keys(this.unsubscribes);
                const removedQueryIds = oldQueryIds.filter(id => !queryIds.includes(id));
                const addedQueryIds = queryIds.filter(id => !oldQueryIds.includes(id));
                addedQueryIds.forEach(id => {
                    this.unsubscribes[id] = this.firestore.subscribeQuery(toJS(this.queries[id].query), (data: any) => {
                        runInAction(() => {
                            this.rawQueryResults = { ...this.rawQueryResults, [id]: data };
                        });
                    });
                });
                // delete subscription of removed queries
                removedQueryIds.forEach(id => {
                    this.unsubscribes[id]();
                    delete this.unsubscribes[id];
                    runInAction(() => {
                        delete this.rawQueryResults[id];
                        this.rawQueryResults = { ...this.rawQueryResults };
                    });
                });
            },
            {
                fireImmediately: true
            }
        );
        reaction(() => this.rawQueryResults, (rawQueryResults) => {
            this.rawQueryResultsDebounced = { ...rawQueryResults };
        }, { delay: 500 });
        // autorun(() => console.log('queryOptions', toJS(this.queryOptions)))
        // autorun(() => console.log('pageConfig', toJS(this.pageConfig)))
        // autorun(() => console.log('queries', toJS(this.queries)))
        // autorun(() => console.log('rawQueryResults', toJS(this.rawQueryResults)))
        autorun(() => console.log('queryResult', toJS(this.queryResult)));
    }

    queryOptions: QueryOptions = {};
    pageConfig?: PageData;
    wait = false;

    private get queries(): { [id: string]: QueryData } {
        const hasBrand = this.queryOptions.brand || this.queryOptions.groupBrands;
        if (this.wait || !hasBrand || !this.queryOptions.time || !this.pageConfig) {
            return {};
        }
        const { watchlistKey } = this.pageConfig;

        const pageIds = watchlistKey && this.userStore.getWatchlist(watchlistKey)?.map(({ id }) => id);
        if (watchlistKey && !pageIds?.length) {
            this.watchlistStoreUpdater.update([]);
            return {};
        }

        const queryDataArray = this.createQueries(watchlistKey, pageIds, this.pageConfig);
        return _.fromPairs(queryDataArray.map((queryData: any) => [objectHash(queryData.query), queryData]));
    }

    private createQueries(watchlistKey: string | undefined, pageIds: '' | string[] | undefined, pageConfig: PageData): any {
        if (watchlistKey && pageIds) {
            const result = this.createWatchlistQueriesSplittedByPageId(pageIds, pageConfig);
            this.watchlistStoreUpdater.update(result.watchlistQueryData);
            return result.queryDataArray;
        } else {
            return this.runQueryWithPageIdArray(pageIds, pageConfig);
        }
    }

    private runQueryWithPageIdArray(pageIds: string | string[] | undefined, pageConfig: PageData) {
        const queryOptions = {
            ...this.queryOptions,
            ...(pageIds && { pageIds })
        };
        const queryBuilder = new QueryBuilder();
        return queryBuilder.getQueries(pageConfig, queryOptions);
    }

    private createWatchlistQueriesSplittedByPageId(pageIds: string | string[], pageConfig: PageData) {
        const queryBuilder = new QueryBuilder();
        const queryDataArray: QueryData[] = [];
        const watchlistQueryData: WatchlistQueryData[] = [];
        for (const pageId of pageIds) {
            const queryOptions = {
                pageIds: [pageId],
                ...this.queryOptions
            };
            const temp = queryBuilder.getQueries(pageConfig, queryOptions);
            for (const queryData of temp) {
                const firestoreHash = FirestoreHash.create(queryData.query);
                const sparklineTime = queryData.query.sparklineTime || '';
                watchlistQueryData.push({ pageId, hash: firestoreHash.hash, sparklineTime: sparklineTime });
                queryDataArray.push(queryData);
            }
        }
        return { queryDataArray, watchlistQueryData };
    }

    private rawQueryResults: {
        [id: string]: RawResult
    } = {};

    private rawQueryResultsDebounced: {
        [id: string]: RawResult
    } = {};

    get hasPendingQueries() {
        return Object.keys(this.queries).length > 0;
    }

    public getPageConfig(): PageData | undefined {
        return this.pageConfig;
    }

    get queryResult() {
        return this.pageConfig?.buildQueryResult(this.getData);
    }

    get rowsResult() {
        return this.transpose(this.queryResult?.columns);
    }

    private transpose<T>(array?: (T[] | undefined)[]) {
        return array?.[0]?.map((value, colIndex) => array.map(row => row?.[colIndex]));
    }

    private readonly getData = (requestOptions: RequestOptions): RawResult => {
        const hash = objectHash(requestOptions);
        const [queryId = ''] = Object.entries(this.queries).find(([, query]) => query.pageConfig === this.pageConfig && query.hash === hash) || [];
        return this.rawQueryResultsDebounced[queryId];
    }

    /**
     * Returns a FirestoreHash based on a RequestOptions hash and an optional pageId.
     * The Queriestore holds multiple queries, each stored under a requestHash.
     * There can be multiple queries with the same requestHash but for different pageIds.
     * Adding the pageId will return the firestoreHash which maps to the requestHash and its pageId.
     * Without pageId the first firestoreHash found will be returned.
     * @param hash hash of RequestOptions
     * @param pageId optional pageId
     * @returns Hash referring to a document in Firestore
     */
    public getFirebaseQueryId(hash: string, pageId?: string): string {
        const entries = Object.entries(this.queries);
        const [queryId = ''] = entries.find(([, query]) => {
            return query.pageConfig === this.pageConfig &&
                   query.hash === hash &&
                   pageId ? query.pageIds?.includes(pageId) : false;
        }) || [];
        return queryId;
    }
}
