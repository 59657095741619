import React from 'react';
import { makeStyles, Paper, useMediaQuery } from '@material-ui/core';
import { Menu } from './Menu';
import { TimeFilter } from './TimeFilter';
import { TypeFilter } from './TypeFilter';

const useStyles = makeStyles(() => ({
    root: {
        width: 320
    }
}));

type Props = {
    filterTeasers: (filterMode: number) => void,
    onTimeFilterChange: (positionTimeFilter: { showPositionTime: boolean, showAufmacherTime: boolean }) => void
};

export const Sidebar = ({ filterTeasers, onTimeFilterChange }: Props) => {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:430px)');
    return (
        <Paper className={classes.root}>
            <Menu />
            <TypeFilter filterTeasers={filterTeasers}/>
            {!isMobile && <TimeFilter onTimeFilterChange={onTimeFilterChange} />}
        </Paper>
    );
};
