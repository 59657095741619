import React, { Component } from 'react';
import { withStyles, createStyles, SnackbarContent, Button } from '@material-ui/core';
import amber from '@material-ui/core/colors/amber';
import WarningIcon from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';
import { config } from '../../../../config';

const styles = (theme) => createStyles({
    bannerContainer: {
        position: 'fixed',
        top: 0,
        width: 600,
        maxWidth: 600,
        left: 'calc((100vw / 2) - 324px)',
        zIndex: 2000,
        backgroundColor: amber[600],
        borderRadius: '0 0 5px 5px'
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        color: '#000000',
        fontWeight: 500,
        fontSize: '0.8125rem'
    },
    icon: {
        fontSize: 24,
        marginRight: theme.spacing()
    },
    button: {
        marginLeft: theme.spacing()
    }
});

const CONFIRMED_STAGE_USAGE_KEY = 'confirmedStageUse';

class StageBannerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isProduction: window.location.host === config.prodHost,
            alreadyConfirmed: this.getConfirmStageUsage()
        };
    }

    render() {
        const { classes } = this.props;
        const { isProduction, alreadyConfirmed } = this.state;
        if (isProduction || alreadyConfirmed) {
            return null;
        }
        return (
            <SnackbarContent
                className={classes.bannerContainer}
                message={
                    <span className={classes.message}>
                        <WarningIcon className={classes.icon}/>
                        Sie befinden sich auf der Test-Umgebung, in der neue Features evaluiert werden.<br/>
                        Die hier angezeigten Daten können fehlerhaft sein.
                    </span>
                }
                action={<React.Fragment>
                    <Button variant="contained" size='small' onClick={this.goToProd} className={classes.button}>
                            Zur offiziellen Website
                    </Button>
                    <Button size='small' onClick={this.confirmStageUsage} className={classes.button}>
                            Test-Umgebung nutzen
                    </Button>
                </React.Fragment>}
            />
        );
    }

    getConfirmStageUsage() {
        return !!window.sessionStorage.getItem(CONFIRMED_STAGE_USAGE_KEY);
    }

    confirmStageUsage = () => {
        window.sessionStorage.setItem(CONFIRMED_STAGE_USAGE_KEY, 'true');
        this.setState({ alreadyConfirmed: true });
    }

    goToProd = () => {
        const { location } = window;
        location.href = `https://${config.prodHost}${location.pathname}${location.search}${location.hash}`;
    };
}

StageBannerComponent.propTypes = {
    classes: PropTypes.object.isRequired
};

export const StageBanner = withStyles(styles)(StageBannerComponent);
