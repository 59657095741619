import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Dialog, DialogContent, Button } from '@material-ui/core';
import { lightGreen } from '@material-ui/core/colors';

const styles = theme => ({
    dialog: {
        textAlign: 'center'
    },
    content: {
        width: 560,
        maxWidth: '100%',
        boxSizing: 'border-box',
        padding: 30,
        '& > *': {
            marginTop: 25,
            marginBottom: 25
        }
    },
    icon: {
        display: 'block',
        '& svg': {
            fontSize: 70,
            color: 'grey'
        }
    },
    text: {
        fontSize: 20,
        marginTop: 0,
        lineHeight: 'normal'
    },
    button: {
        color: theme.palette.getContrastText(lightGreen[400]),
        backgroundColor: lightGreen[400],
        '&:hover': {
            backgroundColor: lightGreen[500]
        }
    }
});

class ErrorPageComponent extends Component {

    constructor(props) {
        super(props);
        this.classes = this.props.classes;
        this.onButtonClick = this.onButtonClick.bind(this);
    }

    render() {
        return (
            <Dialog
                className={this.classes.dialog}
                open={true}
            >
                <DialogContent className={this.classes.content}>
                    <span className={this.classes.icon}>
                        {this.props.icon}
                    </span>
                    <p className={this.classes.text}>
                        {this.props.description}
                    </p>
                    <Button
                        variant="contained"
                        onClick={this.onButtonClick}
                        className={this.classes.button}
                        size="large"
                    >
                        {this.props.action}
                    </Button>
                </DialogContent>
            </Dialog>
        );
    }

    onButtonClick() {
        window.open(this.getButtonLink(), '_blank');
    }

    getButtonLink() {
        const actionLink = this.props.actionLink;
        if (actionLink) {
            return actionLink;
        }
        return 'https://axelspringer.service-now.com/itportal?id=sc_cat_item&sys_id=24b7bcb2dbb43550f4d6a6cbd39619a7&sysparm_category=456b9638db103d50f4d6a6cbd39619d7';
    }
}

ErrorPageComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    icon: PropTypes.element.isRequired,
    description: PropTypes.node.isRequired,
    action: PropTypes.string.isRequired,
    actionLink: PropTypes.string
};

export const ErrorPage = withStyles(styles)(ErrorPageComponent);
