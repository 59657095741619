import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { PurestComponent } from '../../../../PurestComponent';
import { NavigationBar, StageBanner } from '../NavigationBar';
import { DailyUUsDashboard, DashboardPage } from '../../../dashboard/components/Metabase';
import { ArticlePage } from '../../../stream/components/Article';
import { ExactagConverterPage } from '../../../dashboard/components/Exactag';
import { JetPage } from '../../../jetstream/components/JetPage';
import { Query } from '../../helpers/Query';
import { JetStats } from '../../../jetstream/components/JetAdmin';
import { FirestoreStore } from '../../helpers/Firestore';
import { JetDashboard, isDashboardPath, DASHBOARD_PATH } from '../../../jetstream/components/JetDashboard/JetDashboard';
import { Heatmap } from '../../../heatmap/components/Heatmap';
import { AppPath } from '../../helpers/Route';
import { withAuth } from '../Auth';
import { BannerBar } from '../Banner/BannerBar';

const styles = theme => ({
    root: {
        position: 'relative',
        zIndex: 1,
        backgroundColor: theme.stream.page.background
    },
    bannerOffset: {
        top: 50
    },
    content: {
        flexGrow: 1,
        minWidth: 0 // needed to have working typography noWrap
    },
    '@media (max-width: 553px)': {
        bannerOffset: {
            top: 100
        }
    }
});

class AppComponent extends PurestComponent {
    constructor(props) {
        super(props);
        this.state = {
            path: props.location.pathname,
            search: {
                coremediaIds: [],
                leanIds: [],
                idCount: 0
            },
            isBannerVisible: false
        };
        this.handleSearchBarChange = this.handleSearchBarChange.bind(this);
        this.handleFilterUpdate = this.handleFilterUpdate.bind(this);
        this.bannerVisibilityChanged = this.bannerVisibilityChanged.bind(this);
        this.queryHelper = new Query();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // change state before render
        // stored path in state to be able to compare when props change
        if (nextProps.location.pathname !== prevState.path) {
            // reset pageData on page change
            return {
                path: nextProps.location.pathname,
                search: {
                    coremediaIds: [],
                    leanIds: [],
                    idCount: 0
                }
            };
        }
        // no state update necessary
        return null;
    }

    bannerVisibilityChanged(visible) {
        this.setState({ isBannerVisible: visible });
    }

    componentDidMount() {
        FirestoreStore.init();
    }

    render() {
        const { classes, app, location } = this.props;
        const isArticleStreamActive = app === AppPath.ARTICLESTREAM;
        const isSearchEnabled = this.isSearchBarEnabled();
        if (isDashboardPath(location.pathname)) {
            return <Route path={DASHBOARD_PATH} component={JetDashboard} />;
        }

        const renderBanner = () => <BannerBar tenant={this.props.tenant} visibilityChange={this.bannerVisibilityChanged}/>;
        return (
            <div className={classNames(classes.root, this.state.isBannerVisible ? classes.bannerOffset : undefined)}>
                <Switch>
                    <Route
                        path={`/${AppPath.JETSTREAM}`}
                        render={renderBanner}
                    />
                    <Route
                        path={`/${AppPath.HEATSTREAM}`}
                        render={renderBanner}
                    />
                </Switch>
                <StageBanner/>
                <NavigationBar
                    useBannerOffset={this.state.isBannerVisible}
                    chipData={this.state.search}
                    onSearchBarChange={this.handleSearchBarChange}
                    isSearchVisible={isArticleStreamActive}
                    isSearchEnabled={isSearchEnabled}
                />
                <main className={classes.content}>
                    <Switch>
                        <Route path={`/${AppPath.ARTICLESTREAM}`} render={props => (
                            <ArticlePage
                                search={this.state.search}
                                onFilterUpdate={this.handleFilterUpdate}
                                {...props}
                            />
                        )} />
                        <Route
                            path={`/${AppPath.SOCIAL_DASHBOARD}`}
                            component={DashboardPage}
                        />
                        <Route
                            path={`/${AppPath.DAILY_UNIQUE_USERS}`}
                            component={DailyUUsDashboard}
                        />
                        <Route
                            path={`/${AppPath.EXACTAG_CONVERTER}`}
                            component={ExactagConverterPage}
                        />
                        <Route
                            path={`/${AppPath.JETSTREAM}/bild/admin/stats`}
                            component={JetStats}
                        />
                        <Route
                            path={`/${AppPath.JETSTREAM}`}
                            render={props => (
                                <JetPage
                                    useBannerOffset={this.state.isBannerVisible}
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            path={`/${AppPath.HEATSTREAM}`}
                            render={props => (
                                <Heatmap
                                    useBannerOffset={this.state.isBannerVisible}
                                    {...props}
                                />
                            )}
                        />
                    </Switch>
                </main>
            </div>
        );
    }

    isSearchBarEnabled() {
        const { tenant, rest } = this.props;
        return tenant === 'bild' && (rest === 'sportbild' || rest === undefined);
    }

    handleFilterUpdate() {
        this.setState({
            search: {
                coremediaIds: [],
                leanIds: [],
                idCount: 0
            }
        });
    }

    handleSearchBarChange(search) {
        // reset filter
        this.queryHelper.setQuerySettings(this.props.location, this.props.history, {});
        // update cms ids
        this.setState({
            search: {
                coremediaIds: search.coremediaIds,
                leanIds: search.leanIds,
                idCount: search.leanIds.length + search.coremediaIds.length
            }
        });
    }
}

AppComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export const App = withStyles(styles)(withAuth(AppComponent));
