import { ArticleItem } from './ArticleStreamTypes';

export class ArticleStreamDataConverter {
    public static convert(rawData: any): { items: ArticleItem[] } {
        return { items: rawData.data.map((e: any): ArticleItem => {
            const url = new URL(e.url);
            return {
                article: {
                    domain: url.hostname,
                    canonicalUrl: url.pathname,
                    cmsId: e.cmsId,
                    leanId: '',
                    uid: e.uid,
                    title: e.title,
                    teaserImageUrl: e.teaserImgUrl,
                    teaserImageFormat: 'landscape',
                    department: e.department,
                    regionDetail: e.regionDetail,
                    isPremium: e.premium,
                    publicationDate: e.pubDate,
                    conversions: e.conversions ? e.conversions : { total: 0, instagram: 0, youtube: 0 }
                },
                articleHistory: {
                    id: e.cmsId,
                    homesPlacedOn: ArticleStreamDataConverter.getHomesPlacedOn(e.articleHistoryRecords)
                },
                facebookPosts: e.posts.map((post: any) => {
                    return {
                        id: post.id,
                        leanId: '',
                        title: post.title,
                        message: post.message,
                        publicationDate: post.published,
                        page: {
                            id: post.fkPageId,
                            name: post.page.name
                        },
                        stats: {
                            clicksTotal: post.clicksTotal,
                            commentsTotal: post.commentsTotal,
                            reactionLike: post.reactionLike,
                            reactionLove: post.reactionLove,
                            reactionWow: post.reactionWow,
                            reactionHaha: post.reactionHaha,
                            reactionAngry: post.reactionAngry,
                            reactionSad: post.reactionSad,
                            reactionThankful: post.reactionThankful,
                            sharesTotal: post.sharesTotal,
                            conversions: 0
                        }
                    };
                })
            };
        })
        };
    }

    private static getHomesPlacedOn(articleHistoryRecords: any) {
        const homesPlacedOn: any = [];
        let onMobile = false;
        let onDesktop = false;
        for (const record of articleHistoryRecords) {
            if (record.eventType === 1) { onMobile = true; }
            if (record.eventType === 2) { onDesktop = true; }
            if (onDesktop && onMobile) { break; }
        }
        if (onDesktop) { homesPlacedOn.push('desktop'); }
        if (onMobile) { homesPlacedOn.push('mobile'); }
        return homesPlacedOn;
    }
}
