import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ListItem, Avatar, Divider } from '@material-ui/core';
import AddCirlceIcon from '@material-ui/icons/AddCircle';
import { FacebookPostItem } from './FacebookPostItem';

const styles = () => ({
    root: {
        display: 'flex'
    },
    content: {
        position: 'relative',
        flexGrow: 1,
        width: '100%',
        height: '100%',
        marginLeft: 34
    },
    avatar: {
        position: 'absolute',
        top: 15,
        left: 5
    },
    addCirlce: {
        position: 'relative',
        fontSize: '1.5em',
        left: 2,
        top: 5
    },
    post: {
    },
    conversions: {
        position: 'relative',
        width: '50%',
        display: 'block',
        margin: '10px 0'
    },
    conversionContainer: {
        margin: 5
    },
    divider: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0
    }
});

class FacebookPageItemComponent extends Component {

    constructor(props) {
        super(props);
        this.classes = this.props.classes;
    }

    render() {
        const data = this.props.posts;
        // console.log(data);
        return (
            <ListItem className={this.classes.root}>
                <Avatar className={this.classes.avatar} src={this._getImgUrl()}/>
                <div className={this.classes.content}>
                    <div className={this.classes.post}>
                        {this._getPosts(data)}
                    </div>
                    {this.props.conversions && (
                        <div className={this.classes.conversions}>
                            {data[0].conversions}
                            <AddCirlceIcon className={this.classes.addCirlce}/> Conversions
                        </div>
                    )}
                    {this.props.showDivider && <Divider/>}
                </div>
            </ListItem>
        );
    }

    _getImgUrl() {
        return 'https://graph.facebook.com/' + this.props.pageId + '/picture';
    }

    _getPosts(posts) {
        const items = [];
        for (let i = 0; i < posts.length; i++) {
            const post = posts[i];
            items.push((
                <FacebookPostItem
                    key={post.id}
                    posts={post}
                />
            ));
        }
        return items;
    }
}

FacebookPageItemComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    pageId: PropTypes.number,
    posts: PropTypes.array.isRequired,
    showDivider: PropTypes.bool.isRequired,
    conversions: PropTypes.object
};

export const FacebookPageItem = withStyles(styles)(FacebookPageItemComponent);
