import React, { FC, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    withStyles,
    makeStyles,
    Typography,
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails
} from '@material-ui/core';
import {
    Add as AddIcon,
    Remove as RemoveIcon
} from '@material-ui/icons';

const StyledAccordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        borderLeft: 0,
        borderRight: 0,
        boxShadow: 'none',
        borderBottom: 0,
        '&:first-child': {
            borderTop: 0
        },
        '&:before': {
            display: 'none'
        },
        '&$expanded': {
            margin: 'auto'
        }
    },
    expanded: {}
})(MuiAccordion);

const StyledAccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56
        }
    },
    content: {
        '&$expanded': {
            margin: '12px 0'
        }
    },
    expanded: {}
})(MuiAccordionSummary);

const useStyles = makeStyles(() => ({
    noIndent: {
        padding: 0
    }
}));

type Props = {
    label: string,
    children: PropTypes.ReactNodeLike,
    className?: string,
    noIndent?: boolean,
    defaultExpanded?: boolean
}

export const Accordion: FC<Props> = ({ label, children, className, noIndent = false, defaultExpanded = true }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
    const handleChange = (event: React.ChangeEvent<unknown>, value: boolean) => {
        setExpanded(value);
    };
    return (
        <StyledAccordion
            className={className}
            square
            expanded={expanded}
            onChange={handleChange}
        >
            <StyledAccordionSummary expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}>
                <Typography variant='subtitle2'>{label}</Typography>
            </StyledAccordionSummary>
            <AccordionDetails classes={{
                root: classNames({ [classes.noIndent]: noIndent })
            }}>
                {children}
            </AccordionDetails>
        </StyledAccordion>
    );
};

Accordion.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    noIndent: PropTypes.bool,
    defaultExpanded: PropTypes.bool
};
