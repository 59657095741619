import objectHash from 'object-hash';
import { QueryStore } from '../../../common/helpers/Firestore';
import { PageData } from '../../../jetstream/components/JetPage/PageData';
import { RawResult, RequestOptions } from '../../../jetstream/helpers/Query/QueryTypes';

export class WatchlistResultBuilder {

    private readonly pageConfig?: PageData;
    private readonly queryStore: QueryStore;
    private readonly pageId: string;
    private rawQueryResults: { [key: string]: RawResult };

    constructor(queryStore: QueryStore, pageId: string) {
        this.pageConfig = queryStore.pageConfig;
        this.queryStore = queryStore;
        this.rawQueryResults = {};
        this.pageId = pageId;
    }

    addFirestoreResult(id: string, rawResult: any) {
        this.rawQueryResults = { ...this.rawQueryResults, [id]: rawResult };
    }

    build() {
        if (this.pageConfig) {
            const queryResult = this.pageConfig.buildQueryResult(this.getData);
            return this.transpose(queryResult?.columns);
        }
        return undefined;
    }

    private readonly getData = (requestOptions: RequestOptions): RawResult => {
        const hash = objectHash(requestOptions);
        const firebaseQueryHash: string = this.queryStore.getFirebaseQueryId(hash, this.pageId);
        return this.rawQueryResults[firebaseQueryHash];
    }

    private transpose<T>(array?: (T[] | undefined)[]) {
        return array?.[0]?.map((value, colIndex) => array.map(row => row?.[colIndex]));
    }

    get rowsResult() {
        return this.transpose(this.queryResult?.columns);
    }

    get queryResult() {
        return this.pageConfig?.buildQueryResult(this.getData);
    }
}
