export const getFormattedSum = (sum: number, isTacho: boolean) => {
    const million = 1_000_000;
    // shorten bigger numbers (e.g. 1_000_000 to 1.00m)
    if (isTacho && sum >= million) {
        // 1_005_000 => 1.005
        const smallerNumber = sum / million;
        // 1.005 => "1,01"
        // using localeString prevents floating number issues when rounding
        const roundedFormat = smallerNumber.toLocaleString('de', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
        // "1,01" => "1.01m"
        return `${roundedFormat.replaceAll(',', '.')}m`;
    }
    return sum.toLocaleString('de');
};
