import React, { FunctionComponent, useEffect, useState } from 'react';
import { firestoreStores } from '../../helpers/Firestore';
import { LocalStorage } from '../Storage';
import { ErrorBanner, InfoBanner, SuccessBanner, WarningBanner } from './Banners';

export type BannerBarProps = {
    tenant: string,
    visibilityChange: (visible: boolean) => void
}

const BANNER_TYPES = [
    { level: 'error', type: ErrorBanner },
    { level: 'warning', type: WarningBanner },
    { level: 'success', type: SuccessBanner },
    { level: 'info', type: InfoBanner }
];

const FIVE_MINUTES = 300;

export const BANNER_DESKTOP_HEIGHT = 38;

export const BannerBar: FunctionComponent<BannerBarProps> = (props: BannerBarProps) => {
    const { firestore } = firestoreStores;
    const [bannerContent, setBannerContent] = useState<any| undefined>();
    let unsubscribe: any | undefined;
    let bannerDocument: any;

    const hideBanner = () => {
        if (bannerDocument) {
            LocalStorage.setBannerDate(bannerDocument.createdAt.seconds * 1000);
            setBannerContent(undefined);
            props.visibilityChange(false);
        }
    };

    const getElapsedTimeInSeconds = (createdAt: {seconds: number }) => {
        const bannerCurrentDate = new Date(createdAt.seconds * 1000);
        const currentDate = new Date();
        return (currentDate.getTime() - bannerCurrentDate.getTime()) / 1000;
    };

    const update = (document: any) => {
        if (!document || !document.banner) {
            return;
        }

        const bannerDate = LocalStorage.getBannerDate();
        if (bannerDate && (`${document.banner.createdAt.seconds * 1000}`) === bannerDate) {
            setBannerContent(undefined);
            return;
        }
        bannerDocument = document.banner;
        const level: string = document.banner.level;
        const bannerType = BANNER_TYPES.find(e => e.level === level)?.type;

        const elapsedTimeInSeconds = getElapsedTimeInSeconds(document.banner.createdAt);
        if (level === 'success' && elapsedTimeInSeconds > FIVE_MINUTES) {
            setBannerContent(undefined);
            return;
        }

        if (bannerType) {
            setBannerContent(React.createElement(bannerType, { ...document.banner, closeCallback: hideBanner }));
            props.visibilityChange(true);
        } else {
            setBannerContent(undefined);
            props.visibilityChange(false);
        }
    };

    useEffect(() => {
        async function subscribeToDocument() {
            if (unsubscribe) {
                unsubscribe();
            }
            if (!unsubscribe) {
                await firestore.waitForLogin();
                if (bannerContent) {
                    setBannerContent(undefined);
                    props.visibilityChange(false);
                }
                const sub = await firestore.subscribeToConfig(props.tenant, update);
                unsubscribe = sub;
            }
        }
        subscribeToDocument();
    }, [props.tenant]);

    return <>{bannerContent}</>;
};
