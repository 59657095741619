import _ from 'lodash';
import React, { useContext, useEffect, useMemo, FC } from 'react';
import { observer } from 'mobx-react';
import { ThemedEcharts } from '../../../../echarts';
import { LocalStorage } from '../../../common/components/Storage';
import { CAPTION } from '../../../common/configs/scaleCategory';
import { ColorMapping } from '../../configs';
import { FilterStoreContext } from '../../helpers/filter/FilterStore';
import type { EChartsReactProps } from 'echarts-for-react';

type WatchListReferrerFilterProps = {
    watchlistKey: string
}

export const WatchlistReferrerFilter: FC<WatchListReferrerFilterProps> = observer(({ watchlistKey }) => {
    const filterStore = useContext(FilterStoreContext);

    const chartReferrer = _.fromPairs(Object.entries(filterStore.chartReferrer).map(
        // label will be transformed from uppercase to capitalized words (e.g. KEIN REFERRER => Kein Referrer)
        ([label, value]) => [label.toLowerCase().replace(/(^|\s)(\w)/g, (str) => str.toUpperCase()), value]
    ));

    useEffect(() => {
        // initial loading of user settings into FilterStore
        // => should be retriggered every time tenant (watchlistKey) or legend keys (chartReferrer) change
        const userReferrers = LocalStorage.getWatchlistReferrers(watchlistKey);
        Object.keys(chartReferrer).forEach((key) => {
            const userValue = userReferrers[key];
            if (userValue !== undefined && userValue !== null) {
                filterStore.chartReferrer[key.toUpperCase()] = userValue;
            }
        });
    }, [watchlistKey, JSON.stringify(Object.keys(filterStore.chartReferrer))]);

    const onEvents = useMemo<EChartsReactProps['onEvents']>(() => ({
        legendselectchanged: ({ name, selected }: { name: string, selected: { [name: string]: boolean } }) => {
            const value = selected[name];
            // update local storage
            const userReferrers = LocalStorage.getWatchlistReferrers(watchlistKey);
            userReferrers[name] = value;
            LocalStorage.setWatchlistReferrers(userReferrers, watchlistKey);
            // update context
            filterStore.chartReferrer[name.toUpperCase()] = value;
        }
    }), [watchlistKey]);

    return (
        <ThemedEcharts
            style={{ width: '100%', height: 60, marginTop: -10, marginBottom: 10 }}
            onEvents={onEvents}
            option={{
                xAxis: {
                    show: false
                },
                yAxis: {
                    show: false
                },
                legend: {
                    selected: chartReferrer,
                    textStyle: { ...CAPTION, color: 'rgba(0, 0, 0, 0.8)' }
                },
                series: Object.entries(chartReferrer).map(([label]) => ({
                    name: label,
                    data: [],
                    type: 'line',
                    symbol: 'none',
                    itemStyle: {
                        color: (ColorMapping)[label.toUpperCase() as keyof typeof ColorMapping]
                    }
                }))
            }}
            notMerge={true}
        />
    );
});

