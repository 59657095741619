import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { firestoreStores } from '../../helpers/Firestore/FirestoreContext';
import { getIframeURLFromPathName, getDaysDiffFromNow, getViewNameFromPathName } from './utils';
import { SurveyPopup } from './SurveyPopup';
import { Survey } from './SurveyIframe';
import { SurveyData } from '../../helpers/Firestore/UserStore';

export const UserSurvey = () => {
    const location = useLocation();
    const { userStore } = firestoreStores;
    // const [surveyData, setSurveyData] = useState<SurveyData | null>(null);
    const [surveyData] = useState<SurveyData | null>(null);
    const [showUserSurvey, setShowUserSurvey] = useState(false);
    const [showSurveyIframe, setShowSurveyIframe] = useState(false);
    const iframeUrl = getIframeURLFromPathName(location.pathname);
    const viewName = getViewNameFromPathName(location.pathname);

    if (viewName === '') {
        return null;
    }

    useEffect(() => {
        /* disable showing the survey */
        // userStore.getUserSurveyData(viewName)
        //     .then((_surveyData) => { setSurveyData(_surveyData); });
    }, []);

    useEffect(() => {
        if (!surveyData) {
            return;
        }
        const lastDisplayedDate = new Date(surveyData.lastDisplayedDate);
        const diffInDays = getDaysDiffFromNow(lastDisplayedDate);
        /*
           UserSurvey needs to be displayed every quarter for each view (Articlestream, Heatstream, Jetstream).
           The UserSurvey should be displayed only for max 5 times for each user for each view.
        */
        if (surveyData.displayedTimes >= 5) {
            setShowUserSurvey(false);
            userStore.resetDisplayTimesAndSubmitStatus(viewName);
        } else if (diffInDays > 90) {
            setShowUserSurvey(true);
        } else if (surveyData.displayedTimes < 5 && diffInDays >= 1 && !surveyData.isSubmitted) {
            setShowUserSurvey(true);
        }
    }, [surveyData]);

    useEffect(() => {
        /*
          If the UserSurvey is showed to the user then we should increase the displayed times
          and we should also update the last displayed date.
        */
        if (showUserSurvey) {
            userStore.syncDisplayedDateAndDisplayedTime(viewName);
        }
    }, [showUserSurvey]);

    return (
        <>
            {showUserSurvey && <SurveyPopup onSurveyStart={() => {
                setShowSurveyIframe(true);
                userStore.submitSurvey(viewName);
            }} />}
            {showSurveyIframe && <Survey iframeUrl={iframeUrl} />}
        </>
    );
};
