import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import closeNotification from './images/closeNotification.svg';
import { config, LEAN_ID_PLACEHOLDER } from '../../../../config';

const useStyles = makeStyles(() => ({
    container: {
        zIndex: 1,
        background: '#FFFFFF',
        boxShadow: `0px 8px 10px rgba(0, 0, 0, 0.14),
                    0px 3px 14px rgba(0, 0, 0, 0.12),
                    0px 4px 5px rgba(0, 0, 0, 0.2)`,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 4,
        width: 448
    },
    titleContainer: {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        backgroundColor: '#F8D627',
        display: 'flex',
        justifyContent: 'space-between'
    },
    titleContent: {
        fontFamily: 'Lato',
        fontWeight: 700,
        fontSize: 12,
        lineHeight: '14.6px',
        color: '#000000',
        padding: 10,
        paddingTop: 15
    },
    message: {
        fontFamily: 'Lato',
        fontSize: 12,
        fontWeight: 500,
        letterSpacing: 0,
        textAlign: 'left',
        lineHeight: '14.6px',
        color: 'black',
        padding: 10
    },
    changeModelContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        paddingBottom: 15,
        paddingRight: 15
    },
    changeModelText: {
        textTransform: 'none',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        color: '#1E94BF'
    }
}));

export const NotificationPrompt = ({ leanId, handleClose, hidePrompt }: any) => {
    const classes = useStyles();
    return (
        <div onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()} className={classes.container} >
            <div className={classes.titleContainer}>
                <Typography className={classes.titleContent}>Schwacher Plus-Artikel</Typography>
                <Button style={{ paddingLeft: 5 }} onClick={handleClose} endIcon={<img src={closeNotification} />}>
                </Button>
            </div>
            <Typography className={classes.message}>
                Dieser Artikel performt nicht gut. Möchtest du den Plus-Status anpassen?
            </Typography>
            <div className={classes.changeModelContainer}>
                <Button onClick={() => {
                    window.open((config.editorLink.replace(LEAN_ID_PLACEHOLDER, leanId)), '_blank', 'noreferrer');
                    hidePrompt();
                }}>
                    <Typography className={classes.changeModelText}>Artikel entplussen</Typography>
                </Button>
            </div>
        </div >
    );
};

