import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, MenuItem, Select } from '@material-ui/core';
import { Accordion } from '../../../common/components/Accordion';
import { StreamFilterButton } from '../Button';
import { PurestComponent } from '../../../../PurestComponent';
import { FilterQuery, FilterType, FilterMode } from '../../../common/helpers/Query';

const styles = () => ({
    categoryArea: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    select: {
        width: 285
    }
});

export const PlatformType = {
    facebook: 'facebook',
    home: 'home'
};
export const PlatformSubType = {
    planned: 'planned',
    live: 'live',
    desktop: 'desktop',
    mobile: 'mobile',
    bildPage: 'bildPage',
    sportPage: 'sportPage'
};

class StreamFilterComponent extends PurestComponent {

    constructor(props) {
        super(props);
        this.classes = this.props.classes;
        this.state = {
            showRegiosFilter: false
        };
    }


    static getDerivedStateFromProps(props, state) {
        if (props.showRegiosFilter !== state.showRegiosFilter) {
            return {
                showRegiosFilter: props.showRegiosFilter
            };
        }
        return null;
    }

    render() {
        const content = [];
        const config = this.props.filterHelper.getAllFilter(this.props.rawFilter);
        const { showRegiosFilter } = this.state;

        for (let i = 0; i < config.length; i++) {
            const categoryConfig = config[i];
            if (categoryConfig.id === 'regios' && !showRegiosFilter) {
                continue;
            }
            content.push((
                <Accordion key={'container-' + i} label={categoryConfig.label}>
                    <div className={this.classes.categoryArea}>
                        {this.getCategoryContent(categoryConfig)}
                    </div>
                </Accordion>
            ));
        }
        return (
            <div>
                {content}
            </div>
        );
    }

    getCategoryContent(categoryConfig) {
        if (categoryConfig.type === FilterType.select) {
            return this.getSelectionControl(categoryConfig);
        }
        return this.getChips(categoryConfig);
    }

    getChips(categoryConfig) {
        const chips = [];
        for (let i = 0; i < categoryConfig.content.length; i++) {
            const chipConfig = categoryConfig.content[i];
            chips.push((
                <StreamFilterButton
                    key={chipConfig.id}
                    name={chipConfig.label}
                    mode={chipConfig.value}
                    onClick={this.toggleFilter.bind(this, chipConfig.label, categoryConfig.id)}>
                </StreamFilterButton>
            ));
        }
        return chips;
    }

    getSelectionControl(categoryConfig) {
        const options = [];
        for (let i = 0; i < categoryConfig.content.length; i++) {
            const optionConfig = categoryConfig.content[i];
            options.push((
                <MenuItem
                    key={optionConfig.id}
                    value={optionConfig.id}
                >
                    {optionConfig.label}
                </MenuItem>
            ));
        }

        let defaultValue;
        if (categoryConfig.value === null) {
            defaultValue = categoryConfig.content[0].id;
        } else {
            defaultValue = categoryConfig.value;
        }
        return (
            <Select
                aria-label={categoryConfig.label}
                value={defaultValue}
                onChange={this.toggleSelectionControl.bind(this, categoryConfig)}
                className={this.classes.select}
                fullWidth={true}
            >
                {options}
            </Select>
        );
    }

    toggleFilter(filterName, filterType) {
        const copy = this.props.filterHelper.getUsedFilter(this.props.rawFilter);
        // delete regios filter if News & Regio is unselected
        const deleteRegios = filterType === 'departments' &&
            filterName === 'News & Regio' &&
            copy[filterType] &&
            copy[filterType]['News & Regio'] === 1 &&
            copy['regios'] !== undefined;

        // create missing empty object
        if (copy[filterType] === undefined) {
            copy[filterType] = {};
        }
        if (deleteRegios) {
            delete copy['regios'];
        }
        // assign updated object
        this.toggleFilterMode(copy[filterType], filterName);
        this.props.onFilterChange(copy, filterType);
    }

    togglePlatformFilter(type, subType) {
        const copy = this.props.filterHelper.getUsedFilter(this.props.rawFilter);
        // create missing empty objects
        if (copy.platforms === undefined) {
            copy.platforms = {};
        }
        if (copy.platforms[type] === undefined) {
            copy.platforms[type] = {};
        }

        // assign updated object
        this.toggleFilterMode(copy.platforms[type], subType);
        this.props.onFilterChange(copy);
    }

    toggleFilterMode(filter, filterName) {
        let mode = filter[filterName];
        switch (mode) {
            case FilterMode.enabled:
                mode = FilterMode.inverted;
                break;
            case FilterMode.inverted:
                mode = FilterMode.disabled;
                break;
            case FilterMode.disabled:
                // fallthrough
            default:
                // if is disabled or not yet used (default is disabled)
                mode = FilterMode.enabled;
        }
        if (mode === FilterMode.disabled) {
            // remove default value
            delete filter[filterName];
        } else {
            filter[filterName] = mode;
        }

        if (filterName === 'News & Regio') {
            this.setState({ showRegiosFilter: mode === FilterMode.enabled });
        }
    }

    toggleSelectionControl(categoryConfig, event) {
        const filterValue = event.target.value;
        const copy = this.props.filterHelper.getUsedFilter(this.props.rawFilter);
        const filterName = categoryConfig.id;
        if (filterValue === categoryConfig.content[0].id) {
            // remove default value
            delete copy[filterName];
        } else {
            copy[filterName] = filterValue;
        }
        this.props.onFilterChange(copy, filterName);
    }
}

StreamFilterComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    rawFilter: PropTypes.object.isRequired,
    filterHelper: PropTypes.instanceOf(FilterQuery),
    showRegiosFilter: PropTypes.bool
};

StreamFilterComponent.defaultProps = {
    showRegiosFilter: false
};


export const StreamFilter = withStyles(styles)(StreamFilterComponent);
