import React, { Component } from 'react';
import flatMap from 'lodash/flatMap';
import { Timestamp } from 'firebase/firestore';
import { Table, TableHead, TableRow, TableCell, TableBody, Button, FormControlLabel, Switch } from '@material-ui/core';
import { FirestoreStore } from '../../../common/helpers/Firestore';
import { config } from '../../../../config';

const GROUPS = {
    'All Queries': { filter: () => true },
    'Bild Queries': { filter: ([query]) => query.brand === 'bild' },
    'Welt Queries': { filter: ([query]) => query.brand === 'welt' },
    'Slow Queries (exec. time > 2s)': { filter: ([, queryResult = {}]) => queryResult.executionTime > 2000 },
    'Fast Queries (exec. time <= 2s)': { filter: ([, queryResult = {}]) => queryResult.executionTime <= 2000 },
    'Synced Queries (lastSync < 1m)': { filter: ([, { lastSync } = {}]) => lastSync && lastSync.toMillis() > new Date().getTime() - 60 * 1000 },
    'Synced Queries (lastSync < 6m)': { filter: ([, { lastSync } = {}]) => lastSync && lastSync.toMillis() > new Date().getTime() - 6 * 60 * 1000 }
};

export class JetStats extends Component {
    unsubscribes = [];
    state = {
        queryDocuments: {},
        queryResultDocuments: {},
        showOnlyActive: true
    };

    componentDidMount() {
        this.subscribe();
    }

    componentWillUnmount() {
        this.unsubscribes.forEach(unsubscribe => unsubscribe());
    }

    render() {
        const { queryDocuments, queryResultDocuments, showOnlyActive } = this.state;
        const filteredQueryDocuments = Object.entries(queryDocuments)
            .filter(showOnlyActive ? ([, query]) => !this.isExpiredQuery(query) : () => true)
            .sort(([, queryA], [, queryB]) => queryB.requests - queryA.requests);
        return (<React.Fragment>
            <h2>Jetstream Stats</h2>
            <h3>Query Overview</h3>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Queries</TableCell>
                        <TableCell>Active (Users)</TableCell>
                        <TableCell>Requests</TableCell>
                        <TableCell>Exec. Time Min (ms)</TableCell>
                        <TableCell>Exec. Time Avg (ms)</TableCell>
                        <TableCell>Exec. Time Max (ms)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(GROUPS).map(([name, { filter }]) => {
                        const filtered = Object.entries(queryDocuments).map(([id, query]) => [query, queryResultDocuments[id]]).filter(filter);
                        const execTimes = filtered.map(([, queryResult = {}]) => queryResult.executionTime).filter(executionTime => typeof executionTime === 'number');
                        const active = filtered.filter(([query]) => !this.isExpiredQuery(query));
                        const users = this.getDisinctUsers(active);
                        return (<TableRow key={name}>
                            <TableCell component="th" scope="row">{name}</TableCell>
                            <TableCell>{filtered.length}</TableCell>
                            <TableCell>{active.length} ({users.length})</TableCell>
                            <TableCell>{filtered.reduce((sum, [query]) => sum + (query.requests || 0), 0)}</TableCell>
                            <TableCell>{Math.min(...execTimes)}</TableCell>
                            <TableCell>{~~(execTimes.reduce((sum, x) => sum + x, 0) / execTimes.length)}</TableCell>
                            <TableCell>{Math.max(...execTimes)}</TableCell>
                        </TableRow>);
                    })}
                </TableBody>
            </Table>
            <h3>Queries</h3>
            <FormControlLabel
                control={<Switch checked={showOnlyActive} onChange={(_, showOnlyActive) => this.setState({ showOnlyActive })} />}
                label="Show only active"
            />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Brand</TableCell>
                        <TableCell>Users (active)</TableCell>
                        <TableCell>Requests</TableCell>
                        <TableCell>Exec. Time (ms)</TableCell>
                        <TableCell>LastSync</TableCell>
                        <TableCell>More</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredQueryDocuments.map(([id, query]) => {
                        const queryResult = queryResultDocuments[id] || {};
                        return (<TableRow key={id} style={this.isExpiredQuery(query) ? null : { backgroundColor: '#c8e6c9' }}>
                            <TableCell component="th" scope="row">{id}</TableCell>
                            <TableCell>{query.brand}</TableCell>
                            <TableCell>{this.getDisinctUsers([[query]], true).length} ({this.getDisinctUsers([[query]]).length})</TableCell>
                            <TableCell>{query.requests}</TableCell>
                            <TableCell>{queryResult.executionTime}</TableCell>
                            <TableCell>{queryResult.lastSync && queryResult.lastSync.toDate().toLocaleString()}</TableCell>
                            <TableCell><Button onClick={() => console.log({ id, query, queryResult })}>Console Log</Button></TableCell>
                        </TableRow>);
                    })}
                </TableBody>
            </Table>
        </React.Fragment>);
    }

    getDisinctUsers(queries, countExpired = false) {
        return Array.from(new window.Set(flatMap(queries, ([query]) =>
            Object.entries(query.requestTimes)
                .filter(([, requestTime]) => countExpired || (requestTime && !this.isExpired(requestTime.toMillis())))
                .map(([user]) => user)
        )).keys());
    }

    getLastRequestTime = ({ requestTimes }) => {
        return Math.max(...Object.values(requestTimes)
            .filter(requestTime => requestTime instanceof Timestamp)
            .map(requestTime => requestTime.toMillis())
        );
    }

    isExpired = (time) => time < new Date().getTime() - 11 * 60 * 1000;
    isExpiredQuery = ({ requestTimes }) => this.isExpired(this.getLastRequestTime({ requestTimes }));

    async subscribe() {
        const urlParams = new URLSearchParams(window.location.search);
        const stage = urlParams.get('stage');
        const firestore = await FirestoreStore._firestore;
        this.unsubscribes = ['query', 'queryResult'].map((key) =>
            firestore.collection(`/stage/${stage || config.stage}/${key}`).onSnapshot(snapshot => {
                this.setState({
                    [`${key}Documents`]: Object.assign({}, ...snapshot.docs.map(doc => ({ [doc.id]: doc.data() })))
                });
            })
        );
    }
}
