import ctrAverages from './ctrAverages.json';

export const getCurrentCTRAverage = (page: 'app' | 'web') => {
    const today = new Date();
    let averageCTR = '0';
    /*
       in the dataset we have, Sunday is represented by 7
       however getDay function retrun 0 for Sunday,
       so we map 0 to 7 for Sunday
     */
    const currentDay = today.getDay() === 0 ? '7' : `${today.getDay()}`;
    const currentHour = today.getHours() < 10 ? `0${today.getHours()}` : `${today.getHours()}`;
    const averageCTRForCurrentHour = ctrAverages.find((average: any) => average.hour === currentHour && average.weekday === currentDay);

    if (averageCTRForCurrentHour) {
        // need to use mobile for now because we do not have data for webCTR yet
        averageCTR = averageCTRForCurrentHour['mobileCTR'];
    }

    return parseInt(averageCTR, 10);
};
