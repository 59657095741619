import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Icon } from '@material-ui/core';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ShareIcon from '@material-ui/icons/Share';
import { TimeHelper } from '../../../common/helpers/Time';
import { IconBar } from '../Icon';

const styles = () => ({
    row: {
        display: 'flex',
        height: '100%'
    },
    contentText: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        marginRight: 24,
        '& p': {
            margin: 0
        },
        marginBottom: 10
    },
    kicker: {
        fontSize: 12
    },
    title: {
        fontSize: 14,
        // by default flex items can not be shrinked smaller than content size (auto)
        // -> enable it by setting min-height to 0
        minHeight: 0,
        overflow: 'hidden'
    },
    contentInfo: {
        flexShrink: 0

    },
    icon: {
        fontSize: '1em',
        paddingLeft: 5
    }
});

class FacebookPostItemComponent extends Component {

    constructor(props) {
        super(props);
        this.classes = this.props.classes;
    }

    render() {
        const data = this.props.posts;
        return (
            <div className={this.classes.row}>
                <div className={this.classes.contentText}>
                    <p className={this.classes.kicker}>
                        {TimeHelper.getHourAndMinute(data.publicationDate)} Uhr - {data.page.name}
                    </p>
                    <p className={this.classes.title}>{data.title || data.message}</p>
                </div>
                <IconBar
                    className={this.classes.contentInfo}
                    data={[
                        {
                            hint: 'Facebook Shares',
                            value: this._countShares(),
                            icon: <ShareIcon className={this.classes.icon}/>
                        },
                        {
                            hint: 'Interaktionen',
                            value: this._countInteractions(),
                            icon: <ChatBubbleOutlineIcon className={this.classes.icon} />
                        },
                        {
                            hint: 'Klicks',
                            value: this._countClicks(),
                            icon: <Icon className={
                                classNames('fas fa-mouse-pointer', this.classes.icon)}
                            />
                        }
                    ]}
                />
            </div>
        );
    }

    _countShares() {
        return this.props.posts.stats.sharesTotal;
    }

    _countInteractions() {
        // count comments, reactions and shares
        const post = this.props.posts.stats;
        return post.commentsTotal
            + post.reactionLike
            + post.reactionLove
            + post.reactionWow
            + post.reactionHaha
            + post.reactionAngry
            + post.reactionSad
            + post.reactionThankful;
    }

    _countClicks() {
        return this.props.posts.stats.clicksTotal;
    }
}

FacebookPostItemComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    posts: PropTypes.object.isRequired
};

export const FacebookPostItem = withStyles(styles)(FacebookPostItemComponent);
