import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Icon, Collapse, List, Avatar } from '@material-ui/core';
import AddCirlceIcon from '@material-ui/icons/AddCircle';
import ShareIcon from '@material-ui/icons/Share';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import InstagramIcon from '../../../common/images/instagram-icon.png';
import YoutubeIcon from '../../../common/images/youtube-icon.png';
import { PurestComponent } from '../../../../PurestComponent';
import { ExpandButton } from '../Button';
import { IconBar } from '../Icon';
import { FacebookPageItem } from '../Facebook';
import { StreamItem } from '../Stream';
import { ArticleItemData } from './ArticleItemData';
import { BODY_2 } from '../../../common/configs/scaleCategory';

const styles = (theme) => ({
    pageInfo: BODY_2,
    bottomRightItem: {
        [theme.breakpoints.down('sm')]: {
            minWidth: 60
        }
    },
    addCirlce: {
        position: 'relative',
        fontSize: '1.5em',
        left: 2,
        marginRight: 2,
        top: 5
    },
    expandButton: {
        marginRight: -8,
        marginLeft: 8
    },
    icon: {
        fontSize: '1em',
        paddingLeft: 5,
        width: 'auto'
    },
    social: {
        marginRight: 5
    },

    conversion: {
        display: 'flex',
        margin: 10,
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center'
    },

    conversionText: {
        marginLeft: 2
    }

});

class ArticleItemComponent extends PurestComponent {

    constructor(props) {
        super(props);
        this.classes = this.props.classes;
        this.state = {
            showExpansion: false
        };
        this.handleExpandButtonClick = this.handleExpandButtonClick.bind(this);
    }

    render() {
        const data = this.props.data;
        const conversionText = <span className={this.classes.conversionText}>Conversions</span>;
        return (
            <StreamItem
                data={data}
                iconBar={(
                    <IconBar
                        data={[{
                            hint: 'Facebook Shares',
                            value: data.fbShares,
                            icon: <ShareIcon className={this.classes.icon}/>
                        }, {
                            hint: 'Interaktionen',
                            value: data.interactions,
                            icon: <ChatBubbleOutlineIcon className={this.classes.icon} />
                        }, {
                            hint: 'Klicks',
                            value: data.clicks,
                            icon: <Icon className={classNames('fas fa-mouse-pointer', this.classes.icon)} />
                        }]}
                        classes={{
                            root: this.classes.bottomRight,
                            item: this.classes.bottomRightItem
                        }}
                    />
                )}
                bottomRight={(
                    <ExpandButton
                        className={this.classes.expandButton}
                        expanded={this.state.showExpansion}
                        disabled={!data.posts || data.posts.length === 0}
                        onClick={this.handleExpandButtonClick}
                    />
                )}
                bottom={(
                    <div>
                        <Collapse in={this.state.showExpansion}>
                            <List>
                                {this.getFacebookItems(data.pages)}
                            </List>
                            {data.conversions && (
                                <div className={this.classes.socialConversions}>
                                    <div className={this.classes.conversion}>
                                        <Avatar className={this.classes.social} src={InstagramIcon}/>
                                        {data.conversions.instagram}
                                        <AddCirlceIcon className={this.classes.addCircle}/> {conversionText}
                                    </div>
                                    <div className={this.classes.conversion}>
                                        <Avatar className={this.classes.social} src={YoutubeIcon}/>
                                        {data.conversions.youtube}
                                        <AddCirlceIcon className={this.classes.addCircle}/> {conversionText}
                                    </div>
                                </div>

                            )}

                        </Collapse>
                    </div>
                )}
            >
                <div className={this.classes.pageInfo}>
                    <span>Live: </span>
                    {this.addPageInfoContent(data.livePages)}
                </div>
                <div className={this.classes.pageInfo}>
                    <span>Geplant: </span>
                    {this.addPageInfoContent(data.plannedPages)}
                </div>
            </StreamItem>
        );
    }

    addPageInfoContent(data) {
        const links = [];
        for (let i = 0; i < data.length; i++) {
            const pageData = data[i];
            links.push((
                <a key={'a' + i} href={data[i].url} target="_blank" rel="noopener noreferrer">
                    {pageData.name}
                </a>
            ));
            links.push((
                <span key={'s' + i}> {'(' + pageData.time + ' Uhr)'} </span>
            ));
        }
        return links;
    }

    getFacebookItems(pages) {
        const items = [];
        const pageIds = Object.keys(pages);
        for (let i = 0; i < pageIds.length; i++) {
            const showDivider = i !== pageIds.length - 1;
            const pageId = parseInt(pageIds[i], 10);
            const posts = pages[pageId];
            items.push((
                <FacebookPageItem
                    key={pageId}
                    pageId={pageId}
                    posts={posts}
                    conversions={this.props.data.conversions}
                    showDivider={showDivider}
                />
            ));
        }
        return items;
    }

    handleExpandButtonClick() {
        this.setState(prevState => ({
            showExpansion: !prevState.showExpansion
        }));
    }
}

ArticleItemComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.instanceOf(ArticleItemData).isRequired
};

export const ArticleItem = withStyles(styles)(ArticleItemComponent);
