import { FilterQuery, FilterKey, FilterValue, FilterType } from '../../../common/helpers/Query';
import { FirestoreStore } from '../../../common/helpers/Firestore';
import { BRANDS } from '../constants/brands';
import { DEPARTMENTS } from '../constants/departments';

export const TIME_LABEL = {
    'today': 'Heute',
    '2h': 'letzte 2h',
    '1h': 'letzte 1h',
    '30min': 'letzte 30min',
    '15min': 'letzte 15min',
    // make typing happy for now
    'yesterday': undefined
};
const timeFilterValues = Object.entries(TIME_LABEL)
    .filter(([, label]) => label !== undefined)
    .map(([time, label]) => new FilterValue(time, label));

export class JetFilterQuery extends FilterQuery {

    constructor() {
        super([
            new FilterKey('time', 'Zeit', timeFilterValues, FilterType.select)
        ]);
        this.firestore = new FirestoreStore();
    }

    addDepartments(brand, view) {
        const excludedViews = ['watchlist', 'premiumSportBild'];
        if (excludedViews.includes(view)) {
            return;
        }
        let departments = [];
        if (brand === 'welt') {
            departments = this._getWeltDepartments();
        } else if (brand === 'bild') {
            if (view === 'premium') {
                departments = [...this._getBildDepartments(), new FilterValue('premium-event', 'Premium-Event')];
            } else {
                departments = [...this._getBildDepartments(), new FilterValue('sportbild', 'Sport BILD')];
            }
        }
        if (departments.length > 0) {
            const departmentFilter = new FilterKey('departments', 'Ressort', departments);
            this.configuration.push(departmentFilter);
        }
    }

    addRegios(brand, regios) {
        if (brand === 'bild' && regios.length) {
            const regiosFilterKeys = regios.map((channelName) => new FilterValue(channelName, channelName));
            const regiosFilter = new FilterKey('regios', 'Regios', regiosFilterKeys);
            this.configuration.push(regiosFilter);
        }
    }

    addDivisions(brand, view) {
        const excludedViews = ['video', 'watchlist'];
        if (excludedViews.includes(view)) {
            return;
        }
        let division = [];
        if (brand === 'welt') {
            division = this._getWeltDivisions();
        } else if (brand === 'bild') {
            division = this._getBildDivisions();
        }
        if (division.length > 0) {
            this.configuration.push(new FilterKey('divisions', 'Abteilung', division));
        }
    }

    addUserFilter(brand, view) {
        if (brand === 'welt' && view !== 'premium') {
            this.configuration.push(new FilterKey('users', 'Nutzer', [new FilterValue('bestandskunden', 'Bestandskunden')]));
        }
    }

    addTypes(brand, view) {
        if (brand !== 'bild' && brand !== 'welt') {
            return;
        }
        const filters = [];
        if (view === 'watchlist') {
            return;
        }
        if (view !== 'video') {
            filters.push(new FilterValue('video', 'Video'));
        }
        if (view !== 'premium') {
            filters.push(new FilterValue('plus', 'Plus'));
        }
        if (view === 'premium' && (brand === 'welt' || brand === 'bild')) {
            filters.push(new FilterValue('plus', 'Plus'));
        }
        if (view === 'video' && brand === 'bild') {
            filters.push(new FilterValue('vod', 'VoD'));
            filters.push(new FilterValue('clipout', 'Clipout'));
            filters.push(new FilterValue('replay', 'Replay'));
        }
        const typesFilter = new FilterKey('filter', 'Typ', filters);
        this.configuration.push(typesFilter);
    }

    addTaxonomies(brand, view) {
        if (brand === 'bild' && view === 'premium') {
            const filters = [new FilterValue('82570010', 'Sport Events')];
            this.configuration.push(new FilterKey('taxonomyIds', 'Taxonomie', filters));
        }
    }

    _getBildDepartments() {
        return this._getDepartments(BRANDS.BILD);
    }

    _getWeltDepartments() {
        return this._getDepartments(BRANDS.WELT);
    }

    _getDepartments(brand) {
        const departments = DEPARTMENTS[brand];
        return Object.keys(departments).map((department) => new FilterValue(department, departments[department]));
    }

    _getBildDivisions() {
        return [
            new FilterValue('deskdelta', 'Desk Delta'),
            new FilterValue('brandstudio', 'Brand Studio')
        ];
    }

    _getWeltDivisions() {
        return [
            new FilterValue('brandstudio', 'Brand Studio')
        ];
    }
}
