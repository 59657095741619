// source:
// https://github.com/apache/echarts/blob/11a9247e7fab2e6ff9c60b545ba775e8c1b2412f/src/theme/light.ts

const colorAll = [
    '#37A2DA', '#32C5E9', '#67E0E3', '#9FE6B8', '#FFDB5C', '#ff9f7f',
    '#fb7293', '#E062AE', '#E690D1', '#e7bcf3', '#9d96f5', '#8378EA', '#96BFFF'
];

export const echartsTheme = {
    color: colorAll,
    colorLayer: [
        ['#37A2DA', '#ffd85c', '#fd7b5f'],
        ['#37A2DA', '#67E0E3', '#FFDB5C', '#ff9f7f', '#E062AE', '#9d96f5'],
        ['#37A2DA', '#32C5E9', '#9FE6B8', '#FFDB5C', '#ff9f7f', '#fb7293', '#e7bcf3', '#8378EA', '#96BFFF'],
        colorAll
    ],
    tooltip: {
        textStyle: {
            color: 'rgba(0, 0, 0, 0.8)'
        }
    }
};
