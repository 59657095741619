import React, { FC } from 'react';
import Gradient from 'linear-gradient';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

export type Color = { r: number, g: number, b: number, a?: number }
export const colorToStyle = (color: Color) => (`rgba(${color.r},${color.g},${color.b},${color.a})`);

const colors = ['#0000ff', '#00ff00', '#ffff00', '#ff8800', '#ff3300', '#ff0000'];
const hexToDec = (hex: string) => parseInt(hex, 16);
const gradient = new Gradient(colors.map((color) => [hexToDec(color.substr(1, 2)), hexToDec(color.substr(3, 2)), hexToDec(color.substr(5, 2))]));

export const getTeaserBackgroundColor = (val: number, max: number): Color => {
    // with log: Math.log(val + 1) / Math.log(max + 1) || 0;
    const color = gradient.calcArray(val / max || 0);
    return {
        r: color[0],
        g: color[1],
        b: color[2],
        a: 0.6
    };
};

type Props = {
    min: number,
    max: number
}

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        left: 15,
        zIndex: 10,
        [theme.breakpoints.up('sidebarTransform')]: {
            left: 340
        }
    },
    colorGradient: {
        height: 200,
        width: 15,
        backgroundImage: `linear-gradient(to top,${colors.join(',')})`
    }
}));

const compactIntLabel = (x: number) => x > 1000 ? `${Math.round(x / 1000)}k` : x;

export const ColorScale: FC<Props> = ({ min, max }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div>{compactIntLabel(max)}</div>
            <div className={classes.colorGradient} />
            <div>{compactIntLabel(min)}</div>
        </div>
    );
};

ColorScale.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired
};
