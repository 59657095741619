import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { List } from '@material-ui/core';
import { ProgressView, ProgressStatus } from '../ProgressView';
import { LoadButton } from '../Button';
import { Page } from '../../../common/components/Page';

const styles = () => ({
    headline: {
        textAlign: 'left'
    },
    contentBottom: {
        textAlign: 'center',
        paddingTop: 8
    }
});

class StreamPageComponent extends Component {

    constructor(props) {
        super(props);
        this.classes = props.classes;
    }

    render() {
        let masterProgressStatus = this.props.progressStatus;
        if (masterProgressStatus === ProgressStatus.LOAD) {
            // do not show progress view if data already exists
            masterProgressStatus = this.props.children.length > 0 ? ProgressStatus.SUCCESS : ProgressStatus.LOAD;
        }
        return (
            <Page
                sidebarTitle="Filter nach:"
                sidebarContent={this.getSidebarContent()}
                onAutoRefresh={this.props.onAutoRefresh}
                autoRefreshInterval={60000}
                contentMaxWidth={812}
            >
                <ProgressView status={masterProgressStatus}>
                    <List>
                        {this.props.children}
                    </List>
                    <div className={this.classes.contentBottom}>
                        <LoadButton
                            isVisible={this.props.showLoadMoreButton}
                            label='Weitere Artikel laden'
                            onClick={this.props.onPaginationRequest}
                            isLoading={this.props.progressStatus === ProgressStatus.LOAD}>
                        </LoadButton>
                    </div>
                </ProgressView>
            </Page>
        );
    }

    getSidebarContent() {
        return (
            <React.Fragment>
                {this.props.topRight}
                {this.props.filter}
            </React.Fragment>
        );
    }
}

StreamPageComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    showLoadMoreButton: PropTypes.bool.isRequired,
    progressStatus: PropTypes.oneOf(Object.values(ProgressStatus)).isRequired,
    topRight: PropTypes.element,
    filter: PropTypes.element,
    onPaginationRequest: PropTypes.func.isRequired,
    onAutoRefresh: PropTypes.func.isRequired
};

export const StreamPage = withStyles(styles)(withRouter(StreamPageComponent));
