import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Paper, CircularProgress } from '@material-ui/core';
import { Authenticator } from '../../../common/components/Auth';
import { BODY_1, BODY_2 } from '../../../common/configs/scaleCategory';

const styles = (theme) => ({
    root: {
        display: 'none',
        [theme.breakpoints.up('sidebarTransform')]: {
            display: 'block',
            width: '100%',
            padding: theme.spacing(),
            paddingTop: theme.spacing(3),
            boxSizing: 'border-box',
            textAlign: 'center',
            borderBottom: '1px solid rgba(0, 0, 0, .125)'
        }
    },
    title: BODY_2,
    text: BODY_1
});

class InfoBoxComponent extends Component {

    constructor(props) {
        super(props);
        this.classes = this.props.classes;

        this.state = {
            isLoading: true,
            data: -1
        };
    }

    render() {
        return (
            <Paper square className={this.classes.root}>
                {this.props.title && <h3 className={this.classes.title}>{this.props.title}</h3>}
                {!this.state.isLoading && <span className={this.classes.text}>{this.state.data}</span>}
                {this.state.isLoading && <CircularProgress size={24} />}
            </Paper>
        );
    }

    componentDidMount() {
        this.refreshContent();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.url !== this.props.url) {
            this.refreshContent();
        }
    }

    refreshContent() {
        this.getData()
            .then(res => {
                this.setState({
                    isLoading: false,
                    data: typeof res.data === 'number' ? this.formatNumber(res.data) : res.data
                });
            })
            .catch(error => {
                console.error(error);
            });
    }

    getData() {
        return Authenticator.secureFetch(this.props.url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    formatNumber(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
}

InfoBoxComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string
};

export const InfoBox = withStyles(styles)(InfoBoxComponent);
