import _ from 'lodash';
import React from 'react';
import { observable, makeObservable } from 'mobx';

export enum Filter {
    CHART_REFERRER = 'chartReferrer'
}

export class FilterStore {
    chartReferrer: {
        [label: string]: boolean
    } = {};

    private uses: { [filter in Filter]?: { [label: string]: number }} = {}

    constructor() {
        makeObservable(this, {
            chartReferrer: observable
        });
    }

    useFilter(filter: Filter, labels: string[] = []) {
        const oldLabels = Object.keys(this[filter]);
        const newLabels = labels.map(label => label.toUpperCase());

        // check if label contains new entries
        if (_.difference(newLabels, oldLabels).length > 0) {
            const oldFilter = this[filter];
            const newFilter: { [label: string]: boolean } = {};
            for (const label of newLabels) {
                newFilter[label] = true;
            }
            // necessary to keep label order during brand switch
            this[filter] = { ...newFilter, ...oldFilter };
        }

        const uses = this.uses[filter] = this.uses[filter] || {};
        for (const label of newLabels) {
            uses[label] = (uses[label] || 0) + 1;
        }
    }

    unuseFilter(filter: Filter, labels: string[] = []) {
        const uses = this.uses[filter];
        for (let i = 0; i < labels.length; i++) {
            const label = labels[i].toUpperCase();
            if (uses && uses[label]) {
                uses[label]--;
                if (uses[label] === 0) {
                    delete this[filter][label];
                }
            }
        }
    }

    isVisible(filter: Filter, label = ''): boolean {
        label = label.toUpperCase();
        return this[filter][label] !== false;
    }
}

export const FilterStoreContext = React.createContext(new FilterStore());
export const FilterStoreProvider = FilterStoreContext.Provider;
