export type ArticleRawData = {
    isPremium: number,
    wasPremium: number,
    isVideo?: boolean,
    cmsType?: string,
    articleType?: string,
    cmsIsPremium?: boolean,
    url?: string
}

export class Article {

    private readonly rawData: ArticleRawData;

    constructor(rawData: ArticleRawData) {
        this.rawData = rawData;
    }

    /**
     * Returns the isPremium status of an article.
     * @returns true if article is currently premium.
     */
    public isPremium(): boolean {
        return !!this.rawData.isPremium;
    }

    public isVideo(): boolean {
        return !!(
            this.rawData.isVideo ||
            this.rawData.cmsType === 'video' ||
            this.rawData.articleType === 'media' ||
            this.rawData.articleType === 'video' ||
            this.rawData.url?.includes('/video'));
    }

    /**
     * Returns the wasPremium status of an article.
     * @returns true if article ever was premium.
     */
    public wasPremium(): boolean {
        return (!!this.rawData.wasPremium || !!this.rawData.isPremium);
    }
}
