import React from 'react';
import classNames from 'classnames';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import {
    AppBar as EpAppBar,
    AppSwitch,
    Environments,
    Stages
} from '@spring-media/ep-ui-components';
import { config } from '../../../../config';
import { PurestComponent } from '../../../../PurestComponent';
import { JetTabBar } from '../../../jetstream/components/JetNavBar';
import { RouteManager, AppPath, TenantManager } from '../../helpers/Route';
import { Network } from '../../helpers/Network';
import { withAuth, AuthRole } from '../Auth';
import { ArticleTabBar } from './ArticleTabBar';
import { SearchBar } from './SearchBar';
import { TenantSwitcher } from './TenantSwitcher';
import { Spacer } from './Spacer';
import { ArticleSearchValidation } from './ArticleSearchValidation';

const styles = theme => createStyles({
    bannerOffset: {
        top: [[38], '!important']
    },
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        flexGrow: 1
    },
    searchContainer: {
        width: '100%',
        marginRight: 20
    },
    tabBar: {
        position: 'fixed',
        top: 0,
        width: '100%',
        borderRadius: 0,
        zIndex: 1000
    },
    toolbarSpacer: theme.mixins.toolbar,
    tabBarSpacer: theme.mixins.tabBar,
    noShadow: {
        borderBottom: 0
    },
    noShadowWithOffset: {
        top: 50,
        borderBottom: 0
    },
    row: {
        flexWrap: 'nowrap',
        overflowX: 'auto'
    },
    leftContent: {
        flexGrow: 0
    },
    centerContent: {
        flexGrow: 1
    },
    rightContent: {
        flexGrow: 0
    },
    '@media (max-width: 553px)': {
        noShadowWithOffset: {
            top: 112,
            borderBottom: 0
        },
        bannerOffset: {
            top: [[112], '!important']
        }
    }
});

class NavigationBarComponent extends PurestComponent {

    constructor() {
        super();
    }

    render() {
        const { classes, app, useBannerOffset } = this.props;
        const isTabBarActive = [AppPath.ARTICLESTREAM, AppPath.JETSTREAM].includes(app);
        const title = RouteManager.getTabTitle(this.props.location);
        const favIcon = RouteManager.getFavIcon(this.props.location);

        return (
            <div>
                <Helmet>
                    <title>{title}</title>
                    <link rel="icon" type="image/svg+xml" href={favIcon} sizes="16x16"/>
                </Helmet>
                <EpAppBar
                    position='fixed'
                    color='inherit'
                    noShadow={isTabBarActive}
                    classes={{
                        // hide AppBar shadow when displaying tabbar
                        noShadow: useBannerOffset ? classes.noShadowWithOffset : classes.noShadow,
                        // prevent wrap of AppBar content (Articlestream)
                        row: classes.row,
                        // allow SearchBar to grow
                        leftContent: classes.leftContent,
                        centerContent: classes.centerContent,
                        rightContent: classes.rightContent
                    }}
                >
                    <div className={classes.container}>
                        <Typography className={classes.title} variant="h6">{RouteManager.getAppTitle(app)}</Typography>
                        {this.props.isSearchVisible && (
                            <SearchBar
                                chipData={this.props.chipData}
                                placeholder="CMS-ID, Lean-ID, oder URL"
                                onChange={this.props.onSearchBarChange}
                                isEnabled={this.props.isSearchEnabled}
                                validation={new ArticleSearchValidation(new Network())}
                            />
                        )}
                        {this.getAppSwitch()}
                        <TenantSwitcher key="action_tenant" />
                    </div>
                </EpAppBar>
                {isTabBarActive && (
                    <Paper elevation={4} className={classNames(classes.tabBar, {
                        [classes.bannerOffset]: useBannerOffset
                    })}>
                        <div className={classes.toolbarSpacer}/>
                        <Switch>
                            <Route path="/articlestream" component={ArticleTabBar}/>
                            <Route path="/jetstream" component={JetTabBar}/>
                        </Switch>
                    </Paper>
                )}
                <Spacer />
            </div>
        );
    }

    setDocumentTitle() {
        document.title = RouteManager.getTabTitle(this.props.location);
    }

    getAppSwitch() {
        const stage = this.getStage();
        const apps = RouteManager.getApps(this.props.location)
            .filter(app => app.environment)
            .map(app => {
                const Environment = app.environment;
                return <Environment
                    key={app.path}
                    stage={stage}
                    getUrl={this.getUrl}
                />;
            });
        if (TenantManager.getTenantByPath(this.props.tenant).role === AuthRole.BILD) {
            apps.push(<Environments.Lean key='lean' stage={stage} client='bild' />);
        }
        if (this.props.userRoles && this.props.userRoles.includes(AuthRole.CATEGORIZATION)) {
            apps.push(<Environments.Categorization key='categorization' stage={stage}/>);
        }
        return (
            <AppSwitch key="action_app" title="Apps">
                {apps}
            </AppSwitch>
        );
    }

    getStage() {
        if (config.isLocalHost) {
            return Stages.LOCAL;
        } else if (config.stage === 'production') {
            return Stages.PRODUCTION;
        } else {
            return Stages.DEVELOPMENT;
        }
    }

    getUrl = (url) => {
        return `${url}/${this.props.tenant}`;
    }

}

NavigationBarComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    useBannerOffset: PropTypes.bool.isRequired,
    chipData: PropTypes.object.isRequired,
    onSearchBarChange: PropTypes.func.isRequired,
    isSearchVisible: PropTypes.bool.isRequired,
    isSearchEnabled: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    tenant: PropTypes.string.isRequired,
    userRoles: PropTypes.array
};

export const NavigationBar = withStyles(styles)(withAuth(NavigationBarComponent));
