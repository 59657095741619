import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { fixUrl } from '../../../common/helpers/Utils';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: 'none',
        color: 'inherit',
        overflow: 'hidden',
        maxWidth: 320,
        textOverflow: 'ellipsis',
        display: 'inline-block',
        [theme.breakpoints.down('xs')]: {
            maxWidth: 160
        },
        '& svg': {
            fontSize: '14px',
            verticalAlign: 'middle'
        }
    }
}));

type Props = {
    url: string,
    children: PropTypes.ReactNodeLike
}

export const TitleLink: FunctionComponent<Props> = ({ url, children }) => {
    const classes = useStyles();
    return (
        <a
            className={classes.link}
            href={fixUrl(url)}
            target="_blank"
            rel="noopener noreferrer"
        >
            {children}
        </a>
    );
};

TitleLink.propTypes = {
    url: PropTypes.string.isRequired,
    children: PropTypes.node
};
