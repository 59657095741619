import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isMatch from 'lodash/isMatch';
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { JetOrderOption } from '../../helpers/Query';
import { SparklineChart, PercentChart, BarChart, TimelineBarChart } from '../JetChart';
import { ColumnData } from './ColumnData';
import { ColumnType } from './ColumnType';
import { RTRDisplay } from '../common/rtrDisplay';
import { firestoreStores } from '../../../common/helpers/Firestore';
import { shouldDisplayReadThroughRate } from '../../../common/helpers/Utils';

const styles = (theme) => createStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        // probably to be able to show full overlay messages from cells
        paddingTop: 200,
        marginTop: -200
    },
    table: {
        width: '100%',
        left: 0,
        minWidth: 300
    },
    tableHeader: {
        whiteSpace: 'nowrap',
        padding: 14,
        boxSizing: 'border-box'
    },
    tableHeaderWithSort: {
        paddingLeft: 0
    },
    tableContainer: {
        width: '100%'
    },
    stickyContainer: {
        position: 'fixed',
        overflow: 'hidden',
        zIndex: 500,
        backgroundColor: 'white',
        [theme.breakpoints.down('xs')]: {
            top: '104px',
            paddingTop: '8px'
        }
    },
    tableStickyHeader: {
        tableLayout: 'fixed',
        whiteSpace: 'nowrap',
        position: 'relative'
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
        overflow: 'hidden',
        maxWidth: 320,
        textOverflow: 'ellipsis',
        display: 'inline-block',
        [theme.breakpoints.down('xs')]: {
            maxWidth: 160
        }
    },
    cell: {
        lineHeight: 'normal',
        position: 'relative'
    },
    markedCell: {
        fontWeight: 'bold'
    },
    title: {
        fontSize: 14,
        padding: '4px 14px 4px 14px'
    },
    sparkline: {
        padding: '8px 0'
    },
    timelineBarChart: {
        padding: 0
    },
    center: {
        textAlign: 'center',
        '& span': {
            paddingRight: 24
        }
    },
    stickyHeaderCell: {
        position: 'sticky',
        top: 0,
        backgroundColor: 'white !important',
        zIndex: 500
    },
    stickyBodyCell: {
        position: 'sticky',
        backgroundColor: '#fafafa',
        left: 0,
        zIndex: 100,
        '&:first-child::after': {
            content: '""',
            position: 'absolute',
            right: 0,
            width: '2px',
            height: '100%',
            boxShadow: '6px 5px 15px 0px rgb(0 0 0 / 20%)'
        }
    },
    rtr: {
        width: '40px',
        margin: '0 auto'
    }
});

class JetTableComponent extends React.Component {

    constructor(props) {
        super(props);
        this.classes = this.props.classes;
        this.handleSortClick = this.handleSortClick.bind(this);
        this.rootDivRef = React.createRef();
        this.tableContainerRef = React.createRef();
        this.state = {
            showStickyHeader: false,
            contentWidth: 300,
            columnWidths: null,
            scrollLeft: 0
        };

        this.isBetaUser = firestoreStores.userStore.getBetaStatus();
    }

    componentDidMount() {
        if (this.rootDivRef.current === null || window === null) {
            return;
        }
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);
        this.rootDivRef.current.addEventListener('scroll', this.handleHorizontalScroll);
    }

    componentWillUnmount() {
        if (this.rootDivRef.current === null || window === null) {
            return;
        }
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
        this.rootDivRef.current.removeEventListener('scroll', this.handleHorizontalScroll);
    }

    handleHorizontalScroll = (e) => {
        this.setState({
            scrollLeft: -e.target.scrollLeft
        });
    }

    handleScroll = () => {
        const tableNode = this.tableContainerRef.current;
        if (tableNode === null) {
            return; // table not loaded yet
        }
        const rect = tableNode.getBoundingClientRect();

        if (this.showStickyHeader !== (rect.y <= this.props.stickyThreshold)) {
            const selector = '#mainJetTable > thead > tr > th';
            const colWidths = Array.from(tableNode.querySelectorAll(selector)).map(
                e => e.clientWidth
            );
            const tableWidth = tableNode.querySelectorAll('#mainJetTable')[0].clientWidth;

            const newState = {
                showStickyHeader: rect.y <= this.props.stickyThreshold,
                contentWidth: rect.width,
                tableWidth: tableWidth,
                columnWidths: colWidths
            };
            if (!isMatch(this.state, newState)) {
                this.setState(newState);
            }
        }
    }

    handleResize = () => {
        const node = this.tableContainerRef.current;
        if (!node) {
            return;
        }
        const rect = node.getBoundingClientRect();
        this.setState({
            contentWidth: rect.width
        });
    }

    render() {
        return (
            <div className={classNames(this.classes.root, this.props.className)}
                ref={this.rootDivRef}
            >
                <div
                    ref={this.tableContainerRef}
                    className={this.classes.tableContainer}
                >
                    {this.getStickyHeaderContainer()}
                    <Table
                        id="mainJetTable"
                        className={this.classes.table}
                    >
                        {this.getTableHead()}
                        {this.getTableBody()}
                    </Table>
                </div>
            </div>
        );
    }

    getStickyHeaderContainer() {
        if (!this.state.showStickyHeader) {
            return null;
        }
        return (
            <div className={this.classes.stickyContainer}
                style={{
                    width: this.state.contentWidth,
                    top: this.props.stickyThreshold
                }}>
                {this.getStickyHeader()}
            </div>);
    }

    getStickyHeader() {
        return (
            <Table
                className={this.classes.tableStickyHeader}
                style={{
                    width: this.state.tableWidth,
                    left: this.state.scrollLeft
                }}
            >
                {this.getTableHead({ sticky: true })}
            </Table>
        );
    }

    getTableHead({ sticky = false } = {}) {
        const tableColumns = this.isBetaUser ? this.props.columns : this.props.columns.filter(column => !column.hasRTR);
        return (
            <TableHead
                innerRef={sticky ? null : this.tableHeadHandle}
            >
                <TableRow>
                    {tableColumns.map((column, index) => (
                        <TableCell
                            key={column.label}
                            align={column.type === ColumnType.NUMERIC ? 'right' : 'left'}
                            className={classNames(this.classes.tableHeader, {
                                [this.classes.tableHeaderWithSort]: column.isSortable,
                                [this.classes.center]: column.hasSparkline,
                                [this.classes.stickyHeaderCell]: index === 0 && sticky,
                                [this.classes.stickyBodyCell]: index === 0
                            })}
                            colSpan={
                                (column.id ? 1 : 0) +
                                (column.hasSparkline ? 1 : 0) +
                                (column.hasPercentChart ? 1 : 0) +
                                (column.hasBarChart ? 1 : 0) + (column.hasTimelineBarChart ? 1 : 0)
                            }
                            style={sticky ? { width: this.state.columnWidths[index] } : null}
                            data-testid={`table-header-${column.id}`}
                        >
                            {column.isSortable ? (
                                <TableSortLabel
                                    active={this.props.sortBy === column.id}
                                    direction={this.props.sortBy === column.id ? this.props.orderBy : this.getDefaultOrder(column.id)}
                                    onClick={this.handleSortClick.bind(this, column.id)}
                                >
                                    {column.label}
                                </TableSortLabel>
                            ) : column.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    getTableBody() {
        return (
            <TableBody>
                {this.props.rowsData.map((cells, index) => this.getTableRow(cells, index))}
            </TableBody>
        );
    }

    getTableRow(cells, rowIndex) {
        const children = [];
        for (let cellIndex = 0; cellIndex < cells.length; cellIndex++) {
            const cell = { ...cells[cellIndex], cellIndex };
            const column = this.props.columns[cellIndex];
            if (column.id && !column.hasRTR) {
                children.push(this.getTableCell(cell, column));
            }
            if (column.hasSparkline) {
                children.push(this.getSparklineCell(cell, column));
            }
            if (column.hasPercentChart) {
                children.push(this.getPercentChartCell(cell, column));
            }
            if (column.hasBarChart) {
                children.push(this.getBarChartCell(cell, column));
            }
            if (column.hasTimelineBarChart) {
                children.push(this.getTimelineBarChartCell(cell, column));
            }
            if (column.hasRTR && this.isBetaUser) {
                children.push(this.getRTRCell(cell, column));
            }
        }
        return (
            <TableRow key={rowIndex}>
                {children}
            </TableRow>
        );
    }

    getTableCell({ data, relatedData, cellIndex }, column) {
        let content = column.postProcessor(data, relatedData);
        const customStyle = column.type === ColumnType.LINK ? this.classes.title : undefined;
        const isNumeric = column.type === ColumnType.NUMERIC;
        if (isNumeric && typeof content === 'number') {
            content = content.toLocaleString();
        }
        return (
            <TableCell
                key={column.id}
                align={isNumeric ? 'right' : 'left'}
                className={classNames(this.classes.cell, customStyle, {
                    [this.classes.markedCell]: column.isHighlighted,
                    [this.classes.stickyBodyCell]: cellIndex === 0
                })}
            >
                {content}
            </TableCell>
        );
    }

    getSparklineCell({ sparkline = [], sparklineTimes, sharedMaxValues = {} }, column) {
        return (
            <TableCell
                key={column.id + 'Sparkline'}
                padding={'none'}
                className={classNames(this.classes.cell, this.classes.sparkline)}
            >
                <SparklineChart
                    data={sparkline}
                    times={sparklineTimes}
                    max={sharedMaxValues.sparkline}
                    style={column.sparklineStyle}
                />
            </TableCell>
        );
    }

    getPercentChartCell({ data, relatedData }, column) {
        const withTableCell = (percentChart) => (
            <TableCell
                key={column.id + 'Percent'}
                padding={'none'}
                className={classNames(this.classes.cell, this.classes.percentChart)}
            >
                {percentChart}
            </TableCell>
        );

        if (column.percentChartHideIfNotPremium && !relatedData.wasPremium) {
            return withTableCell(null);
        }
        if (column.percentChartPostProcessor) {
            data = column.percentChartPostProcessor(data, relatedData);
        }
        return withTableCell(
            <PercentChart
                percent={data}
                style={column.percentChartStyle}
            />
        );
    }

    getBarChartCell(cell, column) {
        return (
            <TableCell
                key={column.id + 'Bar'}
                className={classNames(this.classes.cell, this.classes.barChart)}
            >
                <BarChart
                    label={column.label}
                    barOptions={column.barOptions}
                    data={cell}
                    style={column.barChartStyle}
                />
            </TableCell>
        );
    }

    getTimelineBarChartCell({ sumSparklines }, column) {
        return (
            <TableCell
                key={column.id + 'TimelineBar'}
                className={classNames(this.classes.cell, this.classes.timelineBarChart)}
            >
                <TimelineBarChart
                    label={column.label}
                    settings={column.sumSparklines}
                    data={sumSparklines}
                    style={column.style}
                />
            </TableCell>
        );
    }

    handleSortClick(columnId) {
        let orderBy;
        if (this.props.sortBy === columnId) {
            orderBy = this.props.orderBy === JetOrderOption.ASC ? JetOrderOption.DESC : JetOrderOption.ASC;
        } else {
            orderBy = this.getDefaultOrder(columnId);
        }
        this.props.onSortUpdate(columnId, orderBy);
    }

    getDefaultOrder(columnId) {
        return this.props.columns.find(column => column.id === columnId).orderBy;
    }

    getRTRCell({ data, relatedData }, column) {
        const showRTR = shouldDisplayReadThroughRate(relatedData.articleType);

        return (
            <TableCell
                key={column.id + 'RTR'}
                className={classNames(this.classes.cell)}
            >
                <div className={this.classes.rtr}>
                    <RTRDisplay rtr={data} showRTR={showRTR}/>
                </div>
            </TableCell>
        );
    }
}

JetTableComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.instanceOf(ColumnData)),
    rowsData: PropTypes.arrayOf(PropTypes.array).isRequired,
    sortBy: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    onSortUpdate: PropTypes.func.isRequired,
    sparklineTimes: PropTypes.array,
    stickyThreshold: PropTypes.number.isRequired
};

export const JetTable = withStyles(styles)(JetTableComponent);
