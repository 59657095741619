import { ViewMode } from './ViewMode';
import { SumData } from '../JetSum/SumData';
import { ColumnData } from '../JetTable/ColumnData';
import { QueryProducer, BuildQueriesFunction, BuildQueryResultFunction, QueryOptions } from '../../helpers/Query/QueryTypes';

export type PageResult = {
    columns: ReturnType<ColumnData['buildQueryResult']>[]
    sums: ReturnType<SumData['buildQueryResult']>[]
    tables: ReturnType<ColumnData['buildQueryResult']>[][]
}

export class PageData implements QueryProducer<PageResult> {
    label: string
    sums: SumData[]
    columns: ColumnData[]
    tables: { columns: ColumnData[], filter?: QueryOptions, numberOfRows?: number }[]
    viewModes: ViewMode[]
    filter: QueryOptions['filter']
    watchlistKey?: string

    constructor({ label = '', sums = [], columns = [], viewModes = [ViewMode.TABLE], filter = {}, tables = [], watchlistKey }: Partial<PageData>) {
        this.label = label;
        this.sums = sums;
        this.columns = columns;
        this.tables = tables;
        this.viewModes = viewModes;
        this.filter = filter;
        this.watchlistKey = watchlistKey;
    }

    buildQueries: BuildQueriesFunction = (queryBuilder, queryOptions) => {
        this.columns.forEach(column => column.buildQueries(queryBuilder, queryOptions));
        this.sums.forEach(sum => sum.buildQueries(queryBuilder, queryOptions));
        this.tables.forEach(({ columns, filter }) =>
            columns.forEach(column => column.buildQueries(queryBuilder, queryOptions, filter))
        );
    }

    buildQueryResult: BuildQueryResultFunction<PageResult> = (getData) => ({
        columns: this.columns.map(column => column.buildQueryResult(getData)),
        sums: this.sums.map(sum => sum.buildQueryResult(getData)),
        tables: this.tables.map(({ columns, filter }) => {
            const tableGetData: typeof getData = (requestOptions) => getData({ ...filter, ...requestOptions });
            return columns.map(column => column.buildQueryResult(tableGetData));
        })
    })
}
