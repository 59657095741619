import React, { useEffect, useState, useCallback } from 'react';

export function useSize(refElement: React.RefObject<HTMLElement>) {
    const [width, setWidth] = useState<number | null>(null);
    const [height, setHeight] = useState<number | null>(null);
    const updateSize = useCallback(() => {
        // use setTimeout to delay after layout to get updated size information
        setTimeout(() => {
            if (refElement && refElement.current) {
                const rect = refElement.current.getBoundingClientRect();
                setWidth(rect.width);
                setHeight(rect.height);
            }
        }, 0);
    }, [refElement]);

    useEffect(() => {
        updateSize();
        window.addEventListener('resize', updateSize);
        return () => {
            window.removeEventListener('resize', updateSize);
        };
    }, [updateSize]);

    return [width, height];
}
