import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { ErrorRouter } from '../Error';
import { AuthRoute } from '../Auth';
import { LocalStorage } from '../Storage';
import { Query } from '../../helpers/Query';
import { trackLink } from '../../../../trackingScript';
import { RouteManager } from '../../../common/helpers/Route';

class AppGateComponent extends React.Component {
    queryHelper = new Query();

    componentDidMount() {
        // check if is coming from IDP
        const querySettings = this.queryHelper.getQuerySettings(this.props.location, this.props.history);
        const apiToken = querySettings['api_token'];
        const firestoreToken = querySettings['firestore_token'];
        // save tokens and remove from url
        if (apiToken) {
            LocalStorage.setApiToken(apiToken);
            this.queryHelper.removeQuerySetting(querySettings, 'api_token');
        }
        if (firestoreToken) {
            LocalStorage.setFirestoreToken(firestoreToken);
            this.queryHelper.removeQuerySetting(querySettings, 'firestore_token');
        }
        if (apiToken || firestoreToken) {
            this.queryHelper.setQuerySettings(
                this.props.location,
                this.props.history,
                querySettings
            );
        } else {
            // track page view only for non auth calls
            this.trackPageView();
        }
    }

    componentDidUpdate(prevProps) {
        const isDifferentPathname = prevProps.location.pathname !== this.props.location.pathname;
        const isDifferentSearch = prevProps.location.search !== this.props.location.search;
        if (isDifferentPathname || isDifferentSearch) {
            this.trackPageView();
        }
    }

    /* Allow guest users to see error pages. (e.g. authentication errors) */
    render() {
        const querySettings = this.queryHelper.getQuerySettings(this.props.location, this.props.history);
        const apiToken = querySettings['api_token'];
        if (apiToken) {
            // wait for refresh
            return null;
        }
        return (
            <Switch>
                <Route path="/error" component={ErrorRouter}/>
                <AuthRoute
                    render={() => {
                        // ensure users are authenticated
                        return this.props.children;
                    }}
                />
            </Switch>
        );
    }

    trackPageView() {
        const routeData = RouteManager.getRouteData(this.props.location);
        if (routeData === undefined) {
            return;
        }

        const appName = routeData.app || null;
        let envTenant = routeData.tenant || null;
        let pageName = routeData.rest || null;

        if (appName === 'error') {
            pageName = envTenant;
            envTenant = null;
        }

        trackLink({
            'app_name': appName,
            'env_tenant': envTenant,
            'event_name': pageName,
            'event_action': 'view',
            'event_label': null
        });
    }
}

AppGateComponent.propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export const AppGate = withRouter(AppGateComponent);

