import _ from 'lodash';
import { ArticleItemData } from './ArticleItemData';
import { ArticleStreamDataConverter } from './ArticleStreamDataConverter';
import { ArticleItem } from './ArticleStreamTypes';

export interface NetworkInterface {
    secureFetch(url: string, options: any): Promise<any>;
}

type ArticleSearchValues = {
    coremediaIds: string[],
    leanIds: string[]
}

type RequestParameter = {
    baseDate: string,
    offset: number,
    search: ArticleSearchValues,
    filter: any,
    site: string,
    limit: number, sort: string
}

export class ArticleStreamApi {
    private readonly network: NetworkInterface;
    private readonly basePath: string;

    constructor(network: NetworkInterface, basePath: string) {
        this.network = network;
        this.basePath = basePath;
    }

    async requestArticleData(baseDate: string, offset: number, search: ArticleSearchValues, filter: any, site: string, limit: number, sort: string): Promise<any> {
        const parameter = {
            baseDate,
            offset,
            search,
            filter,
            site,
            limit, sort
        };
        return this.requestArticles(parameter);
    }
    private async requestArticles(parameter: RequestParameter) {
        return this
            .loadRawArticles(parameter)
            .then(response => {
                // transform raw data
                const transformNeeded = parameter.site !== 'bild';
                const transformedData = transformNeeded ? ArticleStreamDataConverter.convert(response.items) : response.items;
                const items = Array.isArray(transformedData?.items) ?
                    transformedData.items.map((rawItem: ArticleItem) => new ArticleItemData(rawItem, parameter.site))
                    : [];
                return {
                    endpoint: response.endpoint,
                    items
                };
            });
    }

    private async loadRawArticles(parameter: RequestParameter) {
        if (parameter.site === 'bild') {
            return this.loadRawArticlesNew(parameter);
        }
        return this.loadRawArticlesDeprecated(parameter);
    }

    private async loadRawArticlesNew(parameter: RequestParameter) {
        let url = this.basePath + '/articles/articlestream';
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: {}
        };

        if (this.isSearchEnabled(parameter.search.leanIds)) {
            // search request (ignore possible other filter)
            options.body = JSON.stringify({
                tenant: parameter.site,
                articleIds: parameter.search.leanIds
            });
        } else if (this.isFilterEnabled(parameter.filter) || this.isSiteActive(parameter.site)) {
            // filter request
            parameter.filter = Object.assign({
                tenant: parameter.site,
                limit: parameter.limit ? parameter.limit : 40,
                offset: parameter.offset ? parameter.offset : 0,
                sort: parameter.sort
            }, parameter.filter);
            options.body = JSON.stringify(parameter.filter);
        }
        url = this.addPagination(url, parameter.baseDate, parameter.offset, parameter.limit);
        return {
            endpoint: url,
            items: await this.network.secureFetch(url, options)
        };
    }

    private async loadRawArticlesDeprecated(parameter: RequestParameter) {
        let url = this.basePath + '/articles';
        const options: any = {
            headers: {
                'Accept': 'application/json'
            }
        };

        if (this.isSearchEnabled(parameter.search.coremediaIds)) {
            // search request (ignore possible other filter)
            url += '/filter';
            options.method = 'POST';
            options.headers['Content-Type'] = 'application/json';
            options.body = JSON.stringify({ cmsIdList: parameter.search.coremediaIds });
        } else if (this.isFilterEnabled(parameter.filter) || this.isSiteActive(parameter.site)) {
            // filter request
            url += '/filter';
            options.method = 'POST';
            options.headers['Content-Type'] = 'application/json';
            parameter.filter = Object.assign({
                site: parameter.site,
                sort: parameter.sort
            }, parameter.filter);
            options.body = JSON.stringify(parameter.filter);
        }
        url = this.addPagination(url, parameter.baseDate, parameter.offset, parameter.limit);
        return {
            endpoint: url,
            items: await this.network.secureFetch(url, options)
        };
    }


    private isSearchEnabled(articleIds: string[] | number[]) {
        return articleIds && articleIds.length > 0;
    }

    private isFilterEnabled(filter: unknown) {
        return filter && JSON.stringify(filter) !== JSON.stringify({});
    }

    private isSiteActive(site: string) {
        return site !== null;
    }

    private addPagination(url: string, baseDate: string, offset: number, limit: number) {
        const queryParams = [];
        if (!_.isNil(baseDate)) {
            queryParams.push('base=' + baseDate);
        }
        if (!_.isNil(offset)) {
            queryParams.push('offset=' + offset);
        }
        if (!_.isNil(limit)) {
            queryParams.push('limit=' + limit);
        }
        if (queryParams.length > 0) {
            url += '?' + queryParams.join('&');
        }
        return url;
    }
}
