import React, { Component, CSSProperties } from 'react';
import { ThemedEcharts } from '../../../../echarts';

type PercentChartProps = {
    percent: number,
    style?: CSSProperties
}

export class PercentChart extends Component<PercentChartProps> {

    render() {
        return (
            <div>
                <ThemedEcharts
                    option={this.getPercentOption(this.props.percent)}
                    style={{
                        height: 20,
                        width: 50,
                        ...this.props.style
                    }}
                />
            </div>
        );
    }

    getPercentOption(percent = 0) {
        return {
            xAxis: {
                show: false,
                type: 'value',
                max: 100
            },
            yAxis: {
                show: false,
                type: 'category'
            },
            series: [{
                data: [percent],
                type: 'bar',
                showBackground: true,
                silent: true, // disables change mouse click icon on hover
                backgroundStyle: {
                    color: 'rgba(220, 220, 220, 0.8)'
                }
            }],
            grid: {
                left: '1%',
                right: '1%',
                top: '1%',
                bottom: '1%'
            }
        };
    }
}
