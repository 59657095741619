import React, { FC } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, useMediaQuery, Paper, useTheme } from '@material-ui/core';
import { Spacer } from '../NavigationBar';
import { Accordion } from '../Accordion';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 0
    },
    spacer: {
        display: 'none',
        [theme.breakpoints.up('sidebarTransform')]: {
            display: 'block'
        }
    }
}));

type Props = {
    children: PropTypes.ReactNodeLike,
    title?: string,
    className?: string,
};

export const Sidebar: FC<Props> = ({ className, children, title }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sidebarTransform'));
    return (
        <Paper className={classNames(classes.root, className)}>
            <Spacer className={classes.spacer} />
            {(isDesktop || (!isDesktop && !title)) && children}
            {!isDesktop && title && (
                <Accordion label={title} noIndent defaultExpanded={false}>
                    {children}
                </Accordion>
            )}
        </Paper>
    );
};

Sidebar.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    className: PropTypes.string
};
