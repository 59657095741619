import { ColorMapping } from '../../configs';
import { JetColumnId } from '../JetPage';

export class BarOption {
    constructor(
        public id: JetColumnId,
        public label: keyof typeof ColorMapping,
        public color: string
    ) {}
}
