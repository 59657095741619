export const fixUrl = (url: any): string => {
    if (!url || typeof url !== 'string') {
        return '';
    }

    if (/fbia.bild.de/.test(url)) {
        const matches = /(\d{8})/.exec(url);
        if (matches && matches.length > 0) {
            url = `bild.de/-${matches[0]}`;
        }
    }

    const urlWithoutProtokoll = url.replace(/^(https?:?)?\/\//, '');
    return `//${urlWithoutProtokoll}`;
};

export const shouldDisplayReadThroughRate = (articleType: string) => {
    // we should not display RTR for video and ticker
    return !['media', 'live', 'sportdaten', 'live-sport'].includes(articleType);
};
