import _ from 'lodash';
import { TIME_LABEL } from '../../helpers/Query/JetFilterQuery';

export class SumDataSparkline {
    static SELECTED_COMBINED = 'SELECTED_COMBINED'

    constructor(id, { label, chartOptions, requestValue = false, timeFilter = null } = {}) {
        this.id = id;
        this.label = label;
        this.chartOptions = chartOptions;
        this.requestValue = requestValue;
        this.timeFilter = timeFilter;
    }

    setRequestOptions(requestOptions) {
        this.requestOptions = requestOptions;
        return this;
    }

    buildQueries(queryBuilder, sparklines) {
        if (this.id === SumDataSparkline.SELECTED_COMBINED) {
            sparklines
                .filter(({ id }) => id !== SumDataSparkline.SELECTED_COMBINED)
                .forEach(({ id }) => queryBuilder.addRequest(id, { sparkline: true, value: this.requestValue }, this.requestOptions));
        } else {
            queryBuilder.addRequest(this.id, { sparkline: true, value: this.requestValue }, this.requestOptions);
        }
    }

    buildQueryResult(getData, sparklines, path = 'sumsDataV2', articleId) {
        const data = getData(this.requestOptions);
        let result = path.split('.').reduce((obj, next) => obj?.[next], data);
        if (!result) {
            return result;
        }
        if (articleId) {
            result = result.find(({ id }) => id === articleId) || {};
        }
        const hasCombined = this.id === SumDataSparkline.SELECTED_COMBINED;
        return {
            sparkline: result[`${this.id}Sparkline`],
            combined: hasCombined ? _.fromPairs(sparklines
                .filter(({ id }) => id !== SumDataSparkline.SELECTED_COMBINED)
                .map(({ id }) => [id, result[`${id}Sparkline`]])
            ) : {},
            sparklineTimes: data.sparklineTimes
        };
    }

    isSparklineVisible(time) {
        return !Array.isArray(this.timeFilter) || this.timeFilter.some((label) => label === TIME_LABEL[time]);
    }
}
