import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import preset from 'jss-preset-default';
import { JssProvider } from 'react-jss';
import { create } from 'jss';
import { createGenerateClassName, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { unregister } from './registerServiceWorker';
import { App, AppGate } from './bundles/common/components/App';
import { themeConfig } from './bundles/common/configs/themeConfig';
import { FirestoreProvider, firestoreStores } from './bundles/common/helpers/Firestore';
import { tealium } from './trackingScript';
import '@fortawesome/fontawesome-free';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';
import '@fortawesome/fontawesome-free/css/brands.min.css';
import './index.css';
import { UserSurvey } from './bundles/common/components/UserSurvey';

// setup global jss instance
// (needed to access additional jss-plugins not covered by material-ui)
const jss = create({ plugins: preset().plugins });
// custom material-ui class name generator
const classNameGenerator = createGenerateClassName();

// setup theme
const defaultTheme = createMuiTheme(themeConfig);

// setup tracking
tealium();

// render app
ReactDOM.render(
    (
        <BrowserRouter>
            <FirestoreProvider value={firestoreStores}>
                <JssProvider jss={jss} generateClassName={classNameGenerator}>
                    <MuiThemeProvider theme={defaultTheme}>
                        <AppGate>
                            <UserSurvey />
                            <App />
                        </AppGate>
                    </MuiThemeProvider>
                </JssProvider>
            </FirestoreProvider>
        </BrowserRouter>
    ),
    document.getElementById('root')
);
// registerServiceWorker();
unregister();
