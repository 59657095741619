import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import { CircularProgress, createStyles } from '@material-ui/core';
import { SumSparklineChart, BarChart, SumTachoChart, SmallTimelineBarChart } from '../JetChart';
import { firestoreContext } from '../../../common/helpers/Firestore';
import { withBreakpoint, WithBreakpoint } from '../../../common/helpers/Component';
import { SumData } from '../JetSum';
import type { ColumnResult } from '../JetTable';
import type { QueryOptions } from '../../helpers/Query/QueryTypes';
import { HEADLINE_3 } from '../../../common/configs/scaleCategory';
import { PieChart } from '../JetChart/PieChart';
import { PieChartGroup } from '../JetChart/PieChartGroup';
import { getFormattedSum } from './getFormattedSum';
import { VisitPrediction } from '../VisitPrediction';

type Props = {
    filters: QueryOptions
    sumData?: {
        data?: number,
        yesterdaySparkline?: string[],
        sparklines?: ColumnResult['sumSparklines'],
        relatedData?: { [key: string]: number }
    },
    settings: SumData,
    brand: string
}

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        width: '100%'
    },
    rootWithTacho: {
        width: 'auto'
    },
    sumContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        position: 'relative',
        marginBottom: 48,
        marginLeft: 20,
        marginRight: 20,
        alignSelf: 'flex-end',
        textAlign: 'left',
        maxWidth: '100%'
    },
    sum: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 16

    },
    sumInSparkline: {
        position: 'absolute',
        left: '5%',
        top: 60,
        fontSize: 28
    },
    sumText: {
        fontSize: 64,
        lineHeight: '60px',
        background: `linear-gradient(94.45deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent'
    },
    sumPercentage: {
        fontSize: 16,
        color: theme.palette.secondary.main
    },
    label: {
        ...HEADLINE_3,
        textTransform: 'capitalize',
        fontSize: '20px',
        fontWeight: 'bold',
        letterSpacing: '0.4px',
        lineHeight: '24px',
        textAlign: 'left'
    },
    labelInTachoChart: {
        ...HEADLINE_3,
        textTransform: 'capitalize',
        fontSize: '20px',
        fontWeight: 'bold',
        letterSpacing: '0.4px',
        lineHeight: '24px',
        textAlign: 'center'
    },
    labelInSparkline: {
        position: 'absolute',
        left: '5%',
        top: 90
    },
    labelText: {
        color: 'rgba(0,0,0,0.6)'
    },
    labelTime: {
        fontSize: '0.8em'
    },
    spinner: {
        fontSize: 'initial'
    },
    barChart: {
        marginTop: 10,
        marginBottom: 10
    },
    pieChart: {
        display: 'flex',
        gap: '40px'
    },
    withTacho: {
        alignItems: 'center',
        marginLeft: 0,
        marginRight: 0,
        '@media (max-width: 500px), (min-width: 600px) and (max-width: 800px)': {
            transform: 'scale(0.8)',
            marginBottom: 0
        }
    },
    withTwoTachosPerLine: {
        '@media (min-width: 1200px) and (max-width: 1400px)': {
            zoom: 0.8
        }
    },
    withSparkline: (props: Props & WithBreakpoint) => ({
        width: props.matchesBreakpoint ? `${100 / props.settings.sparklinesPerRow}%` : '100%',
        marginLeft: 0,
        marginRight: 0
    })
});

type Firestore = {
    subscribeConfig: (
        brand: string,
        updateHandler: (config: Record<string, unknown>) => void
    ) => () => void
}
type Goals = {
    auto: number,
    erotik: number,
    geld: number,
    leben_wissen: number,
    news: number,
    politik: number,
    sport: number,
    total: number,
    unterhaltung: number
}

type State = {
    config?: {
        getTachoGoal: (
            timeFilter: string,
            department: Record<string, unknown>,
            column: string | null,
            subTenant: string | undefined
        ) => number,
        goals: Goals,
        searchGoals: Goals
    }
}

class SumSectionComponent extends PureComponent<Props & WithStyles<typeof styles> & WithBreakpoint> {
    static contextType = firestoreContext
    state: State = {}
    firestore!: Firestore
    unsubscribeConfig?: () => void

    componentDidMount() {
        this.firestore = this.context.firestore;
        if (this.props.settings.hasTacho || this.props.settings.hasPie) {
            this.unsubscribeConfig = this.firestore.subscribeConfig(this.props.brand, (config) => this.setState({ config }));
        }
    }

    componentWillUnmount() {
        if (this.unsubscribeConfig) {
            this.unsubscribeConfig();
        }
    }

    render() {
        const {
            filters: {
                time: timeFilter = 'today',
                departments = {}
            },
            settings: {
                id,
                label,
                tachoKey,
                subTenant,
                style,
                hasBarChart,
                barOptions,
                barLabel,
                barStyle,
                hasPie,
                hasTacho,
                tachoStyle,
                hasSparkline,
                hasTimeline,
                hasVisitPrediction,
                sparklineStyle,
                sparklineShowLegend,
                sparklines,
                sumInSparkline,
                requestOptions
            },
            sumData = {},
            classes
        } = this.props;
        const { config } = this.state;

        const showTotalPie = hasPie && config !== undefined && config.goals && config.searchGoals;
        const hasSum = !!id;
        const sum = sumData.data;
        const showTimeline = hasTimeline && sumData.sparklines && this.props.settings.isSparklineVisible(timeFilter);
        const sparklineYesterday = sumData.yesterdaySparkline;
        const sumYesterday = sparklineYesterday && sparklineYesterday.reduce((sum, val) => sum + parseInt(val, 10), 0);
        const showSparkline = hasSparkline && !hasTimeline && this.props.settings.isSparklineVisible(timeFilter);
        const tachoGoal = hasTacho && config && config.getTachoGoal(timeFilter, { ...departments, ...(requestOptions?.departments) }, tachoKey || id, subTenant);
        const showTacho = hasSum && hasTacho && sum !== undefined && sum >= 0 && tachoGoal;
        const tachoColor = showTacho && tachoStyle ? this.getTachoColor(sum, tachoGoal, tachoStyle) : undefined;
        const relatedData = sumData.relatedData;
        const { totalGoal, totalSearchGoal } = this.getTotalGoals();
        const sumContainerClassName = classNames(classes.sumContainer, {
            [classes.withTacho]: showTacho,
            [classes.withTwoTachosPerLine]: showTacho && tachoStyle?.showTwoPerLine,
            [classes.withSparkline]: showSparkline
        });
        const sumRootClassName = classNames(classes.root, {
            [classes.rootWithTacho]: showTacho
        });

        return (
            <div className={sumRootClassName}>
                <div className={sumContainerClassName} style={style.sumContainer}>
                    {showTacho && <SumTachoChart
                        value={sum}
                        brand={this.props.brand}
                        goal={tachoGoal}
                        yesterday={sumYesterday}
                        color={tachoColor}
                        fontStyle={tachoStyle?.label}
                        style={tachoStyle?.tacho}
                    />}
                    {showSparkline && <SumSparklineChart
                        sparklines={sparklines}
                        sparklinesData={sumData.sparklines}
                        style={sparklineStyle}
                        time={timeFilter}
                        showLegend={sparklineShowLegend}
                        grid={undefined}
                        position={undefined}
                    />}
                    {label && !showTacho && <div className={sumInSparkline ? classes.labelInSparkline : classes.label} style={style.label}>
                        <label className={classes.labelText}>
                            {label}
                        </label>
                    </div>}
                    {hasSum && <div className={sumInSparkline ? classes.sumInSparkline : classes.sum} style={style.sum}>
                        {typeof sum !== 'undefined' && !showTotalPie &&
                            <>
                                <span className={classes.sumText}>{getFormattedSum(Number(sum), !!showTacho)}</span>
                                <span className={classes.sumPercentage}>{tachoGoal && `${Math.round(((Number(sum) / tachoGoal) * 100))}% von ${tachoGoal.toLocaleString()}`} </span>
                            </>
                        }
                        {typeof sum === 'undefined' &&
                        <CircularProgress
                            size={sumInSparkline ? 28 : 40}
                            className={classes.spinner}
                        />}
                    </div>}
                    {label && showTacho && <div className={classes.labelInTachoChart} style={style.label}>
                        <label className={classes.labelText}>
                            {label}
                        </label>
                    </div>}
                    {hasBarChart && (<div className={classes.barChart}>
                        <BarChart
                            label={barLabel}
                            barOptions={barOptions}
                            data={sumData}
                            style={barStyle}
                        />
                    </div>)}
                    {showTotalPie && (<div className={classes.pieChart}>
                        <PieChartGroup>
                            <PieChart
                                title='Reichweite Gesamt'
                                goal={totalGoal}
                                chartOptions={barOptions}
                                data={relatedData}
                            />
                            <PieChart
                                title='Search'
                                goal={totalSearchGoal}
                                chartOptions={barOptions}
                                data={Object.fromEntries(Object.entries(relatedData || {}).filter(([key]) => key === 'referrerSearch'))}
                            />
                        </PieChartGroup>
                    </div>)}
                    {showTimeline && sumData.sparklines && <SmallTimelineBarChart
                        settings={sparklines}
                        data={sumData.sparklines}
                        style={sparklineStyle}
                    />}
                </div>
                {hasVisitPrediction && <VisitPrediction />}
            </div>
        );
    }

    getTachoColor(sum: number | undefined, tachoGoal: number | false | undefined, tachoStyle: Props['settings']['tachoStyle']) {
        if (sum === undefined || tachoGoal === undefined) {
            return undefined;
        }
        if (sum < tachoGoal && tachoStyle?.goalNotReachedColor) {
            return tachoStyle.goalNotReachedColor;
        }
        if (sum >= tachoGoal && tachoStyle?.goalReachedColor) {
            return tachoStyle.goalReachedColor;
        }
    }

    getTotalGoals() {
        let totalGoal, totalSearchGoal;

        const { filters } = this.props;
        const { config } = this.state;
        const departments = filters.departments || {};
        const filteredEntries = Object.entries(departments);
        const hasInvertedFilter = filteredEntries.find(([, value]) => value === -1);
        const hasFilter = Object.entries(departments).find(([, value]) => value === 1);

        if (!hasFilter && !hasInvertedFilter) {
            totalGoal = config?.goals.total;
            totalSearchGoal = config?.searchGoals.total;
        } else if (hasFilter) {
            const entries = filteredEntries
                .filter(([, value]) => value === 1);

            totalGoal = entries
                .reduce((total, [key]) => total + (config?.goals[key as keyof Goals] || 0), 0);

            totalSearchGoal = entries
                .reduce((total, [key]) => total + (config?.searchGoals[key as keyof Goals] || 0), 0);
        } else if (hasInvertedFilter && !hasFilter) {
            const entries = filteredEntries
                .filter(([, value]) => value === -1);
            totalGoal = entries
                .reduce((total, [key]) => total - (config?.goals[key as keyof Goals] || 0), config?.goals.total || 0);

            totalSearchGoal = entries
                .reduce((total, [key]) => total - (config?.searchGoals[key as keyof Goals] || 0), config?.searchGoals.total || 0);
        }

        return { totalGoal, totalSearchGoal };
    }
}

// withBreakpoint should wrap withStyles to allow dynamic styles based on breakpoint
export const SumSection = withBreakpoint<WithBreakpoint & Props>('sm')(
    withStyles(styles)(SumSectionComponent)
);
