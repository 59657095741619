import React from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '../Auth';
import { TabBar } from './TabBar';
import { TabData } from './TabData';
import { RouteManager, TenantManager } from '../../helpers/Route';

class ArticleTabBarComponent extends React.Component {

    render() {
        return (
            <TabBar
                tabs={this.getAuthorizedTabs()}
                targetPath={'/articlestream'}
            />
        );
    }

    getAuthorizedTabs() {
        const tabs = [];
        const appElement = RouteManager.getAppElement(this.props.app);
        const tenantElement = RouteManager.getTenantElement(appElement, this.props.tenant);
        const tenant = TenantManager.getTenantByRole(tenantElement.role);
        tabs.push(new TabData(tenant.label, tenant.path));
        for (const subElement of tenantElement.subElements ?? []) {
            tabs.push(new TabData(subElement.label, `${tenant.path}/${subElement.path}`));
        }
        return tabs;
    }
}

ArticleTabBarComponent.propTypes = {
    app: PropTypes.string.isRequired,
    tenant: PropTypes.string.isRequired
};

export const ArticleTabBar = withAuth(ArticleTabBarComponent);
