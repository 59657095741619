import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { StreamDivider } from './StreamDivider';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 205
    }
});

class StreamSortSelectComponent extends Component {

    constructor(props) {
        super(props);
        this.classes = this.props.classes;
    }

    render() {
        return (
            <StreamDivider
                time={this.props.time}
                classes={{
                    root: this.classes.root
                }}
            >
                <form className={this.classes.form}>
                    <FormControl className={this.classes.formControl}>
                        <InputLabel htmlFor='sort-order'>Sortierung</InputLabel>
                        <Select
                            value={this.props.selectedValue}
                            onChange={this.props.onSortUpdate}
                            inputProps={{
                                id: 'sort-order'
                            }}
                        >
                            {this.getMenuItems()}
                        </Select>
                    </FormControl>
                </form>
            </StreamDivider>
        );
    }

    getMenuItems() {
        return this.props.options.map(option => {
            return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>;
        });
    }
}

StreamSortSelectComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
    time: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]).isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    onSortUpdate: PropTypes.func.isRequired
};

export const StreamSortSelect = withStyles(styles)(StreamSortSelectComponent);
