import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import { CardMedia, Tooltip } from '@material-ui/core';
import ImageNotFound from './image-not-found.png';
import type { DataResults } from '../../helpers/Query';
import { Article, fixUrl, shouldDisplayReadThroughRate } from '../../../common/helpers/Utils';
import type { ArticleRawData } from '../../../common/helpers/Utils/Article';
import { config } from '../../../../config';
import { RTRDisplay } from '../common/rtrDisplay';
import { firestoreStores } from '../../../common/helpers/Firestore';

const styles = () => createStyles({
    card: {
        display: 'flex'
    },
    cardRight: {
        flex: 1,
        padding: '2px 2px 2px 40px',
        position: 'relative'
    },
    cardLeft: {
        width: '260px',
        objectFit: 'cover',
        position: 'relative'
    },
    kickerImage: {
        borderRadius: '10px',
        height: '170px'
    },
    kickerText: {
        fontSize: '12px',
        marginBottom: '5px',
        marginTop: '25px'
    },
    title: {
        fontSize: '20px',
        marginTop: 0
    },
    publication: {
        marginBottom: 0,
        fontSize: '14px'
    },
    department: {
        marginTop: 0,
        marginBottom: 0,
        fontSize: '14px',
        textTransform: 'capitalize'
    },
    label: {
        background: 'white',
        borderRadius: '15px',
        padding: '5px',
        textAlign: 'center',
        margin: '5px',
        fontWeight: 'bold',
        fontSize: '13px'
    },
    labelWrapper: {
        position: 'absolute',
        right: '5px',
        bottom: '20px'
    },
    rtr: {
        display: 'flex',
        position: 'absolute',
        bottom: '0',
        gap: '8px',
        alignItems: 'center'
    }
});

type Props = WithStyles<typeof styles> & {
    metaData: DataResults,
    brand?: string
};

const baseSIP2UrL = 'https://sip2.prd.bild.leancms.de/sip2/v2/i/bild';
const baseBildUrl = 'https://images.bild.de';

const WatchlistMetaDataComponent: FunctionComponent<Props> = observer(({ classes, metaData, brand }) => {
    const { userStore } = firestoreStores;
    const rtr = (metaData.readThroughRate || 0) as number;

    const showRTR = shouldDisplayReadThroughRate(metaData.articleType as string);

    const formatDate = (pubDate: string) => {
        const date = new Date(pubDate);
        if (isNaN(date.getTime())) {
            return null;
        }
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric', month: 'long', day: 'numeric',
            hour12: false, hour: '2-digit', minute: '2-digit'
        };

        return `${date.toLocaleDateString('de-DE', options)} Uhr`;
    };
    const tooltip = 'Position Home';
    const cmsTeaserImage = brand === 'bild' && config.USE_BILD_IMAGE_SERVER && metaData.cmsTeaserImage
        ? (metaData.cmsTeaserImage as string).replace(baseSIP2UrL, baseBildUrl)
        : metaData.cmsTeaserImage;
    return (
        <div className={classes.card}>
            <div className={classes.cardLeft}>
                <CardMedia
                    className={classes.kickerImage}
                    image={`${cmsTeaserImage || ImageNotFound}`}
                    title={`${metaData.title || ''}`}
                />
                <div className={classes.labelWrapper}>
                    {new Article(metaData as ArticleRawData).isPremium() && <div className={classes.label}>Plus</div>}
                    {!!metaData.teaserPosition &&
                        <Tooltip title={tooltip} arrow>
                            <div className={classes.label}>{metaData.teaserPosition}</div>
                        </Tooltip>
                    }
                </div>
            </div>
            <div className={classes.cardRight}>
                <p className={classes.kickerText}>{metaData.cmsKicker}</p>
                <p className={classes.title}>
                    <strong>
                        <a
                            style={{ textDecoration: 'none', color: 'inherit' }}
                            href={fixUrl(metaData.url)}
                            rel='noopener noreferrer'
                            target='_blank'>{metaData.title}
                        </a>
                    </strong>
                </p>
                <p className={classes.publication}>
                    <strong>Publikation: </strong>{formatDate(`${metaData.cmsPublicationDate}`)}
                </p>
                <p className={classes.department}>
                    <strong>Ressort: </strong>{metaData.department}
                </p>
                {
                    userStore.getBetaStatus() &&
                    <div className={classes.rtr}>
                        <span>Read-through rate</span>
                        <RTRDisplay rtr={rtr} showRTR={showRTR} />
                    </div>
                }
            </div>
        </div>
    );
});

WatchlistMetaDataComponent.displayName = 'WatchlistMetaData';

export const WatchlistMetaData = withStyles(styles)(WatchlistMetaDataComponent);

