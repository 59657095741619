import _ from 'lodash';
import React, { useContext, useRef } from 'react';
import { observer } from 'mobx-react';
import { ThemedEcharts } from '../../../../echarts';
import { CAPTION } from '../../../common/configs/scaleCategory';
import { ColorMapping } from '../../configs';
import { FilterStoreContext } from './FilterStore';

export const ReferrerFilter = observer(() => {
    const filterStore = useContext(FilterStoreContext);
    const eventHandler = useRef({
        legendselectchanged: ({ name, selected }: { name: string, selected: { [name: string]: boolean } }) => {
            filterStore.chartReferrer[name.toUpperCase()] = selected[name];
        }
    });
    if (Object.keys(filterStore.chartReferrer).length < 2) {
        return null;
    }
    const chartReferrer = _.fromPairs(Object.entries(filterStore.chartReferrer).map(
        ([label, value]) => [label.toLowerCase().replace(/(^|\s)(\w)/g, (str) => str.toUpperCase()), value]
    ));
    return (
        <ThemedEcharts
            style={{ width: '100%', height: 60, marginTop: -10, marginBottom: 10 }}
            onEvents={eventHandler.current}
            option={{
                xAxis: {
                    show: false
                },
                yAxis: {
                    show: false
                },
                legend: {
                    selected: chartReferrer,
                    textStyle: { ...CAPTION, color: 'rgba(0, 0, 0, 0.8)' }
                },
                series: Object.entries(chartReferrer).map(([label]) => ({
                    name: label,
                    data: [],
                    type: 'line',
                    symbol: 'none',
                    itemStyle: {
                        color: (ColorMapping)[label.toUpperCase() as keyof typeof ColorMapping]
                    }
                }))
            }}
            notMerge={true}
        />
    );
});
