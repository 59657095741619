/**
 * A network scheduler that only performs success operations for the newest request.
 */
export class NetworkQueue {
    constructor() {
        this.lastRequestTime = null;
        this.lastBackgroundRequestTime = null;
    }

    /**
     * Run a task that can be overridden.
     * @param {*} request
     * @param {*} onSuccess
     * @param {*} onError
     */
    runTask(request, onSuccess, onError) {
        const requestTime = new Date();
        this.lastRequestTime = requestTime;
        request.then(data => {
            // check if it is still the newest request
            if (this.lastRequestTime === requestTime) {
                // run callback for successful operation
                onSuccess(data);
            } else {
                // ignore -> wait until newer request is ready
            }
        }).catch(error => {
            if (this.lastRequestTime === requestTime) {
                onError(error);
            }
        });
    }

    /**
     * Run a background task that will not override a standard task.
     * @param {*} request
     * @param {*} onSuccess
     * @param {*} onError
     */
    runBackgroundTask(request, onSuccess, onError) {
        const requestTime = new Date();
        this.lastBackgroundRequestTime = requestTime;
        request.then(data => {
            // check if no new standard task appeared, and if it is still the newest background request
            if (this.lastRequestTime < requestTime && this.lastBackgroundRequestTime === requestTime) {
                onSuccess(data);
            } else {
                // ignore -> wait until newer (background) task is ready
            }
        }).catch(error => {
            onError(error);
        });
    }
}
