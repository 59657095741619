import React, { ReactNode } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
    popup: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0, 0, 0, 0.6)',
        transition: 'opacity 500ms'
    },
    icon: {
        position: 'absolute',
        color: '#fff',
        top: '20px',
        right: '20px',
        transform: 'scale(1.5)'
    }
}));

export type PopupPops = {
    children: ReactNode,
    isOpen: boolean,
    showCloseIcon: boolean,
    onClose: () => void
}

export const Popup = ({ children, isOpen, onClose, showCloseIcon }: PopupPops) => {
    if (!isOpen) {
        return null;
    }
    const classes = useStyles();

    return (
        <div className={classes.popup}>
            {
                showCloseIcon &&
                <IconButton className={classes.icon} disableRipple aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            }
            {children}
        </div>
    );
};
