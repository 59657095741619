import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { PurestComponent } from '../../../../PurestComponent';
import { MetabaseFrame } from './MetabaseFrame';

const styles = () => ({
    root: {
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        '@media (max-width: 553px)': {
            flexDirection: 'column-reverse',
            alignItems: 'center',
            maxWidth: 350
        }
    },
    iframe: {
        height: 600,
        width: 800
    }
});

class DailyUUsDashboardComponent extends PurestComponent {

    constructor(props) {
        super(props);
        this.classes = this.props.classes;
    }

    render() {
        return (
            <MetabaseFrame
                title='dashboard'
                id={12}
                className={this.classes.iframe}
                type='dashboard'
                location={this.props.location}
                history={this.props.history}
            />
        );
    }
}

DailyUUsDashboardComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export const DailyUUsDashboard = withStyles(styles)(DailyUUsDashboardComponent);
