import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import bildplus from './images/bildplus.svg';

const containerStyle = {
    display: 'flex',
    alignItems: 'center'
};

const imgStyle = {
    height: 25,
    zIndex: 10
};

const conversionStyle = {
    background: '#1d1b1b',
    padding: '3px 10px 7px 12px',
    height: 14,
    color: '#fff',
    marginLeft: -10,
    borderRadius: 2
};

export const Conversion = ({ conversion, isPremium }: { conversion?: number, isPremium: boolean }) => {
    const showConversion = isPremium || (conversion !== undefined && conversion > 0);

    return showConversion ? (<Tooltip title='heutige Abos' arrow>
        <div style={containerStyle}>
            <img src={bildplus} style={imgStyle}/>
            <b style={conversionStyle}>{conversion || 0}</b>
        </div>
    </Tooltip>) : null;
};
