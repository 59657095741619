import _ from 'lodash';
import { Component } from 'react';

export class PurestComponent extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        if (!_.isEqual(nextProps, this.props)) {
            return true;
        }
        if (!_.isEqual(nextState, this.state)) {
            return true;
        }
        return false;
    }
}
