import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Button, CircularProgress } from '@material-ui/core';

const styles = () => ({
    wrapper: {
        margin: '3px',
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
});

class LoadButtonComponent extends Component {

    constructor(props) {
        super(props);
        this.classes = this.props.classes;
    }

    render() {
        if (!this.props.isVisible) {
            return (<div> </div>);
        }
        return (
            <div className={this.classes.wrapper}>
                <Button
                    variant="contained"
                    color={this.props.color}
                    type={this.props.type}
                    disabled={this.props.isLoading}
                    onClick={this.props.onClick}
                >
                    {this.props.label}
                </Button>
                {this.props.isLoading && (
                    <CircularProgress
                        size={24}
                        className={this.classes.buttonProgress}
                    />
                )}
            </div>
        );
    }
}

LoadButtonComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    color: PropTypes.string,
    type: PropTypes.string,
    isVisible: PropTypes.bool,
    onClick: PropTypes.func
};

LoadButtonComponent.defaultProps = {
    color: 'default',
    type: 'button',
    isVisible: true
};

export const LoadButton = withStyles(styles)(LoadButtonComponent);
