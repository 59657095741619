import { AuthRole } from '../../components/Auth';
import TenantBildImage from '../../images/tenant-bild.svg';
import TenantWeltImage from '../../images/tenant-welt.svg';
import TenantFitbookImage from '../../images/tenant-fitbook.png';
import TenantHomebookImage from '../../images/tenant-homebook.png';
import TenantStylebookImage from '../../images/tenant-stylebook.png';
import TenantTechbookImage from '../../images/tenant-techbook.png';
import TenantTravelbookImage from '../../images/tenant-travelbook.png';

export type Tenant = {
    label: string,
    path: string,
    image: string,
    role: AuthRole
}

export const tenants: Tenant[] = [{
    label: 'Bild',
    path: 'bild',
    image: TenantBildImage,
    role: AuthRole.BILD
}, {
    label: 'Welt',
    path: 'welt',
    image: TenantWeltImage,
    role: AuthRole.WELT
}, {
    label: 'Travelbook',
    path: 'travelbook',
    image: TenantTravelbookImage,
    role: AuthRole.TRAVELBOOK
}, {
    label: 'Techbook',
    path: 'techbook',
    image: TenantTechbookImage,
    role: AuthRole.TECHBOOK
}, {
    label: 'Fitbook',
    path: 'fitbook',
    image: TenantFitbookImage,
    role: AuthRole.FITBOOK
}, {
    label: 'Stylebook',
    path: 'stylebook',
    image: TenantStylebookImage,
    role: AuthRole.STYLEBOOK
}, {
    label: 'Homebook',
    path: 'homebook',
    image: TenantHomebookImage,
    role: AuthRole.HOMEBOOK
}];
