import React, { KeyboardEvent, useCallback, useState } from 'react';
import Input from '@material-ui/core/Input';
import { Chip, FormControl, makeStyles } from '@material-ui/core';
import { ArticleSearchValidation } from './ArticleSearchValidation';
import { trackLink } from '../../../../trackingScript';

const useStyles = makeStyles(() => ({
    formControl: {
        width: '100%',
        marginRight: '8px',
        overflowX: 'auto'
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    textfield: {
        minWidth: 125,
        marginLeft: 4
    },
    underline: {
        '&:after': {
            // fix disappearing underline on focus
            borderBottom: '2px solid black'
        }
    }
}));

type SearchResult = {
    coremediaIds: string[],
    leanIds: string[]
}

type SearchBarProps = {
    placeholder: string
    isEnabled: boolean
    onChange: (values: SearchResult) => void
    validation: ArticleSearchValidation
}

type ChipData = {
    label: string
    title: string
    coremediaId: string
}

export const SearchBar = (props: SearchBarProps) => {
    const classes = useStyles();
    const [value, setValue] = useState('');
    const [chips, setChips] = useState<ChipData[]>([]);
    const [isInputValid, setIsInputValid] = useState(true);

    const handleChange = (newChips: ChipData[]) => {
        props.onChange({
            coremediaIds: newChips.map(e => e.coremediaId),
            leanIds: newChips.map(e => e.label)
        });
    };

    const deleteChipCallback = useCallback((label: string) => {
        const newChips = chips.filter((chip: ChipData) => chip.label !== label);
        setChips(newChips);
        setValue(label);
        handleChange(newChips);
    }, [chips]);

    const trackSearchVar = (type: string, searchValue: string) => {
        trackLink({
            'app_name': 'jetstream',
            'event_name': `search-${type}-Articlestream`,
            'event_action': 'click',
            'event_label': searchValue
        });
    };

    const submit = async () => {

        if (chips.find((e: ChipData) => e.label === value)) {
            setIsInputValid(false);
            return;
        } else if (!isInputValid) {
            setIsInputValid(true);
        }

        const response = await props.validation.validate(value);
        if (response.value !== false) {
            const validatedValue = response.value;
            const coremediaId = response.coremediaId;

            trackSearchVar(response.type, response.value as string);

            const newChip: ChipData = {
                label: validatedValue as string,
                title: value,
                coremediaId: coremediaId
            };
            const newChips = [...chips, newChip];
            setChips(newChips);
            setValue('');
            handleChange(newChips);
        } else {
            setIsInputValid(false);
        }
    };

    const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            submit();
        }
    };

    const onChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = e.currentTarget.value;
        setValue(newValue);
    };

    const renderedChips = chips.map((chip: ChipData, index: number) => {
        return <Chip
            key={index}
            label={chip.label}
            onDelete={() => deleteChipCallback(chip.label)}
            title={chip.title}
        />;
    });

    return (
        <FormControl className={classes.formControl}>
            <div className={classes.row}>
                {renderedChips}
                <Input
                    value={value}
                    disabled={!props.isEnabled}
                    error={!isInputValid}
                    onKeyDown={onKeyDown}
                    onChange={onChange}
                    placeholder={props.placeholder}
                    className={classes.textfield}
                    classes={{ underline: classes.underline }}
                    fullWidth
                />
            </div>
        </FormControl>
    );
};
