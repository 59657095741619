import _ from 'lodash';
import jwt from 'jsonwebtoken';
import { config } from '../../../../config';
import { LocalStorage } from '../Storage';

export class Authenticator {
    static secureFetch(url, options = {}) {
        const token = LocalStorage.getApiToken();
        let promise;
        if (Authenticator.isTokenActive(token)) {
            if (_.isNil(options.headers)) {
                options.headers = {};
            }
            options.headers['Authorization'] = 'Bearer ' + token;
            promise = fetch(url, options)
                .then(res => {
                    if (res.status === 401) {
                        throw new Error('401');
                    }
                    return res.json();
                });
        } else {
            promise = Promise.reject(new Error('401'));
        }
        return promise.catch(error => {
            if (error.message === '401') {
                Authenticator.redirectToLogin();
            }
            throw error;
        });
    }

    static redirectToLogin() {
        // redirect to perform a login
        const relayState = encodeURIComponent(window.location.href);
        const loginUrl = `${config.api}/api/login?RelayState=${relayState}`;
        window.location.replace(loginUrl);
    }

    static getRoles() {
        const token = LocalStorage.getApiToken();
        if (!_.isNil(token)) {
            return jwt.decode(token).roles;
        }
        return undefined;
    }

    static isLoggedIn() {
        const token = LocalStorage.getApiToken();
        return Authenticator.isTokenActive(token);
    }

    static isTokenActive(token) {
        if (_.isNil(token)) {
            return false;
        }
        const exp = jwt.decode(token).exp;
        // check if no expire date is defined (e.g. for dev environment)
        if (exp === undefined) {
            return true;
        }
        return new Date(exp * 1000) > new Date();
    }
}
