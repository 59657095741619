import React from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import { colorToStyle } from './ColorScale';

interface StyleProps {
    height: number;
    top: number;
    background: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
    overlay: {
        position: 'absolute',
        left: 0,
        width: '100%',
        top: ({ top }) => top,
        height: ({ height }) => height,
        background: ({ background }) => background
    }
});

type Props = {
    svgWidth: number,
    svgHeight: number,
    top: number,
    height: number,
    teasers: any,
    background: string
};

function MinimapOverlayComponent(props: Props) {
    const { teasers = [], top, height, svgWidth, svgHeight, background } = props;
    const viewBox = `0 0 ${svgWidth} ${svgHeight}`;
    const classes = useStyles({ top, height, background });
    return (
        <svg className={classes.overlay} xmlns='https://www.w3.org/2000/svg' viewBox={viewBox}>
            { teasers && !!teasers.length && teasers.map((teaser: any, index: number) => {
                const { rect, color } = teaser;
                return (
                    <rect
                        key={`rect-${index}`}
                        x={rect.x}
                        y={rect.y}
                        width={rect.width}
                        height={rect.height}
                        fill={colorToStyle(color)}
                    />
                );
            })}
        </svg>
    );
}

export const MinimapOverlay = MinimapOverlayComponent;
