import _ from 'lodash';
import { config } from '../../../../config';
import { ArticleStreamApi } from '../../../stream/components/Article/ArticleStreamApi';
import { Authenticator } from '../../components/Auth';

/* implements ArticleExistsNetworkEndpoint */
export class Network {
    constructor(location, history) {
        this.location = location;
        this.history = history;
    }

    async loadArticleData(baseDate, offset, search, filter, site, limit, sort) {
        const api = new ArticleStreamApi(this, config.api);
        const result = await api.requestArticleData(baseDate, offset, search, filter, site, limit, sort);
        return result.items;
    }

    /**
     * Searching article references by url, coremediaId or leanIds
     * Request against hud-backend
     * @param tenant tenant
     * @param searchValues array of pageIds
     * @returns { articlesFound: [{
                searchValue: string,
                leanId: string,
                legacyId: string,
                articlePageId: string
            }],
            articlesNotFound: [string]
        }
     */
    async articlesExist(tenant, searchValues) {
        const api = config.api + '/articles/id/search';
        const options = Network.getPostRequest(JSON.stringify({ tenant: tenant, searchValues: searchValues }));
        return Authenticator.secureFetch(api, options);
    }

    async createExactagUrl(url, pageId, channelId) {
        const api = config.api + '/exactag/url';
        const options = Network.getPostRequest(JSON.stringify({
            url, pageId, channelId
        }));
        const result = await Authenticator.secureFetch(api, options);
        return result.url;
    }

    async shortenUrl(url, domain) {
        const api = config.api + '/exactag/shorten';
        const options = Network.getPostRequest(
            JSON.stringify({ url, domain })
        );
        const result = await Authenticator.secureFetch(api, options);
        return result.shortUrl;
    }

    static getPostRequest(jsonString) {
        return {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: jsonString
        };
    }

    secureFetch(url, options) {
        return new Promise((resolve) => {
            Authenticator.secureFetch(url, options)
                .then(res => {
                    resolve(res);
                });
        });
    }

    _getArticleIds(articleData) {
        return _.map(articleData, article => article.cmsId);
    }

    _assignPageViews(articleData, pageViews) {
        return _.forEach(articleData, function(articleData) {
            const cmsId = articleData.cmsId;
            const articlePageViews = pageViews[cmsId];
            if (!_.isNil(articlePageViews)) {
                _.assign(articleData, articlePageViews);
            }
        });
    }
}
