import React, { FunctionComponent, MouseEvent } from 'react';
import { withRouter, NavLink, matchPath, RouteComponentProps } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import {
    createStyles,
    WithStyles,
    withStyles,
    Menu,
    MenuItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Paper,
    IconButton
} from '@material-ui/core';
import { AuthRole } from '../Auth';
import { RouteManager, TenantManager, AppPath } from '../../helpers/Route';

const styles = (theme: Theme) => createStyles({
    iconButton: {
        height: theme.mixins.toolbar.minHeight,
        width: theme.mixins.toolbar.minHeight,
        // fix firefox vertical alignment
        padding: 0
    },
    menuItem: {
        minWidth: 175
    },
    listItemAvatar: {
        minWidth: 'auto',
        marginRight: theme.spacing(2)
    },
    avatar: {
        width: 30,
        height: 30,
        minWidth: 'auto'
    }
});

type Props = WithStyles<typeof styles> & RouteComponentProps & {
    userRoles?: AuthRole[],
    activeTenant: string
};

type ImageMatch = {
    app: string,
    tenant: string
}

type TargetMatch = {
    app: string,
    tenant?: string,
    rest?: string
}

const TenantSwitcherComponent: FunctionComponent<Props> = observer(({ classes, location }) => {
    const [anchorEl, setAnchorEl] = React.useState<Element|null>(null);

    const handleMenuOpen = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const tenants = TenantManager.getTenants();

    const getCurrentImage = () => {
        const currentPath = location.pathname;
        const match = matchPath<ImageMatch>(currentPath, {
            path: '/:app/:tenant'
        });

        if (match === null) {
            return undefined;
        }
        const tenantPath = match.params.tenant;
        const setting = TenantManager.getTenantByPath(tenantPath);
        return setting?.image;
    };

    const getTargetUrl = (tenant: string) => {
        const currentPath = location.pathname;
        const match = matchPath<TargetMatch>(currentPath, {
            path: '/:app/:tenant?/:rest*'
        });

        if (match === null) {
            // should never happen (there is always an app)
            return `/${AppPath.JETSTREAM}`;
        }

        const app = match.params.app;
        const currentTenant = match.params.tenant;
        const previousRest = match.params.rest;

        // validate authorization of path
        const appElement = RouteManager.getAppElement(app);
        if (!appElement || !RouteManager.getTenantElement(appElement, tenant)) {
            // redirect to default app with tenant
            return `/${AppPath.JETSTREAM}/${tenant}`;
        }

        let path = `/${app}`;
        if (currentTenant !== undefined) {
            // replace with new tenant
            path += `/${tenant}`;
        }
        if (previousRest) {
            // append rest
            path += `/${previousRest}`;
        }
        return path;
    };

    return (
        <div>
            <IconButton className={classes.iconButton} onClick={handleMenuOpen}>
                <Avatar src={getCurrentImage()}></Avatar>
            </IconButton>
            <Paper>
                <Menu
                    onClose={handleMenuClose}
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    open={anchorEl !== null && tenants.length > 1}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                >
                    {tenants
                        .map(tenant => (
                            <MenuItem
                                key={tenant.path}
                                component={NavLink}
                                to={getTargetUrl(tenant.path)}
                                onClick={handleMenuClose}
                                className={classes.menuItem}
                            >
                                <ListItemAvatar className={classes.listItemAvatar}>
                                    <Avatar
                                        src={tenant.image}
                                        className={classes.avatar}
                                    />
                                </ListItemAvatar>
                                <ListItemText>
                                    {tenant.label}
                                </ListItemText>
                            </MenuItem>
                        ))}
                </Menu>
            </Paper>
        </div>
    );
});

export const TenantSwitcher = withStyles(styles)(
    withRouter(TenantSwitcherComponent)
);
