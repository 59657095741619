import React from 'react';
import { matchPath, Redirect } from 'react-router-dom';
import { AuthRole } from '../../components/Auth';
import { AppPath } from './AppPath';
import { TenantManager } from './TenantManager';
import { routes } from './routes';
import type { AppElement, TenantElement, Element } from './routes';

export type RouteData = {
    app: string,
    tenant?: string,
    rest?: string
}

export class RouteManager {

    static getApps(location: Location) {
        const routeData = this.getRouteData(location);
        const tenantPath = routeData?.tenant;
        return routes.filter(app => this.getTenantElement(app, tenantPath));
    }

    static getRouteData(location: Location) {
        const match = matchPath<RouteData>(location.pathname, {
            path: '/:app/:tenant?/:rest*'
        });
        return match?.params;
    }

    static getRedirect(location: Location, userRoles?: AuthRole[]) {
        if (!userRoles || userRoles.length === 0) {
            // redirect unauthorized user
            return <Redirect to="/error/403" />;
        }
        const routeData = this.getRouteData(location);
        if (routeData === undefined) {
            // redirect to default app (invalid url)
            return this.getDefaultAppRedirect();
        }
        const { app, tenant, rest } = routeData;
        const appElement = this.getAppElement(app);
        if (appElement === undefined) {
            // redirect to default app (unknown app)
            return this.getDefaultAppRedirect();
        }
        const tenantElement = this.getTenantElement(appElement, tenant);
        if (tenantElement === undefined) {
            // redirect to default tenant (unknown tenant)
            return this.getDefaultTenantRedirect(appElement);
        }
        if (rest === undefined) {
            if (tenantElement.shouldRedirect) {
                // redirect to default rest (should redirect but unknown rest)
                return this.getDefaultRestRedirect(appElement, tenantElement);
            } else {
                // valid (should not redirect, no rest needing verification)
                return undefined;
            }
        }
        return this.getValidatedRestRedirect(appElement, tenantElement, rest);
    }

    static getAppTitle(appPath: string) {
        return RouteManager.getAppElement(appPath)?.title || 'Stream';
    }

    static getFavIcon(location: Location) {
        const routeData = this.getRouteData(location);
        if (routeData === undefined) {
            return '';
        }
        const appElement = this.getAppElement(routeData.app);
        if (!appElement) {
            return '';
        }
        return appElement.favIcon;
    }

    static getTabTitle(location: Location): string {
        const routeData = this.getRouteData(location);
        if (routeData === undefined) {
            return '';
        }
        const appElement = this.getAppElement(routeData.app);
        if (appElement === undefined) {
            throw new Error(`App ${routeData.app} not found.`);
        }
        const tenantElement = this.getTenantElement(appElement, routeData.tenant);
        if (tenantElement === undefined) {
            throw new Error(`Tenant ${routeData.tenant} not found.`);
        }
        let baseTitle = appElement.title;
        if (tenantElement.label) {
            baseTitle = `${tenantElement.label} - ${baseTitle}`;
        }
        if (!tenantElement.subElements || !routeData.rest) {
            return baseTitle;
        }
        const restComponents = this.getPathComponents(routeData.rest);
        const nestedTitle = this.getNestedTitle(tenantElement.subElements, restComponents);
        return `${nestedTitle}${baseTitle}`;
    }

    static getAppElement(appPath: string) {
        return routes.find(appElement => appElement.path === appPath);
    }

    static getTenantElement(appElement: AppElement, tenantPath?: string) {
        if (tenantPath === undefined) {
            return undefined;
        }
        // get tenant
        const tenant = TenantManager.getTenantByPath(tenantPath);
        if (tenant === undefined) {
            return undefined;
        }
        // get tenant element for tenant
        return appElement.subElements.find(tenantElement => {
            return tenantElement.role === tenant.role;
        });
    }

    private static getDefaultAppRedirect() {
        return <Redirect to={`/${AppPath.JETSTREAM}`} />;
    }

    private static getDefaultTenantRedirect(appElement: AppElement) {
        if (appElement.subElements.length === 0) {
            throw new Error('Missing tenants for app.');
        }
        // get first authorized tenant
        for (const tenantElement of appElement.subElements) {
            const tenant = TenantManager.getTenantByRole(tenantElement.role);
            if (tenant !== undefined) {
                // redirect to first available tenant of app (authorized)
                return <Redirect to={`/${appElement.path}/${tenant.path}`} />;
            }
        }
        // redirect to default app (missing allowed tenant for given app)
        return this.getDefaultAppRedirect();
    }

    private static getDefaultRestRedirect(appElement: AppElement, tenantElement: TenantElement) {
        const tenant = TenantManager.getTenantByRole(tenantElement.role);
        if (tenant === undefined) {
            // should never happen because of previous checks
            throw new Error('Missing tenant for role.');
        }
        const subElement = tenantElement.subElements?.[0];
        if (subElement === undefined) {
            throw new Error('Missing subElement for tenant redirect.');
        }
        const restPath = this.getDefaultRestPath(subElement, '');
        return <Redirect to={`/${appElement.path}/${tenant.path}${restPath}`} />;
    }

    private static getDefaultRestPath(element: Element, path: string): string {
        path += `/${element.path}`;
        const subElement = element.subElements?.[0];
        if (subElement === undefined) {
            return path;
        }
        return this.getDefaultRestPath(subElement, path);
    }

    private static getValidatedRestRedirect(appElement: AppElement, tenantElement: TenantElement, rest: string) {
        const restComponents = this.getPathComponents(rest);
        const validatedRestPath = this.validateRestPath(tenantElement, restComponents, '');
        if (validatedRestPath === `/${rest}`) {
            // validation successful -> no redirect
            return undefined;
        }
        const tenant = TenantManager.getTenantByRole(tenantElement.role);
        if (tenant === undefined) {
            // should never happen because of previous checks
            throw new Error('Missing tenant for role.');
        }
        // redirect to corrected path
        return <Redirect to={`/${appElement.path}/${tenant.path}${validatedRestPath}`} />;
    }

    private static getPathComponents(path: string) {
        return path.split('/');
    }

    private static validateRestPath(parent: TenantElement | Element, restComponents: string[], validatedRestPath: string): string {
        const [currentRestComponent, ...otherRestComponents] = restComponents;
        if (currentRestComponent === undefined && !parent.shouldRedirect) {
            return validatedRestPath;
        }
        const children = parent.subElements;
        if (children === undefined || children.length === 0) {
            // no more sub elements registered
            return validatedRestPath;
        }
        let currentChild;
        if (currentRestComponent !== undefined) {
            currentChild = children.find(child => child.path === currentRestComponent);
        }
        if (currentChild === undefined) {
            currentChild = children[0];
        }
        validatedRestPath += `/${currentChild.path}`;
        return this.validateRestPath(currentChild, otherRestComponents, validatedRestPath);
    }

    private static getNestedTitle(subElements: Element[], childPathComponents: string[]): string {
        const path = childPathComponents.shift();
        const element = subElements.find(el => el.path === path);
        if (!element) {
            return '';
        }
        const baseTitle = `${element.label || this.formatPath(element.path)} - `;
        if (childPathComponents.length > 0 && element.subElements) {
            return `${this.getNestedTitle(element.subElements, childPathComponents)}${baseTitle}`;
        }
        return baseTitle;
    }

    private static formatPath(path: string): string {
        return path
            .replace(/[A-Z]/g, (matchFound: string) => ` ${matchFound}`)
            .replace(/[a-z]/, (matchFound: string) => matchFound.toUpperCase());
    }
}
