import type { CSSProperties } from '@material-ui/styles';
import { HEADLINE_3, SUBTITLE_1, SUBTITLE_2, CAPTION, BODY_2 } from './scaleCategory';

// override theme type
declare module '@material-ui/core/styles/createMixins' {
    interface Mixins {
        tabBar: CSSProperties
    }
}

// override breakpoint type to include custom breakpoint
declare module '@material-ui/core/styles/createBreakpoints' {
    interface BreakpointOverrides {
        sidebarTransform: true
    }
}

export const themeConfig = {
    overrides: {
        MuiTab: {
            root: { ...HEADLINE_3, color: 'rgba(0, 0, 0, 0.6) !important' }
        },
        MuiTableHead: {
            root: {
                '& .MuiTableCell-root': { ...HEADLINE_3, color: 'rgba(0, 0, 0, 0.8)' }
            }
        },
        MuiTableBody: {
            root: {
                '& .MuiTableCell-root': BODY_2
            }
        },
        MuiButtonBase: {
            root: {
                '& .MuiChip-label': { ...CAPTION, color: 'rgba(0, 0, 0, 0.6)' }
            }
        },
        MuiTypography: {
            subtitle2: SUBTITLE_2
        },
        MuiSelect: {
            root: SUBTITLE_1
        },
        MuiMenuItem: {
            root: SUBTITLE_1
        },
        MuiFormControlLabel: {
            root: {
                '& .MuiFormControlLabel-label': SUBTITLE_2
            }
        },
        MuiTooltip: {
            tooltip: { ...CAPTION, color: '#FFFFFF' }
        },
        MuiPaper: {
            root: BODY_2
        }
    },
    palette: {
        primary: {
            main: '#8b1EE0'
        },
        secondary: {
            main: '#1E94BF'
        }
    },
    typography: {
        useNextVariants: true,
        fontFamily: 'MontAS, Roboto, sans-serif'
    },
    stream: {
        page: {
            background: '#fafafa'
        }
    },
    mixins: {
        tabBar: {
            marginTop: 48
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            sidebarTransform: 900,
            md: 960,
            lg: 1280,
            xl: 1920
        }
    }
};
