export enum JetColumnId {
    id = 'id',
    leanId = 'leanId',
    legacyId = 'legacyId',
    documentId = 'documentId',
    mediaId = 'mediaId',
    title = 'title',
    articleKicker = 'articleKicker',
    articleType = 'articleType',
    url = 'url',
    teaserPosition = 'teaserPosition',
    pisTotal = 'pisTotal',
    pisMobile = 'pisMobile',
    pisDesktop = 'pisDesktop',
    pisWeb = 'pisWeb',
    pisApps = 'pisApps',
    pisPremium = 'pisPremium',
    pisConversionPage = 'pisConversionPage',
    pisHomeTotal = 'pisHomeTotal',
    referrerSearch = 'referrerSearch',
    referrerSearchNoNewsNoDiscovery = 'refererSearchNoNewsNoDiscovery',
    referrerSearchRate = 'referrerSearchRate',
    referrerSearchWithNews = 'referrerSearchWithNews',
    referrerSearchWithDiscovery = 'referrerSearchWithDiscovery',
    referrerSearchWithoutDiscovery = 'referrerSearchWithoutDiscovery',
    referrerSocial = 'referrerSocial',
    referrerMisc = 'referrerMisc',
    homeReferralMobile = 'homeReferralMobile',
    homeReferralDesktop = 'homeReferralDesktop',
    homeReferralWeb = 'homeReferralWeb',
    homeReferralApp = 'homeReferralApp',
    pisNoReferrer = 'pisNoReferrer',
    mediaStarted = 'mediaStarted',
    mediaCompleted = 'mediaCompleted',
    mediaCompletionRate = 'mediaCompletionRate',
    mediaWatchtimeAvg = 'mediaWatchtimeAvg',
    conversion = 'conversion',
    conversionApp = 'conversionApp',
    conversionWeb = 'conversionWeb',
    conversionMobile = 'conversionMobile',
    conversionDesktop = 'conversionDesktop',
    conversionSearch = 'conversionSearch',
    conversionSocial = 'conversionSocial',
    conversionMisc = 'conversionMisc',
    sparkline = 'sparkline',
    pushNotification = 'pushNotification',
    mediaHeadline = 'mediaHeadline',
    department = 'department',
    isPremium = 'isPremium',
    wasPremium = 'wasPremium',
    isVideo = 'isVideo',
    cmsIsPremium = 'cmsIsPremium',
    cmsType = 'cmsType',
    cmsKicker = 'cmsKicker',
    cmsPublicationDate = 'cmsPublicationDate',
    cmsTeaserImage = 'cmsTeaserImage',
    pisHomeMobile = 'pisHomeMobile',
    pisHomeDesktop = 'pisHomeDesktop',
    ctrHomeReferralMobile = 'ctrHomeReferralMobile',
    ctrHomeReferralDesktop = 'ctrHomeReferralDesktop',
    ctrHomeReferralApp = 'ctrHomeReferralApp',
    ctrHomeReferralWeb = 'ctrHomeReferralWeb',
    createdAt = 'createdAt',
    readThroughRate = 'readThroughRate'
}
