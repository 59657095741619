import brotliDecompress from 'brotli/decompress';
import base64js from 'base64-js';

export const decompress = (str: unknown) => {
    if (typeof str === 'string') {
        const uInt8Array = base64js.toByteArray(str);
        const decompUIntArray = brotliDecompress(uInt8Array as Buffer);
        const json = String.fromCharCode.apply(null, decompUIntArray as unknown as number[]);
        return JSON.parse(json);
    }
    return str;
};

export const decompressQueryResultInplace = (data: unknown): void => {
    if (data && typeof data === 'object') {
        Object.entries(data).forEach(([key, val]) => {
            if (key.endsWith('Sparkline') || key === 'sparklineTimes') {
                (data as Record<string, unknown>)[key] = decompress(val);
            } else {
                decompressQueryResultInplace(val);
            }
        });
    }
};
