import { ArticleExistsNetworkEndpoint, ArticleSearch } from '../../../jetstream/components/Watchlist/ArticleSearch';
import { DataValidator } from '../../helpers/Validator';

export class ArticleSearchValidation {

    private search: ArticleSearch;

    constructor(endpoint: ArticleExistsNetworkEndpoint) {
        this.search = new ArticleSearch(endpoint);
    }

    private async getLeanAndLegacyId(search: string) {
        const response = await this.search.search('bild', search);
        if (response) {
            return response.articlesFound.find((e: any) => {
                return e.searchValue === search;
            });
        }
        return null;
    }

    async validate(value: string): Promise<{ type: string, value: string | boolean, coremediaId: string }> {
        const leanId = value.length === 24 ? value : DataValidator.getLeanIdFromUrl(value);
        if (leanId) {
            const searchResult = await this.getLeanAndLegacyId(leanId);
            if (searchResult) {
                return {
                    type: 'leanId',
                    value: searchResult.leanId,
                    coremediaId: searchResult.legacyId
                };
            } else {
                return { type: 'invalid', value: false, coremediaId: '' };
            }
        }

        const cmsId = this.isCoremediaId(value) ? value : DataValidator.getCmsIdFromUrl(value);

        if (this.isCoremediaId(cmsId)) {
            const response = await this.search.search('bild', cmsId);
            const result = response.articlesFound.filter(e => e.searchValue === cmsId);

            if (result?.[0]?.leanId && result?.[0]?.leanId !== '-1') {
                return {
                    type: 'leanId',
                    value: result[0].leanId,
                    coremediaId: result[0].legacyId
                };
            } else {
                return {
                    type: 'leanId',
                    value: result[0].leanId,
                    coremediaId: cmsId
                };
            }
        }

        return { type: 'invalid', value: false, coremediaId: '' };
    }

    private isCoremediaId(value: string): boolean {
        return value.length >= 8
            && value !== '-1'
            && value === parseInt(value, 10).toString();
    }
}
