/**
 * Human readable departments text.
 */

import { BRANDS } from './brands';

const WELT_DEPARTMENTS: { [key: string]: string } = {
    'geschichte': 'Geschichte',
    'debatte': 'Debatte',
    'politik_inland': 'Politik Inland',
    'politik_ausland': 'Politik Ausland',
    'wirtschaft_finanzen': 'Wirtschaft & Finanzen',
    'regionales': 'Regionales',
    'vermischtes': 'Vermischtes',
    'newsticker': 'Newsticker',
    'sport': 'Sport',
    'kultur': 'Kultur',
    'iconist': 'Lifestyle',
    'iconmagazine': 'Icon',
    'gesundheit_wissenschaft': 'Gesundheit & Wissenschaft',
    'reise': 'Reise',
    'kmpkt': 'Kmpkt',
    'motor': 'Motor',
    'mediathek': 'Mediathek',
    'videos': 'Videos',
    'satire': 'Satire',
    'food': 'Food',
    'newsteam': 'N&G',
    'investigativ': 'Investigativ'
};

const BILD_DEPARTMENTS: { [key: string]: string } = {
    'news': 'News & Regio',
    'politik': 'Politik',
    'unterhaltung': 'Unterhaltung',
    'sport': 'Sport',
    'leben_gesellschaft': 'Leben & Gesellschaft'
};

export const DEPARTMENTS: { [key: string]: { [key: string]: string } } = {
    [BRANDS.BILD]: BILD_DEPARTMENTS,
    [BRANDS.WELT]: WELT_DEPARTMENTS
};

