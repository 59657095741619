import { config } from '../../../../config';

const API_TOKEN = 'api_token';
const FIRESTORE_TOKEN = 'firestore_token';
const LAST_RELOGIN_DATE = 'last_relogin';
const WATCHLIST_REFERRERS = 'watchlist_referrers_';
const RELOGIN_TIMEOUT_MS = 1000 * 60;
const BANNER_DATE = 'bannerDate';

export class LocalStorage {
    static getApiToken() {
        if (config.jwtTestToken) {
            // local test token (used for dev environment)
            return config.jwtTestToken;
        }
        return localStorage.getItem(API_TOKEN);
    }

    static setApiToken(token) {
        localStorage.setItem(API_TOKEN, token);
    }

    static getBannerDate() {
        return localStorage.getItem(BANNER_DATE);
    }

    static setBannerDate(date) {
        localStorage.setItem(BANNER_DATE, date);
    }

    static getFirestoreToken() {
        return localStorage.getItem(FIRESTORE_TOKEN);
    }

    static setFirestoreToken(token) {
        localStorage.setItem(FIRESTORE_TOKEN, token);
    }

    static getWatchlistReferrers(watchlistKey) {
        return JSON.parse(localStorage.getItem(`${WATCHLIST_REFERRERS}${watchlistKey}`)) || {};
    }

    static setWatchlistReferrers(watchlistReferrers, watchlistKey) {
        localStorage.setItem(`${WATCHLIST_REFERRERS}${watchlistKey}`, JSON.stringify(watchlistReferrers));
    }

    static shouldTryRelogin() {
        const lastRelogin = new Date(localStorage.getItem(LAST_RELOGIN_DATE));
        const result = lastRelogin.getTime() + RELOGIN_TIMEOUT_MS < new Date().getTime();
        if (result) {
            localStorage.setItem(LAST_RELOGIN_DATE, new Date().toISOString());
        }
        return result;
    }
}
