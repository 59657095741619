import { observable, action, makeObservable } from 'mobx';
import { Timestamp, setDoc, getDoc, onSnapshot } from 'firebase/firestore';
import { config } from '../../../../config';
import type { DocumentReference } from 'firebase/firestore';
import type { FirestoreStore } from './FirestoreStore';

export type SurveyData = {
    lastDisplayedDate: Date,
    displayedTimes: number,
    isSubmitted: boolean
}

export type ViewName = 'jetstream' | 'articlestream' | 'heatstream'

export type UserConfig = Partial<{
    watchlists: {
        [key: string]: WatchlistItemData[]
    },
    jetstreamSurveyData: SurveyData,
    heatstreamSurveyData: SurveyData,
    articlestreamSurveyData: SurveyData,
    is_beta: boolean
}>

export type WatchlistItemData = {
    id: string,
    timestamp: Timestamp,
    locked?: boolean
}
const SURVEY_KEY = new Map([
    ['jetstream', 'jetstreamSurveyData'],
    ['articlestream', 'articlestreamSurveyData'],
    ['heatstream', 'heatstreamSurveyData']
]);

export class UserStore {
    private isLoadedResolve?: () => void;
    private readonly firestore: FirestoreStore;
    isLoaded = new Promise((resolve: any) => {
        this.isLoadedResolve = resolve;
        return this.isLoadedResolve;
    });
    userConfig?: UserConfig;
    isLoading = true;

    constructor(firestore: FirestoreStore) {
        this.firestore = firestore;
        makeObservable(this, {
            userConfig: observable,
            isLoading: observable,
            addPageIdToWatchlist: action,
            refreshPageIdInWatchlist: action,
            removePageIdFromWatchlist: action,
            lockPageIdInWatchlist: action,
            unlockPageIdInWatchlist: action
        });

        this.initSync();
    }

    async initSync() {
        const userConfigRef = await this.firestore.getUserConfigRef();
        onSnapshot(userConfigRef, (snapshot) => {
            this.userConfig = snapshot.data() as any;
            /*
               save default survey date for each user
               this should happen only once for each user
            */
            if (!this.userConfig?.['jetstreamSurveyData']) {
                this.addDefaultUserSurveyData('jetstreamSurveyData');
            }
            if (!this.userConfig?.['articlestreamSurveyData']) {
                this.addDefaultUserSurveyData('articlestreamSurveyData');
            }
            if (!this.userConfig?.['heatstreamSurveyData']) {
                this.addDefaultUserSurveyData('heatstreamSurveyData');
            }
            this.isLoadedResolve?.();
            this.isLoading = false;
        });
    }

    getWatchlist(watchlistKey: string) {
        return this.userConfig?.watchlists?.[watchlistKey]?.filter(({ timestamp, locked }) => {
            return locked || (timestamp && (new Date().getTime() - config.watchlistExpireMs < timestamp.toMillis()));
        }) ?? (this.isLoading ? null : []);
    }

    getWatchlistIds(watchlistKey: string) {
        return this.getWatchlist(watchlistKey)?.map(({ id }) => id);
    }

    async addPageIdsToWatchlist(watchlistKey: string, pageIds: string[]) {
        const oldWatchlist = this.getWatchlist(watchlistKey) ?? [];
        const newEntries = pageIds.map(id => ({
            id: id,
            timestamp: Timestamp.now()
        }));
        await this.setWatchlist(watchlistKey, [
            ...newEntries,
            ...oldWatchlist.filter(({ id }) => !pageIds.includes(id))
        ]);
    }

    async addPageIdToWatchlist(watchlistKey: string, pageId: string) {
        const oldWatchlist = this.getWatchlist(watchlistKey) ?? [];
        await this.setWatchlist(watchlistKey, [
            {
                id: pageId,
                timestamp: Timestamp.now()
            },
            ...oldWatchlist.filter(({ id }) => id !== pageId)
        ]);
    }

    async refreshPageIdInWatchlist(watchlistKey: string, pageId: string) {
        const watchlist = this.getWatchlist(watchlistKey) ?? [];
        const watchlistItem = watchlist.find(({ id }) => id === pageId);
        if (!watchlistItem || watchlistItem.locked) {
            return;
        }
        watchlistItem.timestamp = Timestamp.now();
        await this.setWatchlist(watchlistKey, watchlist);
    }

    async clearWatchlist(watchlistKey: string) {
        const watchlist = this.getWatchlist(watchlistKey) ?? [];
        const lockedWatchlistItem: WatchlistItemData[] = watchlist.filter(item => item.locked);
        await this.setWatchlist(watchlistKey, lockedWatchlistItem);
    }

    async removePageIdFromWatchlist(watchlistKey: string, pageId: string) {
        const oldWatchlist = this.getWatchlist(watchlistKey) ?? [];
        await this.setWatchlist(watchlistKey, oldWatchlist.filter(({ id }) => id !== pageId));
    }

    async lockPageIdInWatchlist(watchlistKey: string, pageId: string) {
        const watchlist = this.getWatchlist(watchlistKey) ?? [];
        const watchlistItem = watchlist.find(({ id }) => id === pageId);
        if (!watchlistItem) {
            return;
        }
        watchlistItem.locked = true;
        await this.setWatchlist(watchlistKey, watchlist);
    }

    async unlockPageIdInWatchlist(watchlistKey: string, pageId: string) {
        const watchlist = this.getWatchlist(watchlistKey) ?? [];
        const watchlistItem = watchlist.find(({ id }) => id === pageId);
        if (!watchlistItem) {
            return;
        }
        watchlistItem.locked = false;
        watchlistItem.timestamp = Timestamp.now();
        await this.setWatchlist(watchlistKey, watchlist);
    }

    async resetDisplayTimesAndSubmitStatus(viewName: ViewName) {
        const surveyKey = SURVEY_KEY.get(viewName);
        if (!surveyKey) {
            return;
        }
        const userConfigRef: DocumentReference<UserConfig> = await this.firestore.getUserConfigRef();
        setDoc(userConfigRef, {
            [surveyKey]: {
                displayedTimes: 0,
                isSubmitted: false
            }
        }, { merge: true });
    }

    async submitSurvey(viewName: ViewName) {
        const surveyKey = SURVEY_KEY.get(viewName);
        if (!surveyKey) {
            return;
        }
        const userConfigRef: DocumentReference<UserConfig> = await this.firestore.getUserConfigRef();
        await setDoc(userConfigRef, {
            [surveyKey]: {
                isSubmitted: true
            }
        }, { merge: true });
    }

    async syncDisplayedDateAndDisplayedTime(viewName: ViewName) {
        const surveyKey = SURVEY_KEY.get(viewName);
        if (!surveyKey) {
            return;
        }
        const userConfigRef: DocumentReference<UserConfig> = await this.firestore.getUserConfigRef();
        const oldSurveyData = await this.getUserSurveyData(viewName);
        if (!oldSurveyData) {
            return;
        }
        await setDoc(userConfigRef, {
            [surveyKey]: {
                lastDisplayedDate: new Date().toString(),
                displayedTimes: oldSurveyData.displayedTimes + 1
            }
        }, { merge: true });
    }

    async getUserSurveyData(viewName: ViewName) {
        const surveyKey = SURVEY_KEY.get(viewName);
        if (!surveyKey) {
            return undefined;
        }
        const user = await this.getUser();
        if (!user) {
            return undefined;
        }
        return user[surveyKey];
    }

    private async getUser() {
        const docRef = await this.firestore.getUserConfigRef();
        const userDoc = await getDoc(docRef);
        return userDoc.data();
    }

    private async setWatchlist(watchlistKey: string, watchlist: WatchlistItemData[]) {
        const userConfigRef: DocumentReference<UserConfig> = await this.firestore.getUserConfigRef();
        await setDoc(userConfigRef, { watchlists: { [watchlistKey]: watchlist } }, { merge: true });
    }

    private async addDefaultUserSurveyData(surveyKey: 'jetstreamSurveyData' | 'articlestreamSurveyData' | 'heatstreamSurveyData') {
        const userConfigRef: DocumentReference<UserConfig> = await this.firestore.getUserConfigRef();
        await setDoc(userConfigRef, {
            [surveyKey]: {
                lastDisplayedDate: new Date().toString(),
                displayedTimes: 0,
                isSubmitted: false
            }
        }, { merge: true });
    }

    getBetaStatus() {
        return this.userConfig?.is_beta ?? false;
    }
}
