import objectHash from 'object-hash';
import { Query } from '../../../jetstream/helpers/Query/QueryTypes';

/**
 * Represents a hash used as a reference for a document in firestore.
 */
export class FirestoreHash {

    readonly nameOfInstance = 'FirestoreHash'; // prevents conversion by identical type
    readonly hash: string;

    /**
     * Creates a FirestoreHash based on a Query
     * @param query
     * @returns Instance of FirestoreHash
     */
    static create(query: Query): FirestoreHash {
        const hash: string = FirestoreHash.createHash(query);
        return new FirestoreHash(hash);
    }

    private static createHash(query: any): string {
        return objectHash(query);
    }

    private constructor(hash: string) {
        this.hash = hash;
    }
}
