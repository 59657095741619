import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Lato, sans- serif',
        width: '328px',
        height: '162px',
        backgroundColor: '#fff',
        boxShadow: `0px 0px 2px rgba(0, 0, 0, 0.14), 
                    0px 2px 2px rgba(0, 0, 0, 0.12), 
                    0px 1px 3px rgba(0, 0, 0, 0.2);`,
        borderRadius: '4px',
        padding: '16px',
        marginBottom: '20px',
        color: 'rgba(0, 0, 0, 0.8)'
    },
    title: {
        textAlign: 'left',
        color: 'rgba(0, 0, 0, 0.8)',
        marginBottom: '16px',
        fontSize: 16,
        fontWeight: 700
    },
    stats: {
        display: 'flex',
        textAlign: 'left'
    },
    totalStats: {
        display: 'flex',
        flexDirection: 'column',
        height: 100,
        gap: 8,
        flexBasis: 90,
        fontSize: 14,
        borderRight: '1px solid #e5e5e5'
    },
    premiumStats: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        flexGrow: 1,
        paddingLeft: 16
    },
    videoStats: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        flexGrow: 1
    },
    sectionTitle: {
        fontSize: 14
    },
    count: {
        fontSize: 12
    },
    sectionValue: {
        color: 'rgba(0, 0, 0, 0.8)',
        height: '16px'
    },
    percentage: {
        fontWeight: 700,
        fontSize: 16
    }
}));


export type AufmacherStatsProps = {
    aufmacherArticlesCount: number,
    premiumArticlesCount: number,
    videoArticlesCount: number
};


export const AufmacherStats = ({ aufmacherArticlesCount, premiumArticlesCount, videoArticlesCount }: AufmacherStatsProps) => {
    const classes = useStyles();
    const videoPercentage = Math.round((videoArticlesCount / aufmacherArticlesCount) * 100);
    const premiumPercentage = Math.round((premiumArticlesCount / aufmacherArticlesCount) * 100);

    return (
        <div className={classes.container}>
            <div className={classes.title}>Inhalte</div>
            <div className={classes.stats}>
                <div className={classes.totalStats}>
                    <div>Teaser</div>
                    <div className={classes.count}>{aufmacherArticlesCount}</div>
                </div>
                <div className={classes.premiumStats}>
                    <div className={classes.sectionTitle}>Premium</div>
                    <div className={classes.count}>{premiumArticlesCount}</div>
                    <div className={classes.percentage}>{premiumPercentage}%</div>
                </div>
                <div className={classes.videoStats}>
                    <div className={classes.sectionTitle}>Video</div>
                    <div className={classes.count}>{videoArticlesCount}</div>
                    <div className={classes.percentage}>{videoPercentage}%</div>
                </div>
            </div>
        </div>
    );
};
