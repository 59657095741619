import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemedEcharts } from '../../../../echarts';

const START_ANGLE = 225;
const END_ANGLE = -45;
const TACHO_WIDTH = 40;
const getShapePointsOfPositions = ([x1, y1], [x2, y2]) => ({ x1, y1, x2, y2 });

export class SumTachoChart extends Component {

    render() {
        return (
            <div style={{ marginBottom: -200 }}>
                <ThemedEcharts
                    option={this.getChartOption()}
                    notMerge={true}
                    style={{
                        ...this.props.style,
                        height: this.height,
                        width: this.width
                    }}
                    opts={{ renderer: 'svg' }}
                />
            </div>
        );
    }

    getRadiusPosition(percent, radius) {
        const angle = 2 * Math.PI * percent;
        const finalAngle = (2 * Math.PI * (360 - START_ANGLE) / 360) + angle * ((START_ANGLE - END_ANGLE) / 360);
        return [(this.width / 2) + radius * Math.cos(finalAngle), (this.height / 2) + radius * Math.sin(finalAngle)];
    }

    getLineAndTextCoordinate() {
        const { goal, brand } = this.props;
        const weltCoordinates = {
            max: goal * 2,
            goalLinePercent: 0.5,
            goalTextPosition: [this.width / 2, this.height / 4 + 5],
            yesterdayTextVariant: 30,
            goalLine1Radius: 0.75 * this.height / 2 - TACHO_WIDTH - 10,
            goalLine2Radius: 0.75 * this.height / 2,
            yesterdayLine1Radius: 0.75 * this.height / 2 - TACHO_WIDTH,
            yesterdayLine2Radius: 0.75 * this.height / 2 + 10
        };
        const bildCoordinates = {
            max: goal,
            goalLinePercent: 1,
            goalTextPosition: [this.width - 100, this.height - 100],
            yesterdayTextVariant: -100,
            goalLine1Radius: 0.75 * this.height / 2 - TACHO_WIDTH,
            goalLine2Radius: 0.75 * this.height / 2 + 10,
            yesterdayLine1Radius: 0.75 * this.height / 2 - TACHO_WIDTH - 10,
            yesterdayLine2Radius: 0.75 * this.height / 2
        };

        return brand === 'welt' ? weltCoordinates : bildCoordinates;
    }

    getChartOption() {
        const { value = 0, goal, yesterday = 0, color = '#1989E2', fontStyle, brand } = this.props;
        const {
            max,
            goalLinePercent,
            goalTextPosition,
            yesterdayTextVariant,
            goalLine1Radius,
            goalLine2Radius,
            yesterdayLine1Radius,
            yesterdayLine2Radius
        } = this.getLineAndTextCoordinate();

        const yesterdayPercent = Math.min(yesterday / max, 1);

        return {
            graphic: {
                elements: [
                    // goal text
                    {
                        type: 'text',
                        silent: true,
                        position: goalTextPosition,
                        style: {
                            text: `${goal.toLocaleString()}${brand === 'welt' ? '\n' : ''}(Ziel)`,
                            textAlign: 'center',
                            fill: '#818181',
                            fontSize: 14,
                            lineWidth: 50,
                            ...fontStyle
                        }
                    },
                    // goal line
                    {
                        type: 'line',
                        silent: true,
                        z: 100,
                        shape: getShapePointsOfPositions(
                            this.getRadiusPosition(goalLinePercent, goalLine1Radius),
                            this.getRadiusPosition(goalLinePercent, goalLine2Radius)
                        ),
                        style: {
                            stroke: '#e5e5e5',
                            lineWidth: 5
                        }
                    },
                    // yesterday line
                    {
                        type: 'line',
                        silent: true,
                        z: 101,
                        shape: getShapePointsOfPositions(
                            this.getRadiusPosition(yesterdayPercent, yesterdayLine1Radius),
                            this.getRadiusPosition(yesterdayPercent, yesterdayLine2Radius)
                        ),
                        style: {
                            stroke: '#e5e5e5',
                            lineWidth: 5
                        }
                    },
                    // yesterday text
                    {
                        type: 'text',
                        silent: true,
                        z: 102,
                        position: this.getRadiusPosition(yesterdayPercent, 0.75 * this.height / 2 + yesterdayTextVariant),
                        style: {
                            text: `${yesterday.toLocaleString()}\n(Gestern)`,
                            textAlign: 'center',
                            textVerticalAlign: 'middle',
                            fill: '#818181',
                            fontSize: 14,
                            lineWidth: 50,
                            x: Math.sin(1.4 * Math.PI * (yesterdayPercent - 0.5)) * 23, // add padding left/right
                            y: Math.pow((yesterdayPercent - 0.5) * 2, 8) * -10, // add padding on 0 and 100%
                            ...fontStyle
                        }
                    }
                ]
            },
            series: [
                {
                    name: '',
                    type: 'gauge',
                    data: [],
                    max,
                    splitNumber: 2,
                    startAngle: START_ANGLE,
                    endAngle: END_ANGLE,
                    axisLine: {
                        lineStyle: {
                            color: [
                                [value / max, color],
                                [1, '#f0f0f0']
                            ],
                            width: TACHO_WIDTH
                        }
                    },
                    axisLabel: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    },
                    pointer: {
                        show: false
                    },
                    title: {
                        show: false
                    },
                    detail: {
                        show: false
                    }
                }
            ]
        };
    }

    get height() {
        return (this.props.style || {})['height'] || 420;
    }

    get width() {
        return (this.props.style || {})['width'] || 500;
    }
}

SumTachoChart.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    color: PropTypes.string,
    fontStyle: PropTypes.any,
    goal: PropTypes.number.isRequired,
    yesterday: PropTypes.number,
    style: PropTypes.object,
    brand: PropTypes.string
};
