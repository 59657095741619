import React, { Component } from 'react';
import { SnackbarContent, Button, createStyles, withStyles, WithStyles } from '@material-ui/core';

const styles = () => createStyles({
    container: {
        color: 'rgba(0,0,0,.6)',
        width: 100,
        position: 'fixed',
        top: 200,
        left: 'calc((100vw / 2) - 74px)',
        zIndex: 2000,
        backgroundColor: '#fff',
        marginLeft: '18px'
    },
    buttonLeft: {
        color: '#6200ee',
        marginRight: 110
    },
    buttonRight: {
        color: '#6200ee',
        margin: '0px 16px 0px 0px'
    },
    head: {
        textAlign: 'left',
        fontWeight: 'bold',
        color: 'rgba(0,0,0,.87)',
        borderRadius: '5px',
        marginLeft: 18
    },
    label: {
        textAlign: 'left',
        marginLeft: 18
    }
});

type Props = WithStyles<typeof styles> & {
    head?: string
    label: string
    onConfirm: () => void
    onCancel: () => void
}

class ConfirmDialogComponent extends Component<Props> {
    private readonly submit = () => {
        this.props.onConfirm();
    }

    private readonly abort = () => {
        this.props.onCancel();
    }

    render() {
        return (
            <SnackbarContent
                className={this.props.classes.container}
                message={
                    <>
                        <p className={this.props.classes.head}>
                            {this.props.head}
                        </p>
                        <p className={this.props.classes.label}>
                            {this.props.label}
                        </p>
                    </>
                }
                action={
                    <React.Fragment>
                        <Button size='small' onClick={this.abort} className={this.props.classes.buttonLeft}>Abbrechen</Button>
                        <Button size='small' onClick={this.submit} className={this.props.classes.buttonRight}>Ok</Button>
                    </React.Fragment>
                }
            />
        );
    }
}

export const ConfirmDialog = withStyles(styles)(ConfirmDialogComponent);
