import qs from 'qs';

export class Query {
    getQuerySettings(location) {
        return qs.parse(location.search, { ignoreQueryPrefix: true });
    }

    setQuerySettings(location, history, settings) {
        // the option encodeValuesOnly from qs does not support encoding the special char "&" inside keys of a dictionary
        // -> encode manually
        // e.g. for N&U and Gesundheit & Wissenschaft
        const departments = settings?.departments;
        Object.entries(departments || {}).forEach(([key, value]) => {
            if (key.includes('&') && value !== undefined) {
                departments[encodeURIComponent(key)] = value;
                delete departments[key];
            }
        });
        history.replace({
            pathname: location.pathname,
            search: qs.stringify(settings, { encodeValuesOnly: true })
        });
    }

    removeQuerySetting(settings, key) {
        settings[key] = undefined;
    }
}
