/**
 * Scale categories as defined here https://as-wiki.axelspringer.de/display/ST/Typography
 */
export const LATO_FONT_FAMILY = 'Lato, sans-serif';

export const HEADLINE_3 = {
    textTransform: 'capitalize',
    fontFamily: LATO_FONT_FAMILY,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '19.1px'
};

export const SUBTITLE_1 = {
    textTransform: 'capitalize',
    fontFamily: LATO_FONT_FAMILY,
    fontWeight: '500',
    fontSize: 16,
    lineHeight: '19.1px',
    color: 'rgba(0, 0, 0, 0.6)'
};

export const SUBTITLE_2 = {
    fontFamily: LATO_FONT_FAMILY,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: '17.1px',
    color: 'rgba(0, 0, 0, 0.8)'
};

export const CAPTION = {
    fontFamily: LATO_FONT_FAMILY,
    fontWeight: 500,
    fontSize: 12,
    textTransform: 'capitalize'
};

export const BODY_1 = {
    fontFamily: LATO_FONT_FAMILY,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20.6px',
    textTransform: 'capitalize',
    color: 'rgba(0, 0, 0, 0.8)'
};

export const BODY_2 = {
    fontFamily: LATO_FONT_FAMILY,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '18.6px',
    color: 'rgba(0, 0, 0, 0.8)'
};
